import { action, computed, makeObservable, observable } from 'mobx';
import { css, SerializedStyles } from '@emotion/react';
import getCardCornersCssClass from '@app/components/corners/CornerCssGenerator';
import { WhiteWarningSvgString } from '@app/components/WhiteWarning';
import { Col, Form, Row } from 'antd';
import React, { ReactElement } from 'react';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import { BasicTheme } from '@app/domain/theme';

export default abstract class ThemeStore<TTheme extends BasicTheme = BasicTheme> {
  @observable private _theme: TTheme;

  protected constructor(theme: TTheme) {
    makeObservable(this);
    this._theme = theme;
  }

  get theme(): TTheme {
    return this._theme;
  }

  @action
  setTheme(value: TTheme): void {
    this._theme = value;
  }

  init(): void {
    setDefaultProps(Form.Item, {
      colon: false,
      required: false,
    });

    setDefaultProps(Row, {
      gutter: 30,
    });

    const RowRenderer = Row.prototype.render;

    Row.prototype.render = function render(...args): ReactElement {
      if (React.Children.count(this.props.children) === 1) {
        // noinspection JSConstantReassignment
        this.props = { ...this.props, children: <Col>{this.props.children}</Col> };
      }

      return RowRenderer.apply(this, args);
    };

    setDefaultProps(ReactPlaceholder, {
      showLoadingAnimation: true,
    });
  }

  @computed
  get globalStyles(): SerializedStyles {
    return globalStyles;
  }

  abstract get modeStyles(): SerializedStyles;
}

function setDefaultProps(Component, props): void {
  // eslint-disable-next-line no-param-reassign
  Component.prototype.constructor.defaultProps = {
    ...Component.prototype.constructor.defaultProps,
    ...props,
  };
}

export function initTheme(): void {}

const dataUri = `url("data:image/svg+xml,${WhiteWarningSvgString}")`;
const globalStyles = css`
  body,
  html body {
    background-color: var(--root-background-color);
    font-size: 15px;
    font-family: var(--title-font-family);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 100%;

    // relevant for Firefox only
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
  }

  :root {
    // Deprecated Colors
    --black-strong: rgba(0, 0, 0, 0.87);
    --black-weak: rgba(0, 0, 0, 0.6);
    --alert-color: #f75757;
    --alert-strong-color: #f5222d;
    --gray-semi-transparent: rgba(0, 0, 0, 0.54);
    --transparent-black-50-deprecated: #767c8b;
    --gray-blue-deprecated: #3d445a;
    --light-slate-grey-deprecated: #7986cb;

    // Color we didn't ask Royi about
    --scrollbar-thumb-color: rgba(0, 0, 0, 0.2);

    // nsKnox design system
    --primary-200: #142361;
    --primary-300: #1d378c;
    --primary-400: #2355b8;
    --primary-500: #283d84;
    --primary-600: #5688eb;
    --primary-700: #89bbff;
    --primary-200-50-a-without-transperanc: #f3f4f7;
    --primary-200-100-a-without-transperanc: #f9f9fa;
    --primary-200-150-a: rgba(20, 35, 97, 0.15);
    --primary-200-100-a: rgba(20, 35, 97, 0.1);
    --primary-200-50-a: rgba(20, 35, 97, 0.05);
    --root-background-color: rgba(20, 35, 97, 0.05);
    --white-1000-10-a: rgba(255, 255, 255, 0.1);
    --accent-blue-200: #142361;
    --accent-blue-300: #026288;
    --accent-blue-400: #0287ba;
    --accent-blue-500: #00a3da;
    --accent-blue-600: #03adef;
    --accent-purple-600: #b243ea;
    --accent-purple-900: #dabce9;
    --accent-orange-400: #f78c21;
    --accent-pink-600: #ea43d9;
    --accent-green-600: #50e381;
    --accent-green-300: #098225;
    --accent-yellow-600: #bec91a;
    --solid-black: #191919;
    --transparent-black-900: rgba(0, 0, 0, 0.87);
    --transparent-black-800: rgba(0, 0, 0, 0.77);
    --transparent-black-700: rgba(0, 0, 0, 0.67);
    --transparent-black-600: rgba(0, 0, 0, 0.6);
    --transparent-black-400: rgba(0, 0, 0, 0.38);
    --transparent-black-200: rgba(0, 0, 0, 0.15);
    --transparent-black-100: rgba(0, 0, 0, 0.07);
    --gray-solid-200: #e4e4e4;
    --transparent-gray-800: rgba(61, 68, 90, 0.8);
    --transparent-gray-150: rgba(61, 68, 90, 0.15);
    --slate-gray-700: #3d455a;
    --slate-gray-300: #8d9dba;
    --white: #ffffff;
    --success-300: #396b49;
    --success-500: #4dc749;
    --error-600: #ed524a;
    --error-100: #ffebea;
    --inner-card-background-color: #f9f9fa;
    --inner-card-background-color-blue: #f6fcff;
    --inner-card-background-color-grey: #f5f6f9;
    --warning-color: #ffca28;

    --disabled-background: #f4f4f4;
    --disabled-text: #909090cc;

    // Our technical css variables
    --box-shadow-1: 0 2px 2px rgba(0, 0, 0, 0.2);
    --box-shadow-1-dark: 0 2px 2px rgba(0, 0, 0, 0.6);
    --box-shadow-2: 0 6px 6px rgba(0, 0, 0, 0.4);
    --box-shadow-3: 0 12px 18px rgba(0, 0, 0, 0.4);
    --page-side-padding: 46px;
  }

  #root {
    height: 100vh;
  }

  a {
    text-decoration: underline !important;

    &:hover {
      color: var(--action-color, var(--accent-blue-600));
    }
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    background-color: var(--scrollbar-thumb-color);
    border-radius: 2px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    width: 10px;
  }

  ::-webkit-scrollbar:hover {
    background-color: transparent;
  }

  .ant-input,
  .ant-input:focus,
  .ant-input:hover,
  .ant-select-selection {
    border-top-width: 0 !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
  }

  .ant-select-selection__rendered,
  .ant-select-selection__placeholder {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-select-arrow {
    right: 0 !important;
  }

  .ant-form-explain,
  .ant-form-extra {
    margin-top: 0 !important;
    margin-left: 12px;
    white-space: pre-wrap;
  }

  .ant-form-item-label label {
    color: #0a3039 !important;
  }

  .ant-form-item {
    margin-bottom: 25px;

    &.ant-form-item-with-help {
      margin-bottom: 5px;
    }

    & .ant-form-explain {
      min-height: 21px;
    }
  }

  // Change modal to be like our new design

  .ant-btn {
    &:focus-visible {
      outline: solid black 3px !important;
      outline-offset: 2px;
    }
  }

  .ant-modal-content {
    background-color: white;
  }

  .ant-modal-confirm-btns > :not(.ant-btn-primary) > span {
    position: relative !important;
    z-index: 100;
  }

  .ant-modal-confirm-btns > .ant-btn {
    background: #c4c8d7;

    width: 100%;

    color: var(--primary-200) !important;

    font-weight: bold;
    font-size: 14.2px;
    letter-spacing: 0.8px;
    padding-top: 8px;
    padding-bottom: 4px;
    height: auto;

    border: solid 2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px;
  }

  .ant-modal-confirm-btns > .ant-btn::before {
    display: inline-block;

    opacity: 1 !important;
    position: absolute;
    background-color: white;
  }

  .ant-modal-confirm-btns > .ant-btn-primary,
  .ant-btn-accent {
    color: white !important;

    // Deactivate any affects the .ant-btn::before applied
    &::before {
      clip-path: none;
      opacity: 0 !important;
      background-color: transparent;
    }
  }

  .ant-modal-confirm-btns > .ant-btn-primary {
    background-color: var(--primary-200) !important;
    border-color: var(--primary-200) !important;

    &:hover {
      background-color: #0f4c8c !important;
      border-color: #0f4c8c !important;
    }

    &:active {
      background-color: #0976b6 !important;
      border-color: #0976b6 !important;
    }
  }

  .ant-modal-confirm-btns > .ant-btn-accent {
    background-color: var(--accent-blue-600) !important;
    border-color: var(--accent-blue-600) !important;

    &:hover {
      background-color: #4fc5f3 !important;
      border-color: #4fc5f3 !important;
    }

    &:active {
      background-color: #81d6f7 !important;
      border-color: #81d6f7 !important;
    }
  }

  .ant-modal-confirm-btns > .ant-btn-sm {
    width: auto !important;
    margin-left: auto;
    margin-right: auto;
    padding: 9px 28px;
    height: auto;
  }

  .ant-modal-body {
    padding: 16px 24px !important;

    .ant-modal-confirm-content {
      margin-top: 0 !important;
      font-size: 15px;
      letter-spacing: 0.1px;
      line-height: 1.6;
      color: var(--transparent-black-700);
    }

    .ant-modal-confirm-title {
      font-size: 20px;
      letter-spacing: 0.27px;
      color: var(--black-strong);
      margin-bottom: 8px;
    }
  }

  .ant-modal-confirm-btns {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .ant-modal {
    padding-bottom: 0 !important;
    box-shadow: var(--box-shadow-3);
  }

  .warning {
    .ant-modal-content::before {
      content: ${dataUri};
      display: flex;
      width: 100%;
      height: 120px;
      background-color: #f2833f;
      align-items: center;
      justify-content: center;
    }
  }

  .custom-content {
    &.ant-modal {
      box-shadow: unset;
    }

    .ant-modal-content {
      clip-path: unset;
      background-color: transparent !important;
      box-shadow: unset;
    }

    .ant-modal-confirm-btns,
    .ant-modal-confirm-title {
      display: none !important;
    }

    .ant-modal-body {
      padding: 0 !important;
    }
  }

  .content-only {
    .ant-modal-confirm-btns,
    .ant-modal-confirm-title {
      display: none !important;
    }

    .ant-modal-body {
      padding: 0 !important;
    }
  }

  .info-modal {
    text-align: center !important;
    width: 280px !important;
  }

  .card {
    .ant-modal-content {
      border-radius: 8px;
    }

    .ant-modal-confirm-btns {
      display: none;
    }

    .ant-modal-body {
      padding: 0 !important;
    }
  }

  .ant-table-filter-dropdown-btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    border: none;
    padding: 8px;

    & > *:not(:first-child) {
      margin-right: 8px;
    }

    .ant-table-filter-dropdown-link {
      padding: 0.7em;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;

      &.confirm {
        color: white;
        background-color: #142361;
        border-radius: 4px;
      }

      &.clear {
        color: #142361;
        background-color: white;
        border: 1px #142361;
      }
    }
  }

  .ant-message-notice-content {
    background: transparent;
    border-radius: 0;
    box-shadow: unset;
    line-height: 1;

    ${getCardCornersCssClass('all')}
    box-shadow: var(--box-shadow-3);

    .ant-message-custom-content {
      display: inline-flex;
      align-items: center;
    }
  }

  .ant-form-item-control {
    line-height: normal;
  }
`;
