import React, { FC, ReactNode } from 'react';
import Loadable from '@app/utils/Loadable';
import { Assignee } from '@mortee/domain/validationSystem';
import styled from '@emotion/styled';
import Button from '@app/components/Button';
import AssigneeWithInitials from '@mortee/components/AssigneeWithInitials';
import { CircularProgress } from '@mui/material';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormSearchInput from '@app/components/NakedFormSearchInput';
import Card from '@app/components/card/Card';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';

interface Props {
  assignedUser: Assignee | null;
  onChange(assignee: Assignee | undefined): void;
  assignableUsersLoadable: Loadable<Assignee[]>;
  onClose(): void;
  className?: string;
}

const AssigneeSelector: FC<Props> = (props) => {
  const { assignedUser, onChange, onClose, assignableUsersLoadable, className } = props;

  function handleAssign(assignee: Assignee): void {
    onChange(assignee);
    onClose();
  }

  const [filteredResultsLoadable, search, setSearch] = useLoadableSearchFilter(assignableUsersLoadable, {
    partialMatches: (item) => [item.name],
  });

  return (
    <Container className={className} shadowType='elevated'>
      {assignedUser && <StyledAssigneeButton assignee={assignedUser} onAssign={handleAssign} />}
      <StyledAssigneeButton assignee={null} onAssign={handleAssign} />
      <Separator />
      {filteredResultsLoadable.resolve<ReactNode>(
        (filteredAssignees) => {
          return (
            <ClickEventPropagationBlocker>
              <SearchInputBox appearance='corners'>
                <NakedFormSearchInput
                  name='inpt-assignee-selector-search'
                  type='text'
                  placeholder='Search'
                  placeholderStyle='onlyWhenEmpty'
                  heightType='ultra-thin'
                  clearable
                  value={search}
                  onChange={(newValue): void => setSearch(newValue ?? '')}
                />
              </SearchInputBox>
              {filteredAssignees.map((assignee) => (
                <StyledAssigneeButton key={assignee.id} assignee={assignee} onAssign={handleAssign} />
              ))}
            </ClickEventPropagationBlocker>
          );
        },
        () => (
          <CircularProgress />
        ),
      )}
    </Container>
  );
};

export default AssigneeSelector;

interface AssigneeButtonProps {
  assignee: Assignee | null;
  onAssign(assignee: Assignee | null): void;
  className?: string;
}

const AssigneeButton: FC<AssigneeButtonProps> = (props) => {
  const { assignee, onAssign, className } = props;
  const assigneeId = assignee?.id ?? null;

  return (
    <ClickEventPropagationBlocker>
      <Button
        id={`btn-assign-assignee-${assigneeId}`}
        appearance='text'
        cornerType='none'
        colorScheme='grey'
        onClick={(): void => {
          onAssign(assignee);
        }}
        className={className}
      >
        <AssigneeWithInitials assignee={assignee} />
      </Button>
    </ClickEventPropagationBlocker>
  );
};

const Container = styled(Card)`
  --inline-margin: 12px;

  max-height: 500px;
  min-width: 300px;
  overflow-y: auto;
  padding: 6px 0;
  display: grid;
`;

const Separator = styled.hr`
  border-top: solid 1px var(--transparent-black-200);
  margin: 8px var(--inline-margin);
`;

const SearchInputBox = styled(InputBox)`
  margin: var(--inline-margin) 10px;
`;

const StyledAssigneeButton = styled(AssigneeButton)`
  padding-right: var(--inline-margin);
  padding-left: var(--inline-margin);
  width: 100%;
`;
