import { computed, makeObservable, observable } from 'mobx';
import Loadable, { lazyLoadable, LoadableCreator } from '@app/utils/Loadable';
import {
  Assignee,
  CancelReason,
  SupplierValidationRecordStatus,
  transformAssignee,
  transformToCancelReason,
  transformValidationSystemOrganization,
  ValidationSystemOrganization,
} from '@mortee/domain/validationSystem';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  mergeFieldFilters,
  SupplierValidationRecordFieldFilters,
  SupplierValidationRecordQuickFilter,
} from '@mortee/domain/validationRecordTable';
import { arrayWithoutValue, getListWithLeading, wrapValueAsArray } from '@app/utils/arrayUtils';
import UserStore from '@app/stores/UserStore';
import { YesNoFilterOption } from '@app/domain/filters';

import { compare } from '@app/utils/comparatorUtils';

type QuickFilterToFieldFiltersConverter = () => SupplierValidationRecordFieldFilters | undefined;

export default class ValidationSystemStore {
  @observable private _allUsers: Loadable<Assignee[]> = LoadableCreator.notStarted();
  @observable private _allAssignableUsers: Loadable<Assignee[]> = LoadableCreator.notStarted();
  @observable private _organizations: Loadable<ValidationSystemOrganization[]> = LoadableCreator.notStarted();
  @observable private _cancelReasons: Loadable<CancelReason[]> = LoadableCreator.notStarted();
  private readonly _userStore: UserStore;
  private readonly _quickFiltersConverters: Record<SupplierValidationRecordQuickFilter, QuickFilterToFieldFiltersConverter>;

  constructor(userStore: UserStore) {
    this._userStore = userStore;
    this._quickFiltersConverters = {
      [SupplierValidationRecordQuickFilter.onlyMine]: this.createOnlyMineFilter.bind(this),
      [SupplierValidationRecordQuickFilter.notCompleted]: this.createNotCompletedFilter.bind(this),
      [SupplierValidationRecordQuickFilter.unlinkedRecords]: this.createUnlinkedRecordsFilter.bind(this),
      [SupplierValidationRecordQuickFilter.manualFollowUp]: this.createManualFollowUpRecordsFilter.bind(this),
    };
    makeObservable(this);
  }

  @computed
  get allUsers(): Loadable<Assignee[]> {
    return lazyLoadable(
      () => this._allUsers,
      (newValue) => (this._allUsers = newValue),
      async (): Promise<Assignee[]> => {
        const allUsersServerResponse = await validationSystemServices.getAllAssignees();
        const allUsers = allUsersServerResponse.map((user) => transformAssignee(user, this._userStore.user?.id));
        return this.usersWithMeAtStart(allUsers);
      },
    );
  }

  @computed
  get allAssignableUsers(): Loadable<Assignee[]> {
    return lazyLoadable(
      () => this._allAssignableUsers,
      (newValue) => (this._allAssignableUsers = newValue),
      async (): Promise<Assignee[]> => {
        const allAssignableUsersServerResponse = await validationSystemServices.getAllAssignees(true);
        const allAssignableUsers = allAssignableUsersServerResponse.map((user) =>
          transformAssignee(user, this._userStore.user?.id),
        );
        return this.usersWithMeAtStart(allAssignableUsers);
      },
    );
  }

  @computed
  get organizations(): Loadable<ValidationSystemOrganization[]> {
    return lazyLoadable(
      () => this._organizations,
      (newValue) => (this._organizations = newValue),
      async (): Promise<ValidationSystemOrganization[]> => {
        const organizationServerResponses = await validationSystemServices.getAllValidationSystemOrganizations();
        return organizationServerResponses.map(transformValidationSystemOrganization);
      },
    );
  }

  @computed
  get cancelReasons(): Loadable<CancelReason[]> {
    return lazyLoadable(
      () => this._cancelReasons,
      (newValue) => (this._cancelReasons = newValue),
      async (): Promise<CancelReason[]> => {
        const cancelReasonServerResponses = await validationSystemServices.getAllValidationRecordCancelReasons();
        return cancelReasonServerResponses.map(transformToCancelReason);
      },
    );
  }

  createCombinedFilters = (
    tableFieldFilters: SupplierValidationRecordFieldFilters | undefined,
    quickFilters: SupplierValidationRecordQuickFilter[] | undefined,
  ): SupplierValidationRecordFieldFilters => {
    const quickFiltersBrokenDownToFieldFilters = Object.entries(
      this._quickFiltersConverters,
    ).map(([quickFilterType, quickFilterCreator]: [SupplierValidationRecordQuickFilter, QuickFilterToFieldFiltersConverter]) =>
      this.createFilterFromQuickFilter(quickFilters, quickFilterType, quickFilterCreator),
    );

    return mergeFieldFilters(tableFieldFilters, ...quickFiltersBrokenDownToFieldFilters);
  };

  createFilterFromQuickFilter = (
    quickFilters: SupplierValidationRecordQuickFilter[] | undefined,
    currentQuickFilter: SupplierValidationRecordQuickFilter,
    creator: QuickFilterToFieldFiltersConverter,
  ): SupplierValidationRecordFieldFilters | undefined => {
    if (!quickFilters || !quickFilters.includes(currentQuickFilter)) {
      return undefined;
    }

    return creator();
  };

  createOnlyMineFilter = (): SupplierValidationRecordFieldFilters => {
    return {
      assignee: wrapValueAsArray(this._userStore.user?.id),
      status: undefined,
      organizationId: undefined,
      registrationDate: undefined,
      didUserRegister: undefined,
      automailerStatus: undefined,
      followUpEmailStatus: undefined,
      evidenceType: undefined,
      customerInvolvement: undefined,
      instructionType: undefined,
      followUpFilter: undefined,
    };
  };

  createNotCompletedFilter = (): SupplierValidationRecordFieldFilters => {
    return {
      assignee: undefined,
      status: arrayWithoutValue(Object.values(SupplierValidationRecordStatus), SupplierValidationRecordStatus.completed),
      organizationId: undefined,
      registrationDate: undefined,
      didUserRegister: undefined,
      automailerStatus: undefined,
      followUpEmailStatus: undefined,
      evidenceType: undefined,
      customerInvolvement: undefined,
      instructionType: undefined,
      followUpFilter: undefined,
    };
  };

  createUnlinkedRecordsFilter = (): SupplierValidationRecordFieldFilters => {
    return {
      assignee: undefined,
      status: undefined,
      organizationId: undefined,
      registrationDate: undefined,
      didUserRegister: false,
      automailerStatus: undefined,
      followUpEmailStatus: undefined,
      evidenceType: undefined,
      customerInvolvement: undefined,
      instructionType: undefined,
      followUpFilter: undefined,
    };
  };

  createManualFollowUpRecordsFilter = (): SupplierValidationRecordFieldFilters => {
    return {
      assignee: undefined,
      status: [
        SupplierValidationRecordStatus.inProgress,
        SupplierValidationRecordStatus.clarification,
        SupplierValidationRecordStatus.updateCompleted,
        SupplierValidationRecordStatus.completedWithNoEmails,
      ],
      organizationId: undefined,
      registrationDate: undefined,
      didUserRegister: undefined,
      automailerStatus: undefined,
      followUpEmailStatus: undefined,
      evidenceType: undefined,
      customerInvolvement: YesNoFilterOption.no,
      instructionType: undefined,
      followUpFilter: true,
    };
  };

  private usersWithMeAtStart = (users: Assignee[]): Assignee[] => {
    const meAsAssignee = users?.find((user) => user.isMe);
    const sortedUsersListWithInactiveUsersLast = users?.sort(
      compare.byField((user: Assignee) => user.isAssignable, compare.booleans()).then(compare.byStringField((user) => user.name)),
    );
    return getListWithLeading(sortedUsersListWithInactiveUsersLast, meAsAssignee);
  };
}
