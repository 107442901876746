import config from '@app/config';
import request, { HttpMethod, HttpStatus } from '@app/libs/request';
import {
  AuthCodeDeliveryMethod,
  AuthMethodType,
  InitiatePhoneLoginResponse,
  KnoxerAuthConfigData,
} from '@app/login/domain/loginConsts';

interface OpenIdTokenResponse {
  idToken: string;
}

export function doesHaveKnoxerAuthConfig(knoxerId: string, authMethodType: AuthMethodType): boolean {
  return !!getNullableKnoxerAuthConfig(knoxerId, authMethodType);
}

export function getKnoxerAuthConfig(knoxerId: string, authMethodType: AuthMethodType): DeepReadonly<KnoxerAuthConfigData> {
  const nullableKnoxerAuthConfig = getNullableKnoxerAuthConfig(knoxerId, authMethodType);

  if (!nullableKnoxerAuthConfig) {
    throw new Error(`could not find auth properties with auth method type ${authMethodType}`);
  }

  return nullableKnoxerAuthConfig;
}

export function getNullableKnoxerAuthConfig(
  knoxerId: string,
  authMethodType: AuthMethodType,
): DeepReadonly<KnoxerAuthConfigData> | null {
  const knoxersAuthConfig = config.knoxersAuthData;

  if (!knoxersAuthConfig) {
    throw new Error('could not find knoxers config');
  }

  const knoxerAuthConfig = knoxersAuthConfig.find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId);

  if (!knoxerAuthConfig) {
    throw new Error(`could not find knoxer with id ${knoxerId}`);
  }

  if (!knoxerAuthConfig.authMethods.includes(authMethodType)) {
    return null;
  }

  return knoxerAuthConfig;
}

export function getPhoneAuthPath(deliveryMethod: AuthCodeDeliveryMethod): string | undefined {
  if (deliveryMethod === AuthCodeDeliveryMethod.TextMessage) {
    return '/mobile-tokener-user-sms';
  }

  if (deliveryMethod === AuthCodeDeliveryMethod.PhoneCall) {
    return '/mobile-tokener-user-call';
  }
}

export async function logout(): Promise<any> {
  return request(config.serverUrls.logout, null, {
    method: HttpMethod.post,
  });
}

export async function generateTokenWithUsernamePassword(
  knoxerId: string,
  username: string,
  password: string,
): Promise<OpenIdTokenResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);

  return request(knoxerAuthConfig.url, `/mobile-tokener-user-basic/auth/token`, {
    method: HttpMethod.post,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
      [HttpStatus.unauthorized]: {
        message: 'Wrong username or password',
      },
    },
    suppressNotification: true,
    data: { username, password },
  });
}

export async function generateTokenWithPhoneAndCode(
  knoxerId: string,
  flowId: string,
  id: string,
  code: string,
  deliveryMethod: AuthCodeDeliveryMethod,
): Promise<OpenIdTokenResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.PhoneNumber);
  const phoneAuthPath = getPhoneAuthPath(deliveryMethod);

  return request(knoxerAuthConfig.url, phoneAuthPath + `/auth/code/token`, {
    method: HttpMethod.post,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
      [HttpStatus.unauthorized]: {
        message: 'Wrong phone or code',
      },
    },
    suppressNotification: true,
    data: { flowId, id, code },
  });
}

export interface ClaimHandleRequestData {
  secretId: string;
  secret: string;
}

export async function claimHandleForKnoxer(
  knoxerId: string,
  handle: string,
  claimData: ClaimHandleRequestData,
  authMethodType: AuthMethodType,
  knoxerIdToken?: string,
): Promise<void> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, authMethodType);
  const authorizationHeader: Record<string, string> = knoxerIdToken ? { Authorization: `Bearer ${knoxerIdToken}` } : {};
  return await request<void>(knoxerAuthConfig.url, `/mobile-userservice/api/login/sessions/:handle`, {
    method: HttpMethod.post,
    pathParams: { handle },
    headers: {
      ...authorizationHeader,
    },
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: `Unexpected error claiming handle ${handle} for knoxer `,
      },
    },
    data: claimData,
  });
}

export async function phoneLoginSendCodeToPhone(
  knoxerId: string,
  id: string,
  deliveryMethod: AuthCodeDeliveryMethod,
  quiet: boolean,
): Promise<InitiatePhoneLoginResponse> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.PhoneNumber);
  const phoneAuthPath = getPhoneAuthPath(deliveryMethod);

  return request<InitiatePhoneLoginResponse>(knoxerAuthConfig.url, phoneAuthPath + `/auth/code/request`, {
    method: HttpMethod.post,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: quiet,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to initiate phone login process',
      },
    },
    data: {
      id,
    },
  });
}

export interface ForgotPasswordFlowRequest {
  flowId: string;
  codeTTLMinutes: number;
  codeLength: number;
}

export async function forgotPasswordEnterEmail(knoxerId: string, email: string): Promise<ForgotPasswordFlowRequest> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);

  return request<ForgotPasswordFlowRequest>(
    knoxerAuthConfig.url,
    `/mobile-email-password-mgmt-user/users/me/password/forgot/request`,
    {
      method: HttpMethod.post,
      generateDynamicHeaders: false,
      dontRedirectToLogin: true,
      errorsHandler: {
        default: {
          message: 'Unexpected error occurred while trying to initiate forgot password process',
        },
      },
      data: { email },
    },
  );
}

export async function forgotPasswordEnterCode(knoxerId: string, flowId: string, email: string, code: string): Promise<any> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);
  return request<any>(knoxerAuthConfig.url, `/mobile-email-password-mgmt-user/users/me/password/forgot/code`, {
    method: HttpMethod.post,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to enter email verification code',
      },
    },
    suppressNotification: true,
    data: { flowId, email, code },
  });
}

export async function forgotPasswordResetPassword(
  knoxerId: string,
  flowId: string,
  email: string,
  newPassword: string,
): Promise<any> {
  const knoxerAuthConfig = getKnoxerAuthConfig(knoxerId, AuthMethodType.EmailPassword);
  return request<any>(knoxerAuthConfig.url, `/mobile-email-password-mgmt-user/users/me/password/forgot/reset`, {
    method: HttpMethod.post,
    suppressNotification: true,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to apply new password',
      },
    },
    data: { flowId, email, newPassword },
  });
}
