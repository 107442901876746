import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { IsraelBankCodes } from '@app/domain/bankCodes';
import { StyledComponents } from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/styles';

const IsraelBankCodeDropdown = forwardRef<
  HTMLDivElement,
  {
    name: string;
    placeholder: string;
    value?: string;
    onChange?: (value: string) => void;
  }
>(({ name, placeholder, value, onChange }, ref) => {
  const { t } = useTranslation();
  const { CountryDropdownItem } = StyledComponents;

  return (
    <NakedDropdown
      ref={ref}
      value={value}
      onChange={onChange}
      accessibilityLabel={t('general.accessibility.country')}
      dataTestId={name}
      name={name}
      placeholder={t<string>(placeholder)}
      isSearchable
    >
      {IsraelBankCodes.sort((a, b) => a.code - b.code).map((bankCode) => (
        <CountryDropdownItem
          key={bankCode.code}
          keywords={[bankCode.code, bankCode.name]}
          value={bankCode.code}
          textWhenSelected={`${bankCode.code} - ${bankCode.name}`}
        >
          <div>{`${bankCode.code} - ${bankCode.name}`}</div>
        </CountryDropdownItem>
      ))}
    </NakedDropdown>
  );
});

IsraelBankCodeDropdown.displayName = 'IsraelBankCodeDropdown';

export default IsraelBankCodeDropdown;
