import React, { FC, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { DisplayedPayeesTotal } from '@mortee/domain/masterDataGuard';
import Loadable, { LoadingState } from '@app/utils/Loadable';
import { CircularProgress } from '@mui/material';
import { pluralize } from '@app/utils/stringUtils';
import LivePrivatePayeesFiltersContext from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import { identityFunc, isTruthy } from '@app/utils/utils';

interface Props {
  info: Loadable<DisplayedPayeesTotal>;
  className?: string;
}

const DisplayedPayeesTotalNumbers: FC<Props> = ({ info, className }) => {
  const { filters, filtersApplied, isAnyFiltersApplied } = useContext(LivePrivatePayeesFiltersContext);

  const calcLoaded = (privateArea: DisplayedPayeesTotal): ReactNode => {
    const { searchQuery: searchQueryApplied, ...otherFilterApplied } = filtersApplied;

    if (isAnyFiltersApplied) {
      return (
        <>
          <Number>{privateArea.payeesAmount}</Number>&nbsp;
          {pluralize('vendor', privateArea.payeesAmount)} for{' '}
          {[
            filters.searchQuery && `"${filters.searchQuery}"`,
            Object.values(otherFilterApplied).some(identityFunc) && 'applied filters',
          ]
            .filter(isTruthy)
            .join(' and ')}
        </>
      );
    }

    const vendorAmount = (
      <>
        <Number>{privateArea.payeesAmount}</Number>&nbsp;{pluralize('vendor', privateArea.payeesAmount)}
      </>
    );

    const accountAmount = (
      <>
        <Number>{privateArea.payeeAccountsAmounts.all}</Number>&nbsp;{pluralize('account', privateArea.payeeAccountsAmounts.all)}
      </>
    );

    switch (privateArea.payeeAccountsAmounts.notValidated.loadState) {
      case LoadingState.Rejected:
      case LoadingState.NotStarted: {
        return (
          <>
            {vendorAmount}&nbsp;|&nbsp;{accountAmount}
          </>
        );
      }
      case LoadingState.InProgress: {
        return (
          <>
            {vendorAmount}&nbsp;|&nbsp;{accountAmount}&nbsp;|&nbsp;
            <CircularProgress color='secondary' size={16} />
            &nbsp;Retrieving 'Not Validated' accounts…
          </>
        );
      }
      case LoadingState.Resolved: {
        return (
          <>
            {vendorAmount}&nbsp;|&nbsp;
            <Number>{privateArea.payeeAccountsAmounts.notValidated.result}</Number>&nbsp;'Not Validated'{' '}
            {pluralize('account', privateArea.payeeAccountsAmounts.notValidated.result)} out of&nbsp;
            {accountAmount}
          </>
        );
      }
    }
  };

  const content: ReactNode = info.resolve(
    calcLoaded,
    () => (
      <>
        <CircularProgress color='secondary' size={16} />
        &nbsp;Loading vendors…
      </>
    ),
    () => <></>,
  );

  return <Container className={className}>{content}</Container>;
};

export default DisplayedPayeesTotalNumbers;

const Container = styled.span`
  display: flex;
  align-items: baseline;

  font-size: 13px;
  letter-spacing: 0.36px;
  color: var(--black-weak);
`;

const Number = styled.span`
  color: var(--primary-500);
  font-weight: bold;
`;
