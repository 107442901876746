import { translateExpectedPaymentsExceedThresholdAnswer } from '@app/domain/commonSupplierRegistration';
import { getCountryName } from '@app/domain/countries';
import { formatInternationalPhoneNumberForDisplay } from '@app/utils/phoneUtils';
import copyToClipboard from 'copy-to-clipboard';
import * as messageLauncher from '@app/utils/messageLauncher';
import { shoot } from '@app/utils/messageLauncher';
import LocaleStore from '@app/stores/LocaleStore';
import { getInstructionType } from '@mortee/domain/validationSystemInfoComponents';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import { isEntryValueTruthy } from '@app/utils/utils';
import { getSupplierRegistrationItemCrowdKnowledgeReportCsv } from '@mortee/services/supplierRegistrationManagementServices';
import { FileContentTypes } from '@app/domain/files';
import FileSaver from 'file-saver';
import sleep from '@app/utils/sleep';
import Log from '@app/libs/logger';

export function copyAllRegistrationFormData(
  supplierRegistrationProcess: SupplierRegistrationProcess,
  localeStore: LocaleStore,
): void {
  const values: (string | null)[] = [
    supplierRegistrationProcess.registrationNumber,
    localeStore.formatDateTime(supplierRegistrationProcess.writeTimestamp),
    supplierRegistrationProcess.referringCustomer,
    getInstructionType(supplierRegistrationProcess.instructionType)?.name ?? null,
    supplierRegistrationProcess.companyName,
    supplierRegistrationProcess.legalIdType,
    supplierRegistrationProcess.legalId,
    supplierRegistrationProcess.address.fullAddress,
    supplierRegistrationProcess.address.addressDetails?.formattedAddress ?? null,
    getCountryName(supplierRegistrationProcess.countryCode, ''),
    supplierRegistrationProcess.asyncFullName,
    supplierRegistrationProcess.firstName,
    supplierRegistrationProcess.lastName,
    formatInternationalPhoneNumberForDisplay(supplierRegistrationProcess.phoneNumber),
    supplierRegistrationProcess.email,
    supplierRegistrationProcess.customerIp,
    supplierRegistrationProcess.companyWebsite,
    supplierRegistrationProcess.expectedPaymentsExceedThreshold
      ? translateExpectedPaymentsExceedThresholdAnswer(supplierRegistrationProcess.expectedPaymentsExceedThreshold)
      : 'Not entered',
    supplierRegistrationProcess.accountDetails.swiftCode,
    supplierRegistrationProcess.accountDetails.bankCode,
    supplierRegistrationProcess.accountDetails.accountNumber,
    getCountryName(supplierRegistrationProcess.accountBankCountryCode, ''),
    supplierRegistrationProcess.furtherCredit,
    supplierRegistrationProcess.currency ?? '',
  ];

  const line = values.map((value) => value || '').join('\t');

  copyToClipboard(line);
  messageLauncher.shootSuccessOld(`all values has been copied to clipboard`, 5);
}

export function copyWiRegistrationFormData(supplierRegistrationProcess: SupplierRegistrationProcess): void {
  const keysAndValues = {
    'Beneficiary Name': supplierRegistrationProcess.companyName,
    'Beneficiary Name (English)': supplierRegistrationProcess.additionalCompanyName,
    'Registration Number': supplierRegistrationProcess.registrationNumber,
    'Tax ID Value': supplierRegistrationProcess.legalId,
    'Full Address':
      supplierRegistrationProcess.address.fullAddress || supplierRegistrationProcess.address.addressDetails?.formattedAddress,
    Address: supplierRegistrationProcess.address.addressDetails?.address,
    'Address City': supplierRegistrationProcess.address.addressDetails?.city,
    'Address State': supplierRegistrationProcess.address.addressDetails?.state,
    'Address Zip Code': supplierRegistrationProcess.address.addressDetails?.zipCode,
    'Address Country': getCountryName(supplierRegistrationProcess.address.addressDetails?.countryCode),
    Country: getCountryName(supplierRegistrationProcess.countryCode, ''),
    Swift: supplierRegistrationProcess.accountDetails.swiftCode,
    'Bank/Routing/Sort/Transit': supplierRegistrationProcess.accountDetails.bankCode,
    'Branch Code': supplierRegistrationProcess.accountDetails.branchCode,
    Account: supplierRegistrationProcess.accountDetails.accountNumber,
    'Fedwire ABA': supplierRegistrationProcess.abaRouting,
    'Can Use ACH': supplierRegistrationProcess.canUseAch,
    'Can Use EFT': supplierRegistrationProcess.canUseEft,
    'Account Bank Country': getCountryName(supplierRegistrationProcess.accountBankCountryCode, ''),
    'Further Credit': supplierRegistrationProcess.furtherCredit,
    Currency: supplierRegistrationProcess.currency,
    'Phone Number': formatInternationalPhoneNumberForDisplay(supplierRegistrationProcess.phoneNumber),
    'Account Type': supplierRegistrationProcess.accountType,
  };

  const line = Object.entries(keysAndValues)
    .filter(isEntryValueTruthy)
    .map(([key, value]) => `${key}: ${value || ''}`)
    .join('\n');

  copyToClipboard(line);
  messageLauncher.shootSuccessOld(`wiring instructions has been copied to clipboard`, 5);
}

export async function downloadCrowdKnowledgeReportCsv(supplierRegistrationProcess: SupplierRegistrationProcess): Promise<void> {
  const closePopUpMessage = shoot({ type: 'info', closeable: true }, 'Downloading Crowd Knowledge Candidates');

  try {
    const csv = await getSupplierRegistrationItemCrowdKnowledgeReportCsv(supplierRegistrationProcess.id);

    const csvBlob = new Blob([csv], { type: FileContentTypes.TEXT_CSV });

    FileSaver.saveAs(csvBlob, `CK candidates - ${supplierRegistrationProcess.id}.csv`);

    closePopUpMessage();

    await sleep(300);

    shoot({ type: 'success', duration: 5, closeable: true }, 'Downloaded');
  } catch (e) {
    closePopUpMessage();
    Log.exception(e);
  }
}
