import { SupplierRegistrationCompanyInfoFormFields } from '@supplierRegistration/domain/supplierRegistration';
import {
  getLegalIdTypesOfCountryForIndividual,
  AllLegalIdTypeForCompany,
} from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import { trimToNull } from '@app/utils/stringUtils';

export function calculateLegalIdType(
  companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
  isIndividual: boolean,
): string | null {
  if (isIndividual) {
    return trimToNull(getLegalIdTypesOfCountryForIndividual(companyInfoValues.country.trim()));
  }

  if (companyInfoValues.leiType !== AllLegalIdTypeForCompany.Other) {
    return trimToNull(companyInfoValues.leiType);
  }

  return trimToNull(companyInfoValues.leiTypeOtherValue);
}
