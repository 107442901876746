import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { COUNTRY_CODES } from '@app/domain/countries';
import { isTruthy } from '@app/utils/utils';
import IsraelBankCodeDropdown from '@supplierRegistration/components/IsraelBankCodeDropdown';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import {
  getCountrySupplierRegistrationBankCodeField,
  SupplierRegistrationField,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { BaseFormFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  bankCodeField: SupplierRegistrationField;
  accountCountryCode: string | undefined;
  fieldDecorators: any; // Replace with proper type
  asyncInitiateValidationMaskedBankCodeAndBranchCode: {
    bankCodeState: string;
    isBankCodeMayContainBranchCode: boolean;
  };
  hasPrefilledData: boolean;
  useDefaultBankCode: boolean;
  form: WrappedFormUtils<BaseFormFields>;
}

export const BankAccountDomesticField = ({
  bankCodeField,
  accountCountryCode,
  fieldDecorators,
  asyncInitiateValidationMaskedBankCodeAndBranchCode,
  hasPrefilledData,
  useDefaultBankCode,
  form,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  if (asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'hidden') {
    return null;
  }

  if (asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'disabled') {
    bankCodeField = getCountrySupplierRegistrationBankCodeField(form);
  }

  if (accountCountryCode === COUNTRY_CODES.Israel) {
    return (
      <SupplierRegistrationFormItemBox
        fieldName='bankCode'
        fieldDecoratorOptions={fieldDecorators.bankCode}
        hasPrefilledData={hasPrefilledData}
      >
        <IsraelBankCodeDropdown
          name='drp-supp-reg-account-bank-Code'
          placeholder={`supplierValidation.supplierRegister.bankCode`}
        />
      </SupplierRegistrationFormItemBox>
    );
  }

  return (
    <SupplierRegistrationFormItemBox
      fieldName='bankCode'
      fieldDecoratorOptions={fieldDecorators.bankCode}
      hasPrefilledData={asyncInitiateValidationMaskedBankCodeAndBranchCode.bankCodeState === 'disabled'}
    >
      <NakedFormInput
        name={bankCodeField?.id}
        dataTestId={bankCodeField?.id}
        type={bankCodeField?.inputType}
        placeholder={t<string>(
          asyncInitiateValidationMaskedBankCodeAndBranchCode.isBankCodeMayContainBranchCode
            ? [
                !useDefaultBankCode && `supplierValidation.supplierRegister.${accountCountryCode}.bankCodeMayContainBranchCode`,
                !useDefaultBankCode && `supplierValidation.supplierRegister.${accountCountryCode}.bankCode`,
                'supplierValidation.supplierRegister.bankCodeMayContainBranchCode',
              ].filter(isTruthy)
            : [
                !useDefaultBankCode && `supplierValidation.supplierRegister.${accountCountryCode}.bankCode`,
                'supplierValidation.supplierRegister.bankCode',
              ].filter(isTruthy),
        )}
        disableSuggestion
      />
    </SupplierRegistrationFormItemBox>
  );
};

export default BankAccountDomesticField;
