import {
  LegalEntityIdentifier,
  LegalEntityIdentifierServerResponse,
  StrongLegalIdentifiesTypes,
} from '@app/domain/legalEntityIdentifier';
import {
  LegalEntityIdTypes,
  LegalIdentifierRequest,
  ValidatedPayeeAccountVisibility,
} from '@mortee/domain/vaildatedPayeeManagement';
import Loadable from '@app/utils/Loadable';
import { isTruthy } from '@app/utils/utils';

export interface ValidatedPayeeServerResponse {
  uniformId: string;
  events: ValidatedPayeeInfoCuratedEventServerResponse[];
  latestSanctionsScreeningEvent: SanctionsScreeningInfoServerResponse | null;
}

export interface SanctionsScreeningInfoServerResponse {
  timestamp: number | null;
  results: SanctionsScreeningResultServerResponse[] | null;
}

export interface SanctionsScreeningResultServerResponse {
  listId: string;
  listName: string;
  result: SanctionsScreeningResultStatus | null;
}

interface ValidatedPayeeInfoCuratedEventServerResponse {
  data: ValidatedPayeeInfoCuratedEventDataServerResponse;
  originatingValidatedPayeeAccountEventId: string | null;
  contributingOrganizationName: string | null;
  contributingOrganizationId: string | null;
  originatingAccountValidationVisibility: ValidatedPayeeAccountVisibility | null;
}

interface ValidatedPayeeInfoCuratedEventDataServerResponse {
  countryCode: string | undefined;
  legalIdentifiers: LegalEntityIdentifierServerResponse[];
  names: string[];
  addresses: string[];
  phones: string[];
  emails: string[];
  faxes: string[];
  websites: string[];
  description: string | undefined;
  primaryFields: ValidatedPayeePrimaryFieldsServerResponse;
}

export interface ValidatedPayee {
  uniformId: string;
  events: ValidatedPayeeInfoCuratedEvent[];
  sanctionsScreeningInfo: SanctionsScreeningInfo | null;
}

export interface SanctionsScreeningInfo {
  timestamp: number | null;
  results: SanctionsScreeningResult[] | null;
}

export interface SanctionsScreeningResult {
  listId: string;
  listName: string;
  result: SanctionsScreeningResultStatus | null;
}

export enum SanctionsScreeningResultStatus {
  Passed = 'Passed',
  Rejected = 'Rejected',
}

export function injectTypeIntoStrongLegalIdRequest<T extends LegalIdentifierRequest>(
  allLEITypes: Loadable<LegalEntityIdTypes>,
  partialLegalIdRequest: T | undefined,
  strongIdType: StrongLegalIdentifiesTypes,
): T | undefined {
  if (!partialLegalIdRequest || !allLEITypes.isResolved()) {
    return;
  }

  const typeId = allLEITypes.result.strongTypes[strongIdType]?.id;

  if (!typeId) {
    return;
  }

  return {
    ...partialLegalIdRequest,
    typeId: typeId,
    value: partialLegalIdRequest.value ?? '',
    countryCode: partialLegalIdRequest.countryCode ?? null,
  };
}

export function convertLeiRequestToLEIWithName(
  allLEITypes: Loadable<LegalEntityIdTypes>,
  legalId: LegalIdentifierRequest | undefined,
): LegalEntityIdentifier | undefined {
  if (!legalId || !allLEITypes.isResolved()) {
    return;
  }

  let typeName: string | undefined = allLEITypes.result.regularTypes.find((typeId) => legalId.typeId === typeId.id)?.name;

  if (!typeName) {
    typeName = Object.values(allLEITypes.result.strongTypes)
      .filter(isTruthy)
      .find((typeId) => legalId.typeId === typeId.id)?.name;
  }

  return {
    countryCode: legalId.countryCode ?? undefined,
    value: legalId.value,
    typeName: typeName,
    typeId: legalId.typeId ?? undefined,
  };
}

export function getSanctionStatusForResults(
  sanctionsScreeningInfo: SanctionsScreeningInfo | null | undefined,
): SanctionsScreeningResultStatus | null {
  if (!sanctionsScreeningInfo?.results?.length) {
    return null;
  }

  if (sanctionsScreeningInfo.results?.every((result) => result.result === SanctionsScreeningResultStatus.Passed)) {
    return SanctionsScreeningResultStatus.Passed;
  }

  return SanctionsScreeningResultStatus.Rejected;
}

export function getTextFromSanctionStatus(status: SanctionsScreeningResultStatus | null): string | null {
  if (!status) {
    return null;
  }

  switch (status) {
    case SanctionsScreeningResultStatus.Passed:
      return 'Screened';
    case SanctionsScreeningResultStatus.Rejected:
    default:
      return 'Potential matches';
  }
}

export function getSanctionStatusFromText(text: string | null): SanctionsScreeningResultStatus | null {
  switch (text) {
    case 'Screened':
      return SanctionsScreeningResultStatus.Passed;
    case 'Potential matches':
      return SanctionsScreeningResultStatus.Rejected;
    default:
      return null;
  }
}

export interface ValidatedPayeeInfoCuratedEvent {
  data: ValidatedPayeeInfoCuratedEventData;
  originatingValidatedPayeeAccountEventId: string | null;
  contributingOrganizationName: string | null;
  contributingOrganizationId: string | null;
  originatingAccountValidationVisibility: ValidatedPayeeAccountVisibility | null;
}

interface ValidatedPayeeInfoCuratedEventData {
  countryCode: string | undefined;
  legalIdentifiers: LegalEntityIdentifier[];
  names: string[];
  addresses: string[];
  phones: string[];
  emails: string[];
  faxes: string[];
  websites: string[];
  description: string | undefined;
  primaryFields: ValidatedPayeePrimaryFields;
}

export interface ValidatedPayeePrimaryFieldsServerResponse {
  name?: string;
  address?: string;
  legalIdentifier?: LegalEntityIdentifierServerResponse;
}

export interface ValidatedPayeePrimaryFields {
  name?: string;
  address?: string;
  legalIdentifier?: LegalEntityIdentifier;
}
