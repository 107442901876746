import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ActionsContainer, PrimaryButton } from '@app/guestLogin/routes/Styles';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import useInfraStores from '@app/hooks/useInfraStores';
import { LoginSubTitle, LoginTitle } from '../GuestLoginFlow';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import GoogleSSOButton from '@app/components/GoogleSSOButton';
import { getKnoxerSSOAddress } from '@app/guestLogin/services/guestLoginService';
import config from '@app/config';
import ArMode from '@ar/arMode';
import useMountEffect from '@app/hooks/useMountEffect';
import { LOGIN_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

interface EnterEmailFormFields {
  email: string;
}

interface Props extends FormComponentProps<EnterEmailFormFields> {
  setEmail: (string?) => void;
  setWaitingForCode: () => void;
  initialEmail?: string;
}

const GuestLoginEnterEmail: FunctionComponent<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.loginEnterEmail);
  const { guestLoginStore } = useInfraStores<ArMode>();
  const { form } = props;
  const { t } = useTranslation();

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,

    setInputErrorText: (errorText: string | null): void => {
      localStore.inputErrorText = errorText;
    },
  }));

  useMountEffect(() => {
    const { validateFields } = form;

    validateFields();
  });

  const fieldDecorators: FormFieldDecorators<EnterEmailFormFields> = {
    email: {
      initialValue: props.initialEmail,
      rules: [
        {
          required: true,
          translatedMessage: 'ar.guestLogin.errors.enterValidEmail',
        },
        {
          max: LOGIN_FIELD_MAX_LENGTH.username,
          translatedMessage: {
            key: 'ar.guestLogin.errors.maxCharacters',
            value: LOGIN_FIELD_MAX_LENGTH.username,
          },
        },
        {
          min: 1,
          translatedMessage: {
            key: 'ar.guestLogin.errors.minCharacters',
            value: LOGIN_FIELD_MAX_LENGTH.username,
          },
        },
        {
          type: 'email',
          translatedMessage: 'ar.guestLogin.errors.invalidEmail',
        },
      ],
    },
  };

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError } = form;
    const fieldsError = getFieldsError();

    const fields = Object.keys(fieldsError);
    return fields.length === 0 || fields.some((field) => fieldsError[field]);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      e?.stopPropagation();
      e?.preventDefault();
      await handleOk();
    }
  };

  const handleOk = (e?: React.FormEvent): void => {
    const { setEmail, setWaitingForCode } = props;
    const { validateFieldsAndScroll } = form;

    e?.stopPropagation();
    e?.preventDefault();

    validateFieldsAndScroll(async (errors: Object, values: EnterEmailFormFields) => {
      if (errors) {
        return;
      }

      const { email } = values;

      const didRequestSucceed = await guestLoginStore.requestCodeFromEmailKnoxer(email.trim());
      if (didRequestSucceed) {
        setEmail(email.trim());
        setWaitingForCode();
      }
    });
  };

  const onBack = (): void => {
    guestLoginStore.setIsUserStartedLogin(false);
  };

  const ssoAddress = guestLoginStore.emailKnoxer?.knoxerId && getKnoxerSSOAddress(guestLoginStore.emailKnoxer?.knoxerId);

  const handleGoogleSsoLogin = (): void => {
    if (!ssoAddress) {
      return;
    }

    const currentAddress = `${location.protocol}//${location.host}`;
    const successUrl = `${currentAddress}?authType=sso`;
    const failureUrl = `${currentAddress}?error=sso`;

    location.assign(`${ssoAddress}/oauth2/authorization/google?${qs.stringify({ successUrl, failureUrl })}`);
  };

  const isFormInvalidEvaluated = isFormInvalid();
  const { shouldDisplayGuestSSO } = config;

  return (
    <>
      <ContentContainer>
        <LoginTitle>{t('ar.guestLogin.emailPassword.emailVerification')}</LoginTitle>
        <LoginSubTitle>{t('ar.guestLogin.emailPassword.enterEmailAddress')}</LoginSubTitle>
        <EmailForm onSubmit={handleOk}>
          <FormItemBox appearance='corners' fieldName='email' fieldDecoratorOptions={fieldDecorators.email} form={form}>
            <NakedFormInput
              name='input-guest-email'
              type='text'
              autoFocus
              placeholder={t<string>('ar.guestLogin.emailPassword.email')}
              onClick={(): void => localStore.setInputErrorText(null)}
              onFocus={(): void => localStore.setInputErrorText(null)}
              onKeyPress={handleKeyPress}
              disableSuggestion
            />
          </FormItemBox>
          <ActionsContainer>
            <Button id='btn-guest-user-back' appearance='text' type='button' size='small' onClick={onBack}>
              {t('general.upperBack')}
            </Button>
            <PrimaryButton id='btn-guest-email-continue' disabled={isFormInvalidEvaluated} onClick={handleOk}>
              {t('general.upperContinue')}
            </PrimaryButton>
          </ActionsContainer>
        </EmailForm>
      </ContentContainer>
      {shouldDisplayGuestSSO && ssoAddress && (
        <GoogleSSOButtonContainer>
          <GoogleSSOButton onClick={handleGoogleSsoLogin} />
        </GoogleSSOButtonContainer>
      )}
    </>
  );
});

export default Form.create<Props>()(GuestLoginEnterEmail);

const GoogleSSOButtonContainer = styled.div`
  border-top: solid 1px var(--transparent-gray-150);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  direction: ltr;
`;

const ContentContainer = styled.div`
  padding: 32px 64px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const EmailForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;
