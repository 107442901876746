import React, { FC, ReactNode, useContext } from 'react';
import styled from '@emotion/styled';
import { FormControlLabel, Radio, RadioProps as AntdRadioProps } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';
import FormInputsContext, { InputGroupState } from './FormInputsContext';
import { preventForwardTheseProps } from '@app/utils/styledUtils';

export interface RadioProps {
  value: string;
  label: ReactNode;
  isChecked: boolean;
  disabled?: boolean;
  id: string;
  dataTestId?: string;
  autoFocus?: boolean;
  textSize?: 'medium' | 'big';
  color?: AntdRadioProps['color'];
  className?: string;
  onChecked?(): void;
}

const FormRadio: FC<RadioProps> = (props) => {
  const { state } = useContext(FormInputsContext);
  const {
    value,
    label,
    isChecked,
    onChecked,
    id,
    dataTestId = id,
    autoFocus,
    disabled: disabledProp,
    textSize = 'big',
    color = 'primary',
    className,
  } = props;

  const disabled = disabledProp || state === 'disabled';

  const getLabelClass = (isSelected: boolean): string => {
    let selectedClass: SerializedStyles;
    let notSelectedClass: SerializedStyles;

    switch (textSize) {
      case 'medium': {
        selectedClass = labelMediumSelectedTextClass;
        notSelectedClass = labelMediumTextClass;
        break;
      }
      default:
      case 'big': {
        selectedClass = labelBigSelectedTextClass;
        notSelectedClass = labelBigTextClass;
      }
    }

    if (isSelected) {
      return selectedClass.name;
    }

    return notSelectedClass.name;
  };

  const getRootClass = (label: ReactNode): string | undefined => {
    if (!label) {
      return withoutLabelRootClass.name;
    }

    return undefined;
  };

  return (
    <StyledFormControlLabel
      disabled={disabled}
      data-testid={dataTestId}
      id={id}
      key={value}
      value={value}
      control={<StyledRadio inputState={state} color={color} autoFocus={autoFocus} />}
      label={label}
      checked={isChecked}
      classes={{
        root: getRootClass(label),
        label: getLabelClass(isChecked),
      }}
      onChange={(event, checked): void => {
        checked && onChecked?.();
      }}
      className={className}
    />
  );
};

export default FormRadio;

const withoutLabelRootClass = css`
  margin-right: 0;
`;

const labelMediumTextClass = css`
  font-weight: 200 !important;
  font-size: 16px !important;
  color: var(--transparent-black-600) !important;
`;

const labelMediumSelectedTextClass = css`
  ${labelMediumTextClass};
  font-weight: normal !important;
`;

const labelBigTextClass = css`
  font-weight: 200 !important;
  font-size: 18px !important;
  color: var(--transparent-black-600) !important;
`;

const labelBigSelectedTextClass = css`
  ${labelBigTextClass};
  font-weight: normal !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 1px !important;
  cursor: pointer;
  user-select: none;
  width: 100%;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  pointer-events: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }

  & > span {
    align-items: center;
    padding: 4px 0;
  }
`;

const StyledRadio = styled(Radio, { shouldForwardProp: preventForwardTheseProps('inputState') })<{ inputState: InputGroupState }>`
  padding: 3px;
  margin-right: 8px;
  fill: blue;
  cursor: pointer;
  z-index: 2;
  ${(p): string | undefined => {
    if (p.inputState === 'error') {
      return 'color: var(--alert-color) !important';
    }
  }};
`;
