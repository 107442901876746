import { FormFieldDecorators } from '@app/utils/form/form';
import { ACCOUNT_FIELD_MAX_LENGTH, VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { FIELD_MAX_LENGTH } from '@supplierRegistration/domain/supplierRegistrationFields';
import { WrappedFormUtils } from 'antd/es/form/Form';
import {
  AsyncInitiateValidationMaskedAccount,
  AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields,
  AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { COUNTRY_CODES, SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST } from '@app/domain/countries';
import { SupplierRegistrationFormValuesState } from '@supplierRegistration/domain/supplierRegistration';
import { SupplierRegistrationRadioButtonOptions } from '@app/domain/commonSupplierRegistration';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { trim } from '@app/utils/stringUtils';
import { atLeastOneOfFields, onChangeValidateOtherFields, requireOnOtherFieldValue } from '@app/utils/validators';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import { isDefined } from '@app/utils/utils';
import { SupplierRegistrationBankAccountFormFields } from '@supplierRegistration/domain/supplierRegistration';
import {
  getAdditionalAccountNumberRulesForCountry,
  getAdditionalSwiftRulesForCountry,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { swiftCodeValidator } from '@app/domain/accountDetailsValidators';

export const createFieldDecorators = (
  form: WrappedFormUtils,
  asyncProcessMaskedAccount: AsyncInitiateValidationMaskedAccount | undefined,
  asyncInitiateValidationMaskedIbanAndAccountNumber: AsyncInitiateValidationMaskedIbanAndAccountNumberFormFields | undefined,
  AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields:
    | AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields
    | undefined,
  accountBankCountryCode: COUNTRY_CODES | undefined,
  requireAccountType: boolean,
  canUseAch: boolean | undefined,
  formInitialValues: SupplierRegistrationFormValuesState,
  accountNumberMaxLength: number,
  countryBankCodeField: SupplierRegistrationField,
  countryBranchCodeField: SupplierRegistrationField | undefined,
  requireBankName: boolean,
): FormFieldDecorators<Omit<SupplierRegistrationBankAccountFormFields, 'hasAgreed' | 'hasAgreedIsrael'>> => ({
  swiftCode: {
    initialValue: asyncProcessMaskedAccount?.swiftCode ?? formInitialValues.accountValues?.swiftCode ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.swiftCode),
    rules: [
      ...getAdditionalSwiftRulesForCountry(accountBankCountryCode, form),
      {
        enabled: calculateSwiftOrBankValidatorEnabled(accountBankCountryCode, form),
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['bankCode', 'branchCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
      {
        validator: swiftCodeValidator,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidSwiftCode',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  bankCode: {
    initialValue:
      AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.bankCode ??
      formInitialValues.accountValues?.bankCode ??
      undefined,
    disableAllRules: AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.bankCodeState !== 'enabled',
    rules: [
      ...(countryBankCodeField?.decoratorRules ?? []),
      {
        enabled:
          accountBankCountryCode &&
          ![
            COUNTRY_CODES.UnitedStates,
            COUNTRY_CODES.Argentina,
            COUNTRY_CODES.Vietnam,
            ...SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST,
          ].includes(accountBankCountryCode),
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['swiftCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
      {
        enabled: accountBankCountryCode === COUNTRY_CODES.UnitedStates && canUseAch,
        transform: trim,
        validator: requireOnOtherFieldValue<SupplierRegistrationBankAccountFormFields>(
          form,
          'canUseAch',
          convertToTranslatedMessage(`supplierValidation.supplierRegister.US.errors.invalidBankCode`),
          SupplierRegistrationRadioButtonOptions.yes,
        ),
      },
      {
        enabled: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        len: ACCOUNT_FIELD_MAX_LENGTH.US.achRouting,
        transform: trim,
        translatedMessage: `supplierValidation.supplierRegister.US.errors.bankCodeLength`,
      },
      {
        transform: trim,
        validator: onChangeValidateOtherFields<SupplierRegistrationBankAccountFormFields>(form, 'abaRouting'),
      },
    ],
  },
  branchCode: {
    initialValue:
      AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.branchCode ??
      formInitialValues.accountValues?.branchCode ??
      undefined,
    disableAllRules: AsyncInitiateValidationMaskedBankCodeAndBranchCodeFormFields?.branchCodeState !== 'enabled',
    rules: [
      ...(countryBranchCodeField?.decoratorRules ?? []),
      {
        enabled: !!countryBranchCodeField,
        transform: trim,
        validator: atLeastOneOfFields<SupplierRegistrationBankAccountFormFields>(
          form,
          ['swiftCode'],
          convertToTranslatedMessage([
            `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.swiftOrBank`,
            'supplierValidation.supplierRegister.errors.swiftOrBank',
          ]),
        ),
      },
    ],
  },
  abaRouting: {
    initialValue: formInitialValues.accountValues?.abaRouting ?? undefined,
    rules: [
      {
        required: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.missingAbaRouting',
      },
      {
        len: ACCOUNT_FIELD_MAX_LENGTH.US.abaRouting,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.abaRoutingLength',
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumeric,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.US.errors.invalidAbaRouting',
      },
    ],
  },
  accountNumber: {
    initialValue:
      asyncInitiateValidationMaskedIbanAndAccountNumber?.accountNumber ??
      formInitialValues.accountValues?.accountNumber ??
      undefined,
    disableAllRules: isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber?.accountNumber),
    rules: [
      ...getAdditionalAccountNumberRulesForCountry(accountBankCountryCode),
      {
        required: true,
        transform: trim,
        translatedMessage: [
          `supplierValidation.supplierRegister.${accountBankCountryCode}.errors.invalidAccount`,
          'supplierValidation.supplierRegister.errors.invalidAccount',
        ],
      },
      {
        max: accountNumberMaxLength,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: accountNumberMaxLength,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidAccountNumber',
      },
    ],
  },
  ibanForAsyncInitiateValidation: {
    initialValue: asyncInitiateValidationMaskedIbanAndAccountNumber?.iban,
  },
  accountBankCountryCode: {
    initialValue: asyncProcessMaskedAccount?.countryCode ?? formInitialValues.accountValues?.accountBankCountryCode ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.countryCode),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAccountBankCountry',
      },
      {
        max: FIELD_MAX_LENGTH.countryCode,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.countryCode,
        },
      },
    ],
  },
  currency: {
    initialValue: asyncProcessMaskedAccount?.currency ?? formInitialValues.accountValues?.currency ?? undefined,
    disableAllRules: isDefined(asyncProcessMaskedAccount?.currency),
    rules: [
      {
        required: true,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingCurrency',
      },
      {
        max: FIELD_MAX_LENGTH.currency,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.currency,
        },
      },
    ],
  },
  furtherCredit: {
    initialValue: formInitialValues.accountValues?.furtherCredit ?? undefined,
    rules: [
      {
        max: FIELD_MAX_LENGTH.furtherCredit,
        transform: trim,
        translatedMessage: {
          key: 'supplierValidation.supplierRegister.maxCharacters',
          value: FIELD_MAX_LENGTH.furtherCredit,
        },
      },
      {
        pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
      },
    ],
  },
  canUseAch: {
    initialValue: formInitialValues.accountValues?.canUseAch ?? undefined,
    rules: [
      {
        required: accountBankCountryCode === COUNTRY_CODES.UnitedStates,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAnswer',
      },
      {
        transform: trim,
        validator: onChangeValidateOtherFields<SupplierRegistrationBankAccountFormFields>(form, 'bankCode'),
      },
    ],
  },
  accountType: {
    initialValue: formInitialValues.accountValues?.accountType ?? undefined,
    rules: [
      {
        required: requireAccountType,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingAccountType',
      },
    ],
  },
  bankName: {
    initialValue: formInitialValues.accountValues?.bankName ?? undefined,
    rules: [
      {
        required: requireBankName,
        transform: trim,
        translatedMessage: 'supplierValidation.supplierRegister.errors.missingBankName',
      },
    ],
  },
});

// Helper function moved from original file
const calculateSwiftOrBankValidatorEnabled = (
  accountBankCountryCode: COUNTRY_CODES | undefined,
  form: WrappedFormUtils,
): boolean => {
  return (
    !!accountBankCountryCode &&
    ![
      COUNTRY_CODES.UnitedStates,
      COUNTRY_CODES.Argentina,
      COUNTRY_CODES.Vietnam,
      ...SUPPLIER_REGISTRATION_SUREPAY_COUNTRY_CODE_LIST,
    ].includes(accountBankCountryCode)
  );
};
