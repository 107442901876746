import { convertStatusesIndicationsToStatusSubsets } from '@app/utils/statusUtils';

export enum SupplierRegistrationRadioButtonOptions {
  yes = 'Yes',
  no = 'No',
  doNotKnow = 'DoNotKnow',
}

export enum SupplierRegistrationProcessFormType {
  regularSupplierValidation = 'RegularSupplierValidation',
  incomingPaymentSecurity = 'IncomingPaymentSecurity',
  externalSupplierValidation = 'ExternalSupplierValidation',
  asyncSupplierValidation = 'AsyncSupplierValidation',
  //Deprecated
  dynamicSupplierValidation = 'DynamicSupplierValidation',
}

export function checkIsIpsMode(mode: SupplierRegistrationProcessFormType): boolean {
  return mode === SupplierRegistrationProcessFormType.incomingPaymentSecurity;
}

export enum SupplierRegistrationProcessInstructionType {
  poalimBankWireTransfer = 'PoalimBankWireTransfer',
  leumiUSBankWireTransfer = 'LeumiUSBankWireTransfer',
  achChaseWireTransfer = 'ACHChaseWireTransfer',
  sepaWireTransfer = 'SEPAWireTransfer',
  bofaWireTransfer = 'BOFAWireTransfer',
  bofaWirePingPong = 'BOFAWirePingPong',
  canadaPayoneerPingPong = 'CanadaPayoneerPingPong',
  barclaysGBP = 'BarclaysGBP',
  barclaysEUR = 'BarclaysEUR',
  barclaysPingPong = 'BarclaysPingPong',
  barclaysUSD = 'BarclaysUSD',
  bankValidation = 'BankValidation',
  bankValidationBofa = 'BankValidationBofa',
  bankValidationBlocked = 'BankValidationBlocked',
  bankValidationPoalim = 'BankValidationPoalim',
  bankValidationPoland = 'BankValidationPoland',
  bankValidationHungary = 'BankValidationHungary',
  bankValidationCzechRepublic = 'BankValidationCzechRepublic',
  bankValidationIndia = 'BankValidationIndia',
  bankValidationChina = 'BankValidationChina',
  bankValidationGreatBritain = 'BankValidationGreatBritain',
  bankValidationSouthAfrica = 'BankValidationSouthAfrica',
  bankValidationKorea = 'BankValidationKorea',
  bankValidationIsrael = 'BankValidationIsrael',
  bankValidationBrazil = 'BankValidationBrazil',
  bankValidationEurope = 'BankValidationEurope',
  bankValidationIndonesia = 'BankValidationIndonesia',
  bankValidationUSOpenBanking = 'BankValidationUSOpenBanking',
  bankValidationLyons = 'BankValidationLyons',
  incomingPaymentSecurityPoalimBankWireTransfer = 'IPSPoalimBankWireTransfer',
  incomingPaymentSecurityLeumiUSBankWireTransfer = 'IPSLeumiUSBankWireTransfer',
  IPSBarclaysEUR = 'IPSBarclaysEUR',
  IPSBOFAWireTransfer = 'IPSBOFAWireTransfer',
  japanPayoneer = 'JapanPayoneer',
  mexicoFrictionless = 'MexicoFrictionless',
  mexicoAlternative = 'MexicoAlternative',
  chileLF = 'ChileLf',
  argentinaLF = 'ArgentinaLf',
  vietnamLF = 'VietnamLf',
  pingPongUSD = 'PingPongUSD',
  pingPongLocalCurrency = 'PingPongLocalCurrency',
  ilLinkedAccount = 'IlLinkedAccount',
}

export const SupplierRegistrationProcessInstructionTypesSubsets = convertStatusesIndicationsToStatusSubsets<
  SupplierRegistrationProcessInstructionType,
  {
    bankValidation: boolean;
    registrationFormFilterable: boolean;
    ips: boolean;
  }
>({
  [SupplierRegistrationProcessInstructionType.poalimBankWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.leumiUSBankWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.achChaseWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.sepaWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bofaWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bofaWirePingPong]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.canadaPayoneerPingPong]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.barclaysGBP]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.barclaysEUR]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.barclaysPingPong]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.barclaysUSD]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidation]: {
    bankValidation: true,
    registrationFormFilterable: false,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBofa]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBlocked]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationPoalim]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationPoland]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationHungary]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationCzechRepublic]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIndia]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationChina]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationGreatBritain]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationSouthAfrica]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationKorea]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIsrael]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationBrazil]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationEurope]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationUSOpenBanking]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationLyons]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityPoalimBankWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: true,
  },
  [SupplierRegistrationProcessInstructionType.incomingPaymentSecurityLeumiUSBankWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: true,
  },
  [SupplierRegistrationProcessInstructionType.IPSBarclaysEUR]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: true,
  },
  [SupplierRegistrationProcessInstructionType.IPSBOFAWireTransfer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: true,
  },
  [SupplierRegistrationProcessInstructionType.japanPayoneer]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.mexicoFrictionless]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.mexicoAlternative]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.chileLF]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.argentinaLF]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.vietnamLF]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.pingPongUSD]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.pingPongLocalCurrency]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.ilLinkedAccount]: {
    bankValidation: false,
    registrationFormFilterable: true,
    ips: false,
  },
  [SupplierRegistrationProcessInstructionType.bankValidationIndonesia]: {
    bankValidation: true,
    registrationFormFilterable: true,
    ips: false,
  },
});

export const translateExpectedPaymentsExceedThresholdAnswer = (answer: SupplierRegistrationRadioButtonOptions): string => {
  switch (answer) {
    case SupplierRegistrationRadioButtonOptions.doNotKnow: {
      return `Don't Know`;
    }
    case SupplierRegistrationRadioButtonOptions.yes:
    case SupplierRegistrationRadioButtonOptions.no:
    default: {
      return answer;
    }
  }
};
