import React, { FC } from 'react';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import styled from '@emotion/styled';
import { Regular22TransparentBlack900, SubtitleSmallStartTransparentBlack400 } from '@app/components/Text';
import { NULL_ORG, SupplierValidationRecord } from '@mortee/domain/validationSystem';
import { FormattedDateTime } from '@app/components/Locale';
import Radio from '@mui/material/Radio';
import validationRecordIcon from '@mortee/images/validationSystem/validationRecordIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import { Tooltip } from 'antd';

interface Props {
  record: SupplierValidationRecord;
  isSelected: boolean;
  className?: string;
  onSelected?(): void;
}

const SupplierValidationRecordCard: FC<Props> = ({ record, isSelected, onSelected, className }) => {
  return (
    <Card className={className} data-testid='svm-inner-card'>
      <Layout>
        <Tooltip title={record.isOfflineForm ? `Cannot link to an offline process` : null}>
          {/*The div is needed for the tooltip to work on a disabled radio button*/}
          <div>
            <Radio
              color='secondary'
              checked={isSelected}
              data-testid={`sv-is-checked`}
              onClick={(): void => onSelected?.()}
              disabled={record.isOfflineForm}
            />
          </div>
        </Tooltip>
        <VerticalSeparator />
        <RecordContent>
          <StyledContainer>
            <Regular22TransparentBlack900.div data-testid='sv-supplier-name'>
              {record.supplierName}
            </Regular22TransparentBlack900.div>
            <IconAndText
              image={validationRecordIcon}
              iconAccessibilityLabel='validation record icon'
              className={SubtitleSmallStartTransparentBlack400.className}
            >
              <span data-testid='sv-presentation-id'>{record.presentationId}</span>
            </IconAndText>
            <SubtitleSmallStartTransparentBlack400.div>
              Created by {record.createdByUserName} at <FormattedDateTime value={record.createTimestamp} />
            </SubtitleSmallStartTransparentBlack400.div>
          </StyledContainer>
          <HorizontalSeparator />
          <TwoColumnGrid>
            <DataGrid>
              <DataGridRow title='Customer Name' valueDataTest='sv-customer-name'>
                {record.organization?.name ?? NULL_ORG.name}
              </DataGridRow>
            </DataGrid>
            <DataGrid>
              {record.companyCode && (
                <DataGridRow title='Company Code' valueDataTest='sv-company-code'>
                  {record.companyCode}
                </DataGridRow>
              )}
            </DataGrid>
          </TwoColumnGrid>
          {record.autoMailerManualLink && (
            <DataGrid>
              <DataGridRow title='Auto Mailer Manual Link' valueDataTest='sv-automailer-manual-link'>
                {record.autoMailerManualLink}
              </DataGridRow>
            </DataGrid>
          )}
        </RecordContent>
      </Layout>
    </Card>
  );
};

export default SupplierValidationRecordCard;

const Card = styled.div`
  border: solid 1px var(--transparent-black-200);
  background-color: var(--inner-card-background-color-blue);
`;

const Layout = styled.div`
  display: flex;
  align-items: center;
`;

const RecordContent = styled.div`
  flex: 1;
  padding: 20px 26px;
`;

const HorizontalSeparator = styled.hr`
  border-top: solid 1px var(--transparent-black-200);
  margin: 14px 0;
`;

const VerticalSeparator = styled.div`
  border-right: solid 1px var(--transparent-black-200);
  align-self: stretch;
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: start;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
