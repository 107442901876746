import React, { FunctionComponent, ReactNode, useState } from 'react';
import Loadable from '@app/utils/Loadable';
import styled from '@emotion/styled';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@app/components/Button';
import {
  CompanyCodesFilter,
  LivePrivatePayeeFilters,
  LivePrivatePayeeFiltersOptions,
  LivePrivatePayeeValidationLevel,
  PrivatePayeeWithDeterministicValidations,
} from '@mortee/domain/masterDataGuard';
import FormRadioGroup, { RadioOption } from '@app/components/inputs/FormRadioGroup';
import { Checkbox, FormControlLabel } from '@mui/material';
import { defaultFilters } from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import { OverlineStartTransparentBlack600 } from '@app/components/Text';
import { isDefined } from '@app/utils/utils';
import Card from '@app/components/card/Card';

interface Props {
  allNotValidatedPayeesWithPercentage: Loadable<PrivatePayeeWithDeterministicValidations[]>;
  filters: LivePrivatePayeeFilters;
  filterOptions: LivePrivatePayeeFiltersOptions;
  setFilters: (newFilters: LivePrivatePayeeFilters) => void;
  onPopupClosed: () => void;
}

const PrivateAreaFilters: FunctionComponent<Props> = ({
  allNotValidatedPayeesWithPercentage,
  filters,
  filterOptions,
  setFilters,
  onPopupClosed,
}) => {
  const [changedFilters, setPendingFilters] = useState<LivePrivatePayeeFilters>(filters);

  const showCompanyCodeFilter: boolean = !!filterOptions.companyCodes.result?.values.size;
  const showValidationLevelFilter: boolean = filterOptions.validationLevel.length > 1;

  const handleValidationLevelChanged = (newValidationLevel: LivePrivatePayeeValidationLevel): void => {
    setPendingFilters((currentPendingFilters) => {
      return {
        ...currentPendingFilters,
        validationLevel: newValidationLevel,
      };
    });
  };

  const applyCompanyCodeToFilters = (
    currentCompanyCodesFilter: CompanyCodesFilter | null,
    companyCode: string | null,
    newValue: boolean,
  ): CompanyCodesFilter | null => {
    const newCompanyCodesValue = duplicateCompanyCodeFilter(currentCompanyCodesFilter);

    if (isDefined(companyCode)) {
      if (newValue) {
        newCompanyCodesValue.values.add(companyCode);
      } else {
        newCompanyCodesValue.values.delete(companyCode);
      }
    } else {
      newCompanyCodesValue.allowBlankValue = newValue;
    }

    if (isCompanyCodeFiltersSameAsDefault(newCompanyCodesValue)) {
      return null;
    }

    return newCompanyCodesValue;
  };

  const isCompanyCodeFiltersSameAsDefault = (currentCompanyCodesFilter: CompanyCodesFilter): boolean => {
    return filterOptions.companyCodes.resolve(
      (companyCodesOptions): boolean => {
        return (
          currentCompanyCodesFilter.values.size === companyCodesOptions.values.size &&
          (!companyCodesOptions.allowBlankValue || currentCompanyCodesFilter.allowBlankValue)
        );
      },
      (): boolean => false,
    );
  };

  const handleCheckAllChanged = (): void => {
    let values: Set<string> = new Set<string>();
    const shouldCheckAll = getIsAllCheckedIndeterminateState() || !getIsAllChecked();
    if (shouldCheckAll) {
      values = new Set(filterOptions.companyCodes.result?.values ?? new Set<string>());
    }
    setPendingFilters((currentPendingFilters) => {
      return {
        ...currentPendingFilters,
        companyCodes: {
          values: values,
          allowBlankValue: shouldCheckAll,
        },
      };
    });
  };

  const getIsAllCheckedIndeterminateState = (): boolean => {
    if (filterOptions.companyCodes.result === null || changedFilters.companyCodes === null) {
      return false;
    }
    return (
      filterOptions.companyCodes.result?.values.size !== changedFilters.companyCodes.values.size &&
      changedFilters.companyCodes.values.size != 0
    );
  };

  const getIsAllChecked = (): boolean => {
    return (
      filterOptions.companyCodes.result === null ||
      changedFilters.companyCodes === null ||
      filterOptions.companyCodes.result?.values.size === changedFilters.companyCodes.values.size
    );
  };

  const handleCompanyCodeCheckChanged = (companyCode: string | null, newValue: boolean): void => {
    setPendingFilters((currentPendingFilters) => {
      return {
        ...currentPendingFilters,
        companyCodes: applyCompanyCodeToFilters(currentPendingFilters.companyCodes, companyCode, newValue),
      };
    });
  };

  const duplicateCompanyCodeFilter = (current: CompanyCodesFilter | null): CompanyCodesFilter => {
    if (!current) {
      return filterOptions.companyCodes.resolve(
        (companyCodes) => ({
          values: new Set<string>(companyCodes.values),
          allowBlankValue: companyCodes.allowBlankValue,
        }),
        () => ({
          values: new Set<string>(),
          allowBlankValue: false,
        }),
      );
    }

    return {
      values: new Set<string>(current.values),
      allowBlankValue: current.allowBlankValue,
    };
  };

  const handleClearFilters = (): void => {
    setPendingFilters(defaultFilters);
  };

  const handleApplyFilters = (): void => {
    setFilters({
      ...filters,
      ...changedFilters,
    });
    onPopupClosed();
  };

  const createValidationLevelRadioOption = (validationLevel: LivePrivatePayeeValidationLevel): RadioOption => {
    let label: ReactNode = validationLevelFilterToLabel[validationLevel];
    let disabled: boolean | undefined = false;

    if (validationLevel === LivePrivatePayeeValidationLevel.notValidated && !allNotValidatedPayeesWithPercentage.isResolved()) {
      label = (
        <RadioItemLabelWithProgressBar>
          {label}
          <LoadProgressBar
            color='primary'
            variant='determinate'
            value={
              allNotValidatedPayeesWithPercentage.isInProgress()
                ? allNotValidatedPayeesWithPercentage.stateMetadata?.loadingPercentage ?? 0
                : 0
            }
          />
        </RadioItemLabelWithProgressBar>
      );
      disabled = true;
    }

    return {
      label,
      disabled,
      value: validationLevel,
    };
  };

  return (
    <PopupCard shadowType='elevated'>
      <Heading>
        <Title>Filter</Title>
        <Button id='mdg-filters-clear' appearance='text' onClick={handleClearFilters} size='small'>
          CLEAR
        </Button>
      </Heading>
      {showValidationLevelFilter && (
        <>
          <FilterTypeTitle>VALIDATION LEVEL</FilterTypeTitle>
          <StyledFormRadioGroup
            id='radio-group-validation-level'
            dataTestId='radio-group-validation-level'
            value={changedFilters.validationLevel}
            onChange={handleValidationLevelChanged}
            accessibilityLabel='validation level'
            direction='column'
            color='primary'
            options={filterOptions.validationLevel.map((validationLevel) => createValidationLevelRadioOption(validationLevel))}
          />
        </>
      )}
      {showCompanyCodeFilter &&
        filterOptions.companyCodes.resolve(
          (companyCodes): ReactNode => (
            <>
              <FilterTypeTitle>SUB-ORGANIZATION</FilterTypeTitle>
              {(filterOptions.companyCodes.result?.values.size ?? 0) > 1 && (
                <>
                  <StyledFormControlLabel
                    key='lable-select-all'
                    control={
                      <StyledCheckbox
                        id='cbox-mdg-filter-company-codes-select-all'
                        data-testid='cbox-mdg-filter-company-codes-select-all'
                        indeterminate={getIsAllCheckedIndeterminateState()}
                        checked={getIsAllChecked()}
                        onChange={(newValue): void => handleCheckAllChanged()}
                        color='primary'
                      />
                    }
                    label='Select All'
                  />
                  <StyledHr />
                </>
              )}
              <CheckboxList>
                {companyCodes.allowBlankValue && (
                  <StyledFormControlLabel
                    key='companyCode-blank'
                    control={
                      <StyledCheckbox
                        id='cbox-mdg-filter-company-codes-blank'
                        data-testid='cbox-mdg-filter-company-codes-blank'
                        checked={changedFilters.companyCodes?.allowBlankValue ?? true}
                        onChange={(newValue): void => handleCompanyCodeCheckChanged(null, newValue.currentTarget.checked)}
                        color='primary'
                      />
                    }
                    label='(Blank)'
                  />
                )}
                {[...companyCodes.values].map((companyCode) => (
                  <StyledFormControlLabel
                    key={`companyCode-value-${companyCode}`}
                    control={
                      <StyledCheckbox
                        id={`cbox-mdg-filter-company-codes-value-${companyCode}`}
                        data-testid={`cbox-mdg-filter-company-codes-value-${companyCode}`}
                        checked={changedFilters.companyCodes?.values.has(companyCode) ?? true}
                        onChange={(newValue): void => handleCompanyCodeCheckChanged(companyCode, newValue.currentTarget.checked)}
                        color='primary'
                      />
                    }
                    label={companyCode}
                  />
                ))}
              </CheckboxList>
            </>
          ),
          () => <></>,
        )}
      <Actions>
        <Button id='mdg-filters-cancel' appearance='outline' colorScheme='primary' onClick={onPopupClosed}>
          CANCEL
        </Button>
        <Button id='mdg-filters-apply' colorScheme='primary' onClick={handleApplyFilters}>
          APPLY
        </Button>
      </Actions>
    </PopupCard>
  );
};

const validationLevelFilterToLabel: Record<LivePrivatePayeeValidationLevel, string> = {
  [LivePrivatePayeeValidationLevel.all]: 'All validation levels',
  [LivePrivatePayeeValidationLevel.notValidated]: 'Not validated only',
};

export default PrivateAreaFilters;

const PopupCard = styled(Card)`
  padding: 16px 16px 16px 24px;
`;

const StyledFormRadioGroup = styled(FormRadioGroup)`
  & > *:not(:first-child) {
    margin-top: 0;
  }
`;

const RadioItemLabelWithProgressBar = styled.div`
  position: relative;
`;

const LoadProgressBar = styled(LinearProgress)`
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 2px !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  font-size: 12px;
  letter-spacing: 0.68px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-left: -12px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--transparent-black-900);

  flex: 1;
`;

const CheckboxList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FilterTypeTitle = styled(OverlineStartTransparentBlack600.div)`
  margin-top: 20px;
  margin-bottom: 5px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 30px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledHr = styled.hr`
  background-color: var(--transparent-black-600);
`;
