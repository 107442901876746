import { SupplierValidationVerificationRecordEvidence, surepayTaxIdTypeText } from '@mortee/domain/validationSystem';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { createEqualityCheckFunctionCaseInsensitiveIgnoredChars } from '@app/utils/stringUtils';
import { LegalIdentifierRequest } from '@mortee/domain/vaildatedPayeeManagement';

/**
 * Converts a string to uppercase while preserving specific phrases in their original case
 */
export const upperCaseExceptDBAFAC = (value: string | null | undefined): string | undefined => {
  return value?.toUpperCase().replace(' DBA ', ' dba ').replace(' D.B.A ', ' d.b.a ').replace(' VIA/FAC ', ' via/fac ');
};

/**
 * Updates a form field value with uppercase text while preserving specific phrases
 */
export const upperCaseFieldValue = (
  e: React.FocusEvent<HTMLInputElement>,
  fieldName: string,
  form: WrappedFormUtils,
  validateFields: () => void,
): void => {
  const finalValue = upperCaseExceptDBAFAC(e.target.value);

  form.setFieldsValue({
    [fieldName]: finalValue,
  });

  validateFields();
};

/**
 * Calculates the tax ID name from evidence data
 */
export const calculateTaxIdNameFromEvidence = (
  evidenceData: SupplierValidationVerificationRecordEvidence | null | undefined,
): string | undefined => {
  if (!evidenceData?.taxIdType) {
    return undefined;
  }

  return surepayTaxIdTypeText[evidenceData.taxIdType] || evidenceData.taxIdType;
};

/**
 * Creates a function to compare two legal identifier objects for equality.
 * @param charsToIgnore - An array of characters to ignore during the comparison.
 * @returns A function that checks the equality of two LegalIdentifierRequest objects.
 */
export function createLegalIdentifierEqualityFunction(
  charsToIgnore: string[],
): (legalId1: LegalIdentifierRequest | undefined, legalId2: LegalIdentifierRequest | undefined) => boolean {
  const valueEqualityFunction = createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore);

  return (legalId1: LegalIdentifierRequest | undefined, legalId2: LegalIdentifierRequest | undefined) => {
    if (legalId1 === legalId2) {
      return true;
    }

    if (!legalId1 || !legalId2) {
      return false;
    }

    return (
      legalId1.countryCode === legalId2.countryCode &&
      legalId1.typeId === legalId2.typeId &&
      valueEqualityFunction(legalId1.value, legalId2.value)
    );
  };
}
