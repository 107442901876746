import { action, computed, flow, makeObservable, observable } from 'mobx';
import { GuestAuthCodeDeliveryMethod, GuestLoginCredentials, GuestLoginStep } from '@app/guestLogin/domain/guestLogin';
import {
  guestLogin,
  guestLogout,
  requestCodeForEmail,
  requestCodeForPhone,
  requestTokenForEmailCode,
  requestTokenForPhoneCode,
  requestTokenForSSOLogin,
  SSOTokenResponse,
} from '@app/guestLogin/services/guestLoginService';
import Log from '@app/libs/logger';
import config from '@app/config';
import * as messageLauncher from '@app/utils/messageLauncher';
import { extractLogErrorIdFromError } from '@app/libs/request';
import { createTranslatedFormalErrorMessage } from '@app/utils/errorMessageUtils';
import i18n from 'i18next';
import { AuthMethodType } from '@app/login/domain/loginConsts';

const STORAGE_GUEST_AUTH_KEY = 'auth_guest';

interface KnoxerDetails {
  knoxerId: string;
  flowId?: string;
  codeLength?: number;
}

interface PhoneKnoxerDetails extends KnoxerDetails {
  phoneNumber?: string;
}

interface EmailKnoxerDetails extends KnoxerDetails {
  email?: string;
}

export default class GuestLoginStore {
  @observable isLoggedIn = false;
  @observable isPhoneKnoxerLoggedIn = false;
  @observable isEmailKnoxerLoggedIn = false;
  @observable phoneKnoxer?: PhoneKnoxerDetails = undefined;
  @observable emailKnoxer?: EmailKnoxerDetails = undefined;
  @observable _isUserStartedLogin = false;
  @observable isLoginLoading = false;

  guestLoginCredentials: GuestLoginCredentials = {};

  constructor() {
    makeObservable(this);
  }

  async initAfterAllStoresInitialized(): Promise<void> {
    this.loadKnoxersAddresses();
    this.loadCredFromStorage();
    await this.login();
  }

  @action
  setIsLoginLoading = (isLoginLoading: boolean): void => {
    this.isLoginLoading = isLoginLoading;
  };

  @computed
  get loginStep(): GuestLoginStep {
    if (this.isLoggedIn) {
      return GuestLoginStep.Account;
    }

    if (this.isEmailKnoxerLoggedIn) {
      if (config.shouldLoginWithPhone) {
        return GuestLoginStep.Phone;
      }

      return GuestLoginStep.Account;
    }

    if (this.isUserStartedLogin) {
      return GuestLoginStep.Email;
    }

    return GuestLoginStep.Welcome;
  }

  @action
  loadKnoxersAddresses = (): void => {
    const knoxersAuthData = config.knoxersAuthData;

    if (!knoxersAuthData) {
      throw new Error('could not find knoxers config');
    }

    knoxersAuthData.forEach((knoxerAuthData) => {
      if (knoxerAuthData.authMethods.includes(AuthMethodType.EmailPassword)) {
        this.emailKnoxer = { knoxerId: knoxerAuthData.id };
      } else if (knoxerAuthData.authMethods.includes(AuthMethodType.PhoneNumber)) {
        this.phoneKnoxer = { knoxerId: knoxerAuthData.id };
      }
    });
  };

  @action
  setIsUserStartedLogin = (isStarted: boolean): void => {
    this._isUserStartedLogin = isStarted;
  };

  @computed
  get isUserStartedLogin(): boolean {
    return this._isUserStartedLogin;
  }

  @action
  setPhoneKnoxerLoggedIn = (isLoggedIn: boolean): void => {
    this.isPhoneKnoxerLoggedIn = isLoggedIn;
  };

  @action
  setEmailKnoxerLoggedIn = (isLoggedIn: boolean): void => {
    this.isEmailKnoxerLoggedIn = isLoggedIn;
  };

  requestCodeFromPhoneKnoxer = flow(function* (
    this: GuestLoginStore,
    phoneNumber: string,
    deliveryMethod: GuestAuthCodeDeliveryMethod,
  ) {
    if (!this.phoneKnoxer) {
      return false;
    }

    const { knoxerId } = this.phoneKnoxer;
    this.phoneKnoxer.phoneNumber = phoneNumber;

    try {
      this.setIsLoginLoading(true);
      const requestCodeResponse = yield requestCodeForPhone({ id: phoneNumber, knoxerId }, deliveryMethod);

      const { flowId, codeLength } = requestCodeResponse;

      this.phoneKnoxer.flowId = flowId;
      this.phoneKnoxer.codeLength = codeLength;
      return true;
    } catch (e) {
      Log.exception('Error while requesting code for phone', { e, phoneNumber, deliveryMethod });
      return false;
    } finally {
      this.setIsLoginLoading(false);
    }
  });

  requestTokenFromPhoneKnoxer = flow(function* (
    this: GuestLoginStore,
    code: string,
    deliveryMethod: GuestAuthCodeDeliveryMethod,
  ) {
    if (this.phoneKnoxer) {
      const { phoneNumber, flowId, knoxerId } = this.phoneKnoxer;

      try {
        if (!phoneNumber || !flowId) {
          throw new Error('Missing knoxer data');
        }
        this.setIsLoginLoading(true);
        const tokenResponse = yield requestTokenForPhoneCode(
          {
            id: phoneNumber,
            flowId,
            code,
            knoxerId,
          },
          deliveryMethod,
        );

        this.guestLoginCredentials.phoneToken = tokenResponse.idToken;
        this.setPhoneKnoxerLoggedIn(true);

        yield this.login();
      } catch (e) {
        Log.exception('Error while requesting token for knoxer', { e, phoneNumber, knoxerId });
        throw e;
      } finally {
        this.setIsLoginLoading(false);
      }
    }
  });

  requestCodeFromEmailKnoxer = flow(function* (this: GuestLoginStore, email: string) {
    if (!this.emailKnoxer) {
      return false;
    }

    const { knoxerId } = this.emailKnoxer;
    this.emailKnoxer.email = email;

    try {
      this.setIsLoginLoading(true);
      const requestCodeResponse = yield requestCodeForEmail({ id: email, knoxerId });

      const { flowId, codeLength } = requestCodeResponse;

      this.emailKnoxer.flowId = flowId;
      this.emailKnoxer.codeLength = codeLength;

      return true;
    } catch (e) {
      Log.exception('Error while requesting code for email', { e, email });
      return false;
    } finally {
      this.setIsLoginLoading(false);
    }
  });

  requestTokenFromEmailKnoxer = flow(function* (this: GuestLoginStore, code: string) {
    if (this.emailKnoxer) {
      const { email, flowId, knoxerId } = this.emailKnoxer;

      try {
        if (!email || !flowId) {
          throw new Error('Missing knoxer data');
        }

        this.setIsLoginLoading(true);
        const tokenResponse = yield requestTokenForEmailCode({ id: email, flowId, code, knoxerId });

        this.guestLoginCredentials.emailToken = tokenResponse.idToken;
        this.setEmailKnoxerLoggedIn(true);

        yield this.login();
      } catch (e) {
        Log.exception('Error while requesting token for knoxer', { e, email, knoxerId });
        throw e;
      } finally {
        this.setIsLoginLoading(false);
      }
    }
  });

  @action
  setLoggedIn = (isLoggedIn: boolean): void => {
    this.isLoggedIn = isLoggedIn;
    this.setPhoneKnoxerLoggedIn(isLoggedIn);
    this.setEmailKnoxerLoggedIn(isLoggedIn);
  };

  login = async (): Promise<void> => {
    if (this.guestLoginCredentials) {
      try {
        const { phoneToken, emailToken, ssoToken } = this.guestLoginCredentials;
        if ((phoneToken || !config.shouldLoginWithPhone) && (emailToken || ssoToken)) {
          this.setIsLoginLoading(true);
          this.setLoggedIn(true);
          await guestLogin();
          this.storeCredentials();
        }
      } catch (e) {
        Log.event('guestLoginStore.login: error while logging in as guest', e);
        this.setLoggedIn(false);
        this.clearCredentials();
      } finally {
        this.setIsLoginLoading(false);
      }
    }
  };

  logout = async (): Promise<void> => {
    this.setIsLoginLoading(true);
    try {
      await guestLogout();
    } catch (e) {
      Log.event('guestLoginStore.logout: error while logging out as guest', e);
    } finally {
      this.setLoggedIn(false);
      this.clearCredentials();
      this.setIsUserStartedLogin(false);
      this.setIsLoginLoading(false);
    }
  };

  clearCredentials = (): void => {
    this.guestLoginCredentials = {};

    if (typeof Storage === 'undefined') {
      return;
    }

    localStorage.removeItem(STORAGE_GUEST_AUTH_KEY);
  };

  storeCredentials = (): void => {
    if (this.guestLoginCredentials) {
      if (typeof Storage === 'undefined') {
        return;
      }

      localStorage.setItem(STORAGE_GUEST_AUTH_KEY, JSON.stringify(this.guestLoginCredentials));
    }
  };

  loadCredFromStorage = (): void => {
    try {
      // Check browser support
      if (typeof Storage === 'undefined') {
        return;
      }

      const authGuest = localStorage.getItem(STORAGE_GUEST_AUTH_KEY);

      if (!authGuest) {
        return;
      }

      const authGuestParsed: GuestLoginCredentials = JSON.parse(authGuest);

      if (
        (authGuestParsed.emailToken || authGuestParsed.ssoToken) &&
        (authGuestParsed.phoneToken || !config.shouldLoginWithPhone)
      ) {
        this.guestLoginCredentials = authGuestParsed;
      }
    } catch (e) {
      Log.exception(e);
    }
  };

  loadTokenForSSO = flow(function* (this: GuestLoginStore, knoxerId: string) {
    try {
      this.setIsLoginLoading(true);
      const ssoToken: SSOTokenResponse = yield requestTokenForSSOLogin({ knoxerId });

      if (ssoToken) {
        this.guestLoginCredentials.ssoToken = ssoToken.token;
        this.setEmailKnoxerLoggedIn(true);

        yield this.login();
      }
    } catch (e) {
      Log.event('guestLoginStore.loadTokenForSSO: error while requesting token for SSO', e);
      const errorLogId = extractLogErrorIdFromError(e);
      const errorMessage = createTranslatedFormalErrorMessage(
        i18n.t,
        'ar.guestLogin.errors.unexpectedLoginError',
        'general.errors.pleaseContact',
        'general.errors.referToErrorCode',
        errorLogId,
      );
      messageLauncher.shootErrorOld(errorMessage, i18n.t('general.errors.errorPopupTitle'));
    } finally {
      this.setIsLoginLoading(false);
    }
  });
}
