import React from 'react';
import styled from '@emotion/styled';
import { Trans } from 'react-i18next';
import Card from '@app/components/card/Card';
import SVG from '@app/components/SVG';
import ErrorSVG from '@supplierRegistration/images/icon-page-error.svg';
import { BodyRegularStartTransparentBlack900 } from '@app/components/Text';

interface Props {
  customerName: string | null;
}

export const RegistrationError: React.FC<Props> = ({ customerName }) => (
  <ErroContainer>
    <ErrorMainCard>
      <StyledSVG image={ErrorSVG} accessibilityLabel='error' />
      <Trans i18nKey='supplierValidation.supplierRegister.errors.invalidLink' values={{ customerName }}>
        This registration link is expired or invalid. For assistance, please contact {{ customerName }} or nsKnox at
        <MailTo href='mailto:support@nsknox.net'>support@nsknox.net</MailTo>
      </Trans>
    </ErrorMainCard>
  </ErroContainer>
);

const ErroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ErrorMainCard = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  ${BodyRegularStartTransparentBlack900.css};
  padding: 30px clamp(20px, 5vw, 50px) 30px;
  max-width: 500px;
`;

const MailTo = styled.a`
  font-weight: bold;
`;

const StyledSVG = styled(SVG)`
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
`;
