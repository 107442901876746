import DatePicker, { DatePickerFormat } from '@app/components/inputs/DatePicker';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { COUNTRY_CODES, SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST } from '@app/domain/countries';
import useAppStores from '@app/hooks/useAppStores';
import useForm from '@app/hooks/useForm';
import useInfraStores from '@app/hooks/useInfraStores';
import useLeaveConfirmation from '@app/hooks/useLeaveConfirmation';
import { storeUserCountry, useLocaleCountry } from '@app/hooks/useLocaleCountry';
import useTheme from '@app/hooks/useTheme';
import { isDefined, isObjectEmpty } from '@app/utils/utils';
import CountryDropdown from '@supplierRegistration/components/CountryDropdown';
import HideableSupplierRegistrationFormItemBox from '@supplierRegistration/components/HideableSupplierRegistrationFormItemBox';
import LEITypeFormItem from '@supplierRegistration/components/LEITypeFormItem';
import PhoneNumberInput from '@supplierRegistration/components/PhoneNumberInput';
import PleaseNote from '@supplierRegistration/components/PleaseNote';
import ReferringOrg from '@supplierRegistration/components/ReferringOrg';
import StateDropdown from '@supplierRegistration/components/StateDropdown';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import * as countryProperties from '@supplierRegistration/domain/countries';
import {
  RegistrationType,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierValidationRegistrationProps,
} from '@supplierRegistration/domain/supplierRegistration';
import { createFieldDecorators } from '@supplierRegistration/domain/supplierRegistrationCompanyInfoDecorators';
import {
  AllLegalIdTypeForCompany,
  getLegalIdTypesOfCountryForCompany,
} from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form/Form';
import { observer, useLocalObservable } from 'mobx-react';
import React, { FC, ReactNode, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardSubTitle,
  CardTitle,
  MainCard,
  SeparatorDiv,
  SmallContinueButton,
  SmallSeparator,
  SmallSeparatorWithExtraMargin,
  StyledForm,
} from '../../Styles';
import {
  DateOfBirth,
  InputGrid,
  RadioOptionLabel,
  StyledFormRadioGroup,
  TransparentSupplierRegistrationFormItemBox,
} from './SupplierRegistrationCompanyInformation.styles';

const monthsTranslations: string[] = [
  'general.months.january',
  'general.months.february',
  'general.months.march',
  'general.months.april',
  'general.months.may',
  'general.months.june',
  'general.months.july',
  'general.months.august',
  'general.months.september',
  'general.months.october',
  'general.months.november',
  'general.months.december',
];

interface Props extends FormComponentProps<SupplierRegistrationCompanyInfoFormFields>, SupplierValidationRegistrationProps {}

const SupplierRegistrationCompanyInformation: FC<Props> = observer((props) => {
  useLeaveConfirmation();

  const { configuration, formValues, onFormSubmit, isIpsMode, nextStep } = props;
  const { form, showFormErrors, setShowFormErrors } = useForm(props);
  const { t } = useTranslation();
  const { languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { brand, continueButtonColor, fontFamily } = useTheme<SupplierRegistrationMode>();

  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const supplierCountryCode = form.getFieldValue('country');
  const addressCountryCode = form.getFieldValue('companyAddressCountry');
  const leiType = form.getFieldValue('leiType');
  const legalIdTypesOfCountry = getLegalIdTypesOfCountryForCompany(supplierCountryCode);
  const isAsync = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.isResolved();

  useLocaleCountry(
    (userCountry, userCountryCode): false | number =>
      !formValues.supplierValues?.phoneCountryCode &&
      window.setTimeout((): void => form.setFieldsValue({ phoneCountryCode: userCountryCode }), 0),
  );

  const onLeiTypeChange = (leiTypeKey: string): void => {
    localStore.setIsOtherLEIType(AllLegalIdTypeForCompany[leiTypeKey] === AllLegalIdTypeForCompany.Other);
    form.validateFields();
  };

  const isIndividual = (): boolean => {
    return form.getFieldValue('registrationType') === RegistrationType.individual;
  };

  const showDateOfBirth = (): boolean => {
    return isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.showDateOfBirth;
  };

  const showAdditionalPersonalName = (): boolean => {
    return isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.additionalPersonalName;
  };

  const showAdditionalCompanyName = (): boolean => {
    return !isIndividual() && !!supplierCountryCode && countryProperties?.[supplierCountryCode]?.additionalCompanyName;
  };

  const doesSupplierCountryHasAdditionalName = showAdditionalPersonalName() || showAdditionalCompanyName();

  const asyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.result;

  useEffect((): void => {
    form.resetFields(['leiType']);
    localStore.setIsOtherLEIType(!!formValues.supplierValues?.leiTypeOtherValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [legalIdTypesOfCountry]);

  useEffect((): void => {
    if (doesSupplierCountryHasAdditionalName) {
      form.resetFields(['additionalCompanyName']);
    }
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [doesSupplierCountryHasAdditionalName]);

  const localStore = useLocalObservable(() => ({
    isOtherLEIType: !!formValues.supplierValues?.leiTypeOtherValue,

    setIsOtherLEIType: (isOtherLEIType: boolean): void => {
      localStore.isOtherLEIType = isOtherLEIType;
    },
  }));

  const handleRegistrationTypeChange = (): void => {
    form.resetFields(Object.keys(fieldDecorators).filter((field) => field !== 'registrationType'));
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;
    setShowFormErrors('all');

    validateFieldsAndScroll((errors: Object, values: SupplierRegistrationCompanyInfoFormFields) => {
      if (errors) {
        return;
      }

      onFormSubmit(values);
      storeUserCountry(values.phoneCountryCode);
      nextStep();
    });
  };

  const onSupplierCountryChange = (countryCode: string | undefined): void => {
    form.setFieldsValue({
      phoneCountryCode: form.getFieldValue('phoneNumber') ? form.getFieldValue('phoneCountryCode') : countryCode,
    });
    form.setFieldsValue({ companyAddressCountry: form.getFieldValue('companyAddressCountry') ?? countryCode });
  };

  const fieldDecorators = createFieldDecorators(
    form,
    asyncProcessMaskedData,
    isIndividual(),
    supplierCountryCode,
    addressCountryCode,
    formValues,
    showDateOfBirth(),
    leiType,
  );

  function getCardSubTitle(): ReactNode {
    if (isIpsMode) {
      return <>{t('supplierValidation.registerSteps.companyInformation.subTitleIps')}</>;
    }

    if (isAsync) {
      return (
        <Trans i18nKey='supplierValidation.registerSteps.companyInformation.subTitleAsync' values={{ customerName }}>
          Complete missing company details. Details already provided to {{ customerName }} are prefilled. To modify any of these,
          please contact {{ customerName }}.
        </Trans>
      );
    }

    return (
      <Trans i18nKey='supplierValidation.registerSteps.companyInformation.subTitle'>
        Enter your company details that you have <strong>provided to the payer</strong>
      </Trans>
    );
  }

  const isAddressCountryUS = form.getFieldValue('companyAddressCountry') === COUNTRY_CODES.UnitedStates;

  return (
    <>
      <MainCard fontFamily={fontFamily}>
        <StyledForm onSubmit={handleOk} showErrors={showFormErrors} form={form} setShowErrors={setShowFormErrors}>
          {configuration?.clientName && !brand && <ReferringOrg orgName={configuration.clientName} />}
          <CardTitle>
            {isAsync
              ? t('supplierValidation.registerSteps.companyInformation.titleAsync')
              : t('supplierValidation.registerSteps.companyInformation.title')}
          </CardTitle>
          <CardSubTitle>{getCardSubTitle()}</CardSubTitle>
          <SupplierRegistrationFormItemBox
            appearance='none'
            fieldName='registrationType'
            fieldDecoratorOptions={fieldDecorators.registrationType}
            hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.isIndividual)}
            onChange={handleRegistrationTypeChange}
          >
            <StyledFormRadioGroup
              accessibilityLabel={t('supplierValidation.accessibility.registrationType')}
              id='radio-group-supp-reg-registration-type'
              dataTestId='radio-group-supp-reg-registration-type'
              options={[
                {
                  value: RegistrationType.company,
                  label: <RadioOptionLabel>{t('supplierValidation.supplierRegister.company')}</RadioOptionLabel>,
                },
                {
                  value: RegistrationType.individual,
                  label: <RadioOptionLabel>{t('supplierValidation.supplierRegister.individual')}</RadioOptionLabel>,
                },
              ]}
              direction='column'
            />
          </SupplierRegistrationFormItemBox>
          <SmallSeparatorWithExtraMargin />
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='country'
              fieldDecoratorOptions={fieldDecorators.country}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.countryCode)}
            >
              <CountryDropdown
                name='drp-supp-reg-supplier-country'
                placeholder={t<string>(
                  isIndividual()
                    ? 'supplierValidation.supplierRegister.country'
                    : 'supplierValidation.supplierRegister.companyCountry',
                )}
                excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
                onChange={onSupplierCountryChange}
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          {showDateOfBirth() && (
            <>
              <SeparatorDiv>
                <SmallSeparator />
                {t(`supplierValidation.supplierRegister.dateOfBirthHeader`)}
              </SeparatorDiv>
              <InputGrid>
                <DateOfBirth>
                  <SupplierRegistrationFormItemBox
                    fieldName='dateOfBirth'
                    fieldDecoratorOptions={fieldDecorators.dateOfBirth}
                    appearance='none'
                    hasPrefilledData={false}
                  >
                    <DatePicker
                      appearance='corners'
                      accessibilityLabel={t(`supplierValidation.supplierRegister.dateOfBirthHeader`)}
                      id='date-picker-birth-of-date'
                      placeholderStyle='onlyWhenEmpty'
                      heightType='thin'
                      dateFormat={DatePickerFormat.YYMMDD}
                      bottomYearLimit={150}
                      translatedMonths={monthsTranslations}
                    />
                  </SupplierRegistrationFormItemBox>
                </DateOfBirth>
              </InputGrid>
            </>
          )}
          <SeparatorDiv>
            <SmallSeparator />
            {t(
              isIndividual()
                ? 'supplierValidation.supplierRegister.individualInfoHeader'
                : 'supplierValidation.supplierRegister.companyInfoHeader',
            )}
          </SeparatorDiv>
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='companyName'
              fieldDecoratorOptions={fieldDecorators.companyName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.name)}
            >
              <NakedFormInput
                name='btn-supp-reg-company-name'
                dataTestId='btn-supp-reg-company-name'
                type='text'
                placeholder={t<string>(
                  isIndividual()
                    ? [
                        `supplierValidation.supplierRegister.${supplierCountryCode}.accountOwnerName`,
                        'supplierValidation.supplierRegister.accountOwnerName',
                      ]
                    : [
                        `supplierValidation.supplierRegister.${supplierCountryCode}.compName`,
                        'supplierValidation.supplierRegister.compName',
                      ],
                )}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {(doesSupplierCountryHasAdditionalName || showAdditionalPersonalName()) && (
              <SupplierRegistrationFormItemBox
                fieldName='additionalCompanyName'
                fieldDecoratorOptions={fieldDecorators.additionalCompanyName}
                hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.additionalCompanyName)}
              >
                <NakedFormInput
                  name='btn-supp-reg-additional-company-name'
                  dataTestId='btn-supp-reg-additional-company-name'
                  type='text'
                  placeholder={t<string>(
                    isIndividual()
                      ? [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.additionalPersonalName`,
                          'supplierValidation.supplierRegister.additionalPersonalName',
                        ]
                      : [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.additionalCompanyName`,
                          'supplierValidation.supplierRegister.additionalCompanyName',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            <LEITypeFormItem
              form={form}
              fieldDecorators={fieldDecorators}
              isIndividual={isIndividual()}
              showFormErrors={showFormErrors}
              asyncProcessMaskedData={asyncProcessMaskedData}
              legalIdTypesOfCountry={legalIdTypesOfCountry}
              isOtherLEIType={localStore.isOtherLEIType}
              onLeiTypeChange={onLeiTypeChange}
              areChildrenRTL={languageStore.isRTL}
            />
            <SupplierRegistrationFormItemBox
              fieldName='leiValue'
              fieldDecoratorOptions={fieldDecorators.leiValue}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.legalIdentifier)}
            >
              <NakedFormInput
                name='btn-supp-reg-lei-value'
                dataTestId='btn-supp-reg-lei-value'
                type='text'
                placeholder={
                  isIndividual()
                    ? t<string>([
                        `supplierValidation.supplierRegister.individualForm.${supplierCountryCode}.legalId`,
                        'supplierValidation.supplierRegister.legalId',
                      ])
                    : t<string>('supplierValidation.supplierRegister.legalId')
                }
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {!configuration?.ref && !isIpsMode && (
              <SupplierRegistrationFormItemBox
                fieldName='referrer'
                fieldDecoratorOptions={fieldDecorators.referrer}
                hasPrefilledData={false}
              >
                <NakedFormInput
                  name='btn-supp-reg-referrer'
                  dataTestId='btn-supp-reg-referrer'
                  type='text'
                  placeholder={t<string>('supplierValidation.supplierRegister.referrer')}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            <SupplierRegistrationFormItemBox
              fieldName='website'
              fieldDecoratorOptions={fieldDecorators.website}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.website)}
            >
              <NakedFormInput
                name='btn-supp-reg-website'
                dataTestId='btn-supp-reg-website'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.website')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SeparatorDiv>
            <SmallSeparator />
            {t(`supplierValidation.supplierRegister.addressHeader`)}
          </SeparatorDiv>
          <InputGrid>
            {!isDefined(asyncProcessMaskedData?.payee.address) ||
            !isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) ? (
              <>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressCountry'
                  fieldDecoratorOptions={fieldDecorators.companyAddressCountry}
                  hasPrefilledData={isDefined(asyncProcessMaskedData?.payee?.addressByFields?.countryCode)}
                >
                  <CountryDropdown
                    name='drp-supp-reg-company-address-country'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressCountry')}
                    excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
                  />
                </SupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddress'
                  fieldDecoratorOptions={fieldDecorators.companyAddress}
                  hasPrefilledData={isDefined(asyncProcessMaskedData?.payee?.addressByFields?.addressLine)}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address'
                    dataTestId='btn-supp-reg-company-address'
                    type='text'
                    placeholder={t<string>(
                      isIndividual()
                        ? 'supplierValidation.supplierRegister.address'
                        : [
                            `supplierValidation.supplierRegister.${supplierCountryCode}.companyAddress`,
                            'supplierValidation.supplierRegister.companyAddress',
                          ],
                    )}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressCity'
                  fieldDecoratorOptions={fieldDecorators.companyAddressCity}
                  hasPrefilledData={isDefined(asyncProcessMaskedData?.payee?.addressByFields?.city)}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address-city'
                    dataTestId='btn-supp-reg-company-address-city'
                    type='text'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressCity')}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
                <TransparentSupplierRegistrationFormItemBox
                  shouldBeHidden={!isAddressCountryUS}
                  fieldName='companyAddressState'
                  fieldDecoratorOptions={fieldDecorators.companyAddressState}
                  hasPrefilledData={isDefined(asyncProcessMaskedData?.payee?.addressByFields?.state)}
                >
                  <StateDropdown
                    name='drp-supp-reg-company-address-state'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressState')}
                  />
                </TransparentSupplierRegistrationFormItemBox>
                <SupplierRegistrationFormItemBox
                  fieldName='companyAddressZipCode'
                  fieldDecoratorOptions={fieldDecorators.companyAddressZipCode}
                  hasPrefilledData={isDefined(asyncProcessMaskedData?.payee?.addressByFields?.zipCode)}
                >
                  <NakedFormInput
                    name='btn-supp-reg-company-address-zipcode'
                    dataTestId='btn-supp-reg-company-address-zipcode'
                    type='text'
                    placeholder={t<string>('supplierValidation.supplierRegister.addressZipCode')}
                    disableSuggestion
                  />
                </SupplierRegistrationFormItemBox>
              </>
            ) : (
              <SupplierRegistrationFormItemBox
                fieldName='companyAddress'
                fieldDecoratorOptions={fieldDecorators.companyAddress}
                hasPrefilledData={true}
              >
                <NakedFormInput
                  name='btn-supp-reg-company-address'
                  dataTestId='btn-supp-reg-company-address'
                  type='text'
                  placeholder={t<string>(
                    isIndividual()
                      ? 'supplierValidation.supplierRegister.address'
                      : [
                          `supplierValidation.supplierRegister.${supplierCountryCode}.companyAddress`,
                          'supplierValidation.supplierRegister.companyAddress',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
          </InputGrid>
          <SeparatorDiv>
            <SmallSeparator />
            {t(`supplierValidation.supplierRegister.contactInfoHeader`)}
          </SeparatorDiv>
          <InputGrid>
            <HideableSupplierRegistrationFormItemBox
              fieldName='asyncFullName'
              fieldDecoratorOptions={fieldDecorators.asyncFullName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName)}
              hidden={
                !isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName) ||
                isDefined(asyncProcessMaskedData?.contactInfo.firstName)
              }
            >
              <NakedFormInput
                name='btn-supp-reg-async-full-name'
                dataTestId='btn-supp-reg-async-full-name'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.contactName')}
                disableSuggestion
              />
            </HideableSupplierRegistrationFormItemBox>
            <HideableSupplierRegistrationFormItemBox
              fieldName='firstName'
              fieldDecoratorOptions={fieldDecorators.firstName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.firstName)}
              hidden={
                !isDefined(asyncProcessMaskedData?.contactInfo.firstName) &&
                isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName)
              }
            >
              <NakedFormInput
                name='btn-supp-reg-first-name'
                dataTestId='btn-supp-reg-first-name'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.contactFirstName')}
                disableSuggestion
              />
            </HideableSupplierRegistrationFormItemBox>
            <HideableSupplierRegistrationFormItemBox
              fieldName='lastName'
              fieldDecoratorOptions={fieldDecorators.lastName}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.lastName)}
              hidden={
                !isDefined(asyncProcessMaskedData?.contactInfo.lastName) &&
                isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName)
              }
            >
              <NakedFormInput
                name='btn-supp-reg-last-name'
                dataTestId='btn-supp-reg-last-name'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.contactLastName')}
                disableSuggestion
              />
            </HideableSupplierRegistrationFormItemBox>
            <PhoneNumberInput
              hasAsyncMaskedDataPhoneNumber={!!asyncProcessMaskedData?.contactInfo.phoneNumber}
              fieldDecorators={fieldDecorators}
            />
            <SupplierRegistrationFormItemBox
              fieldName='email'
              fieldDecoratorOptions={fieldDecorators.email}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.contactInfo.email)}
            >
              <NakedFormInput
                name='inpt-auth-forgot-enter-email'
                dataTestId='inpt-auth-forgot-enter-email'
                type='text'
                placeholder={t<string>('supplierValidation.supplierRegister.mail')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SmallContinueButton id='btn-supp-reg-company-info-continue' onClick={handleOk} colorScheme={continueButtonColor}>
            {t('supplierValidation.supplierRegister.continue')}
          </SmallContinueButton>
        </StyledForm>
      </MainCard>
      <PleaseNote isIpsMode={isIpsMode} configuration={configuration} />
    </>
  );
});

export default Form.create<Props>()(SupplierRegistrationCompanyInformation);
