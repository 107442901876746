// Typescript bug, cant call arrayToObject<Keys>(a, b) - with only Keys sent to the functions,
// need to send all 3 types in the generics. https://github.com/Microsoft/TypeScript/issues/10571

import { SupplierValidationRecord, SupplierValidationVerificationSanctionsScreeningInfo } from '@mortee/domain/validationSystem';
import { LegalIdentifierRequest } from '@mortee/domain/vaildatedPayeeManagement';
import { SupplierRegistrationProcessInstructionType } from '@app/domain/commonSupplierRegistration';

export function identityFunc<T>(item: T): T {
  return item;
}

export function DEFAULT_EQUALS_FUNCTION<T>(a: T, b: T): boolean {
  return a === b;
}

export function DEFAULT_EQUALS_FUNCTION_CASE_INSENSITIVE<T>(a: T, b: T): boolean {
  if (typeof a === 'string' && typeof b === 'string') {
    return a.toLowerCase() === b.toLowerCase();
  }
  return a === b;
}

export function LEGAL_IDENTIFIER_EQUALS_FUNCTION(
  a: LegalIdentifierRequest | undefined,
  b: LegalIdentifierRequest | undefined,
): boolean {
  return a?.countryCode === b?.countryCode && a?.typeId === b?.typeId && a?.value === b?.value;
}

const cleanSwiftCode = (swiftCode: string | undefined): string | undefined => {
  const uppercaseSwift = swiftCode?.toUpperCase();

  if (uppercaseSwift?.length === 11 && uppercaseSwift?.match(/[xX]{3}$/)) {
    return uppercaseSwift?.slice(0, 8);
  }

  return uppercaseSwift;
};

export function SWIFT_EQUALS_FUNCTION(swiftCode1: string | undefined, swiftCode2: string | undefined): boolean {
  return cleanSwiftCode(swiftCode1) === cleanSwiftCode(swiftCode2);
}

export function SCREENING_EQUALS_FUNCTION(
  a: SupplierValidationVerificationSanctionsScreeningInfo | null,
  b: SupplierValidationVerificationSanctionsScreeningInfo | null,
): boolean {
  return a?.screeningTimestamp === b?.screeningTimestamp && a?.result === b?.result;
}

// edited from https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#Polyfill
export function dataURIToBlob(dataURI: string): Blob {
  const binStr = atob(dataURI.split(',')[1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new Blob([arr], {
    type: mimeString,
  });
}

export function extractSupplierValidationRecordCurrentInstructionType(
  record: SupplierValidationRecord,
): SupplierRegistrationProcessInstructionType | null | undefined {
  return record.manualInstructionType ? record.manualInstructionType : record.supplierRegistrationProcess?.instructionType;
}

export function makeCommaSeparatedString(arr: any[]): string {
  const listStart = arr.slice(0, -1).join(', ');
  const listEnd = arr.slice(-1);
  const conjunction = arr.length <= 1 ? '' : arr.length > 2 ? ', and ' : ' and ';

  return [listStart, listEnd].join(conjunction);
}

export function groupBy<T, TKey>(arr: T[], keyGetter: (t: T) => TKey): Map<TKey, T[]> {
  return groupByAndMap(arr, keyGetter, identityFunc);
}

export function groupByAndMap<T, TKey, TOutput = T>(
  arr: T[],
  keyGetter: (t: T) => TKey,
  valueMapper: (t: T) => TOutput,
): Map<TKey, TOutput[]> {
  const map = new Map<TKey, TOutput[]>();

  arr.forEach((item) => {
    const key = keyGetter(item);

    map.set(key, [...(map.get(key) ?? []), valueMapper(item)]);
  });

  return map;
}

export function mergeObjectsIgnoreFalsy<T>(arr: T[]): Partial<T> {
  if (!arr?.length) {
    return {};
  }

  return arr.reduce((aggregated, current) => {
    Object.keys(aggregated).forEach((property) => {
      if (current[property]) {
        aggregated[property] = current[property];
      }
    });

    return aggregated;
  }, {});
}

export function mapValues<TKey extends string | number | symbol, TInput, TOutput>(
  obj: Record<TKey, TInput>,
  mapper: (TInput, TKey) => TOutput,
): Record<TKey, TOutput>;

export function mapValues<TKey extends string | number | symbol, TInput, TOutput>(
  obj: Partial<Record<TKey, TInput>>,
  mapper: (TInput, TKey) => TOutput,
): Partial<Record<TKey, TOutput>>;

export function mapValues<TKey extends string | number | symbol, TInput, TOutput>(
  obj: Partial<Record<TKey, TInput>>,
  mapper: (TInput, TKey) => TOutput,
): Partial<Record<TKey, TOutput>> {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, mapper(value, key)])) as Record<TKey, TOutput>;
}

export function mapMapValues<TKey, TInput, TOutput>(
  originalMap: Map<TKey, TInput>,
  mapper: (TInput, TKey) => TOutput,
): Map<TKey, TOutput> {
  const resultMap = new Map<TKey, TOutput>();

  for (const key of originalMap.keys()) {
    const mappedValue = mapper(originalMap.get(key), key);
    resultMap.set(key, mappedValue);
  }

  return resultMap;
}

export function filterValues<TKey extends keyof any, TValue>(
  obj: Record<TKey, TValue>,
  predicate: (input: TValue) => boolean,
): Record<TKey, TValue>;

export function filterValues<TKey extends keyof any, TValue>(
  obj: Partial<Record<TKey, TValue>>,
  predicate: (input: TValue) => boolean,
): PartialNotOptional<Record<TKey, TValue>>;

export function filterValues<TKey extends keyof any, TValue>(
  obj: Partial<Record<TKey, TValue>>,
  predicate: (input: TValue) => boolean,
): PartialNotOptional<Record<TKey, TValue>> {
  return Object.fromEntries(Object.entries(obj).filter(([, value]: [any, TValue]) => predicate(value))) as Record<TKey, TValue>;
}

export function isTruthy<T>(item: T): item is Truthy<T> {
  return !!item;
}

export function isDefined<T>(item: T): item is NonNullable<T> {
  return item !== null && item !== undefined;
}

export function isDefinedAndHasLength<T>(item: T): item is Exclude<NonNullable<T>, ''> {
  return isDefined(item) && (typeof item !== 'string' || item.length > 0) && (!Array.isArray(item) || item.length > 0);
}

export function isEntryValueDefined<TKey extends keyof any, TValue>(entry: [TKey, TValue]): entry is [TKey, NonNullable<TValue>] {
  return isDefined(entry[1]);
}

export function isEntryValueTruthy<TKey extends keyof any, TValue>(entry: [TKey, TValue]): entry is [TKey, Truthy<TValue>] {
  return isTruthy(entry[1]);
}

export function removeEmptyFields<T extends {}>(obj: T): PartialNotOptional<T> {
  return filterValues(obj, isDefinedAndHasLength) as PartialNotOptional<T>;
}

export function isObjectEmpty(obj: Record<string, any> | null | undefined): boolean {
  if (!obj) return true;
  const isAnyFieldHasValue = Object.values(obj).some(isDefinedAndHasLength);
  return !isAnyFieldHasValue;
}

export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'MacOS',
  UNIX = 'UNIX',
  Linux = 'Linux',
}

export function getOS(): OperatingSystem | null {
  if (navigator.appVersion?.includes('Win')) {
    return OperatingSystem.Windows;
  }
  if (navigator.appVersion?.includes('Mac')) {
    return OperatingSystem.MacOS;
  }
  if (navigator.appVersion?.includes('X11')) {
    return OperatingSystem.UNIX;
  }
  if (navigator.appVersion?.includes('Linux')) {
    return OperatingSystem.Linux;
  }

  return null;
}

export function isPointInsideRect(rect: DOMRect, x: number, y: number): boolean {
  return rect.left <= x && x <= rect.right && rect.top <= y && y <= rect.bottom;
}

export function doesPartialHasAllRequiredFields<T>(
  allFields: Partial<T>,
  onlyFieldsThatShouldBeNonNull: PartialNotOptional<NonNullableFields<T>>,
): allFields is T {
  return Object.values(onlyFieldsThatShouldBeNonNull).every(isDefined);
}
