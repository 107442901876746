import React, { PropsWithChildren, ReactElement, ReactNode, useContext, useEffect } from 'react';
import { isDefined } from '@app/utils/utils';
import { FormInputProps } from '@app/utils/form/form';
import FormInputsContext from '@app/components/inputs/FormInputsContext';
import { makeStyles } from 'tss-react/mui';
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleButtonGroup as MUIToggleButtonGroup } from '@mui/material';

interface ToggleOption<T> {
  value: T;
  text: ReactNode;
  disabled?: boolean;
  id: string;
  dataTestId?: string;
}

interface Props<T extends NonNullable<any>> extends FormInputProps<T | undefined, false> {
  id: string;
  dataTestId?: string;
  options: NonEmptyArray<ToggleOption<T>>;
  disabled?: boolean;
  fixedValue?: T;
}

function ToggleButtonGroup<T>({
  id,
  dataTestId = id,
  options,
  accessibilityLabel,
  disabled: disabledProp,
  fixedValue,
  value,
  onChange,
}: PropsWithChildren<Props<T>>): ReactElement {
  const { state } = useContext(FormInputsContext);
  const disabled = disabledProp || state === 'disabled';

  const useStyles = makeStyles()(() => ({
    selected: {
      '&.Mui-selected': {
        backgroundColor: '#99cdff',
        fontWeight: 'bold',
      },
    },
  }));

  const { classes } = useStyles();

  function shouldDisableButton(toggleButtonOption: ToggleOption<T>): boolean {
    if (disabled) {
      return true;
    }

    if (toggleButtonOption.disabled) {
      return true;
    }

    if (!isDefined(fixedValue)) {
      return false;
    }

    return fixedValue !== toggleButtonOption.value;
  }

  useEffect(() => {
    if (isDefined(fixedValue) && fixedValue !== value) {
      onChange?.(fixedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onChange might change all the time
  }, [fixedValue]);

  return (
    <MUIToggleButtonGroup
      id={id}
      data-testid={dataTestId}
      exclusive
      value={value}
      onChange={(event, newValue: T | undefined): void => {
        if (!isDefined(newValue)) {
          return;
        }

        onChange?.(newValue);
      }}
      aria-label={accessibilityLabel}
      aria-disabled={disabled}
    >
      {options.map((option) => (
        <ToggleButton
          classes={classes}
          id={option.id}
          data-testid={option.dataTestId ?? option.id}
          value={isDefined(option.value) ? option.value : ''}
          key={String(option.value)}
          disabled={shouldDisableButton(option)}
        >
          {option.text}
        </ToggleButton>
      ))}
    </MUIToggleButtonGroup>
  );
}

export default ToggleButtonGroup;
