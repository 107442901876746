import React, { FC, ReactNode } from 'react';
import { Backdrop } from '@mui/material';

interface ClickOutsideDetectorProps {
  onClickOutside: () => void;
  // This is to make sure the user of this component will know that this component will prevent outside clicks
  preventOutsideClick: boolean;
  children: ReactNode;
}

const ClickOutsideDetector: FC<ClickOutsideDetectorProps> = ({ onClickOutside, preventOutsideClick, children }) => {
  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (preventOutsideClick) {
      event.preventDefault();
      event.stopPropagation();
    }

    onClickOutside();
  };

  return (
    <div>
      <Backdrop open invisible onClick={handleClickOutside} transitionDuration={1} />
      {children}
    </div>
  );
};

export default ClickOutsideDetector;
