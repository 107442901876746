import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import PageWithHeader from '@app/components/PageWithHeader';
import styled from '@emotion/styled';
import { SubtitleSmallStartTransparentBlack900 } from '@app/components/Text';
import Button from '@app/components/Button';
import { showCustomModal, showCustomModalAsync } from '@app/components/Modal';
import ModalAppContext from '@app/ModalAppContext';
import CreateValidationRecordModal from '@mortee/routes/validationSystem/createValidationRecord/CreateValidationRecordModal';
import useModalContext from '@app/hooks/useModalContext';
import validationSystemServices from '@mortee/services/validationSystemServices';
import SupplierValidationRecordTable from '@mortee/routes/validationSystem/validationRecordTable/SupplierValidationRecordTable';
import {
  ItemsNavigationArrowsClickHandlers,
  SupplierValidationRecord,
  SupplierValidationRecordStatusObject,
  SupplierValidationRecordUpdateRequest,
  transformSupplierValidationRecord,
  transformSupplierValidationRecordFilterResult,
  transformToSupplierValidationRecordStatusUpdateRequest,
} from '@mortee/domain/validationSystem';
import useInfraStores from '@app/hooks/useInfraStores';
import MorteeMode from '@mortee/morteeMode';
import MultiChipSelector, { MultiChipOption } from '@app/components/MultiChipSelector';
import {
  INITIAL_PAGE_NUMBER,
  isValidationRecordFilterEmpty,
  SupplierValidationRecordFieldFilters,
  SupplierValidationRecordQuickFilter,
  SupplierValidationRecordSorting,
  SupplierValidationRecordTableState,
} from '@mortee/domain/validationRecordTable';
import useAppStores from '@app/hooks/useAppStores';
import SupplierValidationRecordTableSearch from '@mortee/routes/validationSystem/validationRecordTable/SupplierValidationRecordTableSearch';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import Loadable, { LoadableCreator } from '@app/utils/Loadable';
import { useQueryParam } from '@app/hooks/useQueryParam';
import { UUID_REGEX } from '@app/utils/regexUtils';
import useControlledModal from '@app/hooks/useControlledModal';
import EditValidationRecordModal from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordModal';
import SVG from '@app/components/SVG';
import downloadFile from '@app/images/download-file-primary.svg';
import AsyncButton from '@app/components/AsyncButton';
import { CheckFileOptions, DEFAULT_MAX_FILE_NAME_LENGTH, DEFAULT_MAX_FILE_SIZE_MB, FileTypes } from '@app/domain/files';
import { secondsToMilliseconds } from '@app/utils/timeUtils';
import config from '@app/config';
import useIntervalRefreshingLoadable from '@app/hooks/loadable/useIntervalRefreshingLoadable';
import ValidationsReportModal from '@mortee/routes/validationSystem/validationSystemPageForManagement/ValidationsReportModal';
import PageHeader from '@app/components/header/PageHeader';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import IconAndText from '@mortee/components/IconAndText';
import validationRecordIcon from '@mortee/images/validationSystem/validationRecordIcon.svg';
import FileUploadDraggerModal from '@app/components/fileUpload/FileUploadDraggerModal';
import * as messageLauncher from '@app/utils/messageLauncher';
import { shoot } from '@app/utils/messageLauncher';
import { pluralize } from '@app/utils/stringUtils';
import { VALIDATIONS_TABLE_SELECTED_RECORD_ID_QUERY_PARAM } from '@mortee/stores/infraStores/MorteeNavigationStore';
import useValidationRecordTableViewQueryParam from '@mortee/hooks/useValidationRecordTableViewQueryParam';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import DropdownItem from '@app/components/inputs/DropdownItem';
import { TableView, TableViewData } from '@mortee/domain/validationSystemTableViews';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import ReactPlaceholder from 'react-placeholder/lib/ReactPlaceholder';
import { css } from '@emotion/react';

const ValidationSystemPageForManagement: FC = observer(() => {
  const { userStore, permissionsStore } = useInfraStores<MorteeMode>();
  const { validationSystemStore } = useAppStores<MorteeMode>();
  const modalContext = useModalContext();

  // TODO: move filters to url instead of state
  const [tableState, setTableState] = useState<SupplierValidationRecordTableState>({});
  const [loadingRowIds, setLoadingRowIds] = useState<string[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [searchInputText, setSearchInputText] = useState<string>('');
  const [uploadingEvidenceFile, setUploadingEvidenceFile] = useState<boolean>(false);
  const { currentTableView, onTableViewChange, tableViewOptions } = useValidationRecordTableViewQueryParam();
  const [openedEditingRecordIds, setOpenedEditingRecordIds] = useQueryParam(
    VALIDATIONS_TABLE_SELECTED_RECORD_ID_QUERY_PARAM,
    UUID_REGEX,
  );

  const openedEditModalRecordId = openedEditingRecordIds?.[0];
  const shouldRefreshTableInTheBackground = !openedEditModalRecordId && !loadingRowIds.length;

  const { tablePageSize, tableBackgroundRefreshIntervalSecs } = config.svManagement;

  useEffect(() => {
    const openedRecordId = openedEditModalRecordId;
    if (openedRecordId) {
      setSelectedRowId(openedRecordId);
    }
  }, [openedEditModalRecordId, setOpenedEditingRecordIds]);

  const [
    currentValidationsPageLoadable,
    reloadCurrentValidationsPage,
    setCurrentValidationsPageLoadable,
  ] = useIntervalRefreshingLoadable(
    async (abortRequestSignal) => {
      if (tableState.search) {
        const serverResponse = await validationSystemServices.searchValidationRecords(
          tableState.page,
          tablePageSize,
          tableState.search,
          abortRequestSignal,
        );
        return transformSupplierValidationRecordFilterResult(serverResponse, userStore.user?.id);
      } else {
        const combinedFilters = validationSystemStore.createCombinedFilters(tableState.fieldFilters, tableState.quickFilters);
        const serverResponse = await validationSystemServices.getValidationRecords(
          tableState.page,
          tablePageSize,
          combinedFilters,
          tableState.sort,
          abortRequestSignal,
        );
        return transformSupplierValidationRecordFilterResult(serverResponse, userStore.user?.id);
      }
    },
    secondsToMilliseconds(tableBackgroundRefreshIntervalSecs),
    shouldRefreshTableInTheBackground,
    [tableState],
  );

  function getItemsNavigationArrowsClickHandlers<T>(
    items: T[],
    selectedId: string | undefined,
    itemToIdGetter: (item: T) => string,
    onIdChange: (value: string | undefined) => void,
  ): ItemsNavigationArrowsClickHandlers {
    const matchingRecordIndex = items.findIndex((item) => itemToIdGetter(item) === selectedId) ?? -1;
    const isFirstRecordOfPage = matchingRecordIndex === 0;
    const isLastRecordOfPage = items.length && matchingRecordIndex === items.length - 1;

    const onPreviousClick = (): void => {
      onIdChange(itemToIdGetter(items[matchingRecordIndex - 1]));
    };
    const onNextRecordClick = (): void => {
      onIdChange(itemToIdGetter(items[matchingRecordIndex + 1]));
    };

    return {
      onPreviousClick: isFirstRecordOfPage ? undefined : onPreviousClick,
      onNextRecordClick: isLastRecordOfPage ? undefined : onNextRecordClick,
    };
  }

  useControlledModal(
    !!openedEditModalRecordId,
    (onDone) => {
      const { onPreviousClick, onNextRecordClick } = getItemsNavigationArrowsClickHandlers(
        currentValidationsPageLoadable.result?.content ?? [],
        openedEditModalRecordId,
        (item) => item.staticId,
        setOpenedEditingRecordIds,
      );

      return (
        <ModalAppContext {...modalContext}>
          <EditValidationRecordModal
            staticId={openedEditModalRecordId ?? ''}
            onPreviousRecordClick={onPreviousClick}
            onNextRecordClick={onNextRecordClick}
            onClose={onDone}
          />
        </ModalAppContext>
      );
    },
    (isSaved) => {
      setOpenedEditingRecordIds(null);
      if (isSaved) {
        reloadCurrentValidationsPage();
      }
    },
    { maskClosable: false },
    [openedEditingRecordIds?.[0], currentValidationsPageLoadable],
  );

  async function onNewValidationClick(): Promise<void> {
    const isSaved = await showCustomModalAsync((onAccept, onDecline) => (
      <ModalAppContext {...modalContext}>
        <CreateValidationRecordModal onSaved={onAccept} onCanceled={onDecline} />
      </ModalAppContext>
    ));

    if (isSaved) {
      await reloadCurrentValidationsPage();
    }
  }

  const checkFileOptions: CheckFileOptions = {
    allowedExtensions: [FileTypes.csv],
    maxFilesAllowed: 1,
    maxFileSizeMB: DEFAULT_MAX_FILE_SIZE_MB,
    maxFileNameLength: DEFAULT_MAX_FILE_NAME_LENGTH,
  };

  async function handleUpload(newFile: File): Promise<void> {
    const closeLoadingMessage = shoot({ type: 'loading', closeable: true }, 'Uploading Evidence File...');

    try {
      setUploadingEvidenceFile(true);
      const serverResponse = await validationSystemServices.storeVerificationEvidence(newFile);
      closeLoadingMessage();
      shoot(
        {
          type: 'success',
          closeable: true,
        },
        `Evidence file uploaded successfully for ${serverResponse.records.length} ${pluralize(
          'record',
          serverResponse.records.length,
        )}.`,
      );
      reloadCurrentValidationsPage();
    } catch {
      closeLoadingMessage();
    } finally {
      setUploadingEvidenceFile(false);
    }
  }

  async function onNewEvidenceClick(): Promise<void> {
    await showCustomModal((onDone) => (
      <ModalAppContext {...modalContext}>
        <FileUploadDraggerModal
          files={[]}
          checkFileOptions={checkFileOptions}
          onDone={async (newFiles): Promise<void> => {
            await handleUpload(newFiles[0]);
            onDone();
          }}
          onCancel={onDone}
          title='Upload Evidence File'
        />
      </ModalAppContext>
    ));
  }

  function onStatusChange(staticId: string, status: SupplierValidationRecordStatusObject): void {
    setSelectedRowId(staticId);
    // TODO: add cancel reason id via modal
    handleUpdateValidationRecord(staticId, {
      status: {
        content: transformToSupplierValidationRecordStatusUpdateRequest(status),
      },
    });
  }

  function onAssigneeChange(staticId: string, assigneeId: string | null | undefined): void {
    handleUpdateValidationRecord(staticId, { assigneeId: { content: assigneeId ?? null } });
  }

  async function handleUpdateValidationRecord(
    staticId: string,
    updateRequest: SupplierValidationRecordUpdateRequest,
  ): Promise<void> {
    setLoadingRowIds((currentValues) => [...currentValues, staticId]);

    try {
      const newRecordServerResponse = await validationSystemServices.updateValidationRecord(staticId, updateRequest);
      const newRecord = transformSupplierValidationRecord(newRecordServerResponse.record, userStore.user?.id);
      setCurrentValidationsPageLoadable(
        (prevLoadable): Loadable<PaginatedData<SupplierValidationRecord>> => {
          if (!prevLoadable.isResolved()) {
            return prevLoadable;
          }

          const sameOrganizationalUserInTable = prevLoadable.result.content.find((user) => user.staticId === newRecord.staticId);

          // Already in table, replace new old item with the new item
          if (!sameOrganizationalUserInTable) {
            return prevLoadable;
          }

          const newArray = [...prevLoadable.result.content];
          newArray[newArray.indexOf(sameOrganizationalUserInTable)] = newRecord;

          return LoadableCreator.resolved({ content: newArray, totalElements: prevLoadable.result.totalElements });
        },
      );
    } finally {
      setLoadingRowIds((currentValues) => arrayWithoutValue(currentValues, staticId));
    }
  }

  function handleTableViewChanged(newTableView: TableView): void {
    setTableState(
      (currentFilters): SupplierValidationRecordTableState => {
        return {
          ...currentFilters,
          quickFilters: undefined,
          fieldFilters: undefined,
          sort: undefined,
          page: INITIAL_PAGE_NUMBER,
        };
      },
    );

    onTableViewChange(newTableView);
  }

  function onQuickFiltersChanged(newQuickFilters: SupplierValidationRecordQuickFilter[]): void {
    setTableState(
      (currentFilters): SupplierValidationRecordTableState => {
        return {
          ...currentFilters,
          search: undefined,
          page: INITIAL_PAGE_NUMBER,
          quickFilters: newQuickFilters,
        };
      },
    );
  }

  function onFieldFiltersChanged(newFieldFilters: SupplierValidationRecordFieldFilters): void {
    setTableState(
      (currentFilters): SupplierValidationRecordTableState => {
        return {
          ...currentFilters,
          search: undefined,
          page: INITIAL_PAGE_NUMBER,
          fieldFilters: newFieldFilters,
        };
      },
    );
  }

  function onSortChanged(newSort: SupplierValidationRecordSorting | undefined): void {
    setTableState(
      (currentFilters): SupplierValidationRecordTableState => {
        return {
          ...currentFilters,
          search: undefined,
          page: INITIAL_PAGE_NUMBER,
          sort: newSort,
        };
      },
    );
  }

  function onPageChanged(newPage: number): void {
    setTableState(
      (currentFilters): SupplierValidationRecordTableState => {
        return {
          ...currentFilters,
          page: newPage,
        };
      },
    );
  }

  function onSearch(searchQuery: string): void {
    setTableState({
      search: searchQuery,
    });
  }

  function onSearchEnd(): void {
    setTableState({});
  }

  function renderTableHeader(): ReactNode {
    return (
      <TableHeader>
        <TableCustomization>
          <TableViews>
            <ConfigurationText>View:</ConfigurationText>
            <InputBox appearance='corners'>
              <TableViewDropdown
                name='validation-system-table-view'
                dataTestId='validation-system-table-view'
                accessibilityLabel='Validation records table view'
                placeholderStyle='onlyWhenEmpty'
                placeholder={null}
                value={currentTableView}
                heightType='ultra-thin'
                onChange={handleTableViewChanged}
                disabled={disabledTableOperations}
              >
                {tableViewOptions.map((option) => (
                  <DropdownItem key={option} value={option} textWhenSelected={TableViewData[option].text}>
                    {TableViewData[option].text}
                  </DropdownItem>
                ))}
              </TableViewDropdown>
            </InputBox>
          </TableViews>
          <QuickFilters>
            <ConfigurationText>Quick filters:</ConfigurationText>
            <ConditionalTooltip showTooltip={disabledTableOperations} title='Clear search in order to filter'>
              <QuickFiltersChipSelector
                id='validation-system-table-quick-filter'
                accessibilityLabel='Validation records quick filters'
                options={TableViewData[currentTableView].quickFilters.map((quickFilter) => quickFilterOptions[quickFilter])}
                value={tableState.quickFilters ?? []}
                onChange={onQuickFiltersChanged}
                disabled={disabledTableOperations}
                chipClassName={QuickFiltersChip}
              />
            </ConditionalTooltip>
          </QuickFilters>
          <SearchWrapper>
            <StyledSupplierValidationRecordTableSearch
              isSearchActive={!!tableState.search}
              value={searchInputText}
              onValueChange={setSearchInputText}
              onSearch={onSearch}
              onSearchEnd={onSearchEnd}
            />
          </SearchWrapper>
        </TableCustomization>
        <HeaderAmountText>
          {currentValidationsPageLoadable.resolve(
            (currentValidationsPage) => (
              <>
                <AmountText>{currentValidationsPage.totalElements}</AmountText> items
              </>
            ),
            () => (
              <ReactPlaceholder ready={false} type='rect' style={{ height: '1em', width: '8ch' }} children='' />
            ),
          )}
        </HeaderAmountText>
      </TableHeader>
    );
  }

  async function sendSupplierValidationRecordsReport(): Promise<void> {
    try {
      await validationSystemServices.sendValidationsReport();
      messageLauncher.shoot({ type: 'success' }, `Validation records report email sending triggered successfully`);
    } catch (e) {
      messageLauncher.shoot({ type: 'error' }, `Validation records report email sending trigger failed`);
    }
  }

  function showValidationsReportModal(): void {
    showCustomModal(
      (onDone): ReactElement => (
        <ModalAppContext {...modalContext}>
          <ValidationsReportModal onDone={onDone} />
        </ModalAppContext>
      ),
    );
  }

  const disabledTableOperations = !!tableState.search;
  const isTableFiltered =
    !isValidationRecordFilterEmpty(tableState.fieldFilters) || !!tableState.search || !!tableState.quickFilters?.length;

  const pageHeader = (
    <PageHeader>
      <PageHeaderTitle
        title={
          <IconAndText image={validationRecordIcon} iconAccessibilityLabel='validation record icon'>
            Validation System
          </IconAndText>
        }
      />
    </PageHeader>
  );

  return (
    <PageWithHeader width='full' header={pageHeader}>
      <TopRow>
        {permissionsStore.isAllowedToCreateValidationRecord && (
          <Button
            appearance='text'
            id='btn-validation-system-management-add-new-evidence'
            onClick={onNewEvidenceClick}
            disabled={uploadingEvidenceFile}
          >
            + NEW EVIDENCE
          </Button>
        )}
        {permissionsStore.isAllowedToCreateValidationsReport && (
          <Button
            id='btn-validation-system-management-export-validation-report'
            dataTestId='btn-validation-system-management-export-validation-report'
            appearance='text'
            colorScheme='primary'
            onClick={showValidationsReportModal}
          >
            Validations Report
          </Button>
        )}
        <SendRecordsReportButton
          id='btn-svm-send-records-report'
          dataTestId='btn-svm-send-records-report'
          appearance='text'
          colorScheme='primary'
          onClick={async (): Promise<void> => sendSupplierValidationRecordsReport()}
        >
          <SVG accessibilityLabel='' image={downloadFile} height={20} width={20} />
          Send Records Report
        </SendRecordsReportButton>
        {permissionsStore.isAllowedToCreateValidationRecord && (
          <Button id='btn-validation-system-management-add-new-validation' onClick={onNewValidationClick}>
            + NEW RECORD
          </Button>
        )}
      </TopRow>
      <SupplierValidationRecordTable
        header={renderTableHeader()}
        columnsToShow={TableViewData[currentTableView].columns}
        recordsLoadable={currentValidationsPageLoadable.map((x) => x.content)}
        onStatusChange={onStatusChange}
        onRecordClick={(staticId): void => {
          setOpenedEditingRecordIds(staticId);
        }}
        onNewValidationClick={onNewValidationClick}
        allUsersLoadable={validationSystemStore.allUsers}
        assignableUsersLoadable={validationSystemStore.allAssignableUsers}
        allOrganizationsLoadable={validationSystemStore.organizations}
        totalAmountOfItems={currentValidationsPageLoadable.map((x) => x.totalElements)}
        loadingRowIds={loadingRowIds}
        selectedRowId={selectedRowId}
        filters={tableState.fieldFilters}
        onFiltersChange={onFieldFiltersChanged}
        isTableFiltered={isTableFiltered}
        sort={tableState.sort}
        onSortChange={onSortChanged}
        currentPage={tableState.page ?? INITIAL_PAGE_NUMBER}
        onPageChange={onPageChanged}
        pageSize={tablePageSize}
        disableTableOperations={disabledTableOperations}
        onAssigneeChange={onAssigneeChange}
      />
    </PageWithHeader>
  );
});

const quickFilterOptions: Record<SupplierValidationRecordQuickFilter, MultiChipOption<SupplierValidationRecordQuickFilter>> = {
  [SupplierValidationRecordQuickFilter.onlyMine]: {
    id: 'validation-system-table-quick-filter-only-mine',
    text: 'Only Mine',
    value: SupplierValidationRecordQuickFilter.onlyMine,
  },
  [SupplierValidationRecordQuickFilter.notCompleted]: {
    id: 'validation-system-table-quick-filter-not-completed',
    text: 'Not Completed',
    value: SupplierValidationRecordQuickFilter.notCompleted,
  },
  [SupplierValidationRecordQuickFilter.unlinkedRecords]: {
    id: 'validation-system-table-quick-filter-unlinked-records',
    text: 'Unlinked Records',
    value: SupplierValidationRecordQuickFilter.unlinkedRecords,
  },
  [SupplierValidationRecordQuickFilter.manualFollowUp]: {
    id: 'validation-system-table-quick-filter-follow-up',
    text: 'Manual Follow Ups',
    value: SupplierValidationRecordQuickFilter.manualFollowUp,
  },
};

export default ValidationSystemPageForManagement;

const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  margin-bottom: 16px;
`;

const TableHeader = styled.div`
  padding: 16px 24px 0;
`;

const TableCustomization = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: 20px;
`;

const TableViews = styled.div`
  display: flex;
  align-items: baseline;

  // To align with the search bar
  margin-top: 6px;
  gap: 16px;
`;

const QuickFilters = styled.div`
  display: flex;
  align-items: baseline;

  // To align with the search bar
  margin-top: 6px;
  gap: 16px;
`;

const ConfigurationText = styled(SubtitleSmallStartTransparentBlack900.div)`
  flex: 0 0 auto;
`;

const TableViewDropdown = styled(NakedDropdown)`
  width: 200px;
`;

const SearchWrapper = styled.div`
  flex: 0 0 300px;

  display: flex;
  justify-content: flex-end;
  padding-left: 16px;
  // To stick it to the right
  margin-left: auto;
`;

const StyledSupplierValidationRecordTableSearch = styled(SupplierValidationRecordTableSearch)`
  width: min(100%, 400px);
`;

const HeaderAmountText = styled.div`
  padding-block: 20px;
  color: var(--transparent-black-600);
  font-size: 14px;
`;

const AmountText = styled.span`
  color: var(--transparent-black-900);
`;

const SendRecordsReportButton = styled(AsyncButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-right: 10px;
`;

const QuickFiltersChipSelector = styled(MultiChipSelector)`
  flex-wrap: wrap;
`;

const QuickFiltersChip = css`
  font-size: 13px;
`.toString();
