import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { CURRENCIES } from '@app/domain/currencies';
import { StyledComponents } from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/styles';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const CurrencyDropdown = forwardRef<HTMLDivElement, Props>(({ name, value, onChange }, ref) => {
  const { t } = useTranslation();
  const { CountryDropdownItem } = StyledComponents;

  return (
    <NakedDropdown
      ref={ref}
      value={value}
      onChange={onChange}
      accessibilityLabel={t('general.accessibility.currency')}
      name={name}
      dataTestId={name}
      placeholder={t<string>('supplierValidation.supplierRegister.currency')}
      isSearchable
    >
      {Object.keys(CURRENCIES).map((currency) => (
        <CountryDropdownItem key={currency} keywords={[currency]} value={currency} textWhenSelected={currency}>
          <div>{currency}</div>
        </CountryDropdownItem>
      ))}
    </NakedDropdown>
  );
});

CurrencyDropdown.displayName = 'CurrencyDropdown';

export default CurrencyDropdown;
