import request, { HttpMethod, HttpStatus } from '@app/libs/request';
import config, { serverUrl } from '@app/config';
import {
  CodeRequest,
  CodeResponse,
  GuestAuthCodeDeliveryMethod,
  GuestAuthMethodType,
  RequestWithKnoxId,
  TokenRequest,
  TokenResponse,
} from '@app/guestLogin/domain/guestLogin';
import Log from '@app/libs/logger';
import { AuthMethodType } from '@app/login/domain/loginConsts';

export function getKnoxerAddressConfig(
  knoxerId: string,
  authMethodType: GuestAuthMethodType,
  codeDeliveryMethod?: GuestAuthCodeDeliveryMethod,
): string | undefined {
  const knoxersAuthConfig = config.knoxersAuthData;

  if (!knoxersAuthConfig) {
    throw new Error('could not find knoxers config');
  }

  const knoxerAuthConfig = knoxersAuthConfig.find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId);

  if (!knoxerAuthConfig) {
    throw new Error(`could not find knoxer with id ${knoxerId}`);
  }

  if (authMethodType === GuestAuthMethodType.EmailPassword) {
    return knoxerAuthConfig.url + 'mobile-tokener-guest-email';
  }

  if (authMethodType === GuestAuthMethodType.PhoneNumber) {
    throw new Error(`PhoneNumber auth method is currently not supported`);
  }
}

export function getKnoxerSSOAddress(knoxerId: string): string | null {
  const knoxersAuthData = config.knoxersAuthData;

  if (!knoxersAuthData) {
    Log.event('could not find knoxers config');
    return null;
  }

  const knoxerAuthData = knoxersAuthData.find((knoxerAuthConfig) => knoxerAuthConfig.id === knoxerId);

  if (!knoxerAuthData) {
    Log.event(`could not find knoxer with id ${knoxerId}`);
    return null;
  }

  if (!knoxerAuthData.authMethods.includes(AuthMethodType.EmailPassword)) {
    Log.event(`No sso params for ${knoxerId}`);
    return null;
  }

  return knoxerAuthData.url + 'mobile-tokener-guest-oidc';
}

export async function guestLogout(): Promise<any> {
  return request(config.serverUrls.guestLogout, null, {
    method: HttpMethod.post,
  });
}

enum GuestLoginResponseAttributeType {
  email = 'Email',
  phone = 'Phone',
}

interface GuestLoginResponseAttribute {
  type: GuestLoginResponseAttributeType;
  source: string;
  value: string;
}

interface GuestLoginResponse {
  attributes: GuestLoginResponseAttribute[];
}

export async function guestLogin(): Promise<GuestLoginResponse> {
  return request<GuestLoginResponse>(serverUrl, `/api/login/guest`, {
    method: HttpMethod.get,
    dontRedirectToLogin: true,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
      [HttpStatus.unauthorized]: {
        suppressNotification: true,
      },
    },
  });
}

export async function requestCodeForPhone(
  codeRequest: CodeRequest,
  deliveryMethod: GuestAuthCodeDeliveryMethod,
): Promise<CodeResponse> {
  const { knoxerId, id } = codeRequest;

  const authAddress = getKnoxerAddressConfig(knoxerId, GuestAuthMethodType.PhoneNumber, deliveryMethod);

  assertKnoxerHaveUrl(authAddress, knoxerId, deliveryMethod);

  return request<CodeResponse>(authAddress, `/auth/code/request`, {
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    generateDynamicHeaders: false,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
    },
    data: { id },
  });
}

export async function requestCodeForEmail(codeRequest: CodeRequest): Promise<CodeResponse> {
  const { knoxerId, id } = codeRequest;

  const authAddress = getKnoxerAddressConfig(knoxerId, GuestAuthMethodType.EmailPassword);

  assertKnoxerHaveUrl(authAddress, knoxerId, GuestAuthMethodType.EmailPassword);

  return request<CodeResponse>(authAddress, `/auth/code/request`, {
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    generateDynamicHeaders: false,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
    },
    data: { id },
  });
}

export async function requestTokenForPhoneCode(
  tokenRequest: TokenRequest,
  deliveryMethod: GuestAuthCodeDeliveryMethod,
): Promise<TokenResponse> {
  const { id, flowId, code, knoxerId } = tokenRequest;

  const authAddress = getKnoxerAddressConfig(knoxerId, GuestAuthMethodType.PhoneNumber, deliveryMethod);

  assertKnoxerHaveUrl(authAddress, knoxerId, deliveryMethod);

  return request<TokenResponse>(authAddress, `/auth/code/token`, {
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    generateDynamicHeaders: false,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
    },
    suppressNotification: true,
    data: { id, flowId, code },
  });
}

export async function requestTokenForEmailCode(tokenRequest: TokenRequest): Promise<TokenResponse> {
  const { id, flowId, code, knoxerId } = tokenRequest;

  const authAddress = getKnoxerAddressConfig(knoxerId, GuestAuthMethodType.EmailPassword);

  assertKnoxerHaveUrl(authAddress, knoxerId, GuestAuthMethodType.EmailPassword);

  return request<TokenResponse>(authAddress, `/auth/code/token`, {
    method: HttpMethod.post,
    dontRedirectToLogin: true,
    generateDynamicHeaders: false,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
    },
    suppressNotification: true,
    data: { id, flowId, code },
  });
}

export interface SSOTokenResponse {
  token: string;
}

export async function requestTokenForSSOLogin(tokenRequest: RequestWithKnoxId): Promise<SSOTokenResponse> {
  const ssoAddress = getKnoxerSSOAddress(tokenRequest.knoxerId);

  if (!ssoAddress) {
    throw new Error('No SSO address for knoxer');
  }

  assertKnoxerHaveUrl(ssoAddress, tokenRequest.knoxerId, 'sso address');

  return request<SSOTokenResponse>(ssoAddress, `/auth/id-token`, {
    method: HttpMethod.get,
    dontRedirectToLogin: true,
    generateDynamicHeaders: false,
    errorsHandler: {
      default: {
        message: 'Unexpected error occurred while trying to login',
      },
    },
    suppressNotification: true,
  });
}

function assertKnoxerHaveUrl(url: string | undefined, knoxerId: string, urlName: string): asserts url is string {
  if (!url) {
    throw new Error(`Expected knoxerId ${knoxerId} to have ${urlName} configured`);
  }
}
