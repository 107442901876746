import { getDialCodeByCountryCode } from '@app/domain/countries';
import { joinNationalPhoneAndDialCode } from '@app/utils/phoneUtils';

export function calculateFormPhoneNumber(phoneCountryCode: string, localPhoneNumber: string): string | null {
  const dialCode = getDialCodeByCountryCode(phoneCountryCode);
  if (!dialCode) {
    return null;
  }
  return joinNationalPhoneAndDialCode(dialCode.toString(), localPhoneNumber);
}
