import { SupplierValidationRecord, SupplierValidationRecordStatus } from '@mortee/domain/validationSystem';
import { DateRange, YesNoFilterOption } from '@app/domain/filters';
import { SorterResult } from 'antd/lib/table/interface';
import { isDefined, removeEmptyFields } from '@app/utils/utils';
import { distinctValues, itemOrFirst, multiIntersection, size, valueOrValuesOrNullAsArray } from '@app/utils/arrayUtils';
import { SupplierValidationRecordAntdTableFilters } from '@mortee/routes/validationSystem/validationRecordTable/SupplierValidationRecordTable';
import { setsEqual } from '@app/utils/setUtils';

// The table numbers the pages starting from 1
export const INITIAL_PAGE_NUMBER = 1;

export interface SupplierValidationRecordTableState {
  page?: number;
  quickFilters?: SupplierValidationRecordQuickFilter[];
  fieldFilters?: SupplierValidationRecordFieldFilters;
  sort?: SupplierValidationRecordSorting;
  search?: string;
}

export enum SupplierValidationRecordTableColumn {
  id = 'id',
  evidenceType = 'evidenceType',
  supplierName = 'supplierName',
  organizationId = 'organizationId',
  customerInvolvement = 'customerInvolvement',
  automailerStatus = 'automailerStatus',
  followUpEmailStatus = 'followUpEmailStatus',
  registrationForm = 'registrationForm',
  registrationDate = 'registrationDate',
  currentInstruction = 'currentInstruction',
  status = 'status',
  assignee = 'assignee',
}

export enum SupplierValidationRecordSortableField {
  registrationDate = 'RegistrationDate',
}

export enum SortOrder {
  ascending = 'ASC',
  descending = 'DESC',
}

export enum SupplierValidationRecordQuickFilter {
  onlyMine = 'Only mine',
  notCompleted = 'Not completed',
  unlinkedRecords = 'Unlinked Records',
  manualFollowUp = 'Manual Follow Up',
}

export type SupplierValidationRecordFieldFilters = {
  status: SupplierValidationRecordStatus[] | undefined;
  assignee: string[] | undefined;
  didUserRegister: boolean | undefined;
  organizationId: string[] | undefined;
  registrationDate: DateRange | undefined;
  automailerStatus: string[] | undefined;
  followUpEmailStatus: string[] | undefined;
  evidenceType: string[] | undefined;
  customerInvolvement: YesNoFilterOption | undefined;
  instructionType: string[] | undefined;
  followUpFilter: boolean | undefined;
};

export interface SupplierValidationRecordSorting {
  field?: SupplierValidationRecordSortableField;
  order?: SortOrder;
}

export function validationRecordSortingEqual(
  first: Partial<SupplierValidationRecordSorting> | undefined,
  second: Partial<SupplierValidationRecordSorting> | undefined,
): boolean {
  return first?.field === second?.field && first?.order === second?.order;
}

export function isValidationRecordFilterEmpty(filters: SupplierValidationRecordFieldFilters | undefined): boolean {
  return validationRecordFilterEqual(filters, undefined);
}

export function validationRecordFilterEqual(
  first: SupplierValidationRecordFieldFilters | undefined,
  second: SupplierValidationRecordFieldFilters | undefined,
): boolean {
  return (
    first?.didUserRegister === second?.didUserRegister &&
    isDateRangeEquals(first?.registrationDate, second?.registrationDate) &&
    setsEqual(first?.assignee, second?.assignee) &&
    setsEqual(first?.status, second?.status) &&
    setsEqual(first?.organizationId, second?.organizationId) &&
    setsEqual(first?.automailerStatus, second?.automailerStatus) &&
    setsEqual(first?.followUpEmailStatus, second?.followUpEmailStatus) &&
    setsEqual(first?.evidenceType, second?.evidenceType) &&
    setsEqual(first?.instructionType, second?.instructionType) &&
    first?.customerInvolvement === second?.customerInvolvement
  );
}

export function mergeFieldFilters(
  ...multipleFilters: (SupplierValidationRecordFieldFilters | undefined)[]
): SupplierValidationRecordFieldFilters {
  return {
    assignee: mergeFieldFilter(...multipleFilters.map((x) => x?.assignee)),
    status: mergeFieldFilter(...multipleFilters.map((x) => x?.status)),
    organizationId: mergeFieldFilter(...multipleFilters.map((x) => x?.organizationId)),
    registrationDate: multipleFilters.map((x) => x?.registrationDate).find(isDefined),
    didUserRegister: multipleFilters.map((x) => x?.didUserRegister).find(isDefined),
    automailerStatus: multipleFilters.map((x) => x?.automailerStatus).find(isDefined),
    followUpEmailStatus: multipleFilters.map((x) => x?.followUpEmailStatus).find(isDefined),
    evidenceType: multipleFilters.map((x) => x?.evidenceType).find(isDefined),
    customerInvolvement: multipleFilters.map((x) => x?.customerInvolvement).find(isDefined),
    instructionType: multipleFilters.map((x) => x?.instructionType).find(isDefined),
    followUpFilter: multipleFilters.map((x) => x?.followUpFilter).find(isDefined),
  };
}

export function mergeFieldFilter<T>(...filtersValues: (Set<T> | T[] | null | undefined)[]): T[] | undefined {
  if (!filtersValues.length) {
    return undefined;
  }

  const filtersWithValues = filtersValues.filter((filterValues) => size(filterValues));

  if (!filtersWithValues.length) {
    return undefined;
  }

  return distinctValues(multiIntersection(...filtersWithValues));
}

function isDateRangeEquals(dateRange1: DateRange | undefined, dateRange2: DateRange | undefined): boolean {
  return dateRange1?.start === dateRange2?.end && dateRange1?.start === dateRange2?.end;
}

export function convertAntdSortOrderToOurs(
  order: SorterResult<SupplierValidationRecord>['order'] | undefined,
): SortOrder | undefined;
export function convertAntdSortOrderToOurs(order: SorterResult<SupplierValidationRecord>['order']): SortOrder;
export function convertAntdSortOrderToOurs(
  order: SorterResult<SupplierValidationRecord>['order'] | undefined,
): SortOrder | undefined {
  switch (order) {
    case 'ascend':
      return SortOrder.ascending;
    case 'descend':
      return SortOrder.descending;
  }
}

export function convertOurSortOrderToAntd(
  order: SortOrder | undefined,
): SorterResult<SupplierValidationRecord>['order'] | undefined {
  switch (order) {
    case SortOrder.ascending:
      return 'ascend';
    case SortOrder.descending:
      return 'descend';
  }
}

export function convertAntdSortToOurs(
  antdSort: SorterResult<SupplierValidationRecord>,
): SupplierValidationRecordSorting | undefined {
  const order = convertAntdSortOrderToOurs(antdSort.order);
  const field = convertAntdFilterFieldToSortField(antdSort.columnKey as SupplierValidationRecordTableColumn | null | undefined);

  if (!order || !field) {
    return undefined;
  }

  return {
    order,
    field,
  };
}

export function convertAntdFilterFieldToSortField(
  filterField: SupplierValidationRecordTableColumn | null | undefined,
): SupplierValidationRecordSortableField | undefined {
  switch (filterField) {
    case SupplierValidationRecordTableColumn.registrationDate:
      return SupplierValidationRecordSortableField.registrationDate;
  }
}

export function getAntdSortForField(
  currentSortState: SupplierValidationRecordSorting | undefined,
  fieldInQuestion: SupplierValidationRecordSortableField,
): SorterResult<any>['order'] | undefined {
  if (currentSortState?.field === fieldInQuestion) {
    return convertOurSortOrderToAntd(currentSortState.order);
  }

  return undefined;
}

export function convertAntdFilterToOurFilters(
  antdFilters: SupplierValidationRecordAntdTableFilters,
): SupplierValidationRecordFieldFilters {
  return removeEmptyFields({
    ...antdFilters,
    registrationDate: itemOrFirst(antdFilters.registrationDate),
    automailerStatus: antdFilters.automailerStatus,
    followUpEmailStatus: antdFilters.followUpEmailStatus,
    evidenceType: antdFilters.evidenceType,
    customerInvolvement: antdFilters.customerInvolvement,
    instructionType: antdFilters.instructionType,
  });
}

export function convertOurFiltersToAntdFilters(
  ourFilters: SupplierValidationRecordFieldFilters | undefined,
): SupplierValidationRecordAntdTableFilters | undefined {
  if (!ourFilters) {
    return undefined;
  }

  return removeEmptyFields({
    ...ourFilters,
    registrationDate: valueOrValuesOrNullAsArray(ourFilters?.registrationDate),
    automailerStatus: valueOrValuesOrNullAsArray(ourFilters?.automailerStatus),
    evidenceType: valueOrValuesOrNullAsArray(ourFilters?.evidenceType),
    customerInvolvement: ourFilters?.customerInvolvement,
    instructionType: valueOrValuesOrNullAsArray(ourFilters?.instructionType),
  });
}
