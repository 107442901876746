import Loadable, { flowadWithPercentage, LoadableCreator, LoadingState } from '@app/utils/Loadable';
import { observer } from 'mobx-react';
import React, { FunctionComponent, ReactElement, useContext, useState } from 'react';
import useInfraStores from '@app/hooks/useInfraStores';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import styled from '@emotion/styled';
import downloadEnabled from '@app/images/download-v2-primary-enabled.svg';
import downloadDisabled from '@app/images/download-v2-disabled.svg';
import SVG from '@app/components/SVG';
import LinearProgress from '@mui/material/LinearProgress';
import ExportConfirmationPopup from '@mortee/routes/masterDataGuard/masterDataGuardLivePage/livePage/buttons/ExportConfirmationPopup';
import { translateContentTypeToFileExtension } from '@app/domain/files';
import LivePrivatePayeesFiltersContext from '@mortee/routes/masterDataGuard/LivePrivatePayeesFiltersContext';
import { SubtitleSmallStartPrimary300 } from '@app/components/Text';
import { downloadResourceByNamedResource } from '@app/utils/fileUtils';
import ButtonThatOpensPopup from '@app/components/popup/ButtonThatOpensPopup';

interface Props {
  disabled?: boolean;
  className?: string;
}

const ExportButton: FunctionComponent<Props> = observer(({ disabled, className }) => {
  const { permissionsStore, localeStore } = useInfraStores<MorteeMode>();
  const { masterDataGuardStore } = useAppStores<MorteeMode>();
  const { filters } = useContext(LivePrivatePayeesFiltersContext);
  const [reportLoadable, setReportLoadable] = useState<Loadable<NamedResource>>(LoadableCreator.notStarted());

  const loadReportAndDownloadResource = flowadWithPercentage(
    setReportLoadable,
    async () => {
      const timeAtStartOfAction = Date.now();
      const namedResource = await masterDataGuardStore.calculateVisibleLiveData(filters);

      await downloadResourceByNamedResource({
        ...namedResource,
        name: `${namedResource.name} - ${localeStore.formatDateTimeForFilename(
          timeAtStartOfAction,
        )}${translateContentTypeToFileExtension(namedResource.resourceData.mediaType)}`,
      });
      return namedResource;
    },
    () => masterDataGuardStore.exportedReportExpectedExecutionTimeMillis,
  );

  const isReportLoading = reportLoadable.loadState === LoadingState.InProgress;
  const isButtonDisabled = disabled || isReportLoading || !permissionsStore.isMasterDataGuardEnable;

  return (
    <>
      <StyledButton
        id='btn-mdg-export-data'
        disabled={isButtonDisabled}
        colorScheme='lightBluePrimary'
        shadowType='none'
        className={className}
        popupContent={(onDone): ReactElement => (
          <ExportConfirmationPopup
            onApprove={(): void => {
              loadReportAndDownloadResource();
            }}
            onClose={onDone}
          />
        )}
        popupPlacement='bottom-right'
      >
        <StyledDownloadSVG accessibilityLabel='' image={isButtonDisabled ? downloadDisabled : downloadEnabled} />
        <Text>{isReportLoading ? 'Exporting' : 'Export'}</Text>
        {reportLoadable.loadState === LoadingState.InProgress && (
          <LoadProgressBar color='primary' variant='determinate' value={reportLoadable.stateMetadata?.loadingPercentage ?? 0} />
        )}
      </StyledButton>
    </>
  );
});

export default ExportButton;

const StyledButton = styled(ButtonThatOpensPopup)`
  ${SubtitleSmallStartPrimary300.css};

  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  padding: 6px 12px;

  box-shadow: unset;
`;

const Text = styled.div`
  margin-left: 8px;

  font-family: var(--text-font-family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
`;

const StyledDownloadSVG = styled(SVG)`
  width: 24px;
  height: 24px;
`;

const LoadProgressBar = styled(LinearProgress)`
  position: absolute !important;
  bottom: 0;
  left: 6px;
  right: 6px;
  height: 2px !important;
`;
