import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { getAccountTypeByCountry } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import { StyledComponents } from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/styles';

export const AccountTypeDropdown = forwardRef<
  HTMLDivElement,
  { accountCountryCode: string | undefined; value?: string; onChange?: (value: string) => void }
>(({ accountCountryCode, value, onChange }, ref) => {
  const { t } = useTranslation();
  const { AccountTypeDropdownItem } = StyledComponents;

  const accountTypes = getAccountTypeByCountry(accountCountryCode);

  if (!accountTypes) {
    return <></>;
  }

  return (
    <NakedDropdown
      ref={ref}
      accessibilityLabel={t('general.accessibility.accountType')}
      name='account-type-drop'
      dataTestId='account-type-drop'
      placeholder={t<string>('supplierValidation.supplierRegister.accountType')}
      isSearchable
      value={value}
      onChange={onChange}
    >
      {Object.entries(accountTypes).map(([type]) => (
        <AccountTypeDropdownItem
          key={type}
          keywords={[type]}
          value={type}
          textWhenSelected={`supplierValidation.supplierRegister.accountTypes.${type}`}
        >
          {t(`supplierValidation.supplierRegister.accountTypes.${type}`)}
        </AccountTypeDropdownItem>
      ))}
    </NakedDropdown>
  );
});

AccountTypeDropdown.displayName = 'AccountTypeDropdown';

export default AccountTypeDropdown;
