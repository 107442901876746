import React, { FunctionComponent } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import styled from '@emotion/styled';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { action, flow } from 'mobx';
import { LOGIN_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import { ActionsContainer, PrimaryButton, Title } from '../Styles';
import { AuthMethodType, LoginErrorCodes, UnclaimedUserPasswordKnoxer } from '@app/login/domain/loginConsts';
import Button from '@app/components/Button';
import { FormFieldDecorators } from '@app/utils/form/form';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import PasswordFormInput from '@app/components/inputs/PasswordFormInput';
import GoogleSSOButton from '@app/components/GoogleSSOButton';
import useInfraStores from '@app/hooks/useInfraStores';
import AuthenticatedMode from '@app/login/AuthenticatedMode';
import useMountEffect from '@app/hooks/useMountEffect';
import { extractLogErrorIdFromError, isAuthenticatorErrorContent, isRequestError } from '@app/libs/request';
import { createEnglishFormalErrorMessage } from '@app/utils/errorMessageUtils';
import * as messageLauncher from '@app/utils/messageLauncher';

interface UserPasswordLoginFormFields {
  username: string;
  password: string;
}

interface Props extends FormComponentProps<UserPasswordLoginFormFields> {
  knoxerToClaim: UnclaimedUserPasswordKnoxer;
  initialEmail?: string;
  goToForgotPassword: (email: string) => void;
}

const UserPasswordLoginForm: FunctionComponent<Props> = observer((props) => {
  const { authenticationStore } = useInfraStores<AuthenticatedMode>();

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,
  }));

  useMountEffect(() => {
    const { form } = props;
    const { validateFields } = form;

    validateFields();
  });

  const fieldDecorators: FormFieldDecorators<UserPasswordLoginFormFields> = {
    username: {
      initialValue: props.initialEmail,
      rules: [
        {
          required: true,
          message: 'Please enter a valid email',
        },
        {
          max: LOGIN_FIELD_MAX_LENGTH.username,
          message: `Max ${LOGIN_FIELD_MAX_LENGTH.username} characters`,
        },
        {
          type: 'email',
          message: 'Invalid email address',
        },
      ],
    },
    password: {
      rules: [
        {
          required: true,
          message: 'Please enter a valid password',
        },
        {
          max: LOGIN_FIELD_MAX_LENGTH.password,
          message: `Max ${LOGIN_FIELD_MAX_LENGTH.password} characters`,
        },
      ],
    },
  };

  const claimWithUsernamePassword = flow(function* (username: string, password: string) {
    const { knoxerToClaim } = props;

    if (!knoxerToClaim) {
      return;
    }

    try {
      yield authenticationStore.claimWithUsernamePassword(knoxerToClaim.knoxer.id, username, password);
      authenticationStore.setMethodAuthData(AuthMethodType.EmailPassword, { username });
    } catch (e: unknown) {
      if (
        isRequestError(e) &&
        isAuthenticatorErrorContent(e.responseJSON) &&
        e.responseJSON.errorCode === LoginErrorCodes.InvalidCredentials
      ) {
        localStore.inputErrorText = 'Wrong password';
      } else {
        const errorLogId = extractLogErrorIdFromError(e);
        const errorMessage = createEnglishFormalErrorMessage('An error occurred during the sign in process.', errorLogId);
        messageLauncher.shootErrorOld(errorMessage);
      }
    }
  });

  const changeRememberMe = action((event: React.ChangeEvent<HTMLInputElement>): void => {
    const { form } = props;
    authenticationStore.setRememberMe(event.currentTarget.checked);

    // this is a hack to prevent the 'remember me' button from enabling the 'continue' on accident
    const { validateFields } = form;
    validateFields();
  });

  const handlePasswordInputFocus = action(() => {
    localStore.inputErrorText = null;
  });

  const isFormInvalid = (): boolean => {
    const { form } = props;
    const { getFieldsError, getFieldsValue } = form;
    const fieldsError = getFieldsError();

    const fieldsValues = Object.values(getFieldsValue());
    const fields = Object.keys(fieldsError);

    return fields.length === 0 || fields.some((field) => fieldsError[field]) || fieldsValues.some((fieldValue) => !fieldValue);
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      await handleOk();
    }
  };

  const handleOk = (): void => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    if (authenticationStore.loading) {
      return;
    }

    validateFieldsAndScroll((errors: Object, values: UserAuth) => {
      if (errors) {
        return;
      }

      const { username, password } = values;

      claimWithUsernamePassword(username, password);
    });
  };

  const handleGoogleSsoLogin = (): void => {
    const { nextUnclaimedKnoxer } = authenticationStore;

    if (!nextUnclaimedKnoxer) {
      return;
    }

    const knoxerId = nextUnclaimedKnoxer.knoxer.id;

    authenticationStore.redirectToPageForSsoGoogleAuthentication(knoxerId);
  };

  const { knoxerToClaim } = props;

  if (!knoxerToClaim) {
    return <div />;
  }

  const { form, goToForgotPassword } = props;
  const { rememberMe, isGoogleSsoLoginPageMode } = authenticationStore;

  const isFormInvalidEvaluated = isFormInvalid();

  return (
    <>
      <ContentContainer isGoogleSso={isGoogleSsoLoginPageMode}>
        <Title>Sign in</Title>
        <UserPasswordForm onSubmit={handleOk}>
          <FormItemBox appearance='line' fieldName='username' fieldDecoratorOptions={fieldDecorators.username} form={form}>
            <NakedFormInput
              name='btn-auth-user-password-email'
              type='text'
              autoFocus
              colorScheme='secondary'
              placeholder='Email'
              onClick={handlePasswordInputFocus}
              onFocus={handlePasswordInputFocus}
              onKeyPress={handleKeyPress}
              disableSuggestion
            />
          </FormItemBox>
          <FormItemBox
            appearance='line'
            fieldName='password'
            fieldDecoratorOptions={fieldDecorators.password}
            form={form}
            forcedErrorText={localStore.inputErrorText}
          >
            <PasswordFormInput
              name='btn-auth-user-password-password'
              colorScheme='secondary'
              placeholder='Password'
              onClick={handlePasswordInputFocus}
              onFocus={handlePasswordInputFocus}
              onKeyPress={handleKeyPress}
              disableSuggestion
            />
          </FormItemBox>
          <StyledFormControlLabel
            control={
              <Checkbox
                id='cbox-auth-user-password-remember-me'
                checked={rememberMe}
                onChange={changeRememberMe}
                color='primary'
              />
            }
            label={<StyledRememberMe>REMEMBER THIS COMPUTER</StyledRememberMe>}
          />
          <ActionsContainer>
            <Button
              id='btn-auth-user-password-forgot-start'
              appearance='text'
              type='button'
              size='small'
              onClick={(): void => goToForgotPassword(form.getFieldValue('username'))}
            >
              FORGOT PASSWORD
            </Button>
            <PrimaryButton id='btn-auth-user-password-continue' disabled={isFormInvalidEvaluated} onClick={handleOk}>
              CONTINUE
            </PrimaryButton>
          </ActionsContainer>
        </UserPasswordForm>
      </ContentContainer>
      {isGoogleSsoLoginPageMode && (
        <SsoSignInContainer>
          <SSOButtonContainer>
            <GoogleSSOButton onClick={handleGoogleSsoLogin} />
          </SSOButtonContainer>
        </SsoSignInContainer>
      )}
    </>
  );
});

export default Form.create<Props>()(UserPasswordLoginForm);

const ContentContainer = styled.div<{ isGoogleSso: boolean }>`
  padding: 48px 64px ${(p): number => (p.isGoogleSso ? 0 : 64)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const UserPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-left: 0;
  font-size: 12px;
  letter-spacing: 0.68px;
  color: var(--primary-200);
`;

const StyledRememberMe = styled.span`
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.68px;
  color: var(--primary-200);
`;

const SsoSignInContainer = styled.div`
  margin-top: 42px;
  width: 100%;
  border-top: solid 1px var(--transparent-gray-150);
`;

const SSOButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
