import AppStoresByOrganizationProvider from '@app/AppStoresByOrganizationProvider';
import CommonAppContext from '@app/CommonAppContext';
import ErrorBoundary from '@app/components/ErrorBoundary';
import HealthChecker from '@app/components/HealthChecker';
import Loader from '@app/components/Loader';
import config from '@app/config';
import useLoadable from '@app/hooks/loadable/useLoadable';
import Log from '@app/libs/logger';
import GoogleAnalyticsMonitor from '@app/libs/logger/analytics/GoogleAnalyticsMonitor';
import ConsoleLogger from '@app/libs/logger/loggers/ConsoleLogger';
import EmptyEventLogger from '@app/libs/logger/loggers/EmptyEventLogger';
import WebSentryLogger from '@app/libs/logger/loggers/WebSentryLogger';
import ConsolePerfMonitor from '@app/libs/logger/perfMonitor/ConsolePerfMonitor';
import Mode from '@app/modes/Mode';
import MobileNotSupportedPage from '@app/routes/MobileNotSupportedPage';
import Routes from '@app/routes/Routes';
import InfraStores from '@app/stores/InfraStores';
import ArMode from '@ar/arMode';
import BackeeMode from '@backee/backeeMode';
import MorteeMode from '@mortee/morteeMode';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { message } from 'antd';
import isMobile from 'is-mobile';
import { configure } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

configure({
  enforceActions: process.env.NODE_ENV !== 'production' ? 'observed' : 'never',
});

const isProd = process.env.NODE_ENV === 'production';

Log.init({
  exceptionLogger: isProd ? new WebSentryLogger(config.sentryUrlPath, config.version) : new ConsoleLogger(),
  eventLogger: new EmptyEventLogger(),
  analyticsMonitor: config.gtmId ? new GoogleAnalyticsMonitor(config.gtmId) : null,
  perfMonitor: new ConsolePerfMonitor(),
  ignoredExceptions: ['"status":401', '"status":403'],
});

message.config({
  top: 40,
});

interface ModeAndInfraStore {
  mode: Mode<any>;
  infraStores: InfraStores;
}

const App: FunctionComponent = () => {
  const [modeLoadable] = useLoadable<ModeAndInfraStore>(async (): Promise<ModeAndInfraStore> => {
    let mode: Mode<InfraStores>;

    switch (config.mode) {
      case 'supplierRegistration': {
        mode = new SupplierRegistrationMode();
        break;
      }
      case 'ar': {
        mode = new ArMode();
        break;
      }
      case 'backee': {
        mode = new BackeeMode();
        break;
      }
      case 'mortee':
      default: {
        mode = new MorteeMode();
      }
    }

    const infraStores = mode.createInfraStores();
    await infraStores.init();

    return {
      mode,
      infraStores,
    };
  }, []);

  if (!modeLoadable.isResolved()) {
    return <Loader spinning fullScreen />;
  }

  if (isMobile({ tablet: true, featureDetect: true })) {
    return <MobileNotSupportedPage />;
  }

  const { mode, infraStores } = modeLoadable.result;

  const AppContent = mode.getAppContentComponent();

  return (
    <ErrorBoundary fullscreen>
      <MobXProviderContext.Provider value={{ mode, infraStores }}>
        <AppStoresByOrganizationProvider>
          <CommonAppContext>
            <Helmet>
              <title>{mode.getHeadTitle()}</title>
            </Helmet>
            <div id='alertMessageContainer' />
            <HealthChecker>
              <AppContent>
                <Routes />
              </AppContent>
            </HealthChecker>
          </CommonAppContext>
        </AppStoresByOrganizationProvider>
      </MobXProviderContext.Provider>
    </ErrorBoundary>
  );
};

export default App;

ReactDOM.render(<App />, document.getElementById('root'));
