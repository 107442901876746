import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import USA_STATES, { getUsaStatesOrder } from '@app/domain/states';
import styled from '@emotion/styled';
import i18n from 'i18next';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
}

const StateDropdown = forwardRef<any, Props>(({ name, placeholder, value, onChange }, ref) => {
  const { t } = useTranslation();

  return (
    <NakedDropdown
      ref={ref}
      accessibilityLabel={t('general.accessibility.state')}
      name={name}
      dataTestId={name}
      placeholder={t<string>(placeholder)}
      isSearchable
      value={value}
      onChange={onChange}
    >
      {getUsaStatesOrder(i18n.language, (name) => t(`supplierValidation.states.${name}`)).map((state) => {
        const stateValue = USA_STATES[state];
        return (
          <StateDropdownItem
            key={state}
            keywords={[t(`supplierValidation.states.${state}`), state]}
            value={stateValue}
            textWhenSelected={`supplierValidation.states.${state}`}
          >
            <div>{t(`supplierValidation.states.${state}`)}</div>
          </StateDropdownItem>
        );
      })}
    </NakedDropdown>
  );
});

StateDropdown.displayName = 'StateDropdown';

const StateDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

export default StateDropdown;
