import { findLast } from '@app/utils/arrayUtils';
import { ValidatedPayeeAccountVisibility } from '@mortee/domain/vaildatedPayeeManagement';

export interface ValidatedPayeeAccountToDisplay {
  accountDetails: MorteeAccountDetails;
  bankCountryCode: string | undefined;
  bankName: string | undefined;
  furtherCredit: string | undefined;
  presentationAccountNumber: string | null;
}

export interface ValidatedPayeeAccountEventDataServerResponse {
  bankName: string | null;
  bankCountryCode: string | null;
  furtherCredit: string | null;
}

export interface ValidatedPayeeAccountEventServerResponse {
  writeTimestamp: number;
  visibility: ValidatedPayeeAccountVisibility;
  contributingOrganizationName: string | null;
  contributingOrganizationId: string | null;
  data?: ValidatedPayeeAccountEventDataServerResponse;
}

export interface ValidatedPayeeAccountServerResponse {
  uniformId: string;
  payeeUniformId: string;
  events: ValidatedPayeeAccountEventServerResponse[];
  status: ValidatedPayeeAccountValidationStatus;
  accountDetails: MorteeAccountDetails;
  presentationAccountNumber: string | null;
  supplierValidationRegistrationNumber: string | undefined | null;
  attachments: ValidatedPayeeAccountAttachmentServerResponse[] | undefined;
  lastValidationTimestamp: number | undefined;
  localFormat?: string | null | undefined;
}

export interface ValidatedAccountEventData {
  bankName: string | null;
  bankCountryCode: string | null;
  furtherCredit: string | null;
}

export interface ValidatedPayeeAccountAttachmentServerResponse {
  description: string;
  fileMetadata: FileMetadataServerResponse;
  id: string;
  writeTimestamp: number;
  validatedPayeeUniformId: string;
  validatedPayeeAccountUniformId: string;
}

export enum ValidatedPayeeAccountValidationStatus {
  extendedValidation = 'ValidatedPayeeAccountExtendedValidation',
  reverseWireValidation = 'ValidatedPayeeAccountReverseWireValidation',
  internalValidation = 'ValidatedPayeeAccountInternalValidation',
  lightweightValidation = 'ValidatedPayeeAccountLightweightValidation',
  externalBankValidation = 'ValidatedPayeeAccountExternalBankValidation',
  lyonsBankValidation = 'ValidatedPayeeAccountLyonsValidation',
}

export enum ValidatedPayeeAccountValidationStatusSupplierValidationVerification {
  direct = 'Direct',
  linked = 'LinkedAccount',
  bankValidation = 'BankValidation',
  light = 'LightSv',
  arDirect = 'ArDirect',
  arLinked = 'ArLinked',
  internalValidation = 'InternalValidation',
  alreadyValidated = 'AlreadySv',
  lyonsValidation = 'LyonsValidation',
}

export function convertValidatedAccountValidationSupplierValidationLevelToText(
  validationStatus: ValidatedPayeeAccountValidationStatusSupplierValidationVerification | null,
): string {
  switch (validationStatus) {
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.alreadyValidated: {
      return 'Already SV';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.arDirect: {
      return 'AR Direct';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.arLinked: {
      return 'AR Linked';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.bankValidation: {
      return 'Bank Validation';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.linked: {
      return 'Linked Account';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.light: {
      return 'Light SV';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.internalValidation: {
      return 'Internal Validation';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.direct: {
      return 'Direct';
    }
    case ValidatedPayeeAccountValidationStatusSupplierValidationVerification.lyonsValidation: {
      return 'Lyons Validation';
    }
    default: {
      return 'Not Provided';
    }
  }
}

export enum ValidatedPayeeAccountValidationType {
  reverseWireValidation = 'ReverseWire',
  internalValidation = 'InternalValidation',
  extendedValidation = 'ExtendedValidation',
  lightweightValidation = 'LightweightValidation',
  externalBankValidation = 'ExternalBankValidation',
  lyonsValidation = 'LyonsValidation',
}

const validatedPayeeAccountValidationStatusToValidationType: Record<
  ValidatedPayeeAccountValidationStatus,
  ValidatedPayeeAccountValidationType
> = {
  [ValidatedPayeeAccountValidationStatus.extendedValidation]: ValidatedPayeeAccountValidationType.extendedValidation,
  [ValidatedPayeeAccountValidationStatus.reverseWireValidation]: ValidatedPayeeAccountValidationType.reverseWireValidation,
  [ValidatedPayeeAccountValidationStatus.internalValidation]: ValidatedPayeeAccountValidationType.internalValidation,
  [ValidatedPayeeAccountValidationStatus.lightweightValidation]: ValidatedPayeeAccountValidationType.lightweightValidation,
  [ValidatedPayeeAccountValidationStatus.externalBankValidation]: ValidatedPayeeAccountValidationType.externalBankValidation,
  [ValidatedPayeeAccountValidationStatus.lyonsBankValidation]: ValidatedPayeeAccountValidationType.lyonsValidation,
};

export function transformValidationStatusToType(
  validationStatus: ValidatedPayeeAccountValidationStatus,
): ValidatedPayeeAccountValidationType {
  return validatedPayeeAccountValidationStatusToValidationType[validationStatus];
}

export function convertValidatedAccountValidationLevelToText(
  validationStatus: ValidatedPayeeAccountValidationStatus | null,
): string {
  switch (validationStatus) {
    case ValidatedPayeeAccountValidationStatus.extendedValidation: {
      return 'Extended Validation';
    }
    case ValidatedPayeeAccountValidationStatus.lightweightValidation: {
      return 'Lightweight Validation';
    }
    case ValidatedPayeeAccountValidationStatus.reverseWireValidation: {
      return 'Reverse Wire Validation';
    }
    case ValidatedPayeeAccountValidationStatus.internalValidation: {
      return 'Internal Validation';
    }
    case ValidatedPayeeAccountValidationStatus.externalBankValidation: {
      return 'External Bank Validation';
    }
    case ValidatedPayeeAccountValidationStatus.lyonsBankValidation: {
      return 'Lyons Bank Validation';
    }
    default: {
      return 'Not Provided';
    }
  }
}

export interface ValidatedPayeeAccountAttachment {
  description: string;
  fileMetadata: FileMetadata;
  id: string;
  writeTimestamp: number;
  validatedPayeeUniformId: string;
  validatedPayeeAccountUniformId: string;
}

export interface ValidatedPayeeAccount {
  uniformId: string;
  payeeUniformId: string;
  events: ValidatedPayeeAccountEventServerResponse[];
  status: ValidatedPayeeAccountValidationStatus;
  accountDetails: MorteeAccountDetails;
  bankName: string | undefined;
  furtherCredit: string | undefined;
  bankCountryCode: string | undefined;
  supplierValidationRegistrationNumber: string | undefined;
  shortAccountDetails: string | undefined;
  shortAccountIdentifier: string | undefined;
  attachments: ValidatedPayeeAccountAttachment[] | undefined;
  lastValidationTimestamp: number | undefined;
  presentationAccountNumber: string | null;
  localFormat: string | undefined;
  visibility: ValidatedPayeeAccountVisibility | undefined;
  contributingOrganizationName: string | null;
}

export enum ValidatedPayeeAccountVerificationResultText {
  extendedValidation = 'Extended Validation',
  standardValidation = 'Standard Validation',
  unknownResult = 'unknownResult',
}

export const validatedPayeeAccountValidationStatusOrder = Object.values(ValidatedPayeeAccountValidationStatus);
export const translateValidatedAccountValidationLevel = (
  validationLevel: ValidatedPayeeAccountValidationStatus,
): ValidatedPayeeAccountVerificationResultText => {
  switch (validationLevel) {
    case ValidatedPayeeAccountValidationStatus.extendedValidation: {
      return ValidatedPayeeAccountVerificationResultText.extendedValidation;
    }
    case ValidatedPayeeAccountValidationStatus.lightweightValidation:
    case ValidatedPayeeAccountValidationStatus.reverseWireValidation:
    case ValidatedPayeeAccountValidationStatus.internalValidation:
    case ValidatedPayeeAccountValidationStatus.lyonsBankValidation: {
      return ValidatedPayeeAccountVerificationResultText.standardValidation;
    }
    default: {
      return ValidatedPayeeAccountVerificationResultText.unknownResult;
    }
  }
};

function createShortAccountIdentifier(
  bankName: ValidatedPayeeAccount['bankName'],
  shortAccountDetails: ValidatedPayeeAccount['shortAccountDetails'],
): ValidatedPayeeAccount['shortAccountIdentifier'] {
  if (!bankName) {
    return shortAccountDetails;
  }

  if (!shortAccountDetails) {
    return bankName;
  }

  return `${bankName} - ${shortAccountDetails}`;
}

function createShortAccountDetails(accountDetails: MorteeAccountDetails | null | undefined): string | undefined {
  if (!accountDetails) {
    return undefined;
  }

  if (accountDetails.accountNumber) {
    return accountDetails.accountNumber.substr(accountDetails.accountNumber.length - 4);
  }

  return accountDetails.iban?.substr(accountDetails.iban.length - 4);
}

export function transformValidatedPayeeAccount(
  accountServerResponse: ValidatedPayeeAccountServerResponse,
): ValidatedPayeeAccount {
  const bankName = findLast(accountServerResponse.events, (event) => !!event.data?.bankName)?.data?.bankName ?? undefined;
  const furtherCredit =
    findLast(accountServerResponse.events, (event) => !!event.data?.furtherCredit)?.data?.furtherCredit ?? undefined;
  const bankCountryCode =
    findLast(accountServerResponse.events, (event) => !!event.data?.bankCountryCode)?.data?.bankCountryCode ?? undefined;
  const shortAccountDetails = createShortAccountDetails(accountServerResponse.accountDetails);

  // To determine the visibility of the account we have to take a look at all events
  // If there is only one event - we take that visibility of that one
  // If there are multiple events with the same visibility - we take the visibility of the last one
  // If there are multiple events with different visibility - we do not show the visibility

  const calculateAccountVisibility = (
    events: ValidatedPayeeAccountEventServerResponse[],
  ): ValidatedPayeeAccountVisibility | undefined => {
    if (events.length === 1) {
      return events[0].visibility;
    }

    const visibilities = events.map((event) => event.visibility);
    const uniqueVisibilities = new Set(visibilities);

    if (uniqueVisibilities.size === 1) {
      return visibilities[visibilities.length - 1];
    }

    return undefined;
  };

  // Do the same for the contributing organization name:
  // If there is only one event - we take that contributing organization name of that one
  // If there are multiple events with the same contributing organization name - we take the contributing organization name of the last one
  // If there are multiple events with different contributing organization name - we do not show the contributing organization name

  const calculateContributingOrganizationName = (events: ValidatedPayeeAccountEventServerResponse[]): string | null => {
    if (events.length === 1) {
      return events[0].contributingOrganizationName;
    }

    const contributingOrganizationNames = events.map((event) => event.contributingOrganizationName);
    const uniqueContributingOrganizationNames = new Set(contributingOrganizationNames);

    if (uniqueContributingOrganizationNames.size === 1) {
      return contributingOrganizationNames[contributingOrganizationNames.length - 1];
    }

    return null;
  };

  const { attachments = [] } = accountServerResponse;

  return {
    ...accountServerResponse,
    supplierValidationRegistrationNumber: accountServerResponse.supplierValidationRegistrationNumber ?? undefined,
    bankName,
    bankCountryCode,
    furtherCredit,
    shortAccountDetails,
    shortAccountIdentifier: createShortAccountIdentifier(bankName, shortAccountDetails),
    attachments,
    lastValidationTimestamp: accountServerResponse.lastValidationTimestamp ?? undefined,
    localFormat: accountServerResponse.localFormat ?? undefined,
    visibility: calculateAccountVisibility(accountServerResponse.events),
    contributingOrganizationName: calculateContributingOrganizationName(accountServerResponse.events),
  };
}
