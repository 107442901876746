import request, { HttpMethod } from '@app/libs/request';
import config, { serverUrl } from '@app/config';
import { SupplierRegistrationProcessFormType } from '@app/domain/commonSupplierRegistration';
import {
  calculateBankValidationOfProcess,
  ShouldSendInstructionsToSupplier,
  SupplierRegistrationProcess,
  SupplierRegistrationProcessAddressDetails,
  SupplierRegistrationProcessAddressDetailsServerResponse,
  SupplierRegistrationProcessServerResponse,
  WiringFileTypes,
} from '@mortee/domain/morteeRegistrationForms';
import { calculatePriorDate } from '@app/utils/timeUtils';
import qs from 'query-string';
import { getCountryName } from '@app/domain/countries';
import { isTruthy } from '@app/utils/utils';

export async function getSupplierRegistrationProcesses(
  onlyLoadLastMonths: boolean,
  showHiddenRecords: boolean,
  showOnlyUnlinked: boolean,
): Promise<SupplierRegistrationProcessServerResponse[]> {
  let startTimestamp;
  if (onlyLoadLastMonths) {
    startTimestamp = calculatePriorDate(config.svManagement.registrationFormsPriorMonths ?? 3);
  }

  return request<SupplierRegistrationProcessServerResponse[]>(serverUrl, `/api/supplier-validation`, {
    method: HttpMethod.get,
    params: {
      'start-timestamp': startTimestamp,
      includeIgnored: showHiddenRecords,
      onlyUnlinked: showOnlyUnlinked,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    errorsHandler: {
      default: {
        message: 'Could not request for supplier validations.',
      },
    },
  });
}

export async function getSupplierRegistrationItemCrowdKnowledgeReportCsv(supplierRegistrationProcessId: string): Promise<string> {
  return request<string>(serverUrl, `/api/supplier-validation/:supplierRegistrationProcessId/crowd-knowledge-report`, {
    method: HttpMethod.get,
    pathParams: { supplierRegistrationProcessId },
  });
}

type visibilityRecordsBody = {
  isIgnored: boolean;
  processIds: string[];
};

export async function setVisibilitySupplierRegistrationItem(
  data: visibilityRecordsBody,
): Promise<SupplierRegistrationProcessServerResponse[]> {
  return request<SupplierRegistrationProcessServerResponse[]>(serverUrl, `/api/supplier-validation/is-ignored`, {
    method: HttpMethod.post,
    data,
    errorsHandler: {
      default: {
        message: 'Error updating records visibility.',
      },
    },
  });
}

export async function postSupplierRegistrationWiringFileReportCsv(
  supplierRegistrationProcessIds: string[],
  fileType: WiringFileTypes,
): Promise<void> {
  return request<void>(serverUrl, `/api/supplier-validation/generate-payment-file`, {
    method: HttpMethod.post,
    params: { type: fileType },
    data: supplierRegistrationProcessIds,
    errorsHandler: {
      PAYMENT_FILE_DATA_VALIDATION_ERROR: {
        message: (): string => `One or more record details are not compatible with ${fileType} WI.`,
      },
      default: {
        message: `Could not generate ${fileType} WI file for selected records.`,
      },
    },
  });
}

function transformToSupplierRegistrationProcessAddressDetails(
  serverResponse: SupplierRegistrationProcessAddressDetailsServerResponse,
): SupplierRegistrationProcessAddressDetails {
  const formattedAddress: string = [
    serverResponse.address,
    serverResponse.city,
    serverResponse.state,
    serverResponse.zipCode,
    getCountryName(serverResponse.countryCode),
  ]
    .filter(isTruthy)
    .join(', ');

  return {
    ...serverResponse,
    state: serverResponse.state ?? null,
    formattedAddress: formattedAddress,
  };
}

export function transformToSupplierRegistrationProcess(
  serverResponse: SupplierRegistrationProcessServerResponse,
): SupplierRegistrationProcess {
  return {
    id: serverResponse.id,
    type: serverResponse.type,
    isIndividual: serverResponse.isIndividual,
    hasAgreed: serverResponse.hasAgreed,
    asyncFullName: serverResponse.asyncFullName,
    firstName: serverResponse.firstName,
    lastName: serverResponse.lastName,
    email: serverResponse.email,
    phoneNumber: serverResponse.phoneNumber,
    companyName: serverResponse.companyName,
    additionalCompanyName: serverResponse.additionalCompanyName ?? null,
    address: {
      fullAddress: serverResponse.address.fullAddress ?? null,
      addressDetails: serverResponse.address.addressDetails
        ? transformToSupplierRegistrationProcessAddressDetails(serverResponse.address.addressDetails)
        : null,
    },
    legalId: serverResponse.legalId,
    legalIdType: serverResponse.legalIdType,
    countryCode: serverResponse.countryCode,
    companyWebsite: serverResponse.companyWebsite,
    expectedPaymentsExceedThreshold: serverResponse.expectedPaymentsExceedThreshold,
    referringCustomer: serverResponse.referringCustomer,
    accountDetails: serverResponse.accountDetails,
    accountBankCountryCode: serverResponse.accountBankCountryCode,
    abaRouting: serverResponse.abaRouting,
    customerIp: serverResponse.customerIp,
    registrationNumber: serverResponse.registrationNumber,
    writeTimestamp: serverResponse.writeTimestamp,
    organizationId: serverResponse.organizationId ?? null,
    configurationData: serverResponse.configurationData ?? null,
    currency: serverResponse.currency,
    instructionType: calculateBankValidationOfProcess(serverResponse),
    furtherCredit: serverResponse.furtherCredit,
    requestFromWebReferrerOrigins: serverResponse.requestFromWebReferrerOrigins,
    canUseAch: serverResponse.canUseAch,
    canUseEft: serverResponse.canUseEft,
    supplierValidationRelatedValidatedPayeesInfo: serverResponse.supplierValidationRelatedValidatedPayeesInfo,
    doesHaveCrowdKnowledgeCandidate: serverResponse.doesHaveCrowdKnowledgeCandidate ?? null,
    shouldSendInstructionToSupplier: getShouldSendInstructionsToSupplier(serverResponse),
    dateOfBirth: serverResponse.dateOfBirth ?? undefined,
    isLinkedToSvManagementRecord: serverResponse.isLinkedToSvManagementRecord,
    accountType: serverResponse.accountType ?? null,
    registrationId: serverResponse.registrationId ?? null,
    hidden: serverResponse.isIgnored,
    bankName: serverResponse.bankName ?? null,
    lyonsResponseMatch: serverResponse.lyonsResponseMatch ?? null,
    surepayResponseMatch: serverResponse.surepayResponseMatch ?? null,
  };
}

export function getShouldSendInstructionsToSupplier(
  process: SupplierRegistrationProcessServerResponse,
): ShouldSendInstructionsToSupplier {
  const {
    supplierValidationRelatedValidatedPayeesInfo,
    doesHaveCrowdKnowledgeCandidate,
    type,
    requestFromWebReferrerOrigins,
  } = process;

  if (
    supplierValidationRelatedValidatedPayeesInfo?.knoxIds?.length ||
    supplierValidationRelatedValidatedPayeesInfo?.legalEntityIdentifiers?.length
  ) {
    return ShouldSendInstructionsToSupplier.checkValidatedPayeeAccount;
  } else if (doesHaveCrowdKnowledgeCandidate) {
    return ShouldSendInstructionsToSupplier.checkCrowdKnowledgeAccount;
  } else if (requestFromWebReferrerOrigins || type === SupplierRegistrationProcessFormType.dynamicSupplierValidation) {
    return ShouldSendInstructionsToSupplier.yes;
  }

  return ShouldSendInstructionsToSupplier.no;
}
