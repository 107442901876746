import styled from '@emotion/styled';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import COUNTRIES, { TranslatedCountry, getCountriesOrder } from '@app/domain/countries';
import i18n from 'i18next';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import { FormInputProps } from '@app/utils/form/form';

interface CountryDropdownProps extends FormInputProps<string, true> {
  name: string;
  excludedCountries?: string[];
}

const CountryDropdown = forwardRef<any, CountryDropdownProps>(({ name, excludedCountries = [], ...props }, ref) => {
  const { t } = useTranslation();

  const filteredCountryCodes = getCountriesOrder(i18n.language, (countryName: string) =>
    t(`supplierValidation.countries.${countryName}`),
  ).filter((countryCode) => !excludedCountries.includes(countryCode));

  const translatedCountries: TranslatedCountry[] = filteredCountryCodes.map((countryCode) => {
    const country = COUNTRIES[countryCode];
    return {
      code: countryCode,
      translatedName: t(`supplierValidation.countries.${country.name}`),
    };
  });

  return (
    <NakedDropdown accessibilityLabel={t('general.accessibility.country')} name={name} dataTestId={name} isSearchable {...props}>
      {translatedCountries.map(({ code, translatedName }) => (
        <CountryDropdownItem key={code} keywords={[translatedName, code]} value={code} textWhenSelected={translatedName}>
          <div>{translatedName}</div>
        </CountryDropdownItem>
      ))}
    </NakedDropdown>
  );
});

CountryDropdown.displayName = 'CountryDropdown';

export default CountryDropdown;

const CountryDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;
