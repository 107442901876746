export const FIELD_MAX_LENGTH = {
  companyName: 100,
  companyAddress: 100,
  companyAddressCity: 50,
  companyAddressZipCode: 20,
  fullName: 100,
  firstName: 80,
  lastName: 80,
  countryCode: 3,
  dialCode: 4,
  email: 255,
  phoneNumber: 36,
  referrer: 100,
  url: 75,
  leiTypeCode: 30,
  leiValue: 50,
  currency: 3,
  furtherCredit: 200,
};
