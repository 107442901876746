import React, { FC, Suspense } from 'react';
import { Router } from 'react-router-dom';
import browserHistory from '@app/utils/browserHistory';
import Loader from '@app/components/Loader';
import DragNDropIndicationArea from '@app/components/fileUpload/DragNDropIndicationArea';
import { StyledEngineProvider } from '@mui/material/styles';
import AppThemeProvider from './routes/app/ThemeProvider';

const CommonAppContext: FC = (props) => {
  const { children } = props;
  return (
    <StyledEngineProvider injectFirst>
      <AppThemeProvider>
        <Router history={browserHistory}>
          <Suspense fallback={<Loader spinning fullScreen />}>
            <DragNDropIndicationArea>{children}</DragNDropIndicationArea>
          </Suspense>
        </Router>
      </AppThemeProvider>
    </StyledEngineProvider>
  );
};

export default CommonAppContext;
