import ModalAppContext from '@app/ModalAppContext';
import { showCustomModalAsync } from '@app/components/Modal';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { SupplierRegistrationRadioButtonOptions } from '@app/domain/commonSupplierRegistration';
import { COUNTRY_CODES, CountryCode, SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST } from '@app/domain/countries';
import { CURRENCIES_KEYS } from '@app/domain/currencies';
import { ACCOUNT_FIELD_MAX_LENGTH } from '@app/domain/uiConsts';
import useAppStores from '@app/hooks/useAppStores';
import useForm from '@app/hooks/useForm';
import useInfraStores from '@app/hooks/useInfraStores';
import useLeaveConfirmation from '@app/hooks/useLeaveConfirmation';
import useModalContext from '@app/hooks/useModalContext';
import useTheme from '@app/hooks/useTheme';
import { isDefined } from '@app/utils/utils';
import CountryDropdown from '@supplierRegistration/components/CountryDropdown';
import HideableSupplierRegistrationFormItemBox from '@supplierRegistration/components/HideableSupplierRegistrationFormItemBox';
import PleaseNote from '@supplierRegistration/components/PleaseNote';
import ReferringOrg from '@supplierRegistration/components/ReferringOrg';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import {
  canUseWiringTypesAnswerToBoolean,
  SupplierRegistrationBankAccountFormFields,
} from '@supplierRegistration/domain/supplierRegistration';
import {
  doesCountryHasSpecificBankCodeBehavior,
  getCountrySupplierRegistrationBankCodeField,
  getCountrySupplierRegistrationBranchCodeField,
  hideAccountTypeByCountry,
  hideBankNameByCountry,
  hideFurtherCreditByCountry,
} from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import {
  calculateAsyncInitiateValidationMaskedBankCodeAndBranchCode,
  calculateAsyncInitiateValidationMaskedIbanAndAccountNumber,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { createFieldDecorators } from '@supplierRegistration/domain/supplierRegistrationBankAccountDecorators';
import { getLegalIdTypesOfCountryForCompany } from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import {
  BigText,
  CardSubTitle,
  CardTitle,
  IAgreeText,
  MainCard,
  SmallContinueButton,
  SmallSeparatorWithExtraMargin,
  StyledCheckbox,
  StyledForm,
  StyledFormControlLabel,
} from '@supplierRegistration/routes/Styles';
import IsraelConfirmationModal from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/IsraelConfirmationModal';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import AccountTypeDropdown from '@supplierRegistration/components/AccountTypeDropdown';
import BankAccountDomesticField from '@supplierRegistration/components/BankAccountDomesticField';
import BranchAccountDomesticField from '@supplierRegistration/components/BranchAccountDomesticField';
import CurrencyDropdown from '@supplierRegistration/components/CurrencyDropdown';
import { StyledComponents } from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/styles';
import { SupplierRegistrationBankAccountDetailsProps } from '@supplierRegistration/types/supplierRegistrationTypes';

const SupplierRegistrationBankAccountDetails: FC<SupplierRegistrationBankAccountDetailsProps> = observer((props) => {
  useLeaveConfirmation();

  const { InputGrid, StyledRTLFormRadioGroup, HiddenableDiv } = StyledComponents;

  const { formValues, onFormSubmit, configuration, isIpsMode, isIndividual } = props;

  const { t } = useTranslation();

  const { languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const theme = useTheme<SupplierRegistrationMode>();
  const { brand } = theme;
  const modalContext = useModalContext();

  const [hasAgreedToTerms, setHasAgreedToTerms] = useState<boolean>(formValues?.accountValues?.hasAgreed ?? false);

  const { form, showFormErrors, setShowFormErrors } = useForm(props);

  const asyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.result;

  const getMaxAccountNumberLength = (accountBankCountryCode: CountryCode | undefined): number => {
    const countryCode = accountBankCountryCode || 'default';
    const countryMaxLength =
      ACCOUNT_FIELD_MAX_LENGTH[countryCode]?.accountNumber || ACCOUNT_FIELD_MAX_LENGTH['default'].accountNumber;
    return Math.max(countryMaxLength, ACCOUNT_FIELD_MAX_LENGTH.iban);
  };

  const accountBankCountryCode: CountryCode | undefined =
    form.getFieldValue('accountBankCountryCode') || asyncProcessMaskedData?.account.countryCode;
  const accountCurrency = form.getFieldValue('currency');
  const canUseAchAnswer = canUseWiringTypesAnswerToBoolean(form.getFieldValue('canUseAch'));
  const isCountryCodeUS = accountBankCountryCode === COUNTRY_CODES.UnitedStates;
  const isCountryCodeJP = accountBankCountryCode === COUNTRY_CODES.Japan;
  const isCountryCodeBR = accountBankCountryCode === COUNTRY_CODES.Brazil;
  const isCurrencyJPY = accountCurrency === CURRENCIES_KEYS.JPY;
  const accountNumberMaxLength = getMaxAccountNumberLength(accountBankCountryCode);
  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const isAsync = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.isResolved();

  const supplierCountryCode = form.getFieldValue('country');
  const legalIdTypesOfCountry = getLegalIdTypesOfCountryForCompany(supplierCountryCode);

  useEffect((): void => {
    // Prevent resetting the field in case we want to apply a saved value
    if (isCountryCodeUS) {
      form.resetFields(['bankCode']);
    } else {
      form.resetFields(['abaRouting', 'canUseAch']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [accountBankCountryCode, isCountryCodeUS]);

  useEffect((): void => {
    if (doesCountryHasSpecificBankCodeBehavior(accountBankCountryCode)) {
      form.resetFields(['bankCode', 'branchCode']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeUS, accountBankCountryCode, isCountryCodeJP]);

  useEffect((): void => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [legalIdTypesOfCountry]);

  useEffect((): void => {
    form.validateFields(['bankCode'], { force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [canUseAchAnswer]);

  useEffect((): void => {
    if (isCountryCodeJP && isCurrencyJPY) {
      form.resetFields(['accountType']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeJP, isCurrencyJPY]);

  useEffect((): void => {
    if (isCountryCodeBR) {
      form.resetFields(['accountType']);
    }

    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- form is a different instance every time
  }, [isCountryCodeBR]);

  const useDefaultBankCode = accountBankCountryCode === COUNTRY_CODES.Japan && accountCurrency !== CURRENCIES_KEYS.JPY;
  const countryBankCodeField = getCountrySupplierRegistrationBankCodeField(form, accountBankCountryCode, useDefaultBankCode);
  const countryBranchCodeField = getCountrySupplierRegistrationBranchCodeField(form, accountBankCountryCode, useDefaultBankCode);

  const asyncInitiateValidationMaskedIbanAndAccountNumber = calculateAsyncInitiateValidationMaskedIbanAndAccountNumber(
    asyncProcessMaskedData?.account,
  );
  const asyncInitiateValidationMaskedBankCodeAndBranchCode = calculateAsyncInitiateValidationMaskedBankCodeAndBranchCode(
    !!countryBankCodeField,
    !!countryBranchCodeField,
    asyncProcessMaskedData?.account,
  );

  const fieldDecorators = createFieldDecorators(
    form,
    asyncProcessMaskedData?.account,
    asyncInitiateValidationMaskedIbanAndAccountNumber,
    asyncInitiateValidationMaskedBankCodeAndBranchCode,
    accountBankCountryCode,
    !hideAccountTypeByCountry(accountBankCountryCode, form, !!isIndividual),
    canUseAchAnswer,
    formValues,
    accountNumberMaxLength,
    countryBankCodeField,
    countryBranchCodeField,
    !hideBankNameByCountry(accountBankCountryCode),
  );

  const registerDisableClick = (): void => {
    setShowFormErrors('all');
    form.validateFields();
  };

  const changeAgreedToTerms = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setHasAgreedToTerms(event.currentTarget.checked);

    setShowFormErrors('all');
    // this is a hack to prevent the 'remember me' button from enabling the 'continue' on accident
    form.validateFields();
  };

  const showUserPermissionsModal = async (): Promise<boolean | void> => {
    let hasAgreedIsraelTerms;
    await showCustomModalAsync(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <IsraelConfirmationModal
            onDone={(result: boolean | undefined): void => {
              hasAgreedIsraelTerms = result;
              onDone();
            }}
          />
        </ModalAppContext>
      ),
      {
        maskClosable: false,
      },
    );
    return hasAgreedIsraelTerms ? true : undefined;
  };

  const handleOk = (): void => {
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll(async (errors: Object, values: SupplierRegistrationBankAccountFormFields) => {
      if (errors) {
        return;
      }

      let hasAgreedIsraelTerms;
      if (accountBankCountryCode == COUNTRY_CODES.Israel) {
        hasAgreedIsraelTerms = await showUserPermissionsModal();
        if (!hasAgreedIsraelTerms) {
          return;
        }
      }

      onFormSubmit({ ...values, hasAgreed: hasAgreedToTerms, hasAgreedIsrael: hasAgreedIsraelTerms });
    });
  };

  function getCardSubTitle(): ReactNode {
    if (isIpsMode) {
      return <>{t('supplierValidation.registerSteps.bankAccountDetails.subTitleIps')}</>;
    }

    if (isAsync) {
      return (
        <Trans i18nKey='supplierValidation.registerSteps.bankAccountDetails.subTitleAsync' values={{ customerName }}>
          Complete missing bank account details. Details already provided to {{ customerName }} are prefilled. To modify any of
          these, please contact {{ customerName }}.
        </Trans>
      );
    }

    return (
      <Trans i18nKey='supplierValidation.registerSteps.bankAccountDetails.subTitle'>
        Enter the bank account details that you have <strong>provided to the payer</strong>
      </Trans>
    );
  }

  return (
    <>
      <MainCard fontFamily={theme.fontFamily}>
        <StyledForm onSubmit={handleOk} showErrors={showFormErrors} form={form} setShowErrors={setShowFormErrors}>
          {configuration?.clientName && !brand && <ReferringOrg orgName={configuration.clientName} />}
          <CardTitle>{t('supplierValidation.registerSteps.bankAccountDetails.title')}</CardTitle>
          <CardSubTitle>{getCardSubTitle()}</CardSubTitle>
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='accountBankCountryCode'
              fieldDecoratorOptions={fieldDecorators.accountBankCountryCode}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.countryCode)}
            >
              <CountryDropdown
                name='drp-supp-reg-account-bank-country'
                placeholder={t<string>('supplierValidation.supplierRegister.accountCountry')}
                excludedCountries={SUPPLIER_REGISTRATION_COUNTRY_CODE_BLACKLIST}
              />
            </SupplierRegistrationFormItemBox>
            <SupplierRegistrationFormItemBox
              fieldName='currency'
              fieldDecoratorOptions={fieldDecorators.currency}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.currency)}
            >
              <CurrencyDropdown name='drp-supp-reg-account-currency' />
            </SupplierRegistrationFormItemBox>
          </InputGrid>
          <SmallSeparatorWithExtraMargin />
          <InputGrid>
            <SupplierRegistrationFormItemBox
              fieldName='swiftCode'
              fieldDecoratorOptions={fieldDecorators.swiftCode}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.swiftCode)}
            >
              <NakedFormInput
                name='inpt-supp-reg-swift-code'
                dataTestId='inpt-supp-reg-swift-code'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.swift')}
                disableSuggestion
              />
            </SupplierRegistrationFormItemBox>
            {asyncInitiateValidationMaskedIbanAndAccountNumber.showAccountNumber && (
              <SupplierRegistrationFormItemBox
                fieldName='accountNumber'
                fieldDecoratorOptions={fieldDecorators.accountNumber}
                hasPrefilledData={isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber.accountNumber)}
              >
                <NakedFormInput
                  name='inpt-supp-reg-account-number'
                  dataTestId='inpt-supp-reg-account-number'
                  type='string'
                  placeholder={t<string>(
                    asyncInitiateValidationMaskedIbanAndAccountNumber.isAccountOrIban
                      ? [
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.accountOrIban`,
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.account`,
                          'supplierValidation.supplierRegister.accountOrIban',
                        ]
                      : [
                          `supplierValidation.supplierRegister.${accountBankCountryCode}.account`,
                          'supplierValidation.supplierRegister.account',
                        ],
                  )}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            {asyncInitiateValidationMaskedIbanAndAccountNumber.showIban && (
              <SupplierRegistrationFormItemBox
                fieldName='ibanForAsyncInitiateValidation'
                fieldDecoratorOptions={fieldDecorators.ibanForAsyncInitiateValidation}
                hasPrefilledData={isDefined(asyncInitiateValidationMaskedIbanAndAccountNumber.iban)}
              >
                <NakedFormInput
                  name='inpt-supp-reg-iban'
                  dataTestId='inpt-supp-reg-iban'
                  type='string'
                  placeholder={t<string>([
                    `supplierValidation.supplierRegister.${accountBankCountryCode}.iban`,
                    'supplierValidation.supplierRegister.iban',
                  ])}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            )}
            <BankAccountDomesticField
              form={form}
              bankCodeField={countryBankCodeField}
              accountCountryCode={accountBankCountryCode}
              fieldDecorators={fieldDecorators}
              asyncInitiateValidationMaskedBankCodeAndBranchCode={asyncInitiateValidationMaskedBankCodeAndBranchCode}
              hasPrefilledData={isDefined(asyncProcessMaskedData?.account.bankCode)}
              useDefaultBankCode={useDefaultBankCode}
            />
            <HideableSupplierRegistrationFormItemBox
              fieldName='furtherCredit'
              fieldDecoratorOptions={fieldDecorators.furtherCredit}
              hasPrefilledData={false}
              hidden={hideFurtherCreditByCountry(accountBankCountryCode)}
            >
              <NakedFormInput
                name='inpt-supp-reg-further-credit'
                dataTestId='inpt-supp-reg-further-credit'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.furtherCredit')}
                disableSuggestion
              />
            </HideableSupplierRegistrationFormItemBox>
            <BranchAccountDomesticField
              branchCodeField={countryBranchCodeField}
              accountCountryCode={accountBankCountryCode}
              fieldDecorators={fieldDecorators}
              asyncInitiateValidationMaskedBankCodeAndBranchCode={asyncInitiateValidationMaskedBankCodeAndBranchCode}
            />
            <HideableSupplierRegistrationFormItemBox
              fieldName='abaRouting'
              fieldDecoratorOptions={isCountryCodeUS ? fieldDecorators.abaRouting : {}}
              hasPrefilledData={false}
              hidden={!isCountryCodeUS}
            >
              <NakedFormInput
                name='inpt-supp-reg-us-aba-routing'
                dataTestId='inpt-supp-reg-us-aba-routing'
                type='string'
                placeholder={t<string>('supplierValidation.supplierRegister.US.abaRouting')}
                disableSuggestion
              />
            </HideableSupplierRegistrationFormItemBox>
            <HiddenableDiv hidden={hideAccountTypeByCountry(accountBankCountryCode, form, !!isIndividual)}>
              <SupplierRegistrationFormItemBox
                fieldName='accountType'
                fieldDecoratorOptions={fieldDecorators.accountType}
                hasPrefilledData={false}
              >
                <AccountTypeDropdown accountCountryCode={accountBankCountryCode} />
              </SupplierRegistrationFormItemBox>
            </HiddenableDiv>
            <HiddenableDiv hidden={hideBankNameByCountry(accountBankCountryCode)}>
              <SupplierRegistrationFormItemBox
                fieldName='bankName'
                fieldDecoratorOptions={fieldDecorators.bankName}
                hasPrefilledData={false}
              >
                <NakedFormInput
                  name='inpt-supp-reg-bank-name'
                  dataTestId='inpt-supp-reg-bank-name'
                  type='string'
                  placeholder={t<string>('supplierValidation.supplierRegister.bankName')}
                  disableSuggestion
                />
              </SupplierRegistrationFormItemBox>
            </HiddenableDiv>
          </InputGrid>
          <HiddenableDiv hidden={!isCountryCodeUS}>
            <BigText>
              <Trans i18nKey='supplierValidation.supplierRegister.US.achEligible'>
                Select "Yes" if your account is set up to <b>send</b> ACH payments.
                <br />
                Otherwise select "No".
              </Trans>
            </BigText>
            <SupplierRegistrationFormItemBox
              fieldName='canUseAch'
              fieldDecoratorOptions={fieldDecorators.canUseAch}
              appearance='none'
              hasPrefilledData={false}
            >
              <StyledRTLFormRadioGroup
                accessibilityLabel={t('supplierValidation.accessibility.supplierRegister.US.achEligible')}
                isRTL={languageStore.isRTL}
                id='radio-group-can-use-ach'
                dataTestId='radio-group-can-use-ach'
                options={[
                  {
                    value: SupplierRegistrationRadioButtonOptions.yes,
                    label: t('general.yes'),
                    dataTestId: 'yes-ach',
                  },
                  {
                    value: SupplierRegistrationRadioButtonOptions.no,
                    label: t('general.no'),
                    dataTestId: 'no-ach',
                  },
                ]}
              />
            </SupplierRegistrationFormItemBox>
          </HiddenableDiv>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                id='cbox-supp-reg-agree-terms'
                data-testid='cbox-supp-reg-agree-terms'
                checked={hasAgreedToTerms}
                onChange={changeAgreedToTerms}
              />
            }
            label={
              <IAgreeText>
                <Trans i18nKey='supplierValidation.supplierRegister.iAgree'>
                  By completing this form and clicking "Register", I confirm that I am authorized to do so, and authorize nsKnox
                  to process the details I provide, subject to the nsKnox{' '}
                  <a href='https://nsknox.net/terms-of-use/' target='_blank' rel='noreferrer'>
                    terms of use
                  </a>{' '}
                  and nsKnox{' '}
                  <a href='https://nsknox.net/privacy-policy/' target='_blank' rel='noreferrer'>
                    privacy policy
                  </a>
                  .
                </Trans>
              </IAgreeText>
            }
          />
          <SmallContinueButton
            id='btn-supp-reg-bank-account-continue'
            disabled={!hasAgreedToTerms}
            onClick={handleOk}
            onDisabledClick={registerDisableClick}
            colorScheme={theme.continueButtonColor}
          >
            {t('supplierValidation.supplierRegister.register')}
          </SmallContinueButton>
        </StyledForm>
      </MainCard>
      <PleaseNote isIpsMode={isIpsMode} configuration={configuration} />
    </>
  );
});

export default Form.create<SupplierRegistrationBankAccountDetailsProps>()(SupplierRegistrationBankAccountDetails);
