import request, { HttpMethod } from '@app/libs/request';
import { HealthCheckResponse } from '@app/domain/health';
import { trimToNull } from '@app/utils/stringUtils';

export async function serverHealthCheck(serverAddress: string): Promise<HealthCheckResponse> {
  return request<HealthCheckResponse>(serverAddress, `/actuator/health`, {
    method: HttpMethod.get,
    generateDynamicHeaders: false,
    dontRedirectToLogin: true,
    suppressNotification: true,
  });
}

export function checkHealthCheckResultValid(healthCheckResult: HealthCheckResponse): boolean {
  return trimToNull(healthCheckResult?.status) != null;
}
