import React, { FC } from 'react';
import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { BigLoadingSpinner } from '@app/components/BigLoadingSpinner';

export interface LoaderProps {
  spinning: boolean;
  fullScreen?: boolean;
  transparent?: boolean;
  small?: boolean;
  smallSize?: number;
  customColor?: string;
  whiteBackground?: boolean;
}

const Loader: FC<LoaderProps> = ({ spinning, fullScreen, transparent, whiteBackground, small, smallSize, customColor }) => (
  <LoaderInner hidden={!spinning} fullScreen={fullScreen} transparent={transparent} whiteBackground={whiteBackground}>
    {small ? (
      <SmallLoading size={smallSize ?? 16} foreground={customColor} />
    ) : (
      <Wrapper>
        <BigLoadingSpinner />
      </Wrapper>
    )}
  </LoaderInner>
);

export default Loader;

const SmallLoading = styled(CircularProgress)<{ foreground?: string }>`
  color: ${(p): string => p.foreground || 'white'} !important;
`;

const LoaderInner = styled.div<{
  fullScreen: boolean | undefined;
  transparent: boolean | undefined;
  whiteBackground: boolean | undefined;
  hidden: boolean | undefined;
}>`
  background-color: #f4f4f4;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  text-align: center;

  ${(p): string => (p.fullScreen ? 'position: fixed;' : '')}
  ${(p): string => (p.transparent ? 'background-color: transparent;' : '')}
  ${(p): string =>
    p.whiteBackground
      ? `
    background-color: #ffffff;
    margin: 16px;
    width: 95%;
  `
      : ''}
  ${(p): string =>
    p.hidden
      ? `
    z-index: -1;
    opacity: 0;
    transition: opacity 1s ease 0.5s, z-index 0.1s ease 1.0s;
  `
      : ''}
`;

const Wrapper = styled.div`
  width: 100px;
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
`;
