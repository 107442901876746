import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import styled from '@emotion/styled';
import PhoneCountryDropdown from '@supplierRegistration/components/PhoneCountryDropdown';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { SupplierRegistrationCompanyInfoFormFields } from '@supplierRegistration/domain/supplierRegistration';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  hasAsyncMaskedDataPhoneNumber: boolean;
  fieldDecorators: FormFieldDecorators<SupplierRegistrationCompanyInfoFormFields>;
}

const PhoneNumberInput: FC<Props> = ({ hasAsyncMaskedDataPhoneNumber, fieldDecorators }) => {
  const { t } = useTranslation();

  return (
    <MultiInputsContainer isLTRFixed>
      {hasAsyncMaskedDataPhoneNumber || (
        <FixedInputContainer>
          <SupplierRegistrationFormItemBox
            fieldName='phoneCountryCode'
            fieldDecoratorOptions={fieldDecorators.phoneCountryCode}
            hasPrefilledData={false}
          >
            <PhoneCountryDropdown />
          </SupplierRegistrationFormItemBox>
        </FixedInputContainer>
      )}
      <SecondInputContainer>
        <LocaleAwareDiv includeChildren>
          <SupplierRegistrationFormItemBox
            fieldName='phoneNumber'
            fieldDecoratorOptions={fieldDecorators.phoneNumber}
            hasPrefilledData={hasAsyncMaskedDataPhoneNumber}
          >
            <NakedFormInput
              name='inpt-supp-reg-phone-number'
              dataTestId='inpt-supp-reg-phone-number'
              type='text'
              placeholder={t<string>('supplierValidation.supplierRegister.phone')}
              disableSuggestion
            />
          </SupplierRegistrationFormItemBox>
        </LocaleAwareDiv>
      </SecondInputContainer>
    </MultiInputsContainer>
  );
};

const MultiInputsContainer = styled.div<{ isLTRFixed?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(p): string => (p.isLTRFixed ? `direction: ltr;` : '')}
`;

const FixedInputContainer = styled.div`
  width: 115px;
  margin-right: 16px;
`;

const SecondInputContainer = styled.div`
  flex: 7;
`;

export default PhoneNumberInput;
