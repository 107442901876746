import React, { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { SupplierRegistrationProcess } from '@mortee/domain/morteeRegistrationForms';
import SupplierRegistrationsTable from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationsTable';
import { showContentOnlyModal } from '@app/components/Modal';
import useAppStores from '@app/hooks/useAppStores';
import MorteeMode from '@mortee/morteeMode';
import { openSupplierRegistrationAllDataModal } from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationViewModal';
import PageWithHeader from '@app/components/PageWithHeader';
import PageHeader, { PageHeaderCenteredSideContent } from '@app/components/header/PageHeader';
import Button from '@app/components/Button';
import ModalAppContext from '@app/ModalAppContext';
import useModalContext from '@app/hooks/useModalContext';
import CreateValidationRecordModal from '@mortee/routes/validationSystem/createValidationRecord/CreateValidationRecordModal';
import useInfraStores from '@app/hooks/useInfraStores';
import { LoadableCreator } from '@app/utils/Loadable';
import styled from '@emotion/styled';
import config from '@app/config';
import { Checkbox } from '@mui/material';
import LinkSupplierRegistrationToSupplierValidationRecordForm from '@mortee/routes/validationSystem/SelectSupplierValidationRecordForSupplierRegistrationForm';
import PageHeaderTitle from '@app/components/header/PageHeaderTitle';
import IconAndText from '@mortee/components/IconAndText';
import supplierRegistrationProcessIcon from '@mortee/images/validationSystem/supplierRegistrationProcessIcon.svg';
import { arrayWithoutValue } from '@app/utils/arrayUtils';
import useIntervalRefreshingLoadable from '@app/hooks/loadable/useIntervalRefreshingLoadable';
import { secondsToMilliseconds } from '@app/utils/timeUtils';

const SupplierRegistrationListForManagementPage: FC = observer(() => {
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { supplierRegistrationForManagementStore } = useAppStores<MorteeMode>();

  const [onlyLoadLastMonths, setOnlyLoadLastMonths] = useState<boolean>(true);
  const [showHiddenRecords, setShowHiddenRecords] = useState<boolean>(false);
  const [showOnlyUnlinked, setShowOnlyUnlinked] = useState<boolean>(true);

  const modalContext = useModalContext();
  const { tableBackgroundRefreshIntervalSecs } = config.registrationForms;

  const [
    allRegistrationProcessesLoadable,
    reloadRegistrationProcesses,
    setAllRegistrationProcesses,
  ] = useIntervalRefreshingLoadable(
    async () =>
      await supplierRegistrationForManagementStore.getSupplierRegistrationProcesses(
        onlyLoadLastMonths,
        showHiddenRecords,
        showOnlyUnlinked,
      ),
    secondsToMilliseconds(tableBackgroundRefreshIntervalSecs),
    true,
    [onlyLoadLastMonths, showHiddenRecords, showOnlyUnlinked],
  );

  const updatedProcessInTable = (updatedProcess: SupplierRegistrationProcess): void => {
    setAllRegistrationProcesses((allRegistrationProcessesLoadable) => {
      if (!allRegistrationProcessesLoadable.isResolved()) {
        return allRegistrationProcessesLoadable;
      }

      if (!showHiddenRecords && updatedProcess.hidden) {
        return LoadableCreator.resolved(
          arrayWithoutValue(allRegistrationProcessesLoadable.result, updatedProcess, (first, second) => first.id === second.id),
        );
      }

      const sameProcessesInTable = allRegistrationProcessesLoadable.result.find((process) => process.id === updatedProcess.id);

      // Already in table, replace new old item with the new item
      if (sameProcessesInTable) {
        const newArray = [...allRegistrationProcessesLoadable.result];
        newArray[newArray.indexOf(sameProcessesInTable)] = updatedProcess;

        return LoadableCreator.resolved(newArray);
      }

      if (showHiddenRecords || !updatedProcess.hidden) {
        return LoadableCreator.resolved([...allRegistrationProcessesLoadable.result, updatedProcess]);
      }

      return allRegistrationProcessesLoadable;
    });
  };

  const onItemClicked = (item: SupplierRegistrationProcess): void => {
    openSupplierRegistrationAllDataModal(item, modalContext);
  };

  const onItemLinkToNewValidationClick = (item: SupplierRegistrationProcess): void => {
    showContentOnlyModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <CreateValidationRecordModal
            initialSupplierRegistrationProcess={item}
            onSaved={async (updatedProcess?: SupplierRegistrationProcess | undefined): Promise<void> => {
              if (updatedProcess) {
                updatedProcessInTable(updatedProcess);
              }
              onDone();
            }}
            onCanceled={onDone}
          />
        </ModalAppContext>
      ),
      {
        okText: 'DONE',
      },
    );
  };

  const onItemLinkToExistingValidationClick = (item: SupplierRegistrationProcess): void => {
    showContentOnlyModal(
      (onDone) => (
        <ModalAppContext {...modalContext}>
          <LinkSupplierRegistrationToSupplierValidationRecordForm
            supplierRegistrationProcess={item}
            onSaved={async (updatedProcess?: SupplierRegistrationProcess | undefined): Promise<void> => {
              if (updatedProcess) {
                updatedProcessInTable(updatedProcess);
              }
              onDone();
            }}
            onCanceled={onDone}
          />
        </ModalAppContext>
      ),
      {
        okText: 'DONE',
      },
    );
  };

  const headerSideContent = (
    <PageHeaderCenteredSideContent>
      <ReloadButtonsHeaderContainer>
        <ReloadButtonsContainer>
          <PartiallyDisplayedText>
            <Checkbox
              id='cbox-load-only-unlinked'
              data-testid='cbox-load-only-unlinked'
              checked={showOnlyUnlinked}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setShowOnlyUnlinked(event.currentTarget.checked)}
            />
            Show Only Unlinked
            <Checkbox
              id='cbox-load-partial-results'
              data-testid='cbox-load-partial-results'
              checked={onlyLoadLastMonths}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setOnlyLoadLastMonths(event.currentTarget.checked)}
            />
            Show Only Last {config.svManagement.registrationFormsPriorMonths} Months
            <Checkbox
              id='cbox-show-hidden-records'
              data-testid='cbox-show-hidden-records'
              checked={showHiddenRecords}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setShowHiddenRecords(event.currentTarget.checked)}
            />
            Show Hidden Records
          </PartiallyDisplayedText>
          <Button id='btn-supplier-registration-management-reload-table' appearance='text' onClick={reloadRegistrationProcesses}>
            RELOAD TABLE
          </Button>
        </ReloadButtonsContainer>
      </ReloadButtonsHeaderContainer>
    </PageHeaderCenteredSideContent>
  );

  const pageHeaderTitle = (
    <PageHeaderTitle
      title={
        <IconAndText image={supplierRegistrationProcessIcon} iconAccessibilityLabel='supplier registration process icon'>
          Registration Forms
        </IconAndText>
      }
    />
  );

  return (
    <PageWithHeader width='full' header={<PageHeader sideContent={headerSideContent}>{pageHeaderTitle}</PageHeader>}>
      <SupplierRegistrationsTable
        validationsLoadable={allRegistrationProcessesLoadable}
        onItemUpdate={updatedProcessInTable}
        onItemClick={onItemClicked}
        linkToSupplierValidationRecordActions={
          permissionsStore.isAllowedToCreateValidationRecord && {
            onItemLinkToNewValidationClick,
            onItemLinkToExistingValidationClick,
          }
        }
      />
    </PageWithHeader>
  );
});

export default SupplierRegistrationListForManagementPage;

const ReloadButtonsHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const PartiallyDisplayedText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
