import React, { FC, ReactElement } from 'react';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import { observer } from 'mobx-react';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { AggregatedValidatedPayee } from '@app/domain/aggregatedValidatedPayee';
import { GuestPayeeSearchVerifyResult } from '@ar/domain/arAccountVerification';
import Button from '@app/components/Button';
import DownloadIcon from '@app/images/download-v2-accent-enabled.svg';
import VerificationValidatedPayeeDetails from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeDetails';
import SVG from '@app/components/SVG';
import { LinearProgress } from '@mui/material';
import { downloadGuestAccountValidationByUniformId } from '@ar/services/arVerifyService';
import { downloadResourceByFileContent } from '@app/utils/fileUtils';
import { FileContentTypes, translateContentTypeToFileExtension } from '@app/domain/files';
import PageWithHeader from '@app/components/PageWithHeader';
import { Regular22TransparentBlack900, SubtitleSmallStartTransparentBlack600 } from '@app/components/Text';
import JoinARBanner from '../JoinARBanner';
import PayeeSearchGuestAccountDetails from './PayeeSearchGuestAccountDetails';
import VerificationValidatedPayeeDetailsMoreInfoButton from '@app/components/verificationValidatedPayeeDetails/VerificationValidatedPayeeDetailsMoreInfoButton';
import ARAboveMainCardTitle from '../ARAboveMainCardTitle';
import VerificationResultCard from '../VerificationResultCard';
import config from '@app/config';
import useLoadableWithPercentage from '@app/hooks/loadable/useLoadableWithPercentage';
import { LoadingState } from '@app/utils/Loadable';
import { AccountAttachments } from '@app/components/accountVerification/AccountAttachments';
import { AttachmentSource, downloadAccountAttachment } from '@app/services/attachmentServices';
import { Trans, useTranslation } from 'react-i18next';
import SanctionsBadge, { PayeeTitle } from '@app/components/SanctionsBadge';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import DownloadDisabledIcon from '@app/images/download-v2-disabled.svg';

interface Props {
  inputAccountDetails: Partial<MorteeAccountDetails>;
  verificationResult: GuestPayeeSearchVerifyResult;
  backButtonHandler: () => void;
}

const GuestVerificationWithPayeeAfterVerificationPage: FC<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.verifyWithPayeeResult);
  const { t } = useTranslation();
  const { themeStore } = useInfraStores<ArMode>();

  const [downloadLoadable, downloadValidationWithPercentage] = useLoadableWithPercentage(
    async (
      presentationAccountNumber: string | null,
      selectedPayee: AggregatedValidatedPayee,
      accountDetails: Partial<MorteeAccountDetails>,
    ): Promise<void> => {
      // presentationAccountNumber should get priority over accountNumber in case it exists
      accountDetails.accountNumber = presentationAccountNumber ? presentationAccountNumber : accountDetails.accountNumber;

      const resource = await downloadGuestAccountValidationByUniformId(selectedPayee.uniformId, accountDetails);

      const { name, resourceData } = resource;

      downloadResourceByFileContent(
        resourceData.content,
        resourceData.mediaType ?? FileContentTypes.APPLICATION_PDF,
        `${name}${translateContentTypeToFileExtension(resourceData.mediaType)}`,
      );
    },
    config.downloadGuestPDFProgressExpectedDurationSec * 1000,
  );

  const { inputAccountDetails, verificationResult, backButtonHandler } = props;

  const renderVerifyResult = (isValidated: boolean, hasAttachments: boolean): ReactElement => {
    if (isValidated) {
      return (
        <StyledVerificationResultCard isValidated resultTitle={t('ar.guestVerification.details.validated')}>
          <Trans i18nKey='ar.guestVerification.details.validatedText'>
            The bank account details entered have been verified and match the entry in the nsKnox Global Payee Database. Make sure
            you use these validated banking details when making payments to us.
          </Trans>
          {hasAttachments && (
            <ValidationExtraLineContainer>{t('ar.guestVerification.details.youMayAccess')}</ValidationExtraLineContainer>
          )}
        </StyledVerificationResultCard>
      );
    }

    return (
      <StyledVerificationResultCard isValidated={false} resultTitle={t('ar.guestVerification.details.notValidated')}>
        <Trans i18nKey='ar.guestVerification.details.notValidatedText'>
          The account details you have entered do not match the validated account details.
          <br />
          Make sure you have entered it correctly or contact your payee to receive the correct details and then check them again.
        </Trans>
      </StyledVerificationResultCard>
    );
  };

  const isDownloadButtonEnabled = verificationResult.validated;

  return (
    <PageWithHeader paddingTop={52} width={900} widthReactive>
      <PageContainer>
        {!themeStore.theme.brand?.clientName && <StyledJoinARBanner />}
        <ARAboveMainCardTitle
          title={t('ar.guestVerification.details.accountVerification')}
          showBackButton
          backButtonHandler={backButtonHandler}
        >
          <Button
            id='btn-after-validation-download'
            dataTestId='downloadBtn'
            appearance='text'
            disabled={!isDownloadButtonEnabled}
            onClick={(): boolean | Promise<void> =>
              downloadLoadable.loadState !== LoadingState.InProgress &&
              !!verificationResult.aggregatedValidatedPayeeData &&
              downloadValidationWithPercentage(
                verificationResult.presentationAccountNumber,
                verificationResult.aggregatedValidatedPayeeData,
                inputAccountDetails,
              )
            }
            size='small'
          >
            <ButtonContent>
              <StyledPrintIcon accessibilityLabel='' image={isDownloadButtonEnabled ? DownloadIcon : DownloadDisabledIcon} />
              {t('general.upperDownload')}
            </ButtonContent>
            {downloadLoadable.loadState === LoadingState.InProgress && (
              <LoadProgressBar
                color='primary'
                variant='determinate'
                value={downloadLoadable.stateMetadata?.loadingPercentage ?? 0}
              />
            )}
          </Button>
        </ARAboveMainCardTitle>
        <ResultsCard>
          {renderVerifyResult(verificationResult.validated, !!verificationResult.validatedPayeeAccountAttachments.length)}
          <PayeeTitle>
            <Regular22TransparentBlack900.div>
              {verificationResult.aggregatedValidatedPayeeData?.mainName}
            </Regular22TransparentBlack900.div>
            <SanctionsBadge sanctionsScreeningInfo={verificationResult?.sanctionsScreeningInfo} />
          </PayeeTitle>
          <CertificateDetailsGrid>
            <div>
              {verificationResult.aggregatedValidatedPayeeData && (
                <>
                  <VerificationFoundDetailsTitle>{t('ar.guestVerification.payeeDetails')}</VerificationFoundDetailsTitle>
                  <VerificationValidatedPayeeDetails payee={verificationResult.aggregatedValidatedPayeeData} hideMainName />
                  <StyledGuestPayeeDetailsMoreInfoButton payee={verificationResult.aggregatedValidatedPayeeData} />
                </>
              )}
            </div>
            <div>
              <VerificationFoundDetailsTitle>{t('ar.guestVerification.bankAccountDetails')}</VerificationFoundDetailsTitle>
              <PayeeSearchGuestAccountDetails accountDetails={inputAccountDetails} validationDetails={verificationResult} />
            </div>
          </CertificateDetailsGrid>
          <AccountAttachments
            validatedAccountAttachments={verificationResult.validatedPayeeAccountAttachments}
            downloadHandler={downloadAccountAttachment}
            source={AttachmentSource.guestVerification}
          />
        </ResultsCard>
      </PageContainer>
    </PageWithHeader>
  );
});

export default GuestVerificationWithPayeeAfterVerificationPage;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledJoinARBanner = styled(JoinARBanner)`
  width: 100%;
  margin-bottom: 16px;
`;

const ResultsCard = styled(Card)`
  font-family: var(--text-font-family);

  width: 100%;
  padding: 24px 32px;
  margin-bottom: 36px;
`;

const StyledVerificationResultCard = styled(VerificationResultCard)`
  margin-bottom: 24px;
`;

const CertificateDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  margin-top: 30px;
`;

const VerificationFoundDetailsTitle = styled(SubtitleSmallStartTransparentBlack600.div)`
  margin-bottom: 18px;
`;

const StyledGuestPayeeDetailsMoreInfoButton = styled(VerificationValidatedPayeeDetailsMoreInfoButton)`
  margin-left: -0.7em;
  margin-top: 12px;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledPrintIcon = styled(SVG)`
  width: 17px;
  height: 16px;
  margin-inline-end: 8px;
  fill: var(--action-color, var(--accent-blue-600));
`;

const LoadProgressBar = styled(LinearProgress)`
  position: absolute !important;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 2px !important;
`;

const ValidationExtraLineContainer = styled.div`
  margin-top: 15px;
`;
