import styled from '@emotion/styled';
import Button from '@app/components/Button';
import WarningBullet from '@supplierRegistration/components/WarningBullet';
import Card from '@app/components/card/Card';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import { Checkbox, FormControlLabel } from '@mui/material';

export const TransferTypeTitle = styled.h1`
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.1;
  letter-spacing: normal;
  text-align: center;
  color: var(--solid-black);
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.25;
  color: var(--primary-200);
`;

export const BigText = styled.div`
  font-size: 14px;
  color: var(--solid-black);
  margin-bottom: -0.2em;

  & > strong {
    font-weight: bold;
  }
`;

export const SmallContinueButton = styled(Button)`
  padding: 8px 22px;
  font-size: 14px;
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  align-self: flex-end;
`;

export const SubTitle = styled.h2`
  font-size: 24px;
  color: var(--transparent-black-700);
`;

export const AccentSubTitle = styled(SubTitle)`
  color: var(--accent-blue-600);
`;

export const NormalText = styled.div`
  font-size: 14px;
  color: var(--solid-black);
  white-space: pre-wrap;
`;

export const BlueWarningIcon = styled(WarningBullet)`
  margin-right: 11px;
  margin-left: 11px;
  min-width: 18px;
  max-width: 18px;
`;

export const PleaseNoteText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: black;
`;

export const TransferInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 1px;

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

export const CardTitle = styled.div`
  color: var(--solid-black);
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 50px;
`;

export const CardSubTitle = styled.div`
  color: var(--transparent-black-600);
  font-size: 16px;
  margin-bottom: 50px;
`;

export const SmallSeparator = styled.hr<{ isRTL?: boolean }>`
  width: 64px;
  background-color: var(--primary-200);
  margin-bottom: 10px;
  margin-left: ${(p): string => (p.isRTL ? '0' : 'unset')};
  margin-right: ${(p): string => (p.isRTL ? '0' : 'unset')};
`;

export const SmallSeparatorWithExtraMargin = styled(SmallSeparator)`
  margin-bottom: 25px;
`;

export const SeparatorDiv = styled.div`
  font-size: 18px;
  color: var(--solid-black);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;

export const StyledForm = styled(NsknoxForm)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const MainCard = styled(Card)`
  font-weight: 200;
  padding: 0 clamp(20px, 5vw, 50px) 50px;
  line-height: 1.4;
  transition: none;
`;

export const HighlightedInstructionsCard = styled(Card)`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
`;

export const HighlightedInstructionsContainer = styled.ul`
  width: 100%;
  margin-bottom: 0;
  padding-left: 20px;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const HighlightedInstructionsTitle = styled.li`
  font-weight: bold;
`;

export const HighlightedInstructionsAdditional = styled.li``;

export const HighlightedInstructionsIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5px;
`;

export const InstructionsTitleCard = styled(Card)<{ isHeaderDark: boolean }>`
  font-size: 14px;
  color: var(${(p): string => (p.isHeaderDark ? '--header-text-color' : '--white')});
  margin-bottom: 3px;
  box-shadow: unset;
`;

export const InstructionsCard = styled(Card)<{ noPadding?: boolean }>`
  font-size: 18px;
  color: var(--transparent-black-700);
  box-shadow: unset;
  padding-left: 29px;
  padding-right: 29px;

  ${(p): string => (p.noPadding ? 'padding-top: 0px; padding-bottom: 0px;' : '')}
`;

export const BoldText = styled(NormalText)`
  font-weight: 600;
  color: var(--solid-black);
`;

export const PaymentInstructionsLineHr = styled.hr`
  background-color: var(--gray-blue-deprecated);
  padding: 0 14px;
  margin: 14px 0;
  height: 1px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 12px;
  letter-spacing: 0.68px;
  color: var(--primary-200);
  margin: 30px 0 50px 0;
  align-items: flex-start !important;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: -12px;
`;

export const LightText = styled.div`
  font-weight: 200;
  font-size: 16px;
  color: var(--transparent-black-700);
`;

export const IAgreeText = styled(LightText)`
  padding-top: 9px;
  color: var(--transparent-black-600);
  font-size: 14px;
`;
