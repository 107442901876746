import styled from '@emotion/styled';
import Button from '@app/components/Button';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { H6StartTransparentBlack800 } from '@app/components/Text';

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const SanctionsScreeningTimestampDiv = styled.div`
  position: relative;
`;

export const DateWarning = styled.div`
  margin-top: -17px;
  position: absolute;
  color: var(--accent-orange-400);
`;

export const ResetButton = styled(Button)`
  margin-top: -10px;
`;

export const FieldsTitle = styled(H6StartTransparentBlack800.div)`
  margin-bottom: 8px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

export const StickyHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TransparentFormItemBox = styled(FormItemBox)<{ shouldBeHidden: boolean }>`
  ${(p): string => (p.shouldBeHidden ? 'display: none;' : '')}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const CopyBelowButton = styled(Button)<{ hidden: boolean }>`
  ${(p): string => (p.hidden ? 'display: none;' : '')}
  padding: 10px 12px;
  margin-right: 20px;
  position: absolute;
  right: 55px;
  z-index: 1;
  font-size: 22px;
  font-weight: bold;
`;

export const FullWidth = styled.div`
  width: 100%;
`;
