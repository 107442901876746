import {
  SupplierRegistrationProcessRequest,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationBankAccountFormFields,
} from '@supplierRegistration/domain/supplierRegistration';
import { trimToFallback, trimToNull, trimToUndefined } from '@app/utils/stringUtils';
import { canUseWiringTypesAnswerToBoolean } from '@supplierRegistration/domain/supplierRegistration';
import { convertDatePickerValueToEpoch } from '@app/components/inputs/DatePicker';
import { calculateLegalIdType } from './legalIdUtils';
import { calculateFormPhoneNumber } from './phoneUtils';
import qs from 'query-string';
import { itemOrFirst } from '@app/utils/arrayUtils';
import {
  AsyncInitiateValidationMaskedData,
  SupplierRegistrationAsyncInitiateValidationProcessRequest,
} from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import { isDefined, isObjectEmpty } from '@app/utils/utils';

export function transformRegularValidationProcessRequest(
  companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
  bankAccountValues: SupplierRegistrationBankAccountFormFields,
  isIpsMode: boolean,
  configuration: any,
  isIndividual: boolean,
): SupplierRegistrationProcessRequest {
  const phoneNumber = calculateFormPhoneNumber(companyInfoValues.phoneCountryCode, companyInfoValues.phoneNumber);
  if (!phoneNumber) {
    throw new Error(
      `cannot calculate, phone country code = ${companyInfoValues.phoneCountryCode}, phone number = ${companyInfoValues.phoneNumber}`,
    );
  }

  return {
    isIndividual: isIndividual,
    hasAgreed: bankAccountValues.hasAgreed,
    asyncFullName: null,
    firstName: companyInfoValues.firstName.trim(),
    lastName: companyInfoValues.lastName.trim(),
    email: companyInfoValues.email.trim(),
    phoneNumber: phoneNumber,
    companyName: companyInfoValues.companyName.trim(),
    additionalCompanyName: trimToNull(companyInfoValues.additionalCompanyName),
    address: {
      addressDetails: {
        address: companyInfoValues.companyAddress.trim(),
        city: companyInfoValues.companyAddressCity.trim(),
        countryCode: companyInfoValues.companyAddressCountry.trim(),
        state: trimToNull(companyInfoValues.companyAddressState),
        zipCode: companyInfoValues.companyAddressZipCode.trim(),
      },
    },
    legalId: trimToUndefined(companyInfoValues.leiValue),
    legalIdType: calculateLegalIdType(companyInfoValues, isIndividual) ?? undefined,
    countryCode: companyInfoValues.country.trim(),
    companyWebsite: trimToUndefined(companyInfoValues.website),
    referringCustomer: isIpsMode ? undefined : companyInfoValues.referrer?.trim(),
    dateOfBirth: convertDatePickerValueToEpoch(companyInfoValues.dateOfBirth),
    accountBankCountryCode: bankAccountValues.accountBankCountryCode.trim(),
    currency: bankAccountValues.currency.trim(),
    accountDetails: {
      swiftCode: trimToUndefined(bankAccountValues.swiftCode),
      bankCode: trimToUndefined(bankAccountValues.bankCode),
      branchCode: trimToUndefined(bankAccountValues.branchCode),
      accountNumber: trimToFallback(bankAccountValues.accountNumber, ''),
    },
    abaRouting: trimToUndefined(bankAccountValues.abaRouting),
    furtherCredit: trimToUndefined(bankAccountValues.furtherCredit),
    organizationReferenceId: isIpsMode ? undefined : trimToUndefined(configuration?.ref),
    canUseAch: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseAch),
    registrationId: trimToUndefined(extractRegistrationIdFromUrl()),
    accountType: bankAccountValues.accountType,
    hasAgreedIsrael: bankAccountValues.hasAgreedIsrael,
    bankName: bankAccountValues.bankName,
  };
}

export function transformFormFieldsToSupplierRegistrationAsyncRequest(
  companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
  bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ref: string,
  asyncMaskedData: AsyncInitiateValidationMaskedData,
  isIndividual: boolean,
): SupplierRegistrationAsyncInitiateValidationProcessRequest {
  let phoneNumber: string | null;
  if (asyncMaskedData.contactInfo.phoneNumber) {
    phoneNumber = null;
  } else {
    phoneNumber = calculateFormPhoneNumber(companyInfoValues.phoneCountryCode, companyInfoValues.phoneNumber);
    if (!phoneNumber) {
      throw new Error(
        `cannot calculate, phone country code = ${companyInfoValues.phoneCountryCode}, phone number = ${companyInfoValues.phoneNumber}`,
      );
    }
  }

  return {
    isIndividual: nullInCaseValueComesFromAsync(asyncMaskedData.payee.isIndividual, isIndividual),
    hasAgreed: bankAccountValues.hasAgreed,
    hasAgreedIsrael: bankAccountValues.hasAgreedIsrael ?? null,
    asyncFullName: nullInCaseValueComesFromAsync(
      asyncMaskedData.contactInfo.asyncFullName,
      trimToNull(companyInfoValues.asyncFullName),
    ),
    email: nullInCaseValueComesFromAsync(asyncMaskedData.contactInfo.email, companyInfoValues.email.trim()),
    firstName: nullInCaseValueComesFromAsync(asyncMaskedData.contactInfo.firstName, trimToNull(companyInfoValues.firstName)),
    lastName: nullInCaseValueComesFromAsync(asyncMaskedData.contactInfo.lastName, trimToNull(companyInfoValues.lastName)),
    phoneNumber: phoneNumber,
    companyName: nullInCaseValueComesFromAsync(asyncMaskedData.payee.name, companyInfoValues.companyName.trim()),
    additionalCompanyName: nullInCaseValueComesFromAsync(
      asyncMaskedData.payee.additionalCompanyName,
      trimToNull(companyInfoValues.additionalCompanyName),
    ),
    address: {
      addressDetails: isObjectEmpty(asyncMaskedData.payee.addressByFields)
        ? nullInCaseValueComesFromAsync(asyncMaskedData.payee.address, {
            address: trimToNull(companyInfoValues.companyAddress),
            city: trimToNull(companyInfoValues.companyAddressCity),
            countryCode: trimToNull(companyInfoValues.companyAddressCountry),
            state: trimToNull(companyInfoValues.companyAddressState),
            zipCode: trimToNull(companyInfoValues.companyAddressZipCode),
          })
        : {
            address: nullInCaseValueComesFromAsync(
              asyncMaskedData.payee.addressByFields?.addressLine,
              trimToNull(companyInfoValues.companyAddress),
            ),
            city: nullInCaseValueComesFromAsync(
              asyncMaskedData.payee.addressByFields?.city,
              trimToNull(companyInfoValues.companyAddressCity),
            ),
            countryCode: nullInCaseValueComesFromAsync(
              asyncMaskedData.payee.addressByFields?.countryCode,
              trimToNull(companyInfoValues.companyAddressCountry),
            ),
            state: nullInCaseValueComesFromAsync(
              asyncMaskedData.payee.addressByFields?.state,
              trimToNull(companyInfoValues.companyAddressState),
            ),
            zipCode: nullInCaseValueComesFromAsync(
              asyncMaskedData.payee.addressByFields?.zipCode,
              trimToNull(companyInfoValues.companyAddressZipCode),
            ),
          },
    },
    legalId: nullInCaseValueComesFromAsync(asyncMaskedData.payee.legalIdentifier, trimToNull(companyInfoValues.leiValue)),
    legalIdType: nullInCaseValueComesFromAsync(
      asyncMaskedData.payee.legalIdentifier,
      calculateLegalIdType(companyInfoValues, isIndividual),
    ),
    countryCode: nullInCaseValueComesFromAsync(asyncMaskedData.payee.countryCode, companyInfoValues.country.trim()),
    companyWebsite: nullInCaseValueComesFromAsync(asyncMaskedData.payee.website, trimToNull(companyInfoValues.website)),
    dateOfBirth: convertDatePickerValueToEpoch(companyInfoValues.dateOfBirth) ?? null,
    accountBankCountryCode: nullInCaseValueComesFromAsync(
      asyncMaskedData.account.countryCode,
      bankAccountValues.accountBankCountryCode.trim(),
    ),
    currency: nullInCaseValueComesFromAsync(asyncMaskedData.account.currency, bankAccountValues.currency.trim()),
    accountDetails: {
      swiftCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.swiftCode, trimToNull(bankAccountValues.swiftCode)),
      bankCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.bankCode, trimToNull(bankAccountValues.bankCode)),
      branchCode: nullInCaseValueComesFromAsync(asyncMaskedData.account.branchCode, trimToNull(bankAccountValues.branchCode)),
      accountNumber: nullInCaseValueComesFromAsync(
        asyncMaskedData.account.accountNumber,
        trimToNull(bankAccountValues.accountNumber),
      ),
      iban: nullInCaseValueComesFromAsync(
        asyncMaskedData.account.iban,
        trimToNull(bankAccountValues.ibanForAsyncInitiateValidation),
      ),
    },
    abaRouting: trimToNull(bankAccountValues.abaRouting),
    furtherCredit: trimToNull(bankAccountValues.furtherCredit),
    organizationReferenceId: ref,
    canUseAch: canUseWiringTypesAnswerToBoolean(bankAccountValues.canUseAch) ?? null,
    registrationId: trimToNull(extractRegistrationIdFromUrl()),
    accountType: bankAccountValues.accountType ?? null,
  };
}

function nullInCaseValueComesFromAsync<T>(asyncDataFieldExistenceIndication: unknown, formField: T): T | null {
  if (isDefined(asyncDataFieldExistenceIndication)) {
    return null;
  }
  return formField;
}

export function extractRegistrationIdFromUrl(): string | null | undefined {
  const { id } = qs.parse(location?.search);

  return itemOrFirst(id);
}
