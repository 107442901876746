import { FormFieldDecorators } from '@app/utils/form/form';
import { FIELD_MAX_LENGTH } from '@supplierRegistration/domain/supplierRegistrationFields';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { trim } from '@app/utils/stringUtils';
import { COUNTRY_CODES } from '@app/domain/countries';
import { emailAdditionalValidations, onChangeValidateOtherFields, validatePhoneNumber } from '@app/utils/validators';
import { convertToTranslatedMessage } from '@app/utils/form/formTranslatedMessage';
import { isDefined, isObjectEmpty } from '@app/utils/utils';
import {
  REGISTRATION_TYPES_VALUES,
  RegistrationType,
  registrationTypeFromBoolean,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
} from '@supplierRegistration/domain/supplierRegistration';
import {
  AllLegalIdTypeForCompany,
  getAdditionalLeiValueRules,
  getAdditionalLeiValueRulesForCountryForLeiType,
  getInitialLegalIdTypValuesOfCountryForCompany,
} from '@supplierRegistration/domain/supplierRegistrationLegalIdentifiers';
import { AsyncInitiateValidationMaskedData } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import {
  datePickerValueFullDateRepresentationValidator,
  datePickerValueNotFutureTimestampValidator,
} from '@app/components/inputs/DatePicker';

export const createFieldDecorators = (
  form: WrappedFormUtils,
  asyncProcessMaskedData: AsyncInitiateValidationMaskedData | undefined,
  isIndividual: boolean,
  supplierCountryCode: string | undefined,
  addressCountryCode: string | undefined,
  formInitialValues: SupplierRegistrationFormValuesState,
  showDateOfBirth: boolean | undefined,
  leiType: string | undefined,
): FormFieldDecorators<SupplierRegistrationCompanyInfoFormFields> => {
  return {
    dateOfBirth: {
      initialValue: formInitialValues.supplierValues?.dateOfBirth ?? undefined,
      rules: [
        {
          required: showDateOfBirth,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingDateOfBirth',
        },
        datePickerValueNotFutureTimestampValidator(),
        datePickerValueFullDateRepresentationValidator(),
      ],
    },
    companyName: {
      initialValue: asyncProcessMaskedData?.payee.name ?? formInitialValues.supplierValues?.companyName ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.name),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: isIndividual
            ? [
                `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingOwnerName`,
                'supplierValidation.supplierRegister.errors.missingOwnerName',
              ]
            : [
                `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingCompanyName`,
                'supplierValidation.supplierRegister.errors.missingCompanyName',
              ],
        },
        {
          max: FIELD_MAX_LENGTH.companyName,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.companyName,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    additionalCompanyName: {
      initialValue:
        asyncProcessMaskedData?.payee.additionalCompanyName ??
        formInitialValues.supplierValues?.additionalCompanyName ??
        undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.additionalCompanyName),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: isIndividual
            ? 'supplierValidation.supplierRegister.errors.missingAdditionalPersonalName'
            : 'supplierValidation.supplierRegister.errors.missingAdditionalCompanyName',
        },
        {
          max: FIELD_MAX_LENGTH.companyName,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.companyName,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    companyAddress: {
      initialValue:
        (!isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields)
          ? asyncProcessMaskedData?.payee.addressByFields?.addressLine
          : asyncProcessMaskedData?.payee.address) ??
        formInitialValues.supplierValues?.companyAddress ??
        undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.payee.addressByFields?.addressLine) ||
        (isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) && isDefined(asyncProcessMaskedData?.payee.address)),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: isIndividual
            ? 'supplierValidation.supplierRegister.errors.missingAddress'
            : [
                `supplierValidation.supplierRegister.${supplierCountryCode}.errors.missingCompanyAddress`,
                'supplierValidation.supplierRegister.errors.missingCompanyAddress',
              ],
        },
        {
          max: FIELD_MAX_LENGTH.companyAddress,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.companyAddress,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    companyAddressCountry: {
      initialValue:
        asyncProcessMaskedData?.payee.addressByFields?.countryCode ??
        formInitialValues.supplierValues?.companyAddressCountry ??
        undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.payee.addressByFields?.countryCode) ||
        (isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) && isDefined(asyncProcessMaskedData?.payee.address)),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressCountry',
        },
        {
          max: FIELD_MAX_LENGTH.countryCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.countryCode,
          },
        },
      ],
    },
    companyAddressCity: {
      initialValue:
        asyncProcessMaskedData?.payee.addressByFields?.city ?? formInitialValues.supplierValues?.companyAddressCity ?? undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.payee.addressByFields?.city) ||
        (isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) && isDefined(asyncProcessMaskedData?.payee.address)),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressCity',
        },
        {
          max: FIELD_MAX_LENGTH.companyAddressCity,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.companyAddressCity,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    companyAddressState: {
      initialValue:
        asyncProcessMaskedData?.payee.addressByFields?.state ??
        formInitialValues.supplierValues?.companyAddressState ??
        undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.payee.addressByFields?.state) ||
        (isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) && isDefined(asyncProcessMaskedData?.payee.address)),
      rules: [
        {
          required: addressCountryCode === COUNTRY_CODES.UnitedStates,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressState',
        },
      ],
    },
    companyAddressZipCode: {
      initialValue:
        asyncProcessMaskedData?.payee.addressByFields?.zipCode ??
        formInitialValues.supplierValues?.companyAddressZipCode ??
        undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.payee.addressByFields?.zipCode) ||
        (isObjectEmpty(asyncProcessMaskedData?.payee.addressByFields) && isDefined(asyncProcessMaskedData?.payee.address)),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingAddressZipcode',
        },
        {
          max: FIELD_MAX_LENGTH.companyAddressZipCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.companyAddressZipCode,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    asyncFullName: {
      initialValue: asyncProcessMaskedData?.contactInfo.asyncFullName ?? undefined,
    },
    firstName: {
      initialValue: asyncProcessMaskedData?.contactInfo.firstName ?? formInitialValues.supplierValues?.firstName ?? undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.contactInfo.firstName) || isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingContactFirstName',
        },
        {
          max: FIELD_MAX_LENGTH.firstName,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.firstName,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    lastName: {
      initialValue: asyncProcessMaskedData?.contactInfo.lastName ?? formInitialValues.supplierValues?.lastName ?? undefined,
      disableAllRules:
        isDefined(asyncProcessMaskedData?.contactInfo.lastName) || isDefined(asyncProcessMaskedData?.contactInfo.asyncFullName),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingContactLastName',
        },
        {
          max: FIELD_MAX_LENGTH.lastName,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.lastName,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    leiType: {
      initialValue:
        (asyncProcessMaskedData?.payee.legalIdentifier?.type ? AllLegalIdTypeForCompany.Other : undefined) ??
        formInitialValues.supplierValues?.leiType ??
        getInitialLegalIdTypValuesOfCountryForCompany(supplierCountryCode) ??
        undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalIdType',
        },
        {
          max: FIELD_MAX_LENGTH.leiTypeCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.leiTypeCode,
          },
        },
      ],
    },
    leiTypeOtherValue: {
      initialValue:
        asyncProcessMaskedData?.payee.legalIdentifier?.type ?? formInitialValues.supplierValues?.leiTypeOtherValue ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingOtherLegalId',
        },
        {
          max: FIELD_MAX_LENGTH.leiTypeCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.leiTypeCode,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    leiValue: {
      initialValue: asyncProcessMaskedData?.payee.legalIdentifier?.id ?? formInitialValues.supplierValues?.leiValue ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.legalIdentifier),
      rules: [
        ...getAdditionalLeiValueRules(supplierCountryCode),
        ...getAdditionalLeiValueRulesForCountryForLeiType(supplierCountryCode, leiType),
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingLegalId',
        },
        {
          max: FIELD_MAX_LENGTH.leiValue,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.leiValue,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    phoneCountryCode: {
      initialValue: formInitialValues.supplierValues?.phoneCountryCode ?? COUNTRY_CODES.UnitedStates,
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCountryCode',
        },
        {
          max: FIELD_MAX_LENGTH.dialCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.dialCode,
          },
        },
        {
          validator: onChangeValidateOtherFields<SupplierRegistrationCompanyInfoFormFields>(form, 'phoneNumber'),
        },
      ],
    },
    phoneNumber: {
      initialValue: asyncProcessMaskedData?.contactInfo.phoneNumber ?? formInitialValues.supplierValues?.phoneNumber ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.contactInfo.phoneNumber),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidPhoneNumber',
        },
        {
          max: FIELD_MAX_LENGTH.phoneNumber,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.phoneNumber,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.numericWithSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidPhoneNumber',
        },
        {
          validator: validatePhoneNumber<SupplierRegistrationCompanyInfoFormFields>(
            form,
            'phoneCountryCode',
            convertToTranslatedMessage('supplierValidation.supplierRegister.errors.invalidPhoneNumber'),
            true,
          ),
        },
      ],
    },
    email: {
      initialValue: asyncProcessMaskedData?.contactInfo.email ?? formInitialValues.supplierValues?.email ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.contactInfo.email),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidEmail',
        },
        {
          max: FIELD_MAX_LENGTH.email,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.email,
          },
        },
        {
          type: 'email',
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidEmail',
        },
        {
          validator: emailAdditionalValidations(
            convertToTranslatedMessage('supplierValidation.supplierRegister.errors.invalidEmail'),
          ),
        },
      ],
    },
    country: {
      initialValue: asyncProcessMaskedData?.payee.countryCode ?? formInitialValues.supplierValues?.country ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.countryCode),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: isIndividual
            ? 'supplierValidation.supplierRegister.errors.missingAccountOwnerCountry'
            : 'supplierValidation.supplierRegister.errors.missingCompanyCountry',
        },
        {
          max: FIELD_MAX_LENGTH.countryCode,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.countryCode,
          },
        },
      ],
    },
    website: {
      initialValue: asyncProcessMaskedData?.payee.website ?? formInitialValues.supplierValues?.website ?? undefined,
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.website),
      rules: [
        {
          max: FIELD_MAX_LENGTH.url,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.url,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.url,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidURL',
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    referrer: {
      initialValue: formInitialValues.supplierValues?.referrer ?? undefined,
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingReferrer',
        },
        {
          max: FIELD_MAX_LENGTH.referrer,
          transform: trim,
          translatedMessage: {
            key: 'supplierValidation.supplierRegister.maxCharacters',
            value: FIELD_MAX_LENGTH.referrer,
          },
        },
        {
          pattern: VALIDATION_PATTERNS.alphanumericAndSpecialChars,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
    registrationType: {
      initialValue:
        registrationTypeFromBoolean(asyncProcessMaskedData?.payee.isIndividual) ??
        formInitialValues.supplierValues?.registrationType?.toString() ??
        RegistrationType.company.toString(),
      disableAllRules: isDefined(asyncProcessMaskedData?.payee.isIndividual),
      rules: [
        {
          required: true,
          transform: trim,
          translatedMessage: 'supplierValidation.supplierRegister.errors.missingRegistrationType',
        },
        {
          type: 'enum',
          enum: REGISTRATION_TYPES_VALUES,
          translatedMessage: 'supplierValidation.supplierRegister.errors.invalidCharacter',
        },
      ],
    },
  };
};
