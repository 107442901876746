import React, { ChangeEventHandler, KeyboardEvent, ReactNode, useContext } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { action } from 'mobx';
import ClearIcon from '@app/images/ic_close.svg';
import SVG from '@app/components/SVG';
import Button from '../Button';
import ValueWithPlaceholder from './ValueWithPlaceholder';
import FormInputsContext from './FormInputsContext';
import useLocalObservableWithProps from '@app/hooks/useLocalObservableWithProps';
import { ForwardingFC } from '@app/domain/technicals/components';
import { WRAP_WORDS_CSS_PROPERTIES } from '@app/domain/uiConsts';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { TextareaAutosizeProps } from '@mui/material';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import useColorScheme from '@app/hooks/useColorScheme';
import { ColorScheme, ColorSchemeObject } from '@app/domain/theme';
import { useTranslation } from 'react-i18next';
import { FormInputProps } from '@app/utils/form/form';

interface StyledTextareaProps {
  colorScheme?: ColorScheme;
  textColorScheme?: ColorScheme;
  readOnly?: boolean;
}

type TextareaProps = StyledTextareaProps &
  FormInputProps<string> &
  Omit<TextareaAutosizeProps, 'placeholder' | 'onChange'> & {
    name: string;
    type: 'text' | 'number';
    disabled?: boolean;
    maxLength?: number;
    onMaxLengthAchieved?: (value: string) => void;
    clearable?: boolean;
    onClear?: () => void;
    onKeyPress?: React.KeyboardEventHandler<HTMLTextAreaElement>;
    onClick?: React.MouseEventHandler<HTMLTextAreaElement>;
    onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
    onChangeEvent?: ChangeEventHandler<HTMLTextAreaElement>;
    className?: string;
    textAreaClassName?: string;
    dataTestId?: string;
    prefix?: string;
    disableSuggestion?: boolean;
    renderSideComponent?: () => ReactNode;
  };

const NakedFormTextarea: ForwardingFC<HTMLTextAreaElement, TextareaProps> = observer(
  React.forwardRef((props, ref) => {
    const {
      name,
      value,
      onChange,
      type,
      accessibilityLabel,
      colorScheme: propColorScheme,
      textColorScheme: propTextColorScheme,
      placeholder,
      placeholderStyle,
      placeholderVerticalAlign = 'top',
      disabled: disabledProp,
      heightType,
      className,
      textAreaClassName,
      maxLength,
      onMaxLengthAchieved,
      autoFocus,
      dataTestId = name,
      clearable,
      onClear,
      onKeyPress,
      onClick,
      onChangeEvent,
      prefix,
      readOnly,
      renderSideComponent,
      disableSuggestion,
      ...textareaProps
    } = props;

    const { state } = useContext(FormInputsContext);
    const colorScheme = useColorScheme(props.disabled ? 'disabled' : undefined, propColorScheme);
    const textColorScheme = useColorScheme(props.disabled ? 'disabled' : undefined, propTextColorScheme, 'darkGrey');
    const { t } = useTranslation();

    const localStore = useLocalObservableWithProps(
      () => ({
        _focused: false,
        value: '' as string | undefined,

        setBlur: (): void => {
          localStore._focused = false;
        },

        get hasValue(): boolean {
          return localStore.value !== null && localStore.value !== undefined && localStore.value !== '';
        },

        get active(): boolean {
          return this._focused || this.hasValue;
        },
      }),
      {
        value,
      },
    );

    const handleChangeValue = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      const newValue = event.currentTarget.value;
      if (!isValueValid(newValue) || props.disabled) {
        return;
      }

      const trimmedValue = newValue && maxLength ? newValue.substr(0, maxLength) : newValue;

      if (maxLength && trimmedValue?.length > maxLength) {
        return;
      }

      onChange?.(trimmedValue);
      onChangeEvent?.({ ...event, currentTarget: { ...event.currentTarget, value: trimmedValue } });

      if (maxLength && trimmedValue && trimmedValue.length >= maxLength) {
        onMaxLengthAchieved?.(trimmedValue);
      }
    };

    const handleOnKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>): void => {
      const value: string = event.currentTarget.value;

      if (!isKeypressValid(value, event) || trimValue(value) !== value || props.disabled) {
        event.defaultPrevented = true;
        event.preventDefault?.();
        return;
      }

      onKeyPress?.(event);
    };

    const isKeypressValid = (value: string, keypressEvent: KeyboardEvent<HTMLTextAreaElement>): boolean => {
      return type !== 'number' || isKeyPressNumeric(keypressEvent);
    };

    const isKeyPressNumeric = (event): boolean => {
      let key: string;

      if (event.type === 'paste') {
        key = event.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        const charKey = event.keyCode || event.which;
        key = String.fromCharCode(charKey);
      }

      return isNumeric(key);
    };

    const trimValue = (value: string): string => {
      if (maxLength && value.length >= maxLength) {
        return value.substr(0, maxLength);
      }

      return value;
    };

    const isValueValid = (content: string): boolean => {
      return type !== 'number' || isNumeric(content);
    };

    const isNumeric = (content: string): boolean => {
      return /^[0-9\n\r]*$/.test(content);
    };

    const clearContent = (): void => {
      if (clearable && onChange) {
        onChange('');
      }

      if (clearable && onClear) {
        onClear();
      }
    };

    const onFocus = action((event: React.FocusEvent<HTMLTextAreaElement>) => {
      const { onFocus } = props;
      localStore._focused = true;

      onFocus?.(event);
    });

    const disabled = disabledProp || state === 'disabled';
    const placeholderElementId = `placeholder-of-${name}`;

    return (
      <Container className={className}>
        <StyledValueWithPlaceholder
          colorScheme={colorScheme}
          valueNotEmpty={!!value}
          placeholder={placeholder}
          placeholderElementId={placeholderElementId}
          placeholderStyle={placeholderStyle}
          placeholderVerticalAlign={placeholderVerticalAlign}
          heightType={heightType}
        >
          <Line>
            {localStore.active && prefix && <PrefixContainer>{prefix}</PrefixContainer>}
            <StyledTextarea
              translate='no'
              name={name}
              aria-label={accessibilityLabel}
              aria-labelledby={accessibilityLabel ? undefined : placeholderElementId} // this is a fallback in case the accessibilityLabel is not defined
              data-testid={dataTestId}
              value={value}
              onChange={(event): void => handleChangeValue(event)}
              colorScheme={colorScheme}
              textColorScheme={textColorScheme}
              disabled={disabled}
              onKeyPress={handleOnKeyPress}
              onFocus={onFocus}
              onBlur={localStore.setBlur}
              maxLength={maxLength}
              onClick={onClick}
              autoFocus={autoFocus}
              readOnly={readOnly || (disableSuggestion && !localStore._focused)}
              cursorReadOnly={readOnly ?? false}
              className={textAreaClassName}
              autoComplete='off'
              data-lpignore='true' // Prevents lastpass suggestions
              ref={ref}
              {...textareaProps}
            />
            {clearable && localStore.hasValue && (
              <ClearButton
                id={`btn-${name}-clean`}
                appearance='text'
                colorScheme='primary'
                onClick={clearContent}
                disabled={disabled}
              >
                <SVG accessibilityLabel={t('general.accessibility.clearText', { placeholder })} image={ClearIcon} height={18} />
              </ClearButton>
            )}
          </Line>
        </StyledValueWithPlaceholder>
        {renderSideComponent?.()}
      </Container>
    );
  }),
);

export default NakedFormTextarea;

const ClearButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: rgba(53, 60, 79, 0.4);
  transition: 0.1s all ease-in-out;
  align-self: flex-end;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const Container = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
`;

const StyledValueWithPlaceholder = styled(ValueWithPlaceholder)`
  display: flex;
  flex: 1;
  margin-right: 12px;
`;

const Line = styled.div`
  display: flex;
  flex: 1;
`;

const StyledTextarea = styled(TextareaAutosize, {
  shouldForwardProp: preventForwardTheseProps('innerRef', 'colorScheme', 'textColorScheme', 'cursorReadOnly'),
})<{
  cursorReadOnly: boolean;
  colorScheme: ColorSchemeObject;
  textColorScheme: ColorSchemeObject;
}>`
  ${WRAP_WORDS_CSS_PROPERTIES};
  width: 100%;
  resize: none;
  padding: 1px 0;
  border: none;
  font-size: 16px;
  line-height: 20px;
  background-color: transparent;
  color: var(--gray-blue-deprecated);
  outline: none;
  box-shadow: 0 4px 20px 0 transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
  caret-color: ${(p): string => (p.cursorReadOnly ? 'transparent' : p.colorScheme.main)};
  cursor: ${(p): string => (p.cursorReadOnly ? 'default' : 'text')};
  color: ${(p): string => p.textColorScheme.main} !important;

  &::placeholder {
    color: rgba(61, 68, 90, 0.7);
  }

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus:-moz-placeholder {
    color: transparent;
  }

  /* FF 4-18 */

  &:focus::-moz-placeholder {
    color: transparent;
  }

  /* FF 19+ */

  &:focus:-ms-input-placeholder {
    color: transparent;
  }

  /* IE 10+ */

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const PrefixContainer = styled.div`
  font-size: 16px;
  padding-top: 14px;
`;
