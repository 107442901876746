import NakedDropdown from '@app/components/inputs/NakedDropdown';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';
import COUNTRIES, { getCountriesOrder } from '@app/domain/countries';
import styled from '@emotion/styled';
import DropdownItem from '@app/components/inputs/DropdownItem';
import i18n from 'i18next';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onChange?: (value: string) => void;
}

const PhoneCountryDropdown = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { t } = useTranslation();

  return (
    <NakedDropdown
      ref={ref}
      accessibilityLabel={t('general.accessibility.phoneCountry')}
      name='drp-supp-reg-phone-country-name'
      dataTestId='drp-supp-reg-phone-country-name'
      placeholder={null}
      isSearchable
      popupWidth='auto'
      {...props}
    >
      {getCountriesOrder(i18n.language, (name) => t(`supplierValidation.countries.${name}`)).map((countryCode) => {
        const country = COUNTRIES[countryCode];
        return (
          <PhoneCountryDropdownItem
            key={countryCode}
            keywords={[t(`supplierValidation.countries.${country.name}`), `+${country.dialCode}`, countryCode]}
            value={countryCode}
            textWhenSelected={`+${country.dialCode}`}
          >
            <PhoneCountryDisplay>
              <div>{t(`supplierValidation.countries.${country.name}`)}</div>
              <StyledCountryDialCode>+{country.dialCode}</StyledCountryDialCode>
            </PhoneCountryDisplay>
          </PhoneCountryDropdownItem>
        );
      })}
    </NakedDropdown>
  );
});

PhoneCountryDropdown.displayName = 'PhoneCountryDropdown';

const PhoneCountryDropdownItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
  direction: ltr;
`;

const StyledCountryDialCode = styled.div`
  color: var(--transparent-black-900);
  direction: ltr;
`;

const PhoneCountryDisplay = styled(LocaleAwareDiv)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export default PhoneCountryDropdown;
