import Button from '@app/components/Button';
import ClickEventPropagationBlocker from '@app/components/ClickEventPropagationBlocker';
import Collapse, { CollapsePanelStickyHeader } from '@app/components/Collapse';
import ConditionalTooltip from '@app/components/ConditionalTooltip';
import FormItemBox from '@app/components/inputs/FormItemBox';
import FormItemBoxWithSuggestions from '@app/components/inputs/FormItemBoxWithSuggestions';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import NakedCalendarDatePicker from '@app/components/inputs/NakedCalendarDatePicker';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import Toggle from '@app/components/inputs/Toggle';
import COUNTRIES, { getCountryName } from '@app/domain/countries';
import { StrongLegalIdentifiesTypes } from '@app/domain/legalEntityIdentifier';
import {
  getTextFromSanctionStatus,
  injectTypeIntoStrongLegalIdRequest,
  SanctionsScreeningResultStatus,
} from '@app/domain/validatedPayee';
import useAppStores from '@app/hooks/useAppStores';
import useForm from '@app/hooks/useForm';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import useInfraStores from '@app/hooks/useInfraStores';
import { itemOrFirst, wrapValueAsArray } from '@app/utils/arrayUtils';
import { cleanDunsLegalId, cleanLegalId } from '@app/utils/legalIdentifierUtils';
import { removeCharsAndUpperCase, trimToNull } from '@app/utils/stringUtils';
import { isPastDate } from '@app/utils/timeUtils';
import { isDefined } from '@app/utils/utils';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord,
  EvidenceType,
  StoreSupplierValidationPayeeVerificationRecordRequest,
  SupplierValidationPayeeVerificationRecord,
  SupplierValidationPayeeVerificationRecordServerResponse,
  SupplierValidationRecord,
  SupplierValidationRecordStatus,
  supplierValidationRecordSupplierVerificationAllUpdateFieldsEquators,
  SupplierValidationVerificationRecordEvidence,
  SupplierValidationVerificationSanctionsScreeningInfo,
  transformSupplierValidationPayeeVerification,
} from '@mortee/domain/validationSystem';
import {
  markFirstAsPrimary,
  VerificationLegalIdentifierRequest,
} from '@mortee/domain/validationSystemVerificationLegalIdentifiers';
import {
  createFieldDecorators,
  CreateVerificationDataRecordPayeeFormFields,
} from '@mortee/domain/verificationRecordPayeeDecorators';
import MorteeMode from '@mortee/morteeMode';
import CountryDropdown from '@mortee/routes/validatedPayeesManagement/CountryDropdown';
import LegalIdInput from '@mortee/routes/validatedPayeesManagement/LegalIdInput';
import { ValidatedPayeeFormFields } from '@mortee/routes/validatedPayeesManagement/ValidatedPayeeForm';
import VerificationFormCollapseHeader from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/VerificationFormCollapseHeader';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { observer } from 'mobx-react';
import { toWordsOrdinal } from 'number-to-words';
import React, { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ActionsContainer,
  CopyBelowButton,
  DateWarning,
  FieldsTitle,
  FullWidth,
  InputGrid,
  ResetButton,
  Row,
  SanctionsScreeningTimestampDiv,
  StickyHeaderContainer,
  TitleContainer,
  TransparentFormItemBox,
} from './VerificationRecordPayeeForm.styles';
import {
  calculateTaxIdNameFromEvidence,
  createLegalIdentifierEqualityFunction,
  upperCaseExceptDBAFAC,
  upperCaseFieldValue,
} from './VerificationRecordPayeeForm.utils';

interface Props extends FormComponentProps<CreateVerificationDataRecordPayeeFormFields> {
  verificationRecordPayeeData: SupplierValidationPayeeVerificationRecord | null;
  className?: string;
  onSaved(payeeData: SupplierValidationPayeeVerificationRecord): void;
  onIsFormUnsavedChanged(isFormUnsaved: boolean): void;
  evidenceData: SupplierValidationVerificationRecordEvidence | null | undefined;
  supplierValidationRecord: SupplierValidationRecord;
}

const legalIds: (keyof CreateVerificationDataRecordPayeeFormFields)[] = ['legalId1', 'legalId2', 'legalId3'];

const VerificationRecordPayeeForm: FC<Props> = observer((props) => {
  const {
    verificationRecordPayeeData,
    onSaved,
    onIsFormUnsavedChanged,
    className,
    evidenceData,
    supplierValidationRecord,
  } = props;
  const { validatedPayeesManagementStore } = useAppStores<MorteeMode>();
  const { permissionsStore } = useInfraStores<MorteeMode>();
  const { localeStore } = useInfraStores();
  const allLEITypesLoadable = validatedPayeesManagementStore.allLeiTypes;

  const { form, showFormErrors, setShowFormErrors, validateFields } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const useExistingPayee = form.getFieldValue('useExistingPayee');
  const payeeCountryCode = form.getFieldValue('countryCode');
  const sanctionsScreeningTimestamp = form.getFieldValue('sanctionsScreeningTimestamp');
  const isPastSanctionsDate = !!sanctionsScreeningTimestamp && isPastDate(sanctionsScreeningTimestamp);
  const isPendingApprovalAndDoesntHaveApprovalRole =
    !permissionsStore.isValidationApprover &&
    supplierValidationRecord.status.value === SupplierValidationRecordStatus.waitingForApproval;

  const resetSanctions = (): void => {
    form.setFieldsValue({
      sanctionsStatus: undefined,
      sanctionsScreeningTimestamp: undefined,
    } as Partial<ValidatedPayeeFormFields>);
  };

  function createUpdateRequestAllUpdateFieldsFromFormFields(
    formFields: CreateVerificationDataRecordPayeeFormFields,
  ): StoreSupplierValidationPayeeVerificationRecordRequest {
    const legalIdsList = [
      cleanLegalId(formFields.legalId1),
      cleanLegalId(formFields.legalId2),
      cleanLegalId(formFields.legalId3),
      ...markFirstAsPrimary([
        cleanLegalId(
          injectTypeIntoStrongLegalIdRequest<VerificationLegalIdentifierRequest>(
            allLEITypesLoadable,
            formFields.taxId1,
            StrongLegalIdentifiesTypes.TaxId,
          ),
        ),
        cleanLegalId(
          injectTypeIntoStrongLegalIdRequest<VerificationLegalIdentifierRequest>(
            allLEITypesLoadable,
            formFields.taxId2,
            StrongLegalIdentifiesTypes.TaxId,
          ),
        ),
      ]),
      cleanLegalId(
        injectTypeIntoStrongLegalIdRequest(
          allLEITypesLoadable,
          cleanDunsLegalId(formFields.duns),
          StrongLegalIdentifiesTypes.DUNS,
        ),
      ),
    ].filter(isDefined);

    const sanctionsScreeningObject: SupplierValidationVerificationSanctionsScreeningInfo = {
      result: formFields.sanctionsStatus,
      screeningTimestamp: formFields.sanctionsScreeningTimestamp,
    };

    return {
      name: trimToNull(formFields.name),
      additionalName: trimToNull(formFields.additionalName),
      address: trimToNull(formFields.address),
      additionalAddress: trimToNull(formFields.additionalAddress),
      countryCode: trimToNull(formFields.countryCode),
      legalEntityIdentifiers: legalIdsList.length ? legalIdsList : null,
      sanctionsScreening: Object.values(sanctionsScreeningObject).filter(isDefined).length ? sanctionsScreeningObject : null,
      alreadyExistingValidatedPayeeKnoxId: trimToNull(formFields.alreadyExistingValidatedPayeeKnoxId),
      isPrivate: formFields.isPrivate,
      isUpdateRequired: formFields.isUpdateRequired,
      effectiveTimestamp: formFields.effectiveTimestamp ?? null,
    };
  }

  const isNewEntity = !verificationRecordPayeeData;

  const verificationRecordPayeeBaseData = useMemo((): StoreSupplierValidationPayeeVerificationRecordRequest => {
    if (isNewEntity) {
      return {
        isPrivate: false,
        isUpdateRequired: false,
        alreadyExistingValidatedPayeeKnoxId: null,
        effectiveTimestamp: null,
        sanctionsScreening: null,
        additionalAddress: null,
        address: null,
        countryCode: null,
        additionalName: null,
        name: null,
        legalEntityIdentifiers: null,
      };
    }

    return createSupplierValidationRecordSupplierVerificationAllUpdateFieldsFromRecord(verificationRecordPayeeData);
  }, [verificationRecordPayeeData, isNewEntity]);

  const { isFormNotInitialized, amountOfChanges, amountOfErrors, storeRequest, updateRequest } = useFormChangesStatus(
    verificationRecordPayeeBaseData,
    form,
    createUpdateRequestAllUpdateFieldsFromFormFields,
    supplierValidationRecordSupplierVerificationAllUpdateFieldsEquators,
  );

  const hasChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged can change anytime
  }, [hasChanges]);

  const handleSave = async (): Promise<void> => {
    if (isFormNotInitialized) {
      return;
    }

    try {
      await validateFields();
      setIsSaving(true);
      let payeeVerificationRecordServerResponse: SupplierValidationPayeeVerificationRecordServerResponse;

      if (isNewEntity) {
        payeeVerificationRecordServerResponse = await validationSystemServices.storeSupplierValidationPayeeVerificationRecord(
          supplierValidationRecord.staticId,
          storeRequest,
        );
      } else {
        payeeVerificationRecordServerResponse = await validationSystemServices.updateSupplierValidationPayeeVerificationRecord(
          supplierValidationRecord.staticId,
          updateRequest,
        );
      }

      setIsOpen(false);
      onSaved(transformSupplierValidationPayeeVerification(payeeVerificationRecordServerResponse));
    } catch {
      setIsOpen(true);
      await validateFields();
    } finally {
      setIsSaving(false);
    }
  };

  const taxId1 = form.getFieldValue('taxId1');
  const taxId2 = form.getFieldValue('taxId2');
  const firstEmptyLegalIdFieldIndex = legalIds.findIndex((legalId) => !form.getFieldValue(legalId));
  const firstEmptyLegalIdField = legalIds[firstEmptyLegalIdFieldIndex];

  const isCopyTextButtonVisible = (taxId: VerificationLegalIdentifierRequest | undefined): boolean => {
    if (!taxId) {
      return false;
    }

    return !!removeCharsAndUpperCase(taxId?.value, ['-', '/', ' ', '.']) && !!taxId?.countryCode && !!firstEmptyLegalIdField;
  };

  const copyBelow = (taxId: VerificationLegalIdentifierRequest | undefined): void => {
    if (!taxId) {
      return;
    }

    if (!firstEmptyLegalIdField) {
      return;
    }

    form.setFieldsValue({
      [firstEmptyLegalIdField]: { typeId: undefined, countryCode: taxId.countryCode, value: taxId.value },
    });
  };

  const calculateTooltipTitle = (): string => {
    return `Copy Tax ID to ${toWordsOrdinal(firstEmptyLegalIdFieldIndex + 1)} Regular Legal ID`;
  };

  const fieldDecorators = createFieldDecorators(verificationRecordPayeeData, form);

  function renderFormFields(): ReactElement {
    const charsToIgnore = ['-', '/', ' ', '.', '\\'];

    const currentTaxIdTypeNameFromEvidence = calculateTaxIdNameFromEvidence(evidenceData);

    return (
      <Collapse
        activeKey={isOpen ? 'payee' : undefined}
        onChange={(keyOrKeys): void => setIsOpen(itemOrFirst(keyOrKeys) === 'payee')}
      >
        <CollapsePanelStickyHeader
          key='payee'
          forceRender
          header={
            <StickyHeaderContainer>
              <VerificationFormCollapseHeader
                dataTestId='verification-record-supplier-details-expander'
                title='SUPPLIER DETAILS'
                isNewEntity={isNewEntity}
                amountOfChanges={amountOfChanges}
                amountOfErrors={amountOfErrors}
                isLoading={isSaving}
              />
              <ActionsContainer>
                <ClickEventPropagationBlocker>
                  <Button
                    id='btn-verification-data-payee-save'
                    disabled={!hasChanges || isSaving || isPendingApprovalAndDoesntHaveApprovalRole}
                    onClick={handleSave}
                    disableAnimations
                  >
                    SAVE
                  </Button>
                </ClickEventPropagationBlocker>
              </ActionsContainer>
            </StickyHeaderContainer>
          }
        >
          <NsknoxForm
            form={form}
            showErrors={showFormErrors}
            disabled={isSaving || isPendingApprovalAndDoesntHaveApprovalRole}
            className={className}
            setShowErrors={setShowFormErrors}
          >
            <FieldsTitle>Payee Source</FieldsTitle>
            <FormItemBox appearance='none' fieldName='useExistingPayee' fieldDecoratorOptions={fieldDecorators.useExistingPayee}>
              <Toggle
                accessibilityLabel='Toggle new or existing payee'
                name='toggle-verification-data-payee-use-existing-payee'
                label='Use existing payee'
                onChange={(): void => {
                  setTimeout(() => {
                    form.setFieldsValue({
                      isUpdateRequired: false,
                      alreadyExistingValidatedPayeeKnoxId: undefined,
                    });
                    form.validateFields(['useExistingPayee', 'isUpdateRequired', 'alreadyExistingValidatedPayeeKnoxId']);
                  });
                }}
              />
            </FormItemBox>
            <InputGrid>
              <TransparentFormItemBox
                shouldBeHidden={!useExistingPayee}
                showErrors={useExistingPayee ? 'all' : undefined}
                fieldName='alreadyExistingValidatedPayeeKnoxId'
                fieldDecoratorOptions={fieldDecorators.alreadyExistingValidatedPayeeKnoxId}
                disabled={!useExistingPayee}
              >
                <NakedFormInput
                  name='inpt-verification-data-payee-existing-knox-id'
                  dataTestId='inpt-verification-data-payee-existing-knox-id'
                  type='text'
                  placeholder='Existing Knox Id'
                />
              </TransparentFormItemBox>
              <TransparentFormItemBox
                shouldBeHidden={!useExistingPayee}
                showErrors={useExistingPayee ? 'all' : undefined}
                appearance='none'
                fieldName='isUpdateRequired'
                fieldDecoratorOptions={fieldDecorators.isUpdateRequired}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id='checkbox-verification-data-payee-update-required'
                      data-testid='checkbox-verification-data-payee-update-required'
                      disabled={!useExistingPayee || isPendingApprovalAndDoesntHaveApprovalRole}
                    />
                  }
                  label='Update Required'
                />
              </TransparentFormItemBox>
            </InputGrid>
            {!useExistingPayee && <FieldsTitle>Visibility</FieldsTitle>}
            <TransparentFormItemBox
              shouldBeHidden={!!useExistingPayee}
              appearance='none'
              fieldName='isPrivate'
              fieldDecoratorOptions={fieldDecorators.isPrivate}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id='checkbox-verification-data-payee-private-payee'
                    data-testid='checkbox-verification-data-payee-private-payee'
                    disabled={isPendingApprovalAndDoesntHaveApprovalRole}
                  />
                }
                label='Set payee and accounts as private'
              />
            </TransparentFormItemBox>
            <FieldsTitle>General Info</FieldsTitle>
            <InputGrid>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-name-suggestion'
                fieldName='name'
                fieldDecoratorOptions={fieldDecorators.name}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(supplierValidationRecord.supplierRegistrationProcess?.companyName),
                    displayValue: supplierValidationRecord.supplierRegistrationProcess?.companyName,
                  },
                  {
                    name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                    value: upperCaseExceptDBAFAC(evidenceData?.data.payeeName),
                    displayValue: evidenceData?.data.payeeName,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'name', form, validateFields)}
                  name='inpt-verification-data-payee-name'
                  dataTestId='inpt-verification-data-payee-name'
                  type='text'
                  placeholder='Name'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-additional-name-suggestion'
                fieldName='additionalName'
                fieldDecoratorOptions={fieldDecorators.additionalName}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(supplierValidationRecord.supplierRegistrationProcess?.additionalCompanyName),
                    displayValue: supplierValidationRecord.supplierRegistrationProcess?.additionalCompanyName,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'additionalName', form, validateFields)}
                  name='inpt-verification-data-payee-additional-name'
                  dataTestId='inpt-verification-data-payee-additional-name'
                  type='text'
                  placeholder='Additional Name'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-address-suggestion'
                fieldName='address'
                fieldDecoratorOptions={fieldDecorators.address}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                        supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                    ),
                    displayValue:
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                      supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                  },
                  {
                    name: `Evidence${evidenceData?.type === EvidenceType.finicityOpenBanking ? ' (Finicity)' : ''}`,
                    value: upperCaseExceptDBAFAC(evidenceData?.data.address),
                    displayValue: evidenceData?.data.address,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'address', form, validateFields)}
                  name='inpt-verification-data-payee-address'
                  dataTestId='inpt-verification-data-payee-address'
                  type='text'
                  placeholder='Address'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-address-additional-suggestion'
                fieldName='additionalAddress'
                fieldDecoratorOptions={fieldDecorators.additionalAddress}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: upperCaseExceptDBAFAC(
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                        supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                    ),
                    displayValue:
                      supplierValidationRecord.supplierRegistrationProcess?.address.fullAddress ||
                      supplierValidationRecord.supplierRegistrationProcess?.address.addressDetails?.formattedAddress,
                  },
                ]}
              >
                <NakedFormInput
                  onBlur={(e): void => upperCaseFieldValue(e, 'additionalAddress', form, validateFields)}
                  name='inpt-verification-data-payee-additional-address'
                  dataTestId='inpt-verification-data-payee-additional-address'
                  type='text'
                  placeholder='Additional Address'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-country-suggestion'
                fieldName='countryCode'
                fieldDecoratorOptions={fieldDecorators.countryCode}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: supplierValidationRecord.supplierRegistrationProcess?.countryCode,
                    displayValue: getCountryName(supplierValidationRecord.supplierRegistrationProcess?.countryCode),
                  },
                ]}
              >
                <CountryDropdown
                  accessibilityLabel='Country code dropdown'
                  name='drp-verification-data-payee-countryCode'
                  dataTestId='drp-verification-data-payee-countryCode'
                />
              </FormItemBoxWithSuggestions>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-effective-date-timestamp'
                fieldName='effectiveTimestamp'
                fieldDecoratorOptions={fieldDecorators.effectiveTimestamp}
                suggestions={[
                  {
                    name: 'Evidence',
                    value: evidenceData?.effectiveDate,
                    displayValue: evidenceData?.effectiveDate ? localeStore.formatDate(evidenceData.effectiveDate) : undefined,
                  },
                ]}
              >
                <NakedCalendarDatePicker
                  placeholder='Effective Date'
                  id='date-picker-verification-data-payee-effective-date-timestamp'
                  accessibilityLabel='Effective Date'
                  disabledDate={(date): boolean => date?.isAfter() ?? false}
                />
              </FormItemBoxWithSuggestions>
            </InputGrid>
            <FieldsTitle>Strong Legal Ids</FieldsTitle>
            <FormItemBox fieldName='duns' fieldDecoratorOptions={fieldDecorators.duns}>
              <LegalIdInput
                accessibilityLabel='Duns legal entity id'
                name='verification-data-payee-duns'
                showCountry={false}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.DUNS))}
                forceUpperCaseValue
              />
            </FormItemBox>
            <Row>
              <FormItemBoxWithSuggestions
                dataTestId='inpt-verification-data-payee-taxId1-suggestion'
                fieldName='taxId1'
                fieldDecoratorOptions={fieldDecorators.taxId1}
                equalityCheck={createLegalIdentifierEqualityFunction(charsToIgnore)}
                suggestions={[
                  {
                    name: 'Registration-Form',
                    value: supplierValidationRecord.supplierRegistrationProcess?.legalId
                      ? {
                          typeId: allLEITypesLoadable.result?.strongTypes.TaxId?.id ?? null,
                          countryCode: supplierValidationRecord.supplierRegistrationProcess?.countryCode ?? null,
                          value: removeCharsAndUpperCase(
                            supplierValidationRecord.supplierRegistrationProcess?.legalId,
                            charsToIgnore,
                          ),
                        }
                      : undefined,
                    displayValue: `${supplierValidationRecord.supplierRegistrationProcess?.legalId} - ${
                      supplierValidationRecord.supplierRegistrationProcess?.legalIdType
                    } (${getCountryName(supplierValidationRecord.supplierRegistrationProcess?.countryCode)})`,
                  },
                  {
                    name: 'Evidence',
                    value: evidenceData?.taxId
                      ? {
                          typeId: allLEITypesLoadable.result?.strongTypes.TaxId?.id ?? null,
                          countryCode: evidenceData?.taxIdCountryCode ?? null,
                          value: removeCharsAndUpperCase(evidenceData?.taxId, charsToIgnore),
                        }
                      : undefined,
                    displayValue: `${evidenceData?.taxId} - ${currentTaxIdTypeNameFromEvidence ?? 'NA'}${
                      evidenceData?.taxIdCountryCode ? ` (${COUNTRIES[evidenceData.taxIdCountryCode]?.name})` : ''
                    }`,
                  },
                ]}
              >
                <LegalIdInput
                  accessibilityLabel='First tax id'
                  name='verification-data-payee-tax-id-1'
                  showCountry
                  currentPayeeCountryCode={payeeCountryCode}
                  legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
                  forceUpperCaseValue
                />
              </FormItemBoxWithSuggestions>
              <ConditionalTooltip showTooltip={isCopyTextButtonVisible(taxId1)} title={calculateTooltipTitle()} placement='top'>
                <CopyBelowButton
                  appearance='text'
                  id='btn-verification-data-payee-tax-id-1-copy-below'
                  onClick={(): void => copyBelow(taxId1)}
                  hidden={!isCopyTextButtonVisible}
                >
                  ⬇
                </CopyBelowButton>
              </ConditionalTooltip>
            </Row>
            <Row>
              <FullWidth>
                <FormItemBox fieldName='taxId2' fieldDecoratorOptions={fieldDecorators.taxId2}>
                  <LegalIdInput
                    accessibilityLabel='second tax id'
                    name='verification-data-payee-tax-id-2'
                    showCountry
                    currentPayeeCountryCode={payeeCountryCode}
                    legalIdTypeOptions={allLEITypesLoadable.map((loaded) => wrapValueAsArray(loaded.strongTypes.TaxId))}
                    forceUpperCaseValue
                  />
                </FormItemBox>
              </FullWidth>
              <ConditionalTooltip showTooltip={isCopyTextButtonVisible(taxId2)} title={calculateTooltipTitle()} placement='top'>
                <CopyBelowButton
                  appearance='text'
                  id='btn-verification-data-payee-tax-id-2-copy-below'
                  onClick={(): void => copyBelow(taxId2)}
                  hidden={!isCopyTextButtonVisible}
                >
                  ⬇
                </CopyBelowButton>
              </ConditionalTooltip>
            </Row>
            <FieldsTitle>Regular Legal Ids</FieldsTitle>
            <FormItemBoxWithSuggestions
              dataTestId='inpt-verification-data-payee-legal-id-1-suggestion'
              fieldName='legalId1'
              fieldDecoratorOptions={fieldDecorators.legalId1}
              equalityCheck={createLegalIdentifierEqualityFunction(charsToIgnore)}
              suggestions={[
                {
                  name: 'Evidence',
                  value:
                    currentTaxIdTypeNameFromEvidence && evidenceData?.type === EvidenceType.surepayValidation
                      ? {
                          typeId:
                            allLEITypesLoadable.result?.regularTypes.find(
                              (legalId) => legalId.name === currentTaxIdTypeNameFromEvidence,
                            )?.id ?? null,
                          countryCode: evidenceData?.taxIdCountryCode ?? null,
                          value: removeCharsAndUpperCase(evidenceData?.taxId, charsToIgnore),
                        }
                      : undefined,
                  displayValue: `${evidenceData?.taxId} - ${currentTaxIdTypeNameFromEvidence ?? 'NA'}${
                    evidenceData?.taxIdCountryCode ? ` (${COUNTRIES[evidenceData.taxIdCountryCode]?.name})` : ''
                  }`,
                },
              ]}
            >
              <LegalIdInput
                accessibilityLabel='first legal entity id'
                name='verification-data-payee-legal-id-1'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBoxWithSuggestions>
            <FormItemBox fieldName='legalId2' fieldDecoratorOptions={fieldDecorators.legalId2}>
              <LegalIdInput
                accessibilityLabel='second legal entity id'
                name='verification-data-payee-legal-id-2'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBox>
            <FormItemBox fieldName='legalId3' fieldDecoratorOptions={fieldDecorators.legalId3}>
              <LegalIdInput
                accessibilityLabel='third legal entity id'
                name='verification-data-payee-legal-id-3'
                showCountry
                currentPayeeCountryCode={payeeCountryCode}
                legalIdTypeOptions={allLEITypesLoadable.map((loaded) => loaded.regularTypes)}
                forceUpperCaseValue
              />
            </FormItemBox>
            <TitleContainer>
              <FieldsTitle>Sanctions Screening</FieldsTitle>
              <ResetButton
                appearance='text'
                id='verification-data-payee-reset-sanctions-fields'
                onClick={resetSanctions}
                disabled={isPendingApprovalAndDoesntHaveApprovalRole}
              >
                Reset Sanctions
              </ResetButton>
            </TitleContainer>
            <InputGrid>
              <SanctionsScreeningTimestampDiv>
                <FormItemBox
                  fieldName='sanctionsScreeningTimestamp'
                  fieldDecoratorOptions={fieldDecorators.sanctionsScreeningTimestamp}
                >
                  <NakedCalendarDatePicker
                    id='date-picker-sanctions-date'
                    placeholder='Sanctions Screening Date'
                    accessibilityLabel='Sanctions Screening Date'
                    disabledDate={(date): boolean => date?.isAfter() ?? false}
                  />
                </FormItemBox>
                {isPastSanctionsDate && <DateWarning>Note the date provided is not the current date</DateWarning>}
              </SanctionsScreeningTimestampDiv>
              <FormItemBox appearance='none' fieldName='sanctionsStatus' fieldDecoratorOptions={fieldDecorators.sanctionsStatus}>
                <FormRadioGroup
                  id='radio-group-sanctions-status'
                  dataTestId='radio-group-sanctions-status'
                  accessibilityLabel='Sanctions Screening Result Status'
                  options={[
                    {
                      value: SanctionsScreeningResultStatus.Passed,
                      label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Passed),
                    },
                    {
                      value: SanctionsScreeningResultStatus.Rejected,
                      label: getTextFromSanctionStatus(SanctionsScreeningResultStatus.Rejected),
                    },
                  ]}
                />
              </FormItemBox>
            </InputGrid>
          </NsknoxForm>
        </CollapsePanelStickyHeader>
      </Collapse>
    );
  }

  return renderFormFields();
});

export default Form.create<Props>()(VerificationRecordPayeeForm);
