import { SupplierRegistrationCountryFields } from '@supplierRegistration/domain/supplierRegistrationCountryFields';
import { trim } from '@app/utils/stringUtils';
import { validateIban } from '@app/domain/accountDetailsValidators';

enum ItalyLegalIdTypesForCompany {
  VAT = 'VAT',
  Other = 'Other',
}

export const IT: SupplierRegistrationCountryFields = {
  leis: { types: ItalyLegalIdTypesForCompany },
  accountNumberAdditionalRules: [
    {
      transform: trim,
      validator: validateIban,
    },
  ],
};
