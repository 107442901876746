import React, { FC, ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import ArrowBullet from '@app/components/ArrowBullet';
import { Trans, useTranslation } from 'react-i18next';
import { AccentSubTitle, MainCard, Title } from '@supplierRegistration/routes/Styles';
import { observer } from 'mobx-react';
import useInfraStores from '@app/hooks/useInfraStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import LogoStrip from '@app/components/LogoStrip';
import ReferringOrg from '@supplierRegistration/components/ReferringOrg';
import Card from '@app/components/card/Card';
import useTheme from '@app/hooks/useTheme';
import useAppStores from '@app/hooks/useAppStores';
import { handleReadFromClipboard } from '@supplierRegistration/routes/supplierRegistrationProcess/CopyRegistrationInstructions';
import config from '@app/config';
import {
  SupplierRegistrationBankAccountFormFields,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
  SupplierValidationRegistrationProps,
} from '@supplierRegistration/domain/supplierRegistration';
import Button from '@app/components/Button';
import VideoPopup from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/VideoPopup';

const WelcomeSupplier: FC<SupplierValidationRegistrationProps> = observer((props) => {
  const { navigationStore } = useInfraStores<SupplierRegistrationMode>();
  const theme = useTheme<SupplierRegistrationMode>();
  const { t } = useTranslation();
  const { brand } = useTheme<SupplierRegistrationMode>();
  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { autoplayingUrl, nonAutoplayingUrl, visible: videoVisible } = config.supplierRegistration.video;

  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const { configuration, isIpsMode, nextStep, saveValues } = props;

  const CASS_DISCLAIMER_REFS = [
    'a38ee32e-7b07-42f6-8726-fff0bf12f69d', // Dev Envs - "nsKnox first test ORG" + Preprod - "Etrod"
    // Prod - CASS
    '652a60fe-48c5-4283-962e-2ff8fbcb13af', //TIS
    'b47e12e5-8025-4d0e-8f02-a8d096a6f596', //EM
  ];
  const showCassText = configuration?.ref && CASS_DISCLAIMER_REFS.includes(configuration.ref);
  const isAsync = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData?.isResolved();

  useEffect(() => {
    const handlePasteAnywhere = config.featureFlags?.quickFillSupplierRegistration
      ? async (): Promise<void> => {
          const copiedRow = await handleReadFromClipboard();
          if (!copiedRow) {
            return;
          }

          const supplierValues: RecursivePartial<SupplierRegistrationCompanyInfoFormFields> = copiedRow as RecursivePartial<SupplierRegistrationCompanyInfoFormFields>;
          const accountValues: RecursivePartial<SupplierRegistrationBankAccountFormFields> = copiedRow as RecursivePartial<SupplierRegistrationBankAccountFormFields>;
          const formFieldsFormat: RecursivePartial<SupplierRegistrationFormValuesState> = { supplierValues, accountValues };

          saveValues?.(formFieldsFormat as SupplierRegistrationFormValuesState);
        }
      : (): void => {};

    window.addEventListener('paste', handlePasteAnywhere);

    return () => {
      window.removeEventListener('paste', handlePasteAnywhere);
    };
  }, [saveValues]);

  function getParagraphText(): ReactNode {
    if (showCassText) {
      return (
        <Trans i18nKey='supplierValidation.welcome.toPreventWithCustomerNameForCass' values={{ customerName }}>
          In an effort to protect our company and you against cyber-fraud, Cass Information Systems has engaged nsKnox to validate
          your bank account details.
        </Trans>
      );
    }

    if (customerName) {
      if (configuration?.brand?.reducedText) {
        return (
          <Trans i18nKey='supplierValidation.welcome.toPreventWithCustomerNameReducedText' values={{ customerName }}>
            To prevent attempts by cyber criminals to divert funds, {{ customerName }} has engaged nsKnox to validate your bank
            account details.
          </Trans>
        );
      }

      return (
        <Trans i18nKey='supplierValidation.welcome.toPreventWithCustomerName' values={{ customerName }}>
          To prevent attempts by cyber criminals to divert funds, which were intended for you, to their fraudulent accounts,{' '}
          {{ customerName }} has engaged nsKnox to validate your bank account details.
        </Trans>
      );
    }

    return <>{t('supplierValidation.welcome.toPrevent')}</>;
  }

  return (
    <>
      <MainCard>
        {configuration?.clientName && !brand && <ReferringOrg orgName={configuration.clientName} />}
        <AccentTitle>
          {t('supplierValidation.welcome.welcomeToOnline')}
          <br />
          {t('supplierValidation.welcome.accountValidationProc')}
        </AccentTitle>
        <WelcomeSubTitle>{t('supplierValidation.welcome.protectingSupplier')}</WelcomeSubTitle>
        <Paragraph>{getParagraphText()}</Paragraph>
        <StepsTitle>
          <StyledArrowBullet />
          <Text>{t('supplierValidation.welcome.easyTwoStep')}</Text>
        </StepsTitle>
        <Steps>
          <StepCard customBackgroundColor='#f5f6fa'>
            <StepNumber>{t('supplierValidation.welcome.stepOne')}</StepNumber>
            {isAsync ? t('supplierValidation.welcome.enterCompInfoAsync') : t('supplierValidation.welcome.enterCompInfo')}
          </StepCard>
          <StepsArrows>
            <StyledArrowBullet />
            <StyledArrowBullet />
            <StyledArrowBullet />
          </StepsArrows>
          <StepCard customBackgroundColor='#f5f6fa'>
            <StepNumber>{t('supplierValidation.welcome.stepTwo')}</StepNumber>
            {t('supplierValidation.welcome.makeTransfer')}
          </StepCard>
        </Steps>
        <ActionsContainer>
          <StartButton
            id='vldt-for-supp-wlcm-start'
            onClick={(): void => {
              nextStep();
            }}
            colorScheme={theme.continueButtonColor}
          >
            {t('supplierValidation.welcome.getStarted')}
          </StartButton>
          {configuration && videoVisible && (
            <VideoPopup
              id='proccess-explainer-video'
              title={t<string>('supplierValidation.explainerVideo')}
              autoplaySrc={autoplayingUrl}
              nonAutoplaySrc={nonAutoplayingUrl}
              width='489'
              height='270'
              frameBorder='0'
              allowFullScreen
            />
          )}
          <FAQLink
            id='vldt-for-supp-wlcm-faq'
            data-testid='vldt-for-supp-wlcm-faq'
            to={navigationStore.generateAccountValidationForSuppliersFAQ(
              isIpsMode,
              supplierRegistrationCustomerDataStore.currentReference?.referenceId ?? null,
            )}
          >
            {t('supplierValidation.faq')}
          </FAQLink>
        </ActionsContainer>
      </MainCard>
      {!customerName && <StyledLogoStrip />}
    </>
  );
});

export default WelcomeSupplier;

const WelcomeSubTitle = styled(AccentSubTitle)`
  height: 16px;
  width: 335px;
  color: var(--solid-black);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: var(--transparent-black-700);

  &:first-of-type {
    margin-top: 50px;
  }
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 38px;
`;

const StepsArrows = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ActionsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;

const FAQLink = styled(Link)`
  font-size: 14px;
  text-decoration: underline !important;
  color: var(--action-color);
`;

const StyledArrowBullet = styled(ArrowBullet)`
  min-height: 22px;
  max-height: 22px;
  min-width: 14px;
  max-width: 14px;
`;

const StyledLogoStrip = styled(LogoStrip)`
  width: min(900px, 95vw);
`;

const StepsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 24px;
  line-height: normal;
  color: var(--transparent-black-900);

  margin: 45px 0 25px;

  & > :first-child {
    margin-inline-end: 8px;
  }
`;

const Text = styled.span`
  margin-bottom: -0.25em;
`;

const StepCard = styled(Card)`
  font-size: 20px;
  color: var(--transparent-black-700);
  box-shadow: unset;
`;

const StepNumber = styled.div`
  color: var(--transparent-black-900);
  margin-bottom: 10px;
`;

const AccentTitle = styled(Title)`
  color: var(--solid-black);
  font-size: 32px;
  padding-top: 50px;
  margin-bottom: 10px;
`;

const StartButton = styled(Button)`
  display: inline-block;
  padding: 8px 22px;
  font-size: 14px;
  margin-bottom: 30px;
`;
