import { createTheme, Theme } from '@mui/material/styles';

export const primaryColor = '#142361';

export const theme = (mainColor: string = primaryColor): Theme =>
  createTheme({
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: '#142361',
      },
    },
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'primary',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: mainColor,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            fullWidth: true,
            margin: 'normal',
          },
        },
      },
    },
    typography: {
      fontFamily: 'inherit',
    },
  });
