import { observer, useLocalObservable } from 'mobx-react';
import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import TextMessageImage from '@app/guestLogin/images/guest-text-me.svg';
import PhoneCallImage from '@app/guestLogin/images/guest-call-me.svg';
import CloseImage from '@app/images/ic_close.svg';
import { RowSeparator } from '@app/components/Styles';
import { Form } from 'antd';
import styled from '@emotion/styled';
import { confirm, ModalTitle } from '@app/components/Modal';
import SVG from '@app/components/SVG';
import { GuestAuthCodeDeliveryMethod } from '@app/guestLogin/domain/guestLogin';
import Button from '@app/components/Button';
import { useTimeModel, createTimeModel } from 'react-compound-timer';
import config from '@app/config';
import { FormFieldDecorators } from '@app/utils/form/form';
import useInfraStores from '@app/hooks/useInfraStores';
import ArMode from '@ar/arMode';
import { LoginFooterSubTitle, LoginSubTitle, LoginTitle } from '../GuestLoginFlow';
import ArrowBullet from '@app/components/ArrowBullet';
import * as messageLauncher from '@app/utils/messageLauncher';
import { extractLogErrorIdFromError, isAuthenticatorErrorContent, RequestError } from '@app/libs/request';
import FormItemBox from '@app/components/inputs/FormItemBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import useInnerPageEnterAnalyticsReport, { InnerPage } from '@app/hooks/useInnerPageEnterAnalyticsReport';
import { useTranslation } from 'react-i18next';
import { createTranslatedFormalErrorMessage } from '@app/utils/errorMessageUtils';
import useModalContext from '@app/hooks/useModalContext';
import ModalAppContext from '@app/ModalAppContext';
import { LocaleAwareDiv } from '@app/components/LocaleAwareComponents';

const timer = createTimeModel({
  initialTime: config.resendLoginCodeTimerDurationSec * 1000,
  direction: 'backward',
  timeToUpdate: 300,
});

interface EnterCodeFormFields {
  code: string;
}

interface Props extends FormComponentProps<EnterCodeFormFields> {
  phoneNumber: string;
  cancelWaitingForCode: () => void;
}

const GuestLoginEnterPhoneCode: FunctionComponent<Props> = observer((props) => {
  useInnerPageEnterAnalyticsReport(InnerPage.loginEnterPhoneCode);
  const { guestLoginStore } = useInfraStores<ArMode>();
  const { t } = useTranslation();

  const codeLength = guestLoginStore.phoneKnoxer?.codeLength ?? 0;

  const localStore = useLocalObservable(() => ({
    inputErrorText: null as string | null,
    deliveryMethod: GuestAuthCodeDeliveryMethod.TextMessage as GuestAuthCodeDeliveryMethod,

    setInputErrorText: (errorText: string | null): void => {
      localStore.inputErrorText = errorText;
    },
    setDeliveryMethod: (deliveryMethod: GuestAuthCodeDeliveryMethod): void => {
      localStore.deliveryMethod = deliveryMethod;
    },
  }));

  const fieldDecorators: FormFieldDecorators<EnterCodeFormFields> = {
    code: {
      validateTrigger: 'onBlur',
      rules: [
        {
          required: true,
          translatedMessage: 'ar.guestLogin.errors.enterCode',
        },
        {
          len: codeLength,
          translatedMessage: {
            key: 'ar.guestLogin.errors.codeLength',
            value: codeLength,
          },
        },
        {
          pattern: /^[0-9]+$/,
          translatedMessage: 'ar.guestLogin.errors.mustBeNumeric',
        },
      ],
    },
  };

  const requestToken = async (code: string): Promise<void> => {
    try {
      await guestLoginStore.requestTokenFromPhoneKnoxer(code, localStore.deliveryMethod);
    } catch (e: unknown) {
      const requestError = e as RequestError;

      if (isAuthenticatorErrorContent(requestError.responseJSON) && requestError.responseJSON.errorCode === 32) {
        localStore.setInputErrorText(t('ar.guestLogin.errors.wrongCode'));
      } else {
        const errorLogId = extractLogErrorIdFromError(e);
        const errorMessage = createTranslatedFormalErrorMessage(
          t,
          'ar.guestLogin.errors.unexpectedLoginError',
          'general.errors.pleaseContact',
          'general.errors.referToErrorCode',
          errorLogId,
        );
        messageLauncher.shootErrorOld(errorMessage, t('general.errors.errorPopupTitle'));
      }
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    await handleOk();
  };

  const handleOk = (): void => {
    const { form } = props;
    const { validateFieldsAndScroll } = form;

    validateFieldsAndScroll((errors: Object, values: EnterCodeFormFields) => {
      if (errors) {
        return;
      }

      const { code } = values;

      requestToken(code);
    });
  };

  const handleCodeInputClick = (): void => {
    const { resetFields } = props.form;

    if (localStore.inputErrorText) {
      resetFields();
      localStore.setInputErrorText(null);
    }
  };

  const onCodeNotReceived = (): void => {
    let okText: string;
    let cancelText: string;
    let onCancel: any;

    switch (localStore.deliveryMethod) {
      case GuestAuthCodeDeliveryMethod.TextMessage:
        okText = t('ar.guestLogin.phoneCode.resend');
        cancelText = t('ar.guestLogin.phoneCode.callme');
        onCancel = (): void => {
          requestTextMessageCodeFromPhoneKnoxer();
        };
        break;
      case GuestAuthCodeDeliveryMethod.PhoneCall:
        okText = t('ar.guestLogin.phoneCode.tryAgain');
        cancelText = t('ar.guestLogin.phoneCode.keepWaiting');
        break;
    }

    const { destroy } = confirm({
      okText,
      onCancel,
      cancelText,
      content: renderCodeNotReceived(() => destroy()),
      icon: <span />,
      onOk: () => onBack(),
    });
  };

  const requestTextMessageCodeFromPhoneKnoxer = async (): Promise<void> => {
    const didRequestSucceed = await guestLoginStore.requestCodeFromPhoneKnoxer(
      phoneNumber,
      GuestAuthCodeDeliveryMethod.PhoneCall,
    );

    if (didRequestSucceed) {
      localStore.setDeliveryMethod(GuestAuthCodeDeliveryMethod.PhoneCall);
    }
  };

  const modalContext = useModalContext();

  const renderCodeNotReceived = (handleClose: () => void): ReactElement<void> => {
    if (localStore.deliveryMethod === GuestAuthCodeDeliveryMethod.PhoneCall) {
      return (
        <ModalAppContext {...modalContext}>
          <ModalContainer>
            <CloseButton
              id='btn-guest-phone-enter-code-not-received-close-modal'
              appearance='text'
              cornerType='none'
              colorScheme='primary'
              onClick={handleClose}
            >
              <SVG accessibilityLabel={t('general.accessibility.close')} image={CloseImage} height={18} />
            </CloseButton>
            <ModalTitle>{t('ar.guestLogin.phoneCode.codeNotReceived')}</ModalTitle>
            <ModalBody>
              <ModalRow>
                <ModalTextLine>{t('ar.guestLogin.phoneCode.makeSure')}</ModalTextLine>
              </ModalRow>
            </ModalBody>
          </ModalContainer>
        </ModalAppContext>
      );
    }

    return (
      <ModalAppContext {...modalContext}>
        <ModalContainer>
          <LocaleAwareDiv>
            <CloseButton
              id='btn-guest-phone-enter-code-not-received-close-modal'
              appearance='text'
              cornerType='none'
              colorScheme='primary'
              onClick={handleClose}
            >
              <SVG accessibilityLabel={t('general.accessibility.close')} image={CloseImage} height={18} />
            </CloseButton>
            <ModalTitle>{t('ar.guestLogin.phoneCode.textNotArriving')}</ModalTitle>
            <ModalBody>
              <ModalRow>
                <StyledArrowBullet />
                <ModalTextLine>{t('ar.guestLogin.phoneCode.checkPhoneNumber')}</ModalTextLine>
              </ModalRow>
              <ModalRow>
                <StyledArrowBullet />
                <ModalTextLine>{t('ar.guestLogin.phoneCode.ifYouPrefer')}</ModalTextLine>
              </ModalRow>
            </ModalBody>
          </LocaleAwareDiv>
        </ModalContainer>
      </ModalAppContext>
    );
  };

  const renderFooter = (phoneNumber: string, deliveryMethod: GuestAuthCodeDeliveryMethod): ReactNode => {
    let infoText: string;
    let SVGImage: SpriteSymbol;

    switch (deliveryMethod) {
      case GuestAuthCodeDeliveryMethod.PhoneCall:
        infoText = t('ar.guestLogin.phoneCode.aboutToReceiveCall', { phoneNumber });
        SVGImage = PhoneCallImage;
        break;
      case GuestAuthCodeDeliveryMethod.TextMessage:
        infoText = t('ar.guestLogin.phoneCode.weSentMessage', { phoneNumber });
        SVGImage = TextMessageImage;
        break;
    }

    return (
      <FooterContainer>
        <FooterSVG accessibilityLabel='' image={SVGImage} width={35} />
        <LoginFooterSubTitle>{infoText}</LoginFooterSubTitle>
      </FooterContainer>
    );
  };

  const renderSubTitle = (deliveryMethod: GuestAuthCodeDeliveryMethod): ReactNode => {
    let verificationText: string;

    switch (deliveryMethod) {
      case GuestAuthCodeDeliveryMethod.PhoneCall:
        verificationText = t('ar.guestLogin.phoneCode.codeSentViaVoice');
        break;
      case GuestAuthCodeDeliveryMethod.TextMessage:
        verificationText = t('ar.guestLogin.phoneCode.codeSentViaText');
        break;
    }

    return <LoginSubTitle>{verificationText}</LoginSubTitle>;
  };

  const { value: timeModel } = useTimeModel(timer);

  const formatValue = (value: number): string => `${value < 10 ? `0${value}` : value}`;

  const renderTimerOrButton = (): ReactNode => {
    if (timeModel.state === 'PLAYING' || timeModel.state === 'INITED') {
      const minutes = timeModel.m;
      const seconds = timeModel.s;

      return (
        <StyledTimerDiv>
          {formatValue(minutes)}:{formatValue(seconds)}
        </StyledTimerDiv>
      );
    }

    return (
      <Button id='btn-guest-phone-enter-code-not-received-open-modal' appearance='text' onClick={onCodeNotReceived} size='small'>
        {t('ar.guestLogin.phoneCode.codeNotArriving')}
      </Button>
    );
  };

  const onBack = (): void => {
    props.cancelWaitingForCode();
  };

  const { form, phoneNumber } = props;

  return (
    <>
      <ContentContainer>
        <LoginTitle>{t('ar.guestLogin.phoneCode.phoneVerification')}</LoginTitle>
        {renderSubTitle(localStore.deliveryMethod)}
        <EnterCodeForm onSubmit={handleFormSubmit}>
          <FormItemBox
            appearance='corners'
            form={form}
            fieldName='code'
            fieldDecoratorOptions={fieldDecorators.code}
            forcedErrorText={localStore.inputErrorText}
          >
            <NakedFormInput
              name='inpt-guest-phone-enter-code'
              type='number'
              autoFocus
              placeholder={t<string>('ar.guestLogin.phoneCode.enterCode', { codeLength })}
              onMaxLengthAchieved={handleOk}
              maxLength={codeLength}
              onClick={handleCodeInputClick}
              onFocus={handleCodeInputClick}
            />
          </FormItemBox>
          <ActionsContainer>
            <Button id='btn-guest-phone-enter-code-back' appearance='text' type='button' onClick={onBack} size='small'>
              {t('general.upperBack')}
            </Button>
            {renderTimerOrButton()}
          </ActionsContainer>
        </EnterCodeForm>
      </ContentContainer>
      <RowSeparator />
      {renderFooter(phoneNumber, localStore.deliveryMethod)}
    </>
  );
});

export default Form.create<Props>()(GuestLoginEnterPhoneCode);

const StyledArrowBullet = styled(ArrowBullet)`
  margin-right: 8px;
  margin-top: 4px;
  width: 14px;
  height: 14px;
`;

const ContentContainer = styled.div`
  padding: 32px 64px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`;

const FooterSVG = styled(SVG)`
  align-self: stretch;
`;

const FooterContainer = styled.div`
  padding: 16px 72px;
  display: grid;
  column-gap: 24px;
  grid-template-columns: auto auto;
  align-self: stretch;
`;

const EnterCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  padding-right: 5px;
  padding-left: 5px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

const ModalContainer = styled(LocaleAwareDiv)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalTextLine = styled.div`
  font-family: var(--text-font-family);
  font-size: 16px;
  line-height: 1.5;
  color: var(--transparent-black-600);

  margin-bottom: 8px;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
`;

const StyledTimerDiv = styled.div`
  font-size: 12px;
  padding: 10px;
`;
