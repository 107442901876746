import React, { FC } from 'react';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import {
  renderRecordType,
  ShouldSendInstComponentTypes,
  SupplierRegistrationProcess,
} from '@mortee/domain/morteeRegistrationForms';
import { renderSpecialInfo } from '@app/components/SpecialInfoComponents';
import useInfraStores from '@app/hooks/useInfraStores';
import styled from '@emotion/styled';
import { Regular22TransparentBlack900, SubtitleSmallStartTransparentBlack400 } from '@app/components/Text';
import Button from '@app/components/Button';
import { openSupplierRegistrationAllDataModal } from '@mortee/routes/supplierRegistrationsManagement/SupplierRegistrationViewModal';
import useModalContext from '@app/hooks/useModalContext';
import supplierRegistrationProcessIcon from '@mortee/images/validationSystem/supplierRegistrationProcessIcon.svg';
import IconAndText from '@mortee/components/IconAndText';
import EyeOffImage from '@app/images/ic-eye-off.svg';
import SVG from '@app/components/SVG';

import { renderInstructionType } from '@mortee/domain/validationSystemInfoComponents';

type LinkActions = {
  isLinked: boolean;
  onLinkChanged(): void;
};

interface Props {
  item: SupplierRegistrationProcess;
  titleOnly?: boolean;
  link?: LinkActions | false;
  className?: string;
}

const SupplierRegistrationCard: FC<Props> = ({ item, titleOnly, link, className }) => {
  const { localeStore } = useInfraStores();
  const modalContext = useModalContext();

  return (
    <Card className={className} data-testid='sr-inner-card'>
      <TitleLine>
        <div>
          <Regular22TransparentBlack900.div data-testid='sr-company-name'>{item.companyName}</Regular22TransparentBlack900.div>
          <TitleIconsAndTextContainer>
            {item.hidden && <SVG width={24} height={24} image={EyeOffImage} accessibilityLabel='Hidden Record' />}
            <IconAndText
              image={supplierRegistrationProcessIcon}
              iconAccessibilityLabel='supplier registration process icon'
              className={SubtitleSmallStartTransparentBlack400.className}
            >
              <span data-testid='sr-registration-number'>{item.registrationNumber}</span>
            </IconAndText>
          </TitleIconsAndTextContainer>
        </div>
        <Actions>
          <Button
            id='btn-sr-open-all-data'
            appearance='text'
            colorScheme='secondary'
            size='small'
            onClick={(): void => openSupplierRegistrationAllDataModal(item, modalContext)}
          >
            ALL DATA
          </Button>
          {link &&
            (link.isLinked ? (
              <Button id={`btn-sr-unlink-${item.registrationNumber}`} size='small' onClick={link.onLinkChanged}>
                UNLINK
              </Button>
            ) : (
              <Button id={`btn-sr-link-${item.registrationNumber}`} size='small' onClick={link.onLinkChanged}>
                LINK
              </Button>
            ))}
        </Actions>
      </TitleLine>
      {!titleOnly && (
        <>
          <Separator />
          <TwoColumnGrid>
            <DataGrid>
              <DataGridRow title='Registration time' valueDataTest='sr-registration-time'>
                {localeStore.formatDateTime(item.writeTimestamp)}
              </DataGridRow>
              <DataGridRow title='Form type' valueDataTest='sr-form-type'>
                {renderRecordType(item)}
              </DataGridRow>
              <DataGridRow title='Company/Account holder' valueDataTest='sr-holder'>
                {item.firstName ? `${item.firstName} ${item.lastName}` : item.asyncFullName}
              </DataGridRow>
              <DataGridRow title='Instructions' valueDataTest='sr-instructions'>
                {renderInstructionType(item.instructionType)}
              </DataGridRow>
              <DataGridRow title='Additional company name' valueDataTest='sr-additional-company-name'>
                {item.additionalCompanyName}
              </DataGridRow>
            </DataGrid>
            <DataGrid>
              <DataGridRow title='Email' valueDataTest='sr-email'>
                {item.email}
              </DataGridRow>
              <DataGridRow title='Referring Customer' valueDataTest='sr-referring-customer'>
                {item.referringCustomer}
              </DataGridRow>
              <DataGridRow title='Should send Instructions' valueDataTest='sr-should-send-instructions'>
                {renderSpecialInfo(ShouldSendInstComponentTypes[item.shouldSendInstructionToSupplier])}
              </DataGridRow>
              <DataGridRow title='Additional Parameters (id)' valueDataTest='sr-additional-id'>
                {item.registrationId}
              </DataGridRow>
            </DataGrid>
          </TwoColumnGrid>
        </>
      )}
    </Card>
  );
};

export default SupplierRegistrationCard;

const Card = styled.div`
  padding: 20px 26px;
  border: solid 1px var(--transparent-black-200);
  background-color: var(--inner-card-background-color-blue);
`;

const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Separator = styled.hr`
  border-top: solid 1px var(--transparent-black-200);
  margin: 14px 0;
`;

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  align-content: start;
`;

const TitleIconsAndTextContainer = styled.div`
  ${Regular22TransparentBlack900.css};
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
