import React, { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { BodyRegularStartTransparentBlack600, Regular13TransparentBlack600 } from '@app/components/Text';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import useForm from '@app/hooks/useForm';
import NsknoxForm from '@app/components/inputs/NsknoxForm';
import FormItemBox from '@app/components/inputs/FormItemBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import * as messageLauncher from '@app/utils/messageLauncher';
import AsyncButton from '@app/components/AsyncButton';
import Log from '@app/libs/logger';
import validationSystemServices from '@mortee/services/validationSystemServices';
import {
  createSupplierValidationRecordAutoFollowUpsUpdateFieldsFromRecord,
  displayDistributionWarningMessage,
  SupplierValidationRecord,
  supplierValidationRecordAllUpdateFieldsEquators,
  SupplierValidationRecordAutoFollowUpsUpdateFields,
  transformSupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import Toggle from '@app/components/inputs/Toggle';
import { FormattedDateTime } from '@app/components/Locale';
import DataGrid, { DataGridRow } from '@app/components/DataGrid';
import VerticalShadowScroller from '@app/components/VerticalShadowScroller';
import useInfraStores from '@app/hooks/useInfraStores';
import useFormChangesStatus from '@app/hooks/useFormChangesStatus';
import {
  ActionsContainer,
  SidePadding,
  TabTitle,
  TransparentCard,
} from '@mortee/routes/validationSystem/editValidtionRecord/EditValidationRecordStyles';
import {
  followUpEmailSenderStatusDetailsSpecialComponents,
  followUpEmailSenderStatusSpecialComponents,
} from '@mortee/domain/validationSystemInfoComponents';

interface EditValidationRecordAutoFollowUpsFormFields {
  stopFollowUpEmails: boolean;
}

interface Props extends FormComponentProps<EditValidationRecordAutoFollowUpsFormFields> {
  record: SupplierValidationRecord;
  className?: string;

  onSaved(updatedRecord: SupplierValidationRecord, userWantsToClose: boolean): void;

  onIsFormUnsavedChanged(isFormUnsaved: boolean | null): void;
}

const EditValidationRecordAutoFollowUpsTab: FC<Props> = observer((props) => {
  const { record, onSaved, onIsFormUnsavedChanged, className } = props;
  const { userStore } = useInfraStores();

  const { form, showFormErrors, setShowFormErrors, validateFields, isFormInvalid } = useForm(props);
  const [isSaving, setIsSaving] = useState(false);

  const validationRecordBaseData = useMemo((): SupplierValidationRecordAutoFollowUpsUpdateFields => {
    return createSupplierValidationRecordAutoFollowUpsUpdateFieldsFromRecord(record);
  }, [record]);

  const { amountOfChanges, updateRequest } = useFormChangesStatus(
    validationRecordBaseData,
    form,
    createUpdateRequestAutoFollowUpsUpdateFieldsFromFormFields,
    supplierValidationRecordAllUpdateFieldsEquators,
  );

  const doesFormHaveAnyChanges = !!amountOfChanges;

  useEffect(() => {
    onIsFormUnsavedChanged(doesFormHaveAnyChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onIsFormUnsavedChanged might change on every render
  }, [doesFormHaveAnyChanges]);

  async function onSave(userWantsToClose: boolean): Promise<void> {
    setIsSaving(true);

    try {
      const formFields = await validateFields();
      const formFieldsAllUpdateFields = createUpdateRequestAutoFollowUpsUpdateFieldsFromFormFields(formFields);

      if (!formFieldsAllUpdateFields || !updateRequest) {
        throw new Error('The form is still not valid');
      }

      const serverResponse = await validationSystemServices.updateValidationRecord(record.staticId, updateRequest);
      displayDistributionWarningMessage(serverResponse.warnings);
      const updatedRecord = transformSupplierValidationRecord(serverResponse.record, userStore.user?.id);

      messageLauncher.shoot(
        { type: 'success', duration: 4, closeable: true },
        `Validation ${serverResponse?.record.presentationId} saved successfully`,
      );
      onSaved(updatedRecord, userWantsToClose);
    } catch (e) {
      Log.exception(e);
    } finally {
      setIsSaving(false);
    }
  }

  const fieldDecorators = createFieldDecorators(record);

  return (
    <StyledNsknoxForm form={form} showErrors={showFormErrors} className={className} setShowErrors={setShowFormErrors}>
      <FullHeightVerticalShadowScroller>
        <Content>
          <TabTitle>Auto Follow Ups</TabTitle>
          <SectionTitle>Information</SectionTitle>
          <AutomailerInnerCard>
            <DataGrid>
              <DataGridRow title='Status' valueDataTest=''>
                {followUpEmailSenderStatusSpecialComponents.render(record.followUpEmailStatus) ?? 'Unavailable'}
              </DataGridRow>
              {record.followUpEmailSenderStatusDetails && (
                <DataGridRow title='Stop Reason' valueDataTest=''>
                  {followUpEmailSenderStatusDetailsSpecialComponents.render(record.followUpEmailSenderStatusDetails)}
                </DataGridRow>
              )}
              <DataGridRow title='Email Count' valueDataTest=''>
                {record.followUpEmailCount}
              </DataGridRow>
              {(record.lastFollowUpEmailTimestamp || record.lastFollowUpEmailID) && (
                <DataGridRow title='Last Email' valueDataTest=''>
                  {record.lastFollowUpEmailTimestamp && <FormattedDateTime value={record.lastFollowUpEmailTimestamp} />}
                  {record.lastFollowUpEmailID && <div>Email id = {record.lastFollowUpEmailID}</div>}
                </DataGridRow>
              )}
            </DataGrid>
          </AutomailerInnerCard>
          <FieldLine>
            <FieldLabel>Stop Emails:</FieldLabel>
            <FormItemBox
              fieldName='stopFollowUpEmails'
              fieldDecoratorOptions={fieldDecorators.stopFollowUpEmails}
              appearance='none'
            >
              <Toggle
                accessibilityLabel='Toggle stop follow up emails'
                name='toggle-edit-validation-process-stop-follow-up-emails'
              />
            </FormItemBox>
          </FieldLine>
        </Content>
      </FullHeightVerticalShadowScroller>
      <ActionsContainer>
        <StyledSaveButton
          id='btn-edit-validation-process-save-and-close'
          onClick={(): Promise<void> => onSave(true)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
          appearance='text'
        >
          SAVE AND CLOSE
        </StyledSaveButton>
        <StyledSaveButton
          id='btn-edit-validation-process-save'
          onClick={(): Promise<void> => onSave(false)}
          disabled={isFormInvalid || isSaving || !doesFormHaveAnyChanges}
          onDisabledClick={(): void => setShowFormErrors('all')}
        >
          SAVE
        </StyledSaveButton>
      </ActionsContainer>
    </StyledNsknoxForm>
  );
});

function createUpdateRequestAutoFollowUpsUpdateFieldsFromFormFields(
  formFields: Partial<EditValidationRecordAutoFollowUpsFormFields>,
): SupplierValidationRecordAutoFollowUpsUpdateFields | null {
  return formFields.stopFollowUpEmails === undefined ? null : { stopFollowUpEmails: formFields.stopFollowUpEmails };
}

function createFieldDecorators(
  record: SupplierValidationRecord,
): FormFieldDecorators<EditValidationRecordAutoFollowUpsFormFields> {
  return {
    stopFollowUpEmails: {
      initialValue: record.stopFollowUpEmails,
      rules: [
        {
          type: 'boolean',
          message: 'Invalid Value',
        },
      ],
    },
  };
}

export default Form.create<Props>()(EditValidationRecordAutoFollowUpsTab);

const StyledNsknoxForm = styled(NsknoxForm)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const FullHeightVerticalShadowScroller = styled(VerticalShadowScroller)`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Content = styled(SidePadding)`
  flex: 1;
`;

const SectionTitle = styled.h2`
  ${Regular13TransparentBlack600.css};

  &:not(:first-child) {
    margin-top: 24px;
  }
`;

const StyledSaveButton = styled(AsyncButton)`
  padding: 9px 43px;
  margin-left: 17px;
`;

const FieldLine = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FieldLabel = styled(BodyRegularStartTransparentBlack600.div)`
  // To align with the form item bottom margin (that it needs for error messages)
  margin-bottom: 25px;
`;

const AutomailerInnerCard = styled(TransparentCard)`
  padding: 8px 26px 18px;
`;
