import InputDecorator from '@app/components/inputs/InputDecorator';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import { isDefined } from '@app/utils/utils';
import styled from '@emotion/styled';
import SupplierRegistrationFormItemsBox from '@supplierRegistration/components/SupplierRegistrationFormItemsBox';
import { FormFieldDecorators } from '@app/utils/form/form';
import { SupplierRegistrationCompanyInfoFormFields } from '@supplierRegistration/domain/supplierRegistration';
import { WrappedFormUtils } from 'antd/es/form/Form';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemsBoxProps } from '@app/components/inputs/FormItemsBox';

interface Props {
  form: WrappedFormUtils<SupplierRegistrationCompanyInfoFormFields>;
  fieldDecorators: FormFieldDecorators<SupplierRegistrationCompanyInfoFormFields>;
  isIndividual: boolean;
  showFormErrors: FormItemsBoxProps['showErrors'];
  asyncProcessMaskedData?: any;
  legalIdTypesOfCountry: Record<string, string>;
  isOtherLEIType: boolean;
  onLeiTypeChange: (leiTypeKey: string) => void;
  areChildrenRTL?: boolean;
}

const LEITypeFormItem: FC<Props> = ({
  form,
  fieldDecorators,
  isIndividual,
  showFormErrors,
  asyncProcessMaskedData,
  legalIdTypesOfCountry,
  isOtherLEIType,
  onLeiTypeChange,
  areChildrenRTL,
}) => {
  const { t } = useTranslation();

  if (isIndividual && !asyncProcessMaskedData?.payee.legalIdentifier) {
    return null;
  }

  const otherLEIType = isOtherLEIType || isDefined(asyncProcessMaskedData?.payee.legalIdentifier);

  return (
    <SupplierRegistrationFormItemsBox
      form={form}
      fieldNames={otherLEIType ? ['leiType', 'leiTypeOtherValue'] : ['leiType']}
      showErrors={showFormErrors}
      hasPrefilledData={isDefined(asyncProcessMaskedData?.payee.legalIdentifier)}
    >
      <MultiInputsContainer areChildrenRTL={areChildrenRTL}>
        <FirstInputContainer>
          <InputDecorator form={form} fieldName='leiType' fieldDecoratorOptions={fieldDecorators.leiType}>
            <NakedDropdown
              accessibilityLabel={t<string>('general.accessibility.legalId')}
              name='drp-supp-reg-lei-type'
              dataTestId='drp-supp-reg-lei-type'
              placeholder={otherLEIType ? null : t<string>('supplierValidation.supplierRegister.legalIdType')}
              onChange={onLeiTypeChange}
              popupWidth={otherLEIType ? 'auto' : undefined}
            >
              {Object.entries(legalIdTypesOfCountry).map(([key, legalIdTypeName]) => {
                return (
                  <LEITypeDropdownItem
                    key={key}
                    value={legalIdTypeName}
                    textWhenSelected={`supplierValidation.legalIdType.${key}`}
                  >
                    {t(`supplierValidation.legalIdType.${key}`)}
                  </LEITypeDropdownItem>
                );
              })}
            </NakedDropdown>
          </InputDecorator>
        </FirstInputContainer>
        {otherLEIType && (
          <>
            <InputBuffer />
            <SecondInputContainer>
              <InputDecorator fieldName='leiTypeOtherValue' fieldDecoratorOptions={fieldDecorators.leiTypeOtherValue} form={form}>
                <NakedFormInput
                  name='btn-supp-reg-lei-type-other'
                  dataTestId='btn-supp-reg-lei-type-other'
                  type='text'
                  placeholder={t<string>('supplierValidation.supplierRegister.legalIdType')}
                  disableSuggestion
                />
              </InputDecorator>
            </SecondInputContainer>
          </>
        )}
      </MultiInputsContainer>
    </SupplierRegistrationFormItemsBox>
  );
};

const MultiInputsContainer = styled.div<{ areChildrenRTL?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    ${(p): string => (isDefined(p.areChildrenRTL) ? `direction: ${p.areChildrenRTL ? 'rtl' : 'ltr'};` : '')}
  }
`;

const FirstInputContainer = styled.div`
  flex: 3;
`;

const SecondInputContainer = styled.div`
  flex: 7;
`;

const InputBuffer = styled.div`
  width: 1px;
  height: 32px;
  color: var(--transparent-black-200);
`;

const LEITypeDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

export default LEITypeFormItem;
