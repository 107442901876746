import styled from '@emotion/styled';

export const PageContainer = styled.div`
  max-width: 1000px;
  align-self: center;
`;

export const BlueText = styled.span`
  font-weight: bold;
  color: var(--accent-blue-600);
`;

export const AboveCardSpace = styled.div`
  height: 90px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
`;
