import Button from '@app/components/Button';
import Card from '@app/components/card/Card';
import CheckboxCardsList from '@app/components/CheckboxCardsList';
import InputBox from '@app/components/inputs/inputBox/InputBox';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import PermissionListItem from '@app/components/PermissionListItem';
import SVG from '@app/components/SVG';
import { H5StartTransparentBlack900 } from '@app/components/Text';
import { OrganizationalUser, transformOrganizationalUser } from '@app/domain/userManagement/organizationUsers';
import { UserRoleType } from '@app/domain/userManagement/roleTypes';
import { useLoadableSearchFilter } from '@app/hooks/useSearchFilter';
import ClearIcon from '@app/images/ic_close.svg';
import AppThemeProvider from '@app/routes/app/ThemeProvider';
import { addRoleToUser, removeRoleFromUser } from '@app/services/userManagement/organizationUsersService';
import { distinctValues, wrapValueAsArray } from '@app/utils/arrayUtils';
import { compare } from '@app/utils/comparatorUtils';
import Loadable from '@app/utils/Loadable';
import { isTruthy } from '@app/utils/utils';
import styled from '@emotion/styled';
import React, { FC, ReactNode, useState } from 'react';

interface Props {
  organizationalUser: OrganizationalUser;
  organizationId: string;
  userRoleTypes: Loadable<UserRoleType[]>;
  canEdit: boolean;
  onUserUpdated(organizationalUser: OrganizationalUser);
  onDone: () => void;
}

interface UserRole {
  id: string;
  displayName: string | null;
  isAssignedAsAGlobalRole: boolean;
  isNotInOrgRoleList: boolean;
}

const UserPermissionsModal: FC<Props> = (props) => {
  const { organizationalUser, userRoleTypes, organizationId, canEdit, onUserUpdated, onDone } = props;
  const { tenantUserId, globalAppRoleIds, allActiveAppRoleIds } = organizationalUser;

  const [changingRoleId, setChangingRoleId] = useState<string | undefined>();
  const [activeIds, setActiveIds] = useState<string[]>(allActiveAppRoleIds);

  const allRoleTypesAssignedOrNot: Loadable<UserRole[]> = userRoleTypes.map((loadedUserRoleTypes): UserRole[] => {
    const organizationRelevantRolesById = Object.fromEntries(loadedUserRoleTypes.map((roleType) => [roleType.id, roleType]));
    const allRoleTypeIds = distinctValues([...Object.keys(organizationRelevantRolesById), ...activeIds]);

    return allRoleTypeIds
      .map((roleTypeId) => {
        const matchingRoleType = organizationRelevantRolesById[roleTypeId];
        return {
          id: roleTypeId,
          displayName: matchingRoleType?.displayName ?? null,
          isAssignedAsAGlobalRole: globalAppRoleIds.includes(roleTypeId),
          isNotInOrgRoleList: !matchingRoleType,
        };
      })
      .sort(compare.byStringField((role) => role.id));
  });

  const [filteredData, searchTerm, setSearchTerm] = useLoadableSearchFilter(allRoleTypesAssignedOrNot, {
    partialMatches(role) {
      return [role.id, role.displayName];
    },
  });

  async function onAddRoleRequest(id: string): Promise<void> {
    setChangingRoleId(id);

    try {
      const updatedOrganizationalUser = transformOrganizationalUser(
        await addRoleToUser(organizationId, tenantUserId, { webAppRoleId: id }),
      );
      onUserUpdated(updatedOrganizationalUser);
      setActiveIds(updatedOrganizationalUser.allActiveAppRoleIds);
    } finally {
      setChangingRoleId(undefined);
    }
  }

  async function onRemoveRoleRequest(id: string): Promise<void> {
    setChangingRoleId(id);

    try {
      const updatedOrganizationalUser = transformOrganizationalUser(
        await removeRoleFromUser(organizationId, tenantUserId, { webAppRoleId: id }),
      );
      onUserUpdated(updatedOrganizationalUser);
      setActiveIds(updatedOrganizationalUser.allActiveAppRoleIds);
    } finally {
      setChangingRoleId(undefined);
    }
  }

  const isGlobalRole = (roleId: string): boolean => {
    return globalAppRoleIds?.includes(roleId);
  };

  return (
    <AppThemeProvider>
      <MainCard id='add-users-to-org-modal'>
        <TitleLine>
          <Title>
            <H5StartTransparentBlack900.div>Permissions - {organizationalUser.name}</H5StartTransparentBlack900.div>
          </Title>
          <CloseButton id='btn-user-permissions-modal-close' appearance='text' colorScheme='primary' onClick={onDone}>
            <SVG accessibilityLabel='close' image={ClearIcon} height={30} />
          </CloseButton>
        </TitleLine>
        <InputBox appearance='line' colorScheme='secondary'>
          <NakedFormInput
            name='user-manage-roles-search'
            dataTestId='user-manage-roles-search'
            type='text'
            colorScheme='primary'
            placeholderStyle='onlyWhenEmpty'
            heightType='thick'
            clearable
            placeholder='🔍 Search for Id / Name'
            value={searchTerm}
            onChange={(newValue): void => setSearchTerm(newValue ?? '')}
            autoFocus
          />
        </InputBox>
        <TableDiv>
          <CheckboxCardsList
            id='user-manage-roles-table'
            contentColumnTitle='Roles'
            readonly={!canEdit}
            activeIds={activeIds}
            changingIds={wrapValueAsArray(changingRoleId)}
            allItems={filteredData}
            disableItem={isGlobalRole}
            itemRenderer={(roleType: UserRole): ReactNode => {
              let tooltip: string | null = null;

              if (roleType.isAssignedAsAGlobalRole) {
                tooltip = 'This role is a global role and therefore cannot be unassigned';
              } else if (roleType.isNotInOrgRoleList) {
                tooltip = 'This role is assigned although the organization does not have the proper service for it';
              }

              return <PermissionListItem permissionsId={roleType.id} displayName={roleType.displayName} tooltip={tooltip} />;
            }}
            itemKeywordsGetter={(roleType: UserRole): string[] => {
              return [roleType.id, roleType.displayName].filter(isTruthy);
            }}
            onAdd={onAddRoleRequest}
            onRemove={onRemoveRoleRequest}
          />
        </TableDiv>
      </MainCard>
    </AppThemeProvider>
  );
};

export default UserPermissionsModal;

const MainCard = styled(Card)`
  width: min(900px, 90vw);
  min-height: 600px;
  max-height: 70vh;

  padding: 26px 32px;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;

  margin-bottom: 4px;
`;

const Title = styled.div`
  & > *:not(:first-child) {
    margin-top: 8px;
  }
`;

const CloseButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  transition: 0.1s all ease-in-out;
  padding: 4px 6px 5px;
  margin-bottom: 3px;
`;

const TableDiv = styled.div`
  overflow-y: scroll !important;
`;
