import { FormFieldDecorators } from '@app/utils/form/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SupplierValidationPayeeVerificationRecord } from '@mortee/domain/validationSystem';
import { VALIDATION_PATTERNS } from '@app/domain/uiConsts';
import { trim } from '@app/utils/stringUtils';
import {
  allOrNoneFieldsValidator,
  dunsValidator,
  notFutureTimestampValidator,
  onChangeValidateOtherFields,
  regularLegalIdValidator,
  taxIdValidator,
} from '@app/utils/validators';
import { cleanDunsLegalId, cleanLegalId } from '@app/utils/legalIdentifierUtils';
import { compare } from '@app/utils/comparatorUtils';
import { SanctionsScreeningResultStatus } from '@app/domain/validatedPayee';
import { VerificationLegalIdentifierRequest } from './validationSystemVerificationLegalIdentifiers';
import { removeStrongIds } from '@app/domain/legalEntityIdentifier';
import { SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH } from './validationSystemFields';

export interface CreateVerificationDataRecordPayeeFormFields {
  name: string | undefined;
  additionalName: string | undefined;
  address: string | undefined;
  additionalAddress: string | undefined;
  countryCode: string | undefined;
  duns: VerificationLegalIdentifierRequest | undefined;
  taxId1: VerificationLegalIdentifierRequest | undefined;
  taxId2: VerificationLegalIdentifierRequest | undefined;
  legalId1: VerificationLegalIdentifierRequest | undefined;
  legalId2: VerificationLegalIdentifierRequest | undefined;
  legalId3: VerificationLegalIdentifierRequest | undefined;
  sanctionsScreeningTimestamp: number | undefined;
  sanctionsStatus: SanctionsScreeningResultStatus | undefined;
  alreadyExistingValidatedPayeeKnoxId: string | undefined;
  isPrivate: boolean;
  isUpdateRequired: boolean;
  effectiveTimestamp: number | undefined;
  useExistingPayee: boolean;
}

export function createFieldDecorators(
  verificationRecordPayeeData: SupplierValidationPayeeVerificationRecord | null,
  form: WrappedFormUtils<CreateVerificationDataRecordPayeeFormFields>,
): FormFieldDecorators<CreateVerificationDataRecordPayeeFormFields> {
  const allLegalIdentifiers = verificationRecordPayeeData?.legalEntityIdentifiers || [];
  const strongLegalIdentifiers = verificationRecordPayeeData?.strongLegalIdentifies || {};
  const weakLegalIdentifies = removeStrongIds(allLegalIdentifiers, Object.values(strongLegalIdentifiers).flat());

  const [payeeTaxId1, payeeTaxId2] = [...(strongLegalIdentifiers.TaxId || [])].sort(
    compare.byField((lei) => lei.isPrimary, compare.booleans()),
  );

  const payeeDunsId = strongLegalIdentifiers.DUNS?.[0];

  return {
    name: {
      initialValue: verificationRecordPayeeData?.name ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.name,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.name} characters`,
        },
      ],
    },
    additionalName: {
      initialValue: verificationRecordPayeeData?.additionalName ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalName,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalName} characters`,
        },
      ],
    },
    address: {
      initialValue: verificationRecordPayeeData?.address ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.address,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.address} characters`,
        },
      ],
    },
    additionalAddress: {
      initialValue: verificationRecordPayeeData?.additionalAddress ?? undefined,
      rules: [
        {
          max: SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalAddress,
          transform: trim,
          message: `max ${SUPPLIER_VALIDATION_SUPPLIER_VERIFICATION_DATA_FIELD_MAX_LENGTH.additionalAddress} characters`,
        },
      ],
    },
    countryCode: {
      initialValue: verificationRecordPayeeData?.countryCode ?? undefined,
    },
    sanctionsScreeningTimestamp: {
      initialValue: verificationRecordPayeeData?.sanctionsScreening?.screeningTimestamp,
      rules: [
        {
          type: 'number',
        },
        {
          validator: allOrNoneFieldsValidator(form, ['sanctionsStatus'], 'Missing Sanctions Date'),
        },
        {
          validator: notFutureTimestampValidator(),
        },
      ],
    },
    sanctionsStatus: {
      initialValue: verificationRecordPayeeData?.sanctionsScreening?.result,
      rules: [
        {
          validator: allOrNoneFieldsValidator(form, ['sanctionsScreeningTimestamp'], 'Missing Sanctions Status'),
        },
      ],
    },
    alreadyExistingValidatedPayeeKnoxId: {
      initialValue: verificationRecordPayeeData?.alreadyExistingValidatedPayee?.knoxId ?? undefined,
      rules: [
        {
          required: form.getFieldValue('useExistingPayee'),
          message: 'Please enter payee Knox ID',
        },
        {
          pattern: VALIDATION_PATTERNS.fullKnoxId,
          transform: trim,
          message: 'Invalid Knox Id',
        },
      ],
    },
    useExistingPayee: {
      initialValue: !!verificationRecordPayeeData?.alreadyExistingValidatedPayee,
      rules: [
        {
          validator: onChangeValidateOtherFields(form, ['alreadyExistingValidatedPayeeKnoxId']),
        },
      ],
    },
    isPrivate: {
      valuePropName: 'checked',
      initialValue: verificationRecordPayeeData?.isPrivate ?? false,
    },
    isUpdateRequired: {
      valuePropName: 'checked',
      initialValue: verificationRecordPayeeData?.isUpdateRequired ?? false,
    },
    effectiveTimestamp: {
      initialValue: verificationRecordPayeeData?.effectiveTimestamp ?? undefined,
      rules: [
        {
          type: 'number',
        },
        {
          validator: notFutureTimestampValidator(),
        },
      ],
    },
    taxId1: {
      initialValue: payeeTaxId1,
      rules: [
        {
          transform: cleanLegalId,
          validator: taxIdValidator,
        },
      ],
    },
    taxId2: {
      initialValue: payeeTaxId2,
      rules: [
        {
          transform: cleanLegalId,
          validator: taxIdValidator,
        },
      ],
    },
    legalId1: {
      initialValue: weakLegalIdentifies?.[0],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    legalId2: {
      initialValue: weakLegalIdentifies?.[1],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    legalId3: {
      initialValue: weakLegalIdentifies?.[2],
      rules: [
        {
          transform: cleanLegalId,
          validator: regularLegalIdValidator,
        },
      ],
    },
    duns: {
      initialValue: payeeDunsId,
      rules: [
        {
          transform: cleanDunsLegalId,
          validator: dunsValidator,
        },
      ],
    },
  };
}
