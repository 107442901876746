import {
  SupplierRegistrationProcessInstructionType,
  SupplierRegistrationRadioButtonOptions,
} from '@app/domain/commonSupplierRegistration';
import { DatePickerValue } from '@app/components/inputs/DatePicker';
import { SupplierRegistrationThemeServerResponse } from '@supplierRegistration/domain/supplierRegistrationTheme';
import { isDefined } from '@app/utils/utils';
import { CountryCode } from '@app/domain/countries';

export const WEB_REFERRER_HEADER = 'X-Web-Referrer';
export const API_VERSION_HEADER = 'Api-Version';
export const CAPTCHA_TOKEN_HEADER = 'X-Recaptcha-Token';

export interface SupplierRegistrationProcessRequest {
  isIndividual: boolean;
  hasAgreed: boolean;
  asyncFullName: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string;
  phoneNumber: string;
  companyName: string;
  additionalCompanyName: string | null;
  address: SupplierRegistrationProcessAddress;
  legalId: string | undefined;
  legalIdType: string | undefined;
  countryCode: string;
  companyWebsite: string | undefined;
  referringCustomer: string | undefined;
  accountBankCountryCode: string;
  currency: string;
  accountDetails: SupplierRegistrationProcessAccountDetails;
  abaRouting: string | undefined;
  furtherCredit: string | undefined;
  organizationReferenceId: string | undefined;
  canUseAch: boolean | undefined;
  dateOfBirth: number | undefined;
  registrationId: string | undefined;
  accountType: string | undefined;
  hasAgreedIsrael: boolean | undefined;
  bankName: string | undefined;
}

export const canUseWiringTypesAnswerToBoolean = (newChoice: string | undefined): boolean | undefined => {
  if (newChoice === SupplierRegistrationRadioButtonOptions.yes) {
    return true;
  } else if (newChoice === SupplierRegistrationRadioButtonOptions.no) {
    return false;
  }
};

interface SupplierRegistrationProcessAddress {
  addressDetails: SupplierRegistrationProcessAddressDetails;
}

interface SupplierRegistrationProcessAddressDetails {
  countryCode: string;
  address: string;
  city: string;
  state: string | null;
  zipCode: string;
}

interface SupplierRegistrationProcessAccountDetails {
  bankCode: string | undefined;
  branchCode: string | undefined;
  accountNumber: string;
  swiftCode: string | undefined;
}

export interface ReducedSupplierRegistrationProcessServerResponse {
  registrationNumber: string | null;
  displayInstructionType: SupplierRegistrationProcessInstructionType;
}

export interface ReducedSupplierRegistrationProcess {
  registrationNumber: string | null;
  displayInstructionType: SupplierRegistrationProcessInstructionType;
}

export enum ValidationProcessSteps {
  welcome,
  companyInformation,
  bankAccountDetails,
  useOpenBanking,
}

export enum InstructionNextActionType {
  paymentKnox = 'PaymentKnox',
  payee = 'Payee',
}

export interface SupplierRegistrationCompanyInfoFormFields {
  registrationType: RegistrationType;
  companyName: string;
  additionalCompanyName?: string;
  companyAddressCountry: string;
  companyAddress: string;
  companyAddressCity: string;
  companyAddressState: string | undefined;
  companyAddressZipCode: string;
  asyncFullName: string;
  firstName: string;
  lastName: string;
  leiType: string;
  leiTypeOtherValue?: string;
  leiValue: string;
  phoneCountryCode: string;
  phoneNumber: string;
  email: string;
  country: CountryCode;
  website?: string;
  dateOfBirth?: DatePickerValue;
  referrer: string | undefined;
}

export interface SupplierRegistrationBankAccountFormFields {
  hasAgreed: boolean;
  swiftCode?: string;
  bankCode?: string;
  branchCode?: string;
  accountNumber?: string;
  ibanForAsyncInitiateValidation?: string;
  accountBankCountryCode: CountryCode;
  abaRouting?: string;
  currency: string;
  furtherCredit?: string;
  canUseAch?: string;
  accountType?: string;
  hasAgreedIsrael?: boolean;
  bankName?: string;
}

export enum SupplierRegistrationProgressStep {
  Welcome,
  Registration,
  BankAccountDetails,
  OneTimeTransfer,
}

type SupplierRegistrationSubdomainReference = {
  referenceId: null;
  subdomain: string;
};

type SupplierRegistrationRefIdReference = {
  referenceId: string;
  subdomain: null;
};

export type SupplierRegistrationCustomerReferences = SupplierRegistrationSubdomainReference | SupplierRegistrationRefIdReference;

export interface SupplierRegistrationConfigurationServerResponse {
  ref: string;
  clientName: string;
  brand: SupplierRegistrationThemeServerResponse | null;
  useFinicityOpenBanking: boolean;
}

export interface SupplierRegistrationConfiguration {
  ref: string;
  clientName: string;
  brand?: {
    reducedText: boolean;
  };
  useFinicityOpenBanking: boolean;
}

export interface SupplierValidationRegistrationProps {
  configuration: SupplierRegistrationConfiguration | null;
  nextStep: () => void;
  formValues: SupplierRegistrationFormValuesState;
  isIpsMode: boolean;
  onFormSubmit?;
  saveValues?: (SupplierRegistrationCompanyInfoFormFields) => void;
  isIndividual?: boolean;
}

export enum RegistrationType {
  company = 'company',
  individual = 'individual',
}

export function registrationTypeFromBoolean(isIndividual: boolean | null | undefined): RegistrationType | undefined {
  if (!isDefined(isIndividual)) {
    return undefined;
  }

  if (isIndividual) {
    return RegistrationType.individual;
  }

  return RegistrationType.company;
}

export const REGISTRATION_TYPES_VALUES: RegistrationType[] = Object.values(RegistrationType);

export interface SupplierRegistrationFormValuesState {
  supplierValues: SupplierRegistrationCompanyInfoFormFields | null;
  accountValues: SupplierRegistrationBankAccountFormFields | null;
}
