import styled from '@emotion/styled';
import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

const StyledFormRadioGroup = styled(FormRadioGroup)`
  margin-bottom: 22px;
  .MuiFormControlLabel-root {
    margin-inline-end: 16px !important;
    margin-inline-start: -11px !important;
  }
`;

const StyledLocaleAwareDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const CountryDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const AccountTypeDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

const StyledRTLFormRadioGroup = styled(FormRadioGroup)<{ isRTL?: boolean }>`
  ${(p): string => (p.isRTL ? 'margin-right: -16px' : '')}
`;

const HiddenableDiv = styled.div<{ hidden: boolean }>`
  ${(p): string => (p.hidden ? 'display: none;' : '')}
`;

export const StyledComponents = {
  InputGrid,
  StyledFormRadioGroup,
  StyledLocaleAwareDropdownItem,
  CountryDropdownItem,
  AccountTypeDropdownItem,
  StyledRTLFormRadioGroup,
  HiddenableDiv,
};
