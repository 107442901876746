import ErrorModal from '@app/components/ErrorModal';
import LogoCarousel from '@app/components/logoCarousel/LogoCarousel';
import { showContentOnlyModal } from '@app/components/Modal';
import PageWithHeader from '@app/components/PageWithHeader';
import TransitionLoader from '@app/components/TransitionLoader';
import config from '@app/config';
import {
  SupplierRegistrationProcessFormType,
  SupplierRegistrationProcessInstructionType,
  SupplierRegistrationProcessInstructionTypesSubsets,
} from '@app/domain/commonSupplierRegistration';
import useAppStores from '@app/hooks/useAppStores';
import useInfraStores from '@app/hooks/useInfraStores';
import useIsMounted from '@app/hooks/useIsMounted';
import useTheme from '@app/hooks/useTheme';
import Log from '@app/libs/logger';
import { extractLogErrorIdFromError } from '@app/libs/request';
import browserHistory from '@app/utils/browserHistory';
import BackButton from '@supplierRegistration/components/BackButton';
import { RegistrationError } from '@supplierRegistration/components/RegistrationError';
import {
  InstructionNextActionType,
  ReducedSupplierRegistrationProcess,
  SupplierRegistrationBankAccountFormFields,
  SupplierRegistrationCompanyInfoFormFields,
  SupplierRegistrationFormValuesState,
  SupplierRegistrationProgressStep,
  ValidationProcessSteps,
} from '@supplierRegistration/domain/supplierRegistration';
import { AsyncInitiateValidationMaskedData } from '@supplierRegistration/domain/supplierRegistrationAsyncInitiateValidationProcess';
import SupplierRegistrationBankAccountDetails from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationBankAccountDetails';
import SupplierRegistrationCompanyInformation from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationCompanyInformation';
import SupplierRegistrationOpenBankingSelector from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/SupplierRegistrationOpenBankingSelector';
import WelcomeSupplier from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/WelcomeSupplier';
import {
  isSuitableForOpenBanking,
  isSuitableForOpenBankingAsync,
  validateFinicityAccountAsync,
} from '@supplierRegistration/services/openBankingService';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import {
  transformRegularValidationProcessRequest,
  transformFormFieldsToSupplierRegistrationAsyncRequest,
} from '@supplierRegistration/utils/transformers';
import { observer } from 'mobx-react';
import React, { FC, ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AboveCardSpace, BlueText, PageContainer } from './SupplierRegistrationFormPage.styles';

const SupplierRegistrationFormPage: FC = observer(() => {
  const { navigationStore, languageStore } = useInfraStores<SupplierRegistrationMode>();
  const { supplierRegistrationFormStore, supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();
  const { t } = useTranslation();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const isIpsMode = navigationStore.isIpsPage;
  const { brand } = useTheme<SupplierRegistrationMode>();
  const isMountedRef = useIsMounted();

  const customerName = supplierRegistrationCustomerDataStore.customerName;
  const additionalLogos = brand?.additionalLogos;
  const configuration = supplierRegistrationCustomerDataStore.currentConfiguration.result ?? null;
  const isAsyncProcessErrored = !!supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedDataLoadError;

  function onSupplierDetailsFormSubmit(supplierValues: SupplierRegistrationCompanyInfoFormFields): void {
    saveSupplierDetailsValues(supplierValues);
  }

  function saveLoadedValues(values: SupplierRegistrationFormValuesState): void {
    supplierRegistrationFormStore.setSupplierRegistrationValues({
      supplierValues: values.supplierValues,
      accountValues: values.accountValues,
    });
    nextStep();
  }

  function saveSupplierDetailsValues(supplierValues: SupplierRegistrationCompanyInfoFormFields): void {
    supplierRegistrationFormStore.setSupplierRegistrationValues({
      supplierValues,
      accountValues: supplierRegistrationFormStore.currentSupplierRegistrationValues.accountValues,
    });
  }

  function saveAccountDetailsValues(newAccountValues: SupplierRegistrationBankAccountFormFields): void {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues) {
      supplierRegistrationFormStore.setSupplierRegistrationValues({
        supplierValues: supplierRegistrationFormStore.currentSupplierRegistrationValues.supplierValues,
        accountValues: { ...newAccountValues, hasAgreed: newAccountValues.hasAgreed ?? accountValues?.hasAgreed },
      });
    }
  }

  async function callAsyncPreRegistration(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    ref: string,
    asyncMaskedData: AsyncInitiateValidationMaskedData,
    captchaToken: string | null | undefined,
  ): Promise<void> {
    const payload = transformFormFieldsToSupplierRegistrationAsyncRequest(
      companyInfoValues,
      bankAccountValues,
      ref,
      asyncMaskedData,
      supplierRegistrationFormStore.isIndividual,
    );

    await supplierRegistrationFormStore.preRegistrationRequestFromAsyncInitiateValidation(
      payload,
      asyncMaskedData.id,
      captchaToken,
    );
  }

  async function callPreRegistrationRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ): Promise<void> {
    try {
      setIsRequestLoading(true);

      const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

      let captchaToken: string | null | undefined = undefined;
      if (shouldUseCaptcha) {
        try {
          captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
        } catch (e) {
          Log.event('Cannot load captcha token, sending pre registration request without captcha header', e);
        }
      }

      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        await callAsyncPreRegistration(
          companyInfoValues,
          bankAccountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
          captchaToken,
        );
      } else {
        const payload = transformRegularValidationProcessRequest(
          companyInfoValues,
          bankAccountValues,
          isIpsMode,
          configuration,
          supplierRegistrationFormStore.isIndividual,
        );
        await supplierRegistrationFormStore.preRegistrationRequest(payload, captchaToken);
      }

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }
    } catch (e) {
      Log.event('Error while calling pre-registration api', e);
    } finally {
      if (isMountedRef.current) {
        setIsRequestLoading(false);
      }
    }
  }

  async function onAccountDetailsFormSubmit(accountValuesToSave: SupplierRegistrationBankAccountFormFields): Promise<void> {
    saveAccountDetailsValues(accountValuesToSave);
    await handleAccountDetailsSubmission(accountValuesToSave);
  }

  async function handleAccountDetailsSubmission(accountValuesToSave: SupplierRegistrationBankAccountFormFields): Promise<void> {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues && accountValues) {
      await callPreRegistrationRequest(supplierValues, accountValues);
    }

    if (config.featureFlags?.finicityOpenBanking && (await checkIsSuitableForOpenBanking())) {
      supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.useOpenBanking);

      return;
    }

    await storeValidationProcess();
  }

  async function storeValidationProcess(): Promise<void> {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (supplierValues && accountValues) {
      await storeValidationProcessRequest(supplierValues, accountValues);
    }
  }

  function nextStep(): void {
    switch (supplierRegistrationFormStore.currentValidationProcessStep) {
      case ValidationProcessSteps.welcome:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.companyInformation);
        break;
      case ValidationProcessSteps.companyInformation:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.bankAccountDetails);
        break;
    }
  }

  function previousStep(): void {
    switch (supplierRegistrationFormStore.currentValidationProcessStep) {
      case ValidationProcessSteps.companyInformation:
        if (!supplierRegistrationFormStore.skipWelcome) {
          supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.welcome);
        }
        break;
      case ValidationProcessSteps.bankAccountDetails:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.companyInformation);
        break;
      case ValidationProcessSteps.useOpenBanking:
        supplierRegistrationFormStore.setValidationProcessStep(ValidationProcessSteps.bankAccountDetails);
        break;
    }
  }

  function renderBackButton(): ReactNode {
    const currentStep = supplierRegistrationFormStore.currentValidationProcessStep;

    if (
      currentStep === ValidationProcessSteps.bankAccountDetails ||
      (!supplierRegistrationFormStore.skipWelcome && currentStep === ValidationProcessSteps.companyInformation) ||
      currentStep === ValidationProcessSteps.useOpenBanking
    ) {
      return <BackButton onClick={previousStep}>{t('supplierValidation.supplierRegister.back')}</BackButton>;
    }
  }

  function getValidationStepComponent(step: ValidationProcessSteps): ReactNode {
    if (isAsyncProcessErrored) {
      return <RegistrationError customerName={customerName} />;
    }

    const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

    switch (step) {
      case ValidationProcessSteps.welcome:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.Welcome);
        return (
          <WelcomeSupplier
            configuration={configuration}
            nextStep={nextStep}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveLoadedValues}
            isIpsMode={isIpsMode}
          />
        );
      case ValidationProcessSteps.companyInformation:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.Registration);
        return (
          <SupplierRegistrationCompanyInformation
            configuration={configuration}
            nextStep={nextStep}
            onFormSubmit={onSupplierDetailsFormSubmit}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveSupplierDetailsValues}
            isIpsMode={isIpsMode}
          />
        );
      case ValidationProcessSteps.bankAccountDetails:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.BankAccountDetails);
        return (
          <SupplierRegistrationBankAccountDetails
            configuration={configuration}
            nextStep={nextStep}
            onFormSubmit={onAccountDetailsFormSubmit}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            saveValues={saveAccountDetailsValues}
            isIpsMode={isIpsMode}
            isIndividual={supplierRegistrationFormStore.isIndividual}
          />
        );
      case ValidationProcessSteps.useOpenBanking:
        supplierRegistrationFormStore.setProgressStep(SupplierRegistrationProgressStep.BankAccountDetails);
        return (
          <SupplierRegistrationOpenBankingSelector
            setLoading={setIsRequestLoading}
            onRegularSelect={storeValidationProcess}
            configuration={configuration}
            nextStep={nextStep}
            formValues={supplierRegistrationFormStore.currentSupplierRegistrationValues}
            isIpsMode={isIpsMode}
            validateFinicityAccountAsync={
              currentAsyncProcessMaskedData?.isResolved() && configuration?.ref ? validateFinicityAccountAsyncHandler : undefined
            }
          />
        );
    }
  }

  async function validateFinicityAccountAsyncHandler(customerId: string): Promise<void> {
    const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

    if (!supplierValues || !accountValues) {
      throw new Error('Missing supplier or account values');
    }

    const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

    let captchaToken: string | null | undefined = undefined;
    if (shouldUseCaptcha) {
      try {
        captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
      } catch (e) {
        Log.event('Cannot load captcha token, sending request without captcha header', e);
      }
    }

    const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

    if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
      const asyncInitiateValidationProcessRequest = transformFormFieldsToSupplierRegistrationAsyncRequest(
        supplierValues,
        accountValues,
        configuration?.ref,
        currentAsyncProcessMaskedData.result,
        supplierRegistrationFormStore.isIndividual,
      );

      await validateFinicityAccountAsync(
        asyncInitiateValidationProcessRequest,
        currentAsyncProcessMaskedData.result.id,
        customerId,
        captchaToken,
      );

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }
    } else {
      throw new Error('Missing async properties for async process');
    }
  }

  function getNextAction(displayInstructionType: SupplierRegistrationProcessInstructionType): InstructionNextActionType {
    return SupplierRegistrationProcessInstructionTypesSubsets.bankValidation.includes(displayInstructionType)
      ? InstructionNextActionType.paymentKnox
      : InstructionNextActionType.payee;
  }

  const checkIsSuitableForOpenBanking = async (): Promise<boolean> => {
    let isSuitable = false;
    try {
      setIsRequestLoading(true);

      const { supplierValues, accountValues } = supplierRegistrationFormStore.currentSupplierRegistrationValues;

      if (!supplierValues || !accountValues) {
        return false;
      }

      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        const asyncInitiateValidationProcessRequest = transformFormFieldsToSupplierRegistrationAsyncRequest(
          supplierValues,
          accountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
          supplierRegistrationFormStore.isIndividual,
        );

        isSuitable = await isSuitableForOpenBankingAsync(
          asyncInitiateValidationProcessRequest,
          currentAsyncProcessMaskedData.result.id,
        );
      } else {
        const supplierRegistrationProcessRequest = transformRegularValidationProcessRequest(
          supplierValues,
          accountValues,
          isIpsMode,
          configuration,
          supplierRegistrationFormStore.isIndividual,
        );

        const validationType = isIpsMode
          ? SupplierRegistrationProcessFormType.incomingPaymentSecurity
          : SupplierRegistrationProcessFormType.regularSupplierValidation;

        isSuitable = await isSuitableForOpenBanking(supplierRegistrationProcessRequest, validationType);
      }
    } catch (e) {
      Log.event('Error while checking open banking suitable', e);
      isSuitable = false;
    } finally {
      setIsRequestLoading(false);
    }

    return isSuitable;
  };

  async function storeValidationProcessRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
  ): Promise<void> {
    setIsRequestLoading(true);

    try {
      const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

      let captchaToken: string | null | undefined = undefined;
      if (shouldUseCaptcha) {
        try {
          captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
        } catch (e) {
          Log.event('Cannot load captcha token, sending request without captcha header', e);
        }
      }

      const companyEmail = companyInfoValues.email.trim();

      let processResponse: ReducedSupplierRegistrationProcess;
      const currentAsyncProcessMaskedData = supplierRegistrationCustomerDataStore.currentAsyncProcessMaskedData;

      if (currentAsyncProcessMaskedData?.isResolved() && configuration?.ref) {
        processResponse = await storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
          companyInfoValues,
          bankAccountValues,
          configuration?.ref,
          currentAsyncProcessMaskedData.result,
          captchaToken,
        );

        const iFrameMessage = {
          type: 'nsknoxSupplierRegistrationFinishedSuccessfully',
          data: {
            registrationNumber: processResponse.registrationNumber,
            instruction: {
              nextAction: getNextAction(processResponse.displayInstructionType),
            },
          },
        };

        window.parent.postMessage(JSON.stringify(iFrameMessage), '*');
      } else {
        processResponse = await storeRegularValidationProcessRequest(companyInfoValues, bankAccountValues, captchaToken);
      }

      if (shouldUseCaptcha) {
        supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
      }

      supplierRegistrationFormStore.cleanSupplierRegistrationStoreValues();

      browserHistory.push(
        navigationStore.generateAccountValidationAfterForm(
          false,
          companyEmail,
          null,
          configuration?.ref ?? null,
          processResponse.displayInstructionType,
          supplierRegistrationFormStore.skipWelcome,
        ),
      );
    } catch (e) {
      const errorLogId = extractLogErrorIdFromError(e);

      const bodyContent = (
        <div>
          <Trans
            i18nKey='supplierValidation.supplierRegister.errors.anErrorOccurredDuringTheRegistration'
            values={{ errorLogId }}
          >
            An error occurred during the registration. Please contact us at <BlueText>accountvalidation@nsknox.net</BlueText> for
            assistance.
          </Trans>
          {errorLogId && ` ${t('supplierValidation.supplierRegister.errors.referToErrorCode')} ${errorLogId}`}
        </div>
      );

      showContentOnlyModal((onDone) => (
        <ErrorModal
          headerContent={t('general.somethingWentWrong')}
          bodyContent={bodyContent}
          okButtonText={t('general.ok')}
          onDone={onDone}
        />
      ));
    } finally {
      if (isMountedRef.current) {
        setIsRequestLoading(false);
      }
    }
  }

  async function storeRegularValidationProcessRequest(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> {
    const payload = transformRegularValidationProcessRequest(
      companyInfoValues,
      bankAccountValues,
      isIpsMode,
      configuration,
      supplierRegistrationFormStore.isIndividual,
    );

    return await supplierRegistrationFormStore.storeValidationProcessRequest(
      payload,
      isIpsMode,
      languageStore.selectedLocale,
      captchaToken,
    );
  }

  async function storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
    companyInfoValues: SupplierRegistrationCompanyInfoFormFields,
    bankAccountValues: SupplierRegistrationBankAccountFormFields,
    ref: string,
    asyncMaskedData: AsyncInitiateValidationMaskedData,
    captchaToken: string | null | undefined,
  ): Promise<ReducedSupplierRegistrationProcess> {
    const payload = transformFormFieldsToSupplierRegistrationAsyncRequest(
      companyInfoValues,
      bankAccountValues,
      ref,
      asyncMaskedData,
      supplierRegistrationFormStore.isIndividual,
    );

    return await supplierRegistrationFormStore.storeSupplierRegistrationProcessRequestFromAsyncInitiateValidation(
      payload,
      asyncMaskedData.id,
      languageStore.selectedLocale,
      captchaToken,
    );
  }

  return (
    <TransitionLoader loading={isRequestLoading}>
      <PageWithHeader paddingTop='none' widthReactive>
        <PageContainer>
          <AboveCardSpace>
            {renderBackButton()}
            {supplierRegistrationFormStore.currentValidationProcessStep === ValidationProcessSteps.welcome && (
              <LogoCarousel logos={additionalLogos} maxLogosToKeepStatic={5} />
            )}
          </AboveCardSpace>
          {getValidationStepComponent(supplierRegistrationFormStore.currentValidationProcessStep)}
        </PageContainer>
      </PageWithHeader>
    </TransitionLoader>
  );
});

export default SupplierRegistrationFormPage;
