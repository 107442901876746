import { StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { FC, ReactElement } from 'react';

import useInfraStores from '@app/hooks/useInfraStores';
import { cache } from '@emotion/css';
import { CacheProvider, css, Global } from '@emotion/react';
import { observer } from 'mobx-react';
import { theme } from './theme';

const AppThemeProvider: FC = observer(
  ({ children }): ReactElement => {
    const { languageStore, themeStore } = useInfraStores();

    return (
      <>
        <Global styles={themeStore.globalStyles} />
        <Global
          styles={css`
            :root {
              --title-font-family: ${languageStore.selectedLanguageHasDialectCharacters
                ? 'open-sans'
                : 'Nexa, open-sans, arial, SEBSansSerif'};
              --text-font-family: ${languageStore.selectedLanguageHasDialectCharacters
                ? 'open-sans'
                : 'NexaText, open-sans, arial, SEBSansSerif'};
            }
          `}
        />
        <Global styles={themeStore.modeStyles} />
        <CacheProvider value={cache}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={theme(themeStore.theme.actionColor.main)}>{children}</MuiThemeProvider>
          </StyledEngineProvider>
        </CacheProvider>
      </>
    );
  },
);

export default AppThemeProvider;
