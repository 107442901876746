import request, { HttpMethod } from '@app/libs/request';
import { serverUrl } from '@app/config';
import {
  GuestBankAccountCertificateVerifyResultServerResponse,
  GuestPayeeSearchVerifyResultServerResponse,
  GuestSearchPayeeResponse,
} from '@ar/domain/arAccountVerification';
import { translateFileNameToContentType } from '@app/domain/files';
import { removeIrrelevantAccountDetailsFields } from '@app/domain/accountsDetailsHelpers';

export async function searchPayee(searchText: string, limit?: number): Promise<GuestSearchPayeeResponse[]> {
  return request<GuestSearchPayeeResponse[]>(serverUrl, `/api/guest/verifications`, {
    method: HttpMethod.get,
    params: { limit, query: searchText },
    suppressNotification: true,
  });
}

export async function validateAccountByUniformId(
  validatedId: string,
  account: Partial<MorteeAccountDetails>,
): Promise<GuestPayeeSearchVerifyResultServerResponse> {
  const data = { validatedId, accountDetails: removeIrrelevantAccountDetailsFields(account) };

  return request<GuestPayeeSearchVerifyResultServerResponse>(serverUrl, `/api/guest/accounts/uniform-ids`, {
    method: HttpMethod.post,
    data,
    headers: {
      'Api-Version': 'v2',
    },
    errorsHandler: {
      default: {
        message: 'Could not verify',
      },
    },
  });
}

export async function validateAccountWithBankAccountCertificate(
  file: File,
): Promise<GuestBankAccountCertificateVerifyResultServerResponse> {
  const sendForm = new FormData();

  sendForm.append('bac', new Blob([file], { type: translateFileNameToContentType(file.name) }), file.name);

  return request<GuestBankAccountCertificateVerifyResultServerResponse>(serverUrl, `/api/guest/bac`, {
    method: HttpMethod.post,
    isFileUpload: true,
    data: sendForm,
    timeout: 300000,
    suppressNotification: true,
    errorsHandler: {
      default: {
        message: 'Could not validate with certificate',
      },
    },
  });
}

export async function downloadGuestAccountValidationByUniformId(
  validatedId: string,
  account: Partial<MorteeAccountDetails>,
): Promise<NamedResource> {
  const data = { validatedId, accountDetails: removeIrrelevantAccountDetailsFields(account) };

  return request<NamedResource>(serverUrl, `/api/guest/accounts/uniform-ids/download`, {
    method: HttpMethod.post,
    data,
    headers: {
      'Api-Version': 'v2',
    },
    errorsHandler: {
      default: {
        message: 'Cannot download verification report',
      },
    },
  });
}
