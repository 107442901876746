import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { preventForwardTheseProps } from '@app/utils/styledUtils';
import { ColorScheme, colorSchemePresets, getColorScheme } from '@app/domain/theme';

const SmallLoadingSpinner = styled(CircularProgress, { shouldForwardProp: preventForwardTheseProps('colorScheme') })<{
  colorScheme: ColorScheme | undefined;
}>`
  color: ${(p): string => getColorScheme(p.colorScheme, colorSchemePresets.primary).main} !important;
`;

export default SmallLoadingSpinner;
