import React, { FC, ReactElement, useState } from 'react';
import { NormalText, SmallContinueButton } from '@supplierRegistration/routes/Styles';
import { observer } from 'mobx-react';
import * as messageLauncher from '@app/utils/messageLauncher';
import { SupplierValidationRegistrationProps } from '@supplierRegistration/domain/supplierRegistration';
import { generateFinicityConnectLink, validateFinicityAccount } from '@supplierRegistration/services/openBankingService';
import FinicityFlowConnect from '@supplierRegistration/routes/supplierRegistrationProcess/validationSteps/FinicityFlowConnect';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Radio } from '@mui/material';
import SandClockImage from '@supplierRegistration/images/sandclock.svg';
import PointerClickImage from '@supplierRegistration/images/pointer-click.svg';
import SVG from '@app/components/SVG';
import { SubtitleRegularStartTransparentBlack900 } from '@app/components/Text';
import Card from '@app/components/card/Card';
import { HttpStatus, isWebErrorContent, RequestError } from '@app/libs/request';
import InfoImage from '@app/images/designSystem/indication/info.svg';
import useLeaveConfirmation from '@app/hooks/useLeaveConfirmation';
import config from '@app/config';
import useAppStores from '@app/hooks/useAppStores';
import SupplierRegistrationMode from '@supplierRegistration/supplierRegistrationMode';
import Log from '@app/libs/logger';

interface Props extends SupplierValidationRegistrationProps {
  setLoading: (isLoading: boolean) => void;
  onRegularSelect: () => Promise<void>;
  validateFinicityAccountAsync: ((customerId: string) => Promise<void>) | undefined;
}

enum SelectedOption {
  offlineValidation,
  onlineValidation, // Open banking
}

const SupplierRegistrationOpenBankingSelector: FC<Props> = observer((props) => {
  useLeaveConfirmation();

  const [showFinicityConnect, setShowFinicityConnect] = useState(false);
  const [finicityConnectUrl, setFinicityConnectUrl] = useState<string | undefined>(undefined);
  const [finicityCustomerId, setFinicityCustomerId] = useState<string | undefined>(undefined);
  const [selectedOption, setSelectedOption] = useState<SelectedOption>(SelectedOption.onlineValidation);
  const [showInvalidAccountError, setShowInvalidAccountError] = useState<boolean>(false);

  const { supplierRegistrationCustomerDataStore } = useAppStores<SupplierRegistrationMode>();

  const { t } = useTranslation();

  const { onRegularSelect, setLoading, formValues } = props;

  const launchFinicityConnect = async (): Promise<void> => {
    setLoading(true);
    try {
      if (!finicityConnectUrl || !finicityCustomerId) {
        const connectLink = await generateFinicityConnectLink();

        setFinicityCustomerId(connectLink.customerId);
        setFinicityConnectUrl(connectLink.link);
      }

      setShowFinicityConnect(true);
    } catch (e) {
      messageLauncher.shoot(
        {
          type: 'error',
          duration: 5,
        },
        t('supplierValidation.onlineOpenBanking.errors.launchConnect'),
      );
    } finally {
      setLoading(false);
    }
  };

  const onFinicityError = async (): Promise<void> => {
    messageLauncher.shoot(
      {
        type: 'error',
        duration: 5,
      },
      t('supplierValidation.onlineOpenBanking.errors.usingConnect'),
    );
  };

  const onFinicityCancel = async (): Promise<void> => {
    setShowFinicityConnect(false);
  };

  const handleFinicityOk = async (): Promise<void> => {
    if (
      finicityCustomerId &&
      formValues.accountValues?.accountNumber &&
      formValues.accountValues?.bankCode &&
      formValues.accountValues?.abaRouting
    ) {
      try {
        setLoading(true);
        if (props.validateFinicityAccountAsync) {
          await props.validateFinicityAccountAsync(finicityCustomerId);
        } else {
          const shouldUseCaptcha = config.featureFlags?.supplierRegistrationCaptcha && window['grecaptcha'];

          let captchaToken: string | null | undefined = undefined;
          if (config.featureFlags?.supplierRegistrationCaptcha) {
            try {
              captchaToken = await supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.executeAsync();
            } catch (e) {
              Log.event('Cannot load captcha token, sending request without captcha header', e);
            }
          }

          await validateFinicityAccount(
            finicityCustomerId,
            formValues.accountValues.accountNumber,
            formValues.accountValues.bankCode,
            formValues.accountValues.abaRouting,
            captchaToken,
          );

          if (shouldUseCaptcha) {
            supplierRegistrationCustomerDataStore.getCaptchaRef()?.current?.reset();
          }
        }

        await onRegularSelect();
      } catch (e) {
        setLoading(false);
        setFinicityConnectUrl(undefined);
        setFinicityCustomerId(undefined);

        const requestError = e as RequestError;

        if (
          requestError.code === HttpStatus.badRequest &&
          isWebErrorContent(requestError.responseJSON) &&
          requestError.responseJSON.error === 'FINICITY_INVALID_ACCOUNT_OR_ROUTING_NUMBER'
        ) {
          setShowInvalidAccountError(true);
        } else {
          messageLauncher.shoot(
            {
              type: 'error',
              duration: 5,
            },
            t('supplierValidation.onlineOpenBanking.errors.validateAccount'),
          );
        }
      }
    } else {
      messageLauncher.shoot(
        {
          type: 'error',
          duration: 5,
        },
        t('supplierValidation.onlineOpenBanking.errors.missingAccountOrBank'),
      );
    }
  };

  const handleOnContinue = async (): Promise<void> => {
    if (selectedOption == SelectedOption.onlineValidation) {
      await launchFinicityConnect();
    } else {
      await onRegularSelect();
    }
  };

  const renderInvalidAccountError = (): ReactElement => {
    return (
      <ErrorCard>
        <StyledSVG image={InfoImage} accessibilityLabel='' height={49} />
        <ErrorTextContainer>
          <SelectionTitle>{t('supplierValidation.onlineOpenBanking.errors.invalidAccountOrRoutingTitle')}</SelectionTitle>
          <NormalText>{t('supplierValidation.onlineOpenBanking.errors.invalidAccountOrRouting')}</NormalText>
        </ErrorTextContainer>
      </ErrorCard>
    );
  };

  return (
    <StyledMainCard>
      {showInvalidAccountError && renderInvalidAccountError()}
      {showFinicityConnect && finicityConnectUrl && (
        <FinicityFlowConnect
          url={finicityConnectUrl}
          onDone={handleFinicityOk}
          onError={onFinicityError}
          onCancel={onFinicityCancel}
        />
      )}
      <SubTitle>{t('supplierValidation.onlineOpenBanking.inOrderToComplete')}</SubTitle>
      <SelectionTitle>{t('supplierValidation.onlineOpenBanking.chooseValidationMethod')}</SelectionTitle>
      <SelectionContainer role='radiogroup'>
        <SelectionOption
          data-testid='select-online'
          id='select-online'
          onClick={(): void => setSelectedOption(SelectedOption.onlineValidation)}
        >
          <Radio
            color='secondary'
            checked={selectedOption == SelectedOption.onlineValidation}
            aria-labelledby='online-validation-label'
          />
          <SelectionOptionTitleContainer>
            <SelectionTitle>{t('supplierValidation.onlineOpenBanking.onlineValidation')}</SelectionTitle>
          </SelectionOptionTitleContainer>
          <IconContainer>
            <SVG accessibilityLabel='' image={PointerClickImage} height={22} />
          </IconContainer>
          <TextContainer id='online-validation-label'>
            <NormalText>{t('supplierValidation.onlineOpenBanking.usingToConnect')}</NormalText>
            <NormalText>{t('supplierValidation.onlineOpenBanking.youWillBeBankLogin')}</NormalText>
          </TextContainer>
        </SelectionOption>
        <SelectionOption
          data-testid='select-offline'
          id='select-offline'
          onClick={(): void => setSelectedOption(SelectedOption.offlineValidation)}
        >
          <Radio
            color='secondary'
            checked={selectedOption == SelectedOption.offlineValidation}
            aria-labelledby='offline-validation-label'
          />
          <SelectionOptionTitleContainer>
            <SelectionTitle>{t('supplierValidation.onlineOpenBanking.offlineValidation')}</SelectionTitle>
          </SelectionOptionTitleContainer>
          <IconContainer>
            <SVG accessibilityLabel='' image={SandClockImage} height={22} />
          </IconContainer>
          <TextContainer id='offline-validation-label'>
            <NormalText>{t('supplierValidation.onlineOpenBanking.youWillBeMiniPayment')}</NormalText>
          </TextContainer>
        </SelectionOption>
      </SelectionContainer>
      <ContinueButtonContainer>
        <SmallContinueButton id='continue-button' onClick={handleOnContinue} disabled={selectedOption === undefined}>
          {t('general.upperContinue')}
        </SmallContinueButton>
      </ContinueButtonContainer>
    </StyledMainCard>
  );
});

export default SupplierRegistrationOpenBankingSelector;

const SelectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;

  margin-top: 30px;
  margin-bottom: 20px;
`;

const SelectionOption = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-column-gap: 5px;

  padding: 10px;
  background-color: var(--inner-card-background-color-grey);

  &:hover {
    background-color: var(--gray-solid-200);
    cursor: pointer;
  }

  transition: background-color 0.5s ease;
  border-radius: 4px;
`;

const SelectionOptionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-color: #dddddd;
  border-width: 1px;
  border-style: solid;
  width: 72px;
  height: 48px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / -1;
  grid-row: 2;
  margin-top: 5px;
`;

const SelectionTitle = styled(SubtitleRegularStartTransparentBlack900.div)``;

const SubTitle = styled.div`
  color: var(--transparent-black-600);
  font-size: 16px;
`;

const ContinueButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

const StyledMainCard = styled(Card)`
  font-weight: 200;
  padding: 20px 20px 20px;
  line-height: 1.4;
  transition: none;
`;

const ErrorCard = styled.div`
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  border-color: var(--primary-200);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 var(--transparent-black-400);
`;

const ErrorTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const StyledSVG = styled(SVG)`
  align-self: center;
  color: var(--primary-200);
`;
