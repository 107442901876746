import { isTruthy } from '@app/utils/utils';
import { compare } from '@app/utils/comparatorUtils';

export function capitalizeFirstLetter(string: string = ''): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trim(value: string): string;
export function trim(value: string | undefined): string | undefined;
export function trim(value: string | undefined): string | undefined {
  return value?.trim();
}

export function trimToUndefined(value: string | null | undefined): string | undefined {
  return trimToFallback(value, undefined);
}

export function trimToNull(value: string | null | undefined): string | null {
  return trimToFallback(value, null);
}

export function trimToFallback<TFallback>(value: string | null | undefined, fallback: TFallback): string | TFallback {
  return value?.trim() || fallback;
}

export function caseInsensitiveEquals(str1: string, str2: string): boolean {
  return compare.stringsCaseInsensitive(false)(str1, str2) === 0;
}

export function pluralize(
  singular: string,
  itemAmountOrIsPlural: number | boolean | undefined,
  pluralForm: string = `${singular}s`,
): string {
  const isSingular = itemAmountOrIsPlural === 1 || itemAmountOrIsPlural === false;

  return isSingular ? singular : pluralForm;
}

export function concatAllPairs(arr1: string[], arr2: string[]): string[] {
  return arr1.flatMap((strFromArr1): string[] => {
    return arr2.map((strFromArr2): string => `${strFromArr1}${strFromArr2}`);
  });
}

export function replaceAll(value: string, searchValue: string | RegExp, replaceValue: string): string {
  if (typeof searchValue === 'string') {
    return value.replace(new RegExp(searchValue, 'g'), replaceValue);
  }

  return value.replace(searchValue, replaceValue);
}

export function splitByMultiple(value: string, separators: string[] | undefined): string[] {
  if (!separators?.length || !separators.some(isTruthy)) {
    return [value];
  }

  const separatorSplitRegex = new RegExp(
    separators.map((separator) => (separator.match(/[a-zA-Z0-9]/) ? separator : `\\${separator}`)).join('|'),
  );

  return value.split(separatorSplitRegex);
}

export function length(value: string | undefined | null): number {
  return value?.length ?? 0;
}

export function removeChars(string: string | undefined | null, charsToIgnore: string[]): string | undefined | null {
  return string ? charsToIgnore.reduce((result, char) => result.split(char).join(''), string) : string;
}

/**
 * Removes specified characters and converts the input string to lowercase.
 * @param string - The input string to process.
 * @param charsToIgnore - An array of characters to remove from the string.
 * @returns The processed string in lowercase or undefined if the input was undefined.
 */
export function removeCharsAndLowerCase(string: string | undefined | null, charsToIgnore: string[]): string | undefined | null {
  const stringWithRemovedChars = removeChars(string, charsToIgnore);
  return stringWithRemovedChars ? stringWithRemovedChars.toLowerCase() : string;
}

export function removeCharsAndUpperCase(string: string | undefined | null, charsToIgnore: string[]): string | undefined | null {
  const stringWithRemovedChars = removeChars(string, charsToIgnore);
  return stringWithRemovedChars ? stringWithRemovedChars.toUpperCase() : string;
}

/**
 * Creates a case-insensitive equality check function that ignores specified characters.
 * @param charsToIgnore - An array of characters to ignore during the comparison.
 * @returns A function that compares two strings for equality.
 */
export function createEqualityCheckFunctionCaseInsensitiveIgnoredChars(charsToIgnore: string[]) {
  return (string1: string | undefined, string2: string | undefined): boolean => {
    const cleanedString1 = removeCharsAndLowerCase(string1, charsToIgnore);
    const cleanedString2 = removeCharsAndLowerCase(string2, charsToIgnore);
    return cleanedString1 === cleanedString2;
  };
}

export function removeFirstLeadingKnoxPrefix(value: string): string {
  const knoxPrefix = /^knox/i;
  return value.replace(knoxPrefix, '');
}

export function parseNestedString(input: string): string[] {
  const result = input
    .split(/\.|\[|\]/)
    .map(trimToUndefined)
    .filter(isTruthy);
  return result;
}

export function combinePaths(baseUrl: string, path: string | null | undefined): string {
  if (baseUrl.endsWith('/')) {
    // Remove last char
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  if (path?.startsWith('/')) {
    path = path?.substring(1);
  }

  if (!path) {
    return baseUrl;
  }

  return `${baseUrl}/${path}`;
}

export function cleanPathFromUrl(serverUrl: string): string {
  const url = new URL(serverUrl);
  return `${url.protocol}//${url.host}`;
}
