import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import NakedFormInput from '@app/components/inputs/NakedFormInput';
import { SupplierRegistrationField } from '@supplierRegistration/domain/supplierRegistrationAccountDetailsFields';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';

interface Props {
  branchCodeField: SupplierRegistrationField | undefined;
  accountCountryCode: string | undefined;
  fieldDecorators: any; // Replace with proper type
  asyncInitiateValidationMaskedBankCodeAndBranchCode: {
    branchCodeState: string;
  };
}

export const BranchAccountDomesticField = ({
  branchCodeField,
  accountCountryCode,
  fieldDecorators,
  asyncInitiateValidationMaskedBankCodeAndBranchCode,
}: Props): ReactElement | null => {
  const { t } = useTranslation();

  if (asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'hidden') {
    return null;
  }

  if (asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'disabled') {
    branchCodeField = {
      id: 'inpt-supp-reg-branch-code',
      inputType: 'text',
      decoratorRules: [],
    };
  }

  if (!branchCodeField) {
    return null;
  }

  return (
    <SupplierRegistrationFormItemBox
      fieldName='branchCode'
      fieldDecoratorOptions={fieldDecorators.branchCode}
      hasPrefilledData={asyncInitiateValidationMaskedBankCodeAndBranchCode.branchCodeState === 'disabled'}
    >
      <NakedFormInput
        name={branchCodeField.id}
        dataTestId={branchCodeField.id}
        type={branchCodeField.inputType}
        placeholder={t<string>([
          `supplierValidation.supplierRegister.${accountCountryCode}.branchCode`,
          'supplierValidation.supplierRegister.branchCode',
        ])}
        disableSuggestion
      />
    </SupplierRegistrationFormItemBox>
  );
};

export default BranchAccountDomesticField;
