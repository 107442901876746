import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { isDefined } from '@app/utils/utils';
import {
  SupplierValidationAccountVerificationRecord,
  SupplierValidationPayeeVerificationRecord,
  SupplierValidationRecord,
} from '@mortee/domain/validationSystem';
import { useHeightCoordinator } from '@app/hooks/useHeightCoordinator';
import VerificationPayeeChanges from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationPayeeChanges';
import VerificationAccountChanges from '@mortee/routes/validationSystem/editValidtionRecord/mainTabs/verification/reviewModals/VerificationAccountChanges';

export type PayeeAndAccountsWithWarnings = {
  payee: {
    renderedWarnings: ReactNode[];
    record: SupplierValidationPayeeVerificationRecord | null;
  };
  accounts: {
    renderedWarnings: ReactNode[];
    record: SupplierValidationAccountVerificationRecord;
  }[];
};

interface Props {
  payeeWithAccountsWarnings: PayeeAndAccountsWithWarnings;
  supplierValidationRecord: SupplierValidationRecord;
  className?: string;
}

const VerificationPayeeAndAccountsChanges: FC<Props> = ({ payeeWithAccountsWarnings, supplierValidationRecord, className }) => {
  const heightCoordinator = useHeightCoordinator();

  const payeeRenderedAlerts = payeeWithAccountsWarnings.payee.renderedWarnings.filter(isDefined);

  // Gather all payeeWithAccountsWarnings.accounts and check the visibility level of each account
  // If there is only 1 account - show nothing
  // If there is more than 1 account - check if all visibility levels are the same - if they are, show nothing
  // If they are not, show a notice that the accounts have different visibility levels:

  const accountsVisibilityLevels = new Set(payeeWithAccountsWarnings.accounts.map((account) => account.record.isPrivate));

  const accountsHaveDifferentVisibilityLevels = accountsVisibilityLevels.size > 1;

  return (
    <Main className={className}>
      {payeeWithAccountsWarnings.payee.record && (
        <PaddedVerificationPayeeChanges
          payee={payeeWithAccountsWarnings.payee.record}
          renderedWarnings={payeeRenderedAlerts}
          supplierValidationRecord={supplierValidationRecord}
        />
      )}
      <AccountsGrid>
        {payeeWithAccountsWarnings.accounts.map((account, index) => (
          <VerificationAccountChanges
            key={account.record.staticId}
            heightCoordinator={heightCoordinator}
            account={account.record}
            accountNumber={index + 1}
            payee={payeeWithAccountsWarnings.payee.record}
            renderedWarnings={account.renderedWarnings.filter(isDefined)}
            supplierValidationRecord={supplierValidationRecord}
            visibilityGap={accountsHaveDifferentVisibilityLevels}
          />
        ))}
      </AccountsGrid>
    </Main>
  );
};

export default VerificationPayeeAndAccountsChanges;

const Main = styled.div`
  --items-distance: 12px;
`;

const PaddedVerificationPayeeChanges = styled(VerificationPayeeChanges)`
  margin: var(--items-distance);
`;

const AccountsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
  grid-auto-rows: 1fr;
  gap: var(--items-distance);

  margin: var(--items-distance);
`;
