import React, { FunctionComponent, useState } from 'react';
import Button from '@app/components/Button';
import { Drawer } from '@mui/material';
import CustomerKit from '@mortee/routes/ips/customerKit/CustomerKit';
import styled from '@emotion/styled';

interface Props {
  className?: string;
}

const CustomerKitButton: FunctionComponent<Props> = ({ className }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledButton id='btn-ips-customer-kit-open' onClick={(): void => setOpen(true)} className={className}>
        CUSTOMER KIT
      </StyledButton>
      <Drawer anchor='right' open={open} onClose={(): void => setOpen(false)}>
        <CustomerKit onClose={(): void => setOpen(false)} />
      </Drawer>
    </>
  );
};

export default CustomerKitButton;

const StyledButton = styled(Button)`
  padding: 0.6em 1.2em;
`;
