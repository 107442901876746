import { LocaleAwareDropdownItem } from '@app/components/LocaleAwareComponents';
import FormRadioGroup from '@app/components/inputs/FormRadioGroup';
import { isDefined } from '@app/utils/utils';
import styled from '@emotion/styled';
import SupplierRegistrationFormItemBox from '@supplierRegistration/components/SupplierRegistrationFormItemBox';

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: row;
  align-items: start;
  grid-column-gap: 40px;
`;

export const CountryDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

export const FirstInputContainer = styled.div`
  flex: 3;
`;

export const FixedInputContainer = styled.div`
  width: 115px;
  margin-right: 16px;
`;

export const SecondInputContainer = styled.div`
  flex: 7;
`;

export const LEITypeDropdownItem = styled(LocaleAwareDropdownItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.1px;
  color: var(--transparent-black-600);
`;

export const MultiInputsContainer = styled.div<{ isLTRFixed?: boolean; areChildrenRTL?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(p): string => (p.isLTRFixed ? `direction: ltr;` : '')}
  div {
    ${(p): string => (isDefined(p.areChildrenRTL) ? `direction: ${p.areChildrenRTL ? 'rtl' : 'ltr'};` : '')}
  }
`;

export const InputBuffer = styled.div`
  width: 1px;
  height: 32px;
  color: var(--transparent-black-200);
`;

export const StyledFormRadioGroup = styled(FormRadioGroup)`
  margin-bottom: 22px;
  width: max-content;

  .MuiFormControlLabel-root {
    margin-inline-end: 16px !important;
    margin-inline-start: -11px !important;
  }
`;

export const RadioOptionLabel = styled.div`
  color: var(--solid-black);
  font-size: 18px;
`;

export const DateOfBirth = styled.div`
  position: relative;
  top: 4px;
`;

export const TransparentSupplierRegistrationFormItemBox = styled(SupplierRegistrationFormItemBox)<{ shouldBeHidden: boolean }>`
  ${(p): string => (p.shouldBeHidden ? 'display: none;' : '')}
`;
