import { ModalBody, ModalTextLine, ModalTitle, WrappingModalContainer } from '@app/components/Modal';
import { Checkbox } from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';
import { ModalButton } from '@app/components/Button';
import styled from '@emotion/styled';
import Card from '@app/components/card/Card';
import ModalAppContext, { ModalAppContextProps } from '@app/ModalAppContext';

export interface SuspendUserConfirmationResult {
  confirm: boolean;
  killUserSessions?: boolean;
}

interface Props {
  userName: string | null;
  onResult(result: SuspendUserConfirmationResult): void;
  modalContext: ModalAppContextProps;
}

const SuspendUserConfirmationModal: FC<Props> = (props): ReactElement => {
  const { userName, modalContext, onResult } = props;

  const [killUserSessions, setKillUserSessions] = useState<boolean>(true);

  return (
    <Container>
      <ModalAppContext {...modalContext}>
        <WrappingModalContainer>
          <ModalTitle>Suspend {userName} From Organization?</ModalTitle>
          <ModalBody>
            <ModalTextLine>
              This will prevent {userName} from seeing the organization and prevent them from performing further actions, other
              users in the organization will be able to see the {userName}’s past actions
            </ModalTextLine>
            <div>
              <Checkbox
                id='cbox-kill-user-sessions'
                data-testid='cbox-kill-user-sessions'
                checked={killUserSessions}
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setKillUserSessions(event.currentTarget.checked)}
              />
              Also, kill all the sessions for this user
            </div>
            <ActionContainer>
              <StyledModalButton
                id='cancel-kill-user-sessions-modal'
                appearance='outline'
                onClick={(): void => onResult({ confirm: false })}
              >
                No
              </StyledModalButton>
              <StyledModalButton
                id='confirm-kill-user-sessions-modal'
                colorScheme='primary'
                onClick={(): void => onResult({ confirm: true, killUserSessions })}
              >
                YES
              </StyledModalButton>
            </ActionContainer>
          </ModalBody>
        </WrappingModalContainer>
      </ModalAppContext>
    </Container>
  );
};

export default SuspendUserConfirmationModal;

const ActionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Container = styled(Card)`
  width: 550px;
  padding: 16px 24px;
`;

const StyledModalButton = styled(ModalButton)`
  width: 100%;

  &:first-child {
    margin-right: 9px;
  }
`;
