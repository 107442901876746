import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import AsyncButton from '@app/components/AsyncButton';
import validationSystemServices from '@mortee/services/validationSystemServices';
import { trim } from '@app/utils/stringUtils';
import NakedDropdown from '@app/components/inputs/NakedDropdown';
import config from '@app/config';
import DropdownItem from '@app/components/inputs/DropdownItem';
import FormlessItemBox from '@app/components/inputs/FormlessItemBox';
import { SUPPLIER_VALIDATION_FIELD_MAX_LENGTH } from '@mortee/domain/validationSystemFields';
import { Checkbox } from '@mui/material';
import { CommentTypes } from '@mortee/domain/validationSystem';
import NakedDropdownInnerInputTextArea from '@app/components/inputs/dropdownInnerInput/NakedDropdownInnerInputTextArea';
import useRunningComponentKey from '@app/hooks/useRunningComponentKey';

export type NewCommentTypesWhitelist = 'public-or-internal' | 'internal-validation';

interface Props {
  onCommentSaved(): void;
  staticId: string;
  allowedCommentTypes?: NewCommentTypesWhitelist;
  autoFocus?: boolean;
  className?: string;
}

const SupplierValidationRecordActivityLogCommentInput: FC<Props> = ({
  onCommentSaved,
  staticId,
  allowedCommentTypes,
  autoFocus,
  className,
}) => {
  const [comment, setComment] = useState('');
  const [isInternalComment, setIsInternalComment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commentInputKey, nextCommentInputKey] = useRunningComponentKey('comment-input');

  function commentHasContent(): boolean {
    return !!trim(comment);
  }

  const calculateError = (): string | undefined => {
    if (comment.length > SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.comment) {
      return `max ${SUPPLIER_VALIDATION_FIELD_MAX_LENGTH.comment} characters`;
    }
  };

  function calculateCommentType(): CommentTypes {
    if (allowedCommentTypes === 'internal-validation') {
      return CommentTypes.internalValidation;
    }

    if (isInternalComment) {
      return CommentTypes.internalComment;
    }

    return CommentTypes.publicComment;
  }

  async function handleSubmit(): Promise<void> {
    if (!commentHasContent()) {
      return;
    }
    setIsSubmitting(true);
    await validationSystemServices.postValidationComment(staticId, {
      comment: comment,
      commentType: calculateCommentType(),
    });
    setComment('');
    nextCommentInputKey();
    setIsInternalComment(false);
    setIsSubmitting(false);
    onCommentSaved();
  }

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Container className={className}>
      <FormlessItemBox underText={calculateError()} appearance='none' hasValue={!!comment} onKeyPress={handleKeyPress}>
        <NakedDropdown
          key={commentInputKey}
          accessibilityLabel='comment'
          name='inpt-svm-add-comment'
          dataTestId='inpt-svm-add-comment'
          placeholder='Comment'
          placeholderStyle='onlyWhenEmpty'
          value={comment}
          onChange={(newValue): void => setComment(newValue || '')}
          heightType='thin'
          popupWidth='auto'
          isSearchable
          freeSolo
          autoFocus={autoFocus}
          disabled={isSubmitting}
          InnerInputComponent={NakedDropdownInnerInputTextArea}
        >
          {allowedCommentTypes === 'internal-validation'
            ? []
            : config.svManagement.commonCommentList.map((knownComment) => {
                return (
                  <DropdownItem value={knownComment} key={knownComment} textWhenSelected={knownComment}>
                    {knownComment}
                  </DropdownItem>
                );
              })}
        </NakedDropdown>
      </FormlessItemBox>
      <Actions>
        {allowedCommentTypes === 'internal-validation' ? (
          <CheckboxContainer>
            <Checkbox
              id='cbox-is-internal-validation-comment'
              data-testid='cbox-is-internal-validation-comment'
              checked
              disabled
              onClick={(): void => {}}
            />
            Post as internal verification comment
          </CheckboxContainer>
        ) : (
          <CheckboxContainer onClick={(): void => setIsInternalComment(!isInternalComment)}>
            <Checkbox
              id='cbox-is-internal-comment'
              data-testid='cbox-is-internal-comment'
              checked={isInternalComment}
              onClick={(): void => setIsInternalComment(!isInternalComment)}
            />
            Post as internal comment
          </CheckboxContainer>
        )}
        <AsyncButton
          id='btn-timeline'
          dataTestId='btn-timeline-comment'
          onClick={(): Promise<void> => handleSubmit()}
          size='small'
          disabled={!commentHasContent()}
        >
          SEND
        </AsyncButton>
      </Actions>
    </Container>
  );
};

export default SupplierValidationRecordActivityLogCommentInput;

const Container = styled.div`
  position: relative;
  padding: 16px;

  border: solid 1px var(--transparent-black-200);
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-end;
`;

const CheckboxContainer = styled.div`
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;
